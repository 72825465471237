import { useEffect, useState } from "react";
import "../../styles/AddUserDetails.scss";
import { baseURL } from "../BaseUrl";
import * as swal from "../Consturl/SwalAlert";
import BulkUploadComp from "../ReUseComponents/BulkUploadComp";

const AddBulkClientData = (props) => {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const salesbeeImgAuth = { Travelize_Authentication: userDetails };

  const [bulkUploadResponse, setBulkUploadResponse] = useState({
    res: null,
    message: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [uploadExcelFile, setUploadExcelFile] = useState("");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadExcelFile(fileUploaded);
  };

  useEffect(() => {
    setUploadExcelFile("");
    setBulkUploadResponse({ res: "", message: "" });
  }, [props.show, props.handleCloseBulkClient]);

  const addBulkClient = () => {
    if (uploadExcelFile !== null || uploadExcelFile !== "") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("Files", uploadExcelFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Import/ImportCustomer?Directory=BulkClientUploads`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setBulkUploadResponse({ res: res.success, message: res.message });
            props.handleCloseBulkClient();
            setIsLoading(false);
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            setBulkUploadResponse({
              res: res.success,
              message: res.errorMessage,
            });
            setIsLoading(false);
          }
        });
    }
  };

  const closeModel = () => {
    props.handleCloseBulkClient();
  };

  return (
    <div>
      <BulkUploadComp
        pageFrom="Client"
        show={props.show}
        closeModel={closeModel}
        heading="ADD BULK COMPANY"
        handleChange={handleChange}
        downloadTemplateUrl={
          "BulkUploadTemplate/Excel_BulkClient_20231026174321729.xlsx"
        }
        uploadClick={addBulkClient}
        bulkUploadResponse={bulkUploadResponse}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AddBulkClientData;
