import { useDispatch } from "react-redux";
import "../styles/CommonStyle.scss";
import { loadThemeColor } from "../redux/action";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DarkMode = () => {
  let dispatch = useDispatch();
  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  const storedTheme = localStorage.getItem("theme");

  const prefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const defaultDark =
    storedTheme === "dark" || (storedTheme === null && prefersDark);

  if (defaultDark) {
    setDark();
  }

  const toggleTheme = (e) => {
    if (e.target.checked) {
      dispatch(loadThemeColor("dark"));
      setDark();
    } else {
      dispatch(loadThemeColor("light"));
      setLight();
    }
  };

  return (
    <div className="toggle-theme-wrapper dnone-mobile-view">
      <OverlayTrigger
        delay={{ hide: 450, show: 300 }}
        overlay={(props) => (
          <Tooltip className="theme-tooltip" {...props}>
            {defaultDark ? "Purple Theme" : "Green Theme"}
          </Tooltip>
        )}
        placement="bottom"
      >
        {/* toggle button */}
        <label className="switch" style={{ cursor: "pointer" }}>
          <input
            style={{ cursor: "pointer" }}
            className="switch__input"
            type="checkbox"
            role="switch"
            onChange={toggleTheme}
            defaultChecked={defaultDark}
          />

          <span className="switch__sr">Dark Mode</span>
        </label>
      </OverlayTrigger>
      {/* toggle button */}
    </div>
  );
};

export default DarkMode;
