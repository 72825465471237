import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import * as HeaderData from "../../HeaderAuthentication";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import "../../../styles/category.scss";
import "../../../styles/AddEditModel.scss";
import Select from "react-select";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { travelizeAuth } from "../../HeaderAuthentication";
import { baseURL } from "../../BaseUrl";
import * as swal from "../../Consturl/SwalAlert";

function AddField(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [fieldType, setFieldType] = useState("");
  const [fileAccept, setFileAccept] = useState("");
  const [required, setRequired] = useState(true);
  const [validation, setValidation] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [options, setOptions] = useState("");
  const [numberOfRows, setNumberOfRows] = useState("");
  const [numberOfColumns, setNumberOfColumns] = useState("");
  const [minimum, setMinimum] = useState("");
  const [maximum, setMaximum] = useState("");
  const [count, setCount] = useState(1);
  const [optionCount, setOptionCount] = useState([
    { id: 1, value: "text", label: "Text" },
  ]);

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const fieldTypes = [
    { value: "Text", label: "Text" },
    { value: "Email", label: "Email" },
    { value: "Phone", label: "Phone" },
    { value: "Password", label: "Password" },
    { value: "Number", label: "Number" },
    { value: "Text Area", label: "Text Area" },
    { value: "Drop Down", label: "Drop Down" },
    { value: "Check Box", label: "Check Box" },
    { value: "Radio", label: "Radio" },
    { value: "File", label: "File" },
    { value: "Date", label: "Date" },
  ];

  const fileTypes = [
    { value: "file", label: "ALL Type" },
    { value: ".png, .jpg, .jpeg", label: "JPEG/PNG" },
    { value: ".pdf", label: "PDF" },
  ];

  const offOnSwitch = () => {
    setRequired(!required);
  };

  const validationChange = () => {
    setValidation(!validation);
  };

  const OptionCount = () => {
    let newId = count + 1;
    setCount(newId);
    setOptionCount([
      ...optionCount,
      { id: newId, value: "text", label: "Text" },
    ]);
  };

  const removeOption = (i) => {
    let newId = count - 1;
    setCount(newId);
    optionCount.splice(i, 1);
  };

  const setOptionsVal = (e, index) => {
    let optionData = optionCount.filter(
      (data, i) => data.id == e.target.id && i == index
    );
    optionData[0].label = e.target.value;
    optionData[0].value = e.target.value;
    setOptionCount([...optionCount]);
  };

  // console.log("COUNT:", newOptionsData);

  const addFieldsToForm = () => {
    let newOptionsData = optionCount.map((data) => data.label).toString();
    if (labelName !== "" && fieldType !== "") {
      let fieldsData = {
        subscriptionID: resPassData.subscriptionId,
        status: 1,
        addedByID: resPassData.userId,
        customePageID: props.customPageId,
        fieldName: labelName,
        fieldType: fieldType,
        fieldOptions:
          fieldType == "Radio" ||
          fieldType == "Check Box" ||
          fieldType == "Drop Down"
            ? newOptionsData
            : "",
        placeHolder: placeholder,
        isRequired: required ? 1 : 0,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(fieldsData),
      };
      fetch(`${baseURL}/api/CustomeFields/AddCustomeFields`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            console.log("FIELD-RES::", res.result);
            props.handleClose();
            props.fetchFormDetailsByFormName();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            props.handleClose();
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };

  return (
    <div>
      <Modal show={props.show} className="main_model add-edit-model">
        <div className="add-edit-heading">
          <h6 className="add-edit-head">ADD FIELDS</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>

        <form>
          <div className="container add-edit mt-3">
            <div className="change-text-fields-part">
              <div className="model-inputs mb-3">
                <FloatingLabel
                  label="Enter Label Name"
                  className={`${
                    labelName
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    type="text"
                    className="change-model"
                    placeholder="Label Name"
                    autoComplete="off"
                    value={labelName}
                    required
                    onChange={(e) => setLabelName(e.target.value)}
                  />
                </FloatingLabel>
                {fieldReq && labelName == "" ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="model-inputs mb-3">
                <FloatingLabel
                  label="Select Type"
                  className={`${
                    fieldType
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                  style={{ width: "100%" }}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    defaultValue={fieldTypes[null]}
                    options={fieldTypes}
                    placeholder="Field Type"
                    value={fieldTypes.value}
                    onInputChange={fieldTypes.label}
                    onChange={(data) => setFieldType(data.value)}
                    isSearchable={true}
                    required
                  />
                </FloatingLabel>
                {fieldReq && fieldType == "" ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>
              {fieldType === "Drop Down" ||
              fieldType === "Check Box" ||
              fieldType === "Radio" ? (
                <div className="options-part">
                  {optionCount.map((item, i) => (
                    <div className="model-inputs dflex-align-center mb-3">
                      <div className="option-label">
                        <FloatingLabel
                          label={`Option-${i + 1}`}
                          className={`${
                            item.value
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                          style={{ width: "100%" }}
                        >
                          <Form.Control
                            type="text"
                            id={item.id}
                            className="options-model"
                            placeholder="Placeholder"
                            autoComplete="off"
                            value={item.value}
                            required
                            onChange={(event) => setOptionsVal(event, i)}
                          />
                        </FloatingLabel>
                      </div>
                      {/* <div className="option-value">
                        <FloatingLabel
                          label={`OptionValue-${i + 1}`}
                          className={`${
                            options
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                          style={{ width: "100%" }}
                        >
                          <Form.Control
                            type="text"
                            id={item.id}
                            className="options-model"
                            placeholder="Placeholder"
                            autoComplete="off"
                            value={options}
                            required
                            onChange={(e) => setOptions(e.target.value)}
                          />
                        </FloatingLabel>
                      </div> */}
                      <div className="remove-option">
                        <i
                          className="fa fa-times"
                          onClick={() => removeOption(i)}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="add-option-icon" onClick={OptionCount}>
                    <span className="plus-icon">
                      <i className="fa fa-plus" />
                    </span>
                    <span className="add-option-text">Add Option</span>
                  </div>
                </div>
              ) : (
                <div className="model-inputs mb-3">
                  <FloatingLabel
                    label="Enter Placeholder"
                    className={`${
                      placeholder
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <Form.Control
                      type="text"
                      className="change-model"
                      placeholder="Placeholder"
                      autoComplete="off"
                      value={placeholder}
                      required
                      onChange={(e) => setPlaceholder(e.target.value)}
                    />
                  </FloatingLabel>
                  {fieldReq && placeholder == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              )}

              {/* {fieldType === "Text Area" ? (
                <div className="model-inputs mb-3">
                  <Row>
                    <Col>
                      <FloatingLabel
                        label="Rows"
                        className={`${
                          numberOfRows
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <Form.Control
                          type="number"
                          className="input-model"
                          placeholder="Placeholder"
                          autoComplete="off"
                          value={numberOfRows}
                          required
                          onChange={(e) => setNumberOfRows(e.target.value)}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel
                        label="Columns"
                        className={`${
                          numberOfColumns
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <Form.Control
                          type="number"
                          className="input-model"
                          placeholder="Placeholder"
                          autoComplete="off"
                          value={numberOfColumns}
                          required
                          onChange={(e) => setNumberOfColumns(e.target.value)}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )} */}

              <div className="model-inputs mb-3">
                <div className="d-flex">
                  <Form.Label>Required</Form.Label> &nbsp; &nbsp;
                  <Form.Check
                    type="switch"
                    label={required ? "Mandatory" : "Optional"}
                    checked={required}
                    onChange={offOnSwitch}
                  />
                </div>
              </div>

              {fieldType === "Password" ||
              fieldType === "Number" ||
              fieldType === "File" ? (
                <div className="model-inputs mb-3">
                  <div className="d-flex">
                    <Form.Label>Validation</Form.Label> &nbsp; &nbsp;
                    <Form.Check
                      type="switch"
                      label={validation ? "Yes" : "No"}
                      checked={validation}
                      onChange={validationChange}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* {validation && fieldType === "Password" ? (
                <div className="model-inputs mb-3">
                  <Row>
                    <Col>
                      <FloatingLabel
                        label="Minimum"
                        className={`${
                          minimum
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <Form.Control
                          type="number"
                          className="input-model"
                          placeholder="Placeholder"
                          autoComplete="off"
                          value={minimum}
                          required
                          onChange={(e) => setMinimum(e.target.value)}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel
                        label="Maximum"
                        className={`${
                          maximum
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <Form.Control
                          type="number"
                          className="input-model"
                          placeholder="Placeholder"
                          autoComplete="off"
                          value={maximum}
                          required
                          onChange={(e) => setMaximum(e.target.value)}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}

              {validation && fieldType === "Number" ? (
                <div className="model-inputs mb-3">
                  <Row>
                    <Col>
                      <FloatingLabel
                        label="Minimum"
                        className={`${
                          minimum
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <Form.Control
                          type="number"
                          className="input-model"
                          placeholder="Placeholder"
                          autoComplete="off"
                          value={minimum}
                          required
                          onChange={(e) => setMinimum(e.target.value)}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel
                        label="Maximum"
                        className={`${
                          maximum
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                        style={{ width: "100%" }}
                      >
                        <Form.Control
                          type="number"
                          className="input-model"
                          placeholder="Placeholder"
                          autoComplete="off"
                          value={maximum}
                          required
                          onChange={(e) => setMaximum(e.target.value)}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )} */}

              {validation && fieldType === "File" ? (
                <div className="model-inputs mb-3">
                  <FloatingLabel
                    label="File Type"
                    className={`${
                      fileAccept
                        ? "float-visible-select"
                        : "float-hidden float-select"
                    }`}
                    style={{ width: "100%" }}
                  >
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      defaultValue={fileTypes[null]}
                      options={fileTypes}
                      placeholder="Field Type"
                      value={fileTypes.value}
                      onInputChange={fileTypes.label}
                      onChange={(data) => setFileAccept(data.value)}
                      isSearchable={true}
                      required
                    />
                  </FloatingLabel>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={addFieldsToForm} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}

export default AddField;
