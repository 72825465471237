import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/AddEditModel.scss";
import "../../styles/CommonStyle.scss";
import Form from "react-bootstrap/Form";
import moment from "moment";
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Row } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import {
  loadClientDropDown,
  loadGetSingleClient,
  loadOtherContacts,
  loadPostClientComplaint,
  loadProductsDropDown,
  loadUploadComplaintImages,
  loadUserByClient,
  updateComplaintStatus,
} from "../../redux/action";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { priorityStatus, warrantyStatus } from "../DropdownsData";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import { formatDateToIST } from "../FormattedAmount";
import { baseURL } from "../BaseUrl";
import AddEditFormComponent from "../ReUseComponents/AddEditFormComponent";

function AddComplaint(props) {
  let compaintId = props.compaintId;
  let dispatch = useDispatch();
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const { productDropdown } = useSelector((state) => state.data);
  const { clientDropdown } = useSelector((state) => state.data);
  const { contactByCompanyDrop } = useSelector((state) => state.data);
  const { userDropdownByCompany } = useSelector((state) => state.data);
  const { singleClientCompaint } = useSelector((state) => state.data);
  const { singleClient } = useSelector((state) => state.data);

  const [localImage, setLocalImage] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [companyData, setCompanyData] = useState({ value: "", label: "" });
  const [reportedData, setReportedData] = useState({ value: "", label: "" });
  const [complaintDate, setComplaintDate] = useState(new Date());
  const [selectProduct, setSelectProduct] = useState({ value: "", label: "" });
  const [selectWarranty, setSelectWarranty] = useState({
    value: 1,
    label: "Yes",
  });
  const [selectPriority, setSelectPriority] = useState({
    value: 1,
    label: "Low",
  });
  const [selectAssign, setSelectAssign] = useState({ value: "", label: "" });

  const [isError, setIsError] = useState(false);
  const [phoneLength, setPhoneLength] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    dispatch(loadProductsDropDown());
    dispatch(loadClientDropDown());
  }, []);

  // useEffect(() => {
  //   dispatch(loadUserByClient(singleClientCompaint.clientIDFk));
  // }, [])

  const hiddenFileInput = useRef(null);
  const [complaintData, setComplaintData] = useState({
    subscriptionID: resPassData.subscriptionId,
    userID: resPassData.userId,
    userFullName: resPassData.fullName,
    addedByID: "",
    addedByUserFullName: "",
    addedOn: "",
    status: 0,
    clientIDFk: 0,
    date: moment(new Date()).format("DD/MM/YYYY"),
    productIDFK: "",
    clientEmail: "",
    clientPhone: "",
    issue: "",
    description: "",
    issueImage: "",
    inWarranty: 1,
    address: "",
    priority: 1,
    assingnedTo: "",
    adttenedByID: "",
    reportedBy: "",
    adttenedOn: "",
    solution: "",
    solutionImage: "",
  });

  const { issue, description, clientEmail, clientPhone, address } =
    complaintData;

  useEffect(() => {
    if (compaintId && singleClientCompaint) {
      console.log(singleClientCompaint, "singleClientCompaint");
      setComplaintData(singleClientCompaint);
      if (singleClientCompaint.date) {
        let comDate = formatDateToIST(singleClientCompaint.date);
        setComplaintDate(new Date(comDate));
        productDropdown.map((prod) => {
          if (prod.label === singleClientCompaint.productName) {
            setSelectProduct(prod);
          }
        });
        clientDropdown.map((comp) => {
          if (comp.value === singleClientCompaint.clientIDFk) {
            dispatch(loadOtherContacts(comp.value));
            dispatch(loadUserByClient(comp.value));
            setCompanyData(comp);
          }
        });
        warrantyStatus.map((warranty) => {
          if (warranty.value === singleClientCompaint.inWarranty) {
            setSelectWarranty(warranty);
          }
        });
        priorityStatus.map((prior) => {
          if (prior.value === singleClientCompaint.priority) {
            setSelectPriority(prior);
          }
        });
        // userDropdownByCompany.map(assign => {
        //   if (assign.value === singleClientCompaint.assingnedTo) {
        //     setSelectAssign(assign)
        //     console.log(assign, 'assign')
        //   }
        // })
      }
    }
  }, [compaintId, singleClientCompaint]);

  useEffect(() => {
    if (singleClient && complaintData.clientIDFk) {
      setComplaintData({
        ...complaintData,
        clientEmail: singleClient?.email,
        clientPhone: singleClient?.mobile,
        address: singleClient?.clientLoc?.location,
      });
    }
  }, [singleClient]);

  console.log("singleClientCompaint", singleClientCompaint);

  useEffect(() => {
    if (compaintId && singleClientCompaint && contactByCompanyDrop) {
      contactByCompanyDrop.forEach((contact) => {
        if (contact.label === singleClientCompaint.reportedBy) {
          setReportedData(contact);
        }
      });
    }
  }, [compaintId, singleClientCompaint, contactByCompanyDrop]);

  console.log("contactByCompanyDrop", contactByCompanyDrop);

  useEffect(() => {
    if (compaintId && singleClientCompaint && userDropdownByCompany) {
      userDropdownByCompany.forEach((assign) => {
        if (assign.label === singleClientCompaint.assingnedToName) {
          setSelectAssign(assign);
        }
      });
    }
  }, [compaintId, singleClientCompaint, userDropdownByCompany]);

  const handleDropDown = (data, type) => {
    let list = complaintData;
    if (type === "company") {
      dispatch(loadGetSingleClient(data.value));
      dispatch(loadOtherContacts(data.value));
      dispatch(loadUserByClient(data.value));
      setCompanyData(data);
      setReportedData({ value: "", label: "" });
      setSelectAssign({ value: "", label: "" });
      list.clientIDFk = parseInt(data.value);
      list.addedByID = data.value.toString();
    } else if (type === "report") {
      setReportedData(data);
      list.reportedBy = data.label;
    } else if (type === "ComplaintDate") {
      setComplaintDate(data);
      list.date = moment(new Date(data)).format("DD/MM/YYYY");
    } else if (type === "product") {
      setSelectProduct(data);
      list.productIDFK = parseInt(data.value);
    } else if (type === "warranty") {
      setSelectWarranty(data);
      list.inWarranty = parseInt(data.value);
    } else if (type === "priority") {
      setSelectPriority(data);
      list.priority = parseInt(data.value);
    } else if (type === "assign") {
      setSelectAssign(data);
      list.assingnedTo = data.value;
    }
    setComplaintData(list);
  };

  const uploadClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (event.target.files.length !== 0) {
      const fileUploaded = event.target.files[0];
      setImgFile(fileUploaded);
      setImgFileName(event.target.files[0].name);

      const reader = new FileReader();
      reader.readAsDataURL(fileUploaded);
      reader.onloadend = () => {
        complaintData.issueImage = reader.result;
        setLocalImage(reader.result);
      };
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "clientPhone") {
      setComplaintData({ ...complaintData, [name]: parseInt(value) });
    } else {
      setComplaintData({ ...complaintData, [name]: value });
    }
  };

  const modleClose = () => {
    props.setCompaintId("");
    props.handleClose();
    props.fetchComplaintdata();
  };

  const saveComplaints = (e) => {
    e.preventDefault();
    if (
      !complaintData.date ||
      !complaintData.productIDFK ||
      !complaintData.reportedBy ||
      !issue ||
      !complaintData.priority ||
      !clientEmail ||
      !clientPhone ||
      !complaintData.assingnedTo
    ) {
      setIsError(true);
      setErrorMessage("Required");
    } else {
      if (clientPhone && clientPhone.toString().length < 10) {
        setPhoneLength(true);
      } else {
        if (imgFile) {
          const formData = new FormData();
          if (imgFile) {
            formData.append("Files", imgFile);
          }
          formData.append("Directory", "Complaint");
          formData.append("FileNamePrefix", "Complaint" + imgFile.name);
          var imgurlPost = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.salesbeeImgAuth,
            body: formData,
          };
          dispatch(
            loadUploadComplaintImages(
              imgurlPost,
              complaintData,
              modleClose,
              compaintId
            )
          );
        } else {
          var postRequest = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(complaintData),
          };
          if (compaintId) {
            dispatch(updateComplaintStatus(postRequest, modleClose, "update"));
          } else {
            dispatch(loadPostClientComplaint(postRequest, modleClose));
          }
        }
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length !== 0) {
      const fileUploaded = files[0];
      setImgFile(fileUploaded);
      setImgFileName(files[0].name);

      const reader = new FileReader();
      reader.readAsDataURL(fileUploaded);
      reader.onloadend = () => {
        complaintData.issueImage = reader.result;
        setLocalImage(reader.result);
      };
    }
  };

  return (
    <AddEditFormComponent
      handleClose={modleClose}
      formName={`${compaintId ? "EDIT" : "ADD"} COMPLAINT`}
      formSubTitle={"Please enter the complaint details."}
      sidebarItems={["Complaints"]}
      childrens={
        <div>
          <form onSubmit={saveComplaints}>
            <div className="add-edit add-complaints-model">
              <div className="add-inputs">
                <Row>
                  <div className="col-md-4 col-sm-12 col-12">
                    <div className="mb-3">
                      <FloatingLabel
                        label="Complaint Date"
                        className={`${
                          complaintDate
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <DatePicker
                          selected={complaintDate}
                          className="form-control form model-input"
                          dateFormat="dd/MM/yyyy"
                          placeholder="Complaint Date"
                          onChange={(date) =>
                            handleDropDown(date, "ComplaintDate")
                          }
                          maxDate={new Date()}
                          readOnly={compaintId}
                        />
                      </FloatingLabel>
                    </div>

                    <div className="mb-3">
                      <FloatingLabel
                        label="Client Name"
                        className={`${
                          companyData.value !== ""
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={clientDropdown}
                          placeholder="Client Name"
                          value={companyData.value && companyData}
                          onInputChange={clientDropdown.label}
                          onChange={(data) => handleDropDown(data, "company")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                      {isError && !complaintData.clientIDFk && (
                        <small id={`Error`} className="form-text text-muted ">
                          Company Name is {errorMessage}
                        </small>
                      )}
                    </div>

                    <div className="mb-3">
                      <FloatingLabel
                        label="Reported By"
                        className={`${
                          reportedData.value !== ""
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={contactByCompanyDrop}
                          placeholder="Reported By"
                          value={reportedData.value && reportedData}
                          onInputChange={contactByCompanyDrop.label}
                          onChange={(data) => handleDropDown(data, "report")}
                          isSearchable={true}
                          isDisabled={!companyData.value}
                        />
                      </FloatingLabel>
                      {isError && !complaintData.reportedBy && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please select reported by
                        </small>
                      )}
                    </div>
                    <div className="mb-3">
                      <FloatingLabel
                        label="Assign To"
                        className={`${
                          selectAssign.value !== ""
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={userDropdownByCompany}
                          placeholder="Assign To"
                          value={selectAssign.value && selectAssign}
                          onInputChange={userDropdownByCompany.label}
                          onChange={(data) => handleDropDown(data, "assign")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                      {isError && !complaintData.assingnedTo && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please select Assigned to Name
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 col-12">
                    <div className="mb-3">
                      <FloatingLabel
                        label="Product Name"
                        className={`${
                          selectProduct.value !== ""
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={productDropdown}
                          placeholder="Product Name"
                          value={selectProduct.value && selectProduct}
                          onInputChange={productDropdown.label}
                          onChange={(data) => handleDropDown(data, "product")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                      {isError && !complaintData.productIDFK && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please select Product name
                        </small>
                      )}
                    </div>

                    <div className="mb-3">
                      <FloatingLabel
                        label="Email"
                        className={`${
                          clientEmail
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <Form.Control
                          type="email"
                          name="clientEmail"
                          className="form-control form add-input"
                          placeholder="Client Email"
                          value={clientEmail}
                          onChange={handleInput}
                        />
                      </FloatingLabel>
                      {isError && clientEmail == "" && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please Enter your email
                        </small>
                      )}
                    </div>

                    <div className="mb-3">
                      <FloatingLabel
                        label="In Warranty"
                        className={`${
                          selectWarranty.value !== ""
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={warrantyStatus}
                          placeholder="In Warranty"
                          value={selectWarranty.value !== "" && selectWarranty}
                          onInputChange={warrantyStatus.label}
                          onChange={(data) => handleDropDown(data, "warranty")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>

                    <div className="mb-3">
                      <FloatingLabel
                        label="Issue"
                        className={`${
                          issue
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <Form.Control
                          className="select-add-meeting-model complaint-issue"
                          name="issue"
                          placeholder="Issue"
                          value={issue}
                          onChange={handleInput}
                          // style={{ width: "100%" }}
                        />
                      </FloatingLabel>
                      {isError && !complaintData.issue && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please Enter Issue
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="mb-3">
                      <FloatingLabel
                        label="Product Code"
                        className={`${
                          complaintData.productIDFK
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <Form.Control
                          type="text"
                          className="form-control form add-input"
                          placeholder="Product Code"
                          readOnly={true}
                          value={complaintData.productIDFK}
                        />
                      </FloatingLabel>
                    </div>

                    <div className="mb-3">
                      <FloatingLabel
                        label="Phone Number"
                        className={`${
                          clientPhone
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <Form.Control
                          type="tel"
                          name="clientPhone"
                          className="form-control form add-input"
                          placeholder="Phone Number"
                          // minLength={10}
                          maxLength={10}
                          value={clientPhone || ""}
                          onChange={handleInput}
                        />
                      </FloatingLabel>
                      {isError && clientPhone == "" && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please Enter phone number
                        </small>
                      )}
                      {phoneLength && clientPhone.toString().length < 10 && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please Enter Valid Mobile Number
                        </small>
                      )}
                    </div>
                    <div className="mb-3">
                      <FloatingLabel
                        label="Priority"
                        className={`${
                          selectPriority.value !== ""
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={priorityStatus}
                          placeholder="Priority"
                          value={selectPriority.value && selectPriority}
                          onInputChange={priorityStatus.label}
                          onChange={(data) => handleDropDown(data, "priority")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                      {isError && !complaintData.priority && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please select priority
                        </small>
                      )}
                    </div>
                  </div>
                </Row>

                <div style={{ padding: "0px 10px" }}>
                  <div>
                    <div className="mb-3">
                      <FloatingLabel
                        label="Issue Description"
                        className={`${
                          description
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <textarea
                          rows="3"
                          name="description"
                          wrap="hard"
                          className="form-control form-textarea add-product-input"
                          id="descriptionArea"
                          placeholder="Description"
                          value={description || ""}
                          onChange={handleInput}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="mb-3">
                    <FloatingLabel
                      label="Address"
                      className={`${
                        address
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <textarea
                        rows="3"
                        name="address"
                        wrap="hard"
                        className="form-control form-textarea add-product-input"
                        id="descriptionArea"
                        placeholder="Address"
                        value={address || ""}
                        onChange={handleInput}
                      />
                    </FloatingLabel>
                  </div>

                  <div>
                    <div className="mb-3">
                      <div>
                        <button
                          type="button"
                          className="btn btn-add-product-img"
                        >
                          <span
                            style={{
                              color: "#04A96D",
                              fontSize: "13px",
                              fontWeight: "700",
                            }}
                          >
                            <div
                              className="drop-area"
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={handleDrop}
                            >
                              {!localImage &&
                              !singleClientCompaint?.issueImage ? (
                                <>
                                  <h6>Upload Issue Attachment</h6>
                                  <p>Drag and drop images here</p>
                                  <p>OR</p>
                                </>
                              ) : (
                                <>
                                  {!localImage &&
                                  Object.keys(singleClientCompaint).length !==
                                    0 &&
                                  singleClientCompaint?.issueImage ? (
                                    <img
                                      className="complaint-img"
                                      src={`${baseURL}/${singleClientCompaint?.issueImage}`}
                                      alt="Product Images"
                                    />
                                  ) : (
                                    <img
                                      className="complaint-img"
                                      src={localImage}
                                      alt="Product Images"
                                    />
                                  )}
                                </>
                              )}
                              <SbCancelBtn
                                btnName="Browse"
                                onClickEffect={uploadClick}
                              />
                              <p style={{ fontSize: "11px" }}>
                                Supports Only JPG, JPEG, PNG
                              </p>
                            </div>
                          </span>
                        </button>
                        <input
                          id="getFile"
                          type="file"
                          multiple
                          name="filetobase64"
                          accept="image/*"
                          className="btn-primary upload"
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-page-save-cancel">
                <SBSaveUpdateBtn btnName={compaintId ? "Update" : "Save"} />
                <SbCancelBtn btnName="Cancel" onClickEffect={modleClose} />
              </div>
            </div>
          </form>
        </div>
      }
    />
  );
}

export default AddComplaint;
