import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

function TopBarToolTip(props) {
  let tpName = props.tpName;

  return (
    <div>
      <OverlayTrigger
        delay={{ hide: 450, show: 300 }}
        overlay={(props) => <Tooltip {...props}>{tpName}</Tooltip>}
        placement="top"
      >
        <Link
          className={tpName === "Settings" ? "setting-image" : ""}
          to={props.link}
        >
          <img src={props.image} width="20px" />
        </Link>
      </OverlayTrigger>
    </div>
  );
}

export default TopBarToolTip;
