import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../Skeleton Loading/SkeletonStdTable";
import { baseURL } from "../BaseUrl";
import { travelizeAuth } from "../HeaderAuthentication";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";

export default function CreatedForms(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [formNamesData, setFormNamesData] = useState([]);
  const [loading, setLoading] = useState(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  useEffect(() => {
    fetchCustomFormNames();
  }, [PageSize, currentPage, searchVal]);

  const fetchCustomFormNames = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/CustomePages/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&Status=1&Text=${searchVal}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //   console.log("CUSFORMS-RES::", res.result);
          setFormNamesData(res.result);
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          console.log("CUS-FORM-RES::", `${res.message}`);
          setFormNamesData([]);
          setIsLoading(false);
          setLoading(false);
        }
      });
  };

  return (
    <div>
      <div id="main-page">
        <>
          {/* MAIN BODY START */}

          <div className="customforms-n-names">
            {/* Filter and Header Section */}
            <div className="user-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <h6 className="page-header-text"> FORM NAMES </h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col">
                  <div className="input-div">
                    <input
                      type="search"
                      id="metricInfo_search_bar"
                      placeholder="Search Form Name"
                      className="form-control setting-input"
                      value={searchVal}
                      onChange={(e) => setSearchVal(e.target.value)}
                    />
                    <i className="fa fa-search search-icon" />
                  </div>
                </Col>
                <Col md={4} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters"></div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* Filter and Header Section */}

            <div className="customforms-table">
              <div
                className="table-responsive routes-scrollbar"
                onScroll={(e) =>
                  handleScroll(
                    e,
                    formNamesData,
                    totalData,
                    setLoading,
                    setPageSize,
                    PageSize
                  )
                }
              >
                <table className="table routes-table-data">
                  <thead className="thead-light routes-thead">
                    {isLoading ? (
                      <>
                        <SkeletonStdTableHead />
                      </>
                    ) : (
                      <tr>
                        <th>SL.NO.</th>
                        <th>FORM NAME</th>
                        <th>CREATED BY</th>
                        <th>ADDED ON</th>
                        <th style={{ textAlign: "center" }}>DATA COUNT</th>
                        <th style={{ textAlign: "center" }}>ACTION</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <>
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                        <SkeletonStdTableBody />
                      </>
                    ) : formNamesData != "" ? (
                      formNamesData
                        .filter((item) => item.customePagesID != 1)
                        .map((data, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {" "}
                              <div className="table-row-head-Text">
                                {data?.pageName}
                              </div>
                            </td>
                            <td>{data?.addedByUserFullName}</td>
                            <td>
                              {moment(data?.addedOn, "DD/MM/YYYY").format(
                                "DD MMM YYYY"
                              )}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {data?.formCount}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {data?.formCount == 0 ? (
                                ""
                              ) : (
                                <div className="user-action-td">
                                  <span
                                    className="user-edit-link"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      props.openFormDataByuser();
                                      props.setCustomPageID(
                                        data?.customePagesID
                                      );
                                      props.setFormName(data?.pageName);
                                    }}
                                  >
                                    <i className="bi bi-eye-fill"></i> View
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          {" "}
                          --- NO DATA FOUND ---{" "}
                        </td>
                      </tr>
                    )}
                    {loading && <OnScrollLoading />}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      </div>

      <></>
    </div>
  );
}
