import React, { useEffect, useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Accountsettings.scss";
import Topbar from "../../Topbar";
import { baseURL } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";
import { loggedInRole } from "../../LoginRole";
import { Col, Row } from "react-bootstrap";
import Footer from "../../Footer";
import { useDispatch } from "react-redux";
import { AddCheckInData, AddDirectVisitData } from "../../../redux/action";
import { AddCheckOutData } from "../../../redux/action";
import { travelizeAuth } from "../../HeaderAuthentication";
import AccSettingComp from "./AccSettingComp";
import {
  meetingRangeOptions,
  claimTwoLevelOptions,
  clientMappingOptions,
  directVisitOptions,
  baseLocationOptions,
  allowAttachmentOptions,
} from "../../DropdownsData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AccountSettings() {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [mappingType, setMappingType] = useState(
    resPassData?.subscription?.clientMapType
  );
  const [meetingRange, setMeetingRange] = useState(resPassData?.allowMeetings);
  const [twoLevelClaim, setTwoLevelClaim] = useState(
    resPassData?.subscription?.twoLevelClaim
  );
  const [baseLocationRestriction, setBaseLocationRestriction] = useState(
    resPassData?.checkInOut_RadiusRestriction == 0 ? "No" : "Yes"
  );
  const [
    baseLocationRestrictionForCheckOut,
    setBaseLocationRestrictionForCheckOut,
  ] = useState(resPassData?.checkOut_RadiusRestriction == 0 ? "No" : "Yes");
  const [checkInRadius, setCheckInRadius] = useState(
    resPassData?.checkInOut_RadiusRestriction
  );
  const [checkOutRadius, setCheckOutRadius] = useState(
    resPassData?.checkOut_RadiusRestriction
  );
  const [directVisit, setDirectVisit] = useState(
    resPassData?.directVisitRestrictions_InTime
  );
  const [allowAttachments, setAllowAttachments] = useState(
    resPassData?.camera_Gallery_Permission
  );

  let dispatch = useDispatch();
  let navigate = useNavigate();

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const getLoginUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/DashBoard/GetLoginUserDetails`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        sessionStorage.setItem("usrlgndtls", JSON.stringify(res.result));
      });
  };

  const setRangeforMeetingSubmit = () => {
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify({ status: meetingRange }),
    };
    fetch(`${baseURL}/api/Subscription/UpdateAllowMeeting`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.message, { autoClose: 5000 });
          getLoginUserDetails();
        } else {
          toast.error(res.errorMessage, { autoClose: 6000 });
        }
      });
  };

  const twoLevelClaimApprove = () => {
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify({ status: twoLevelClaim }),
    };
    fetch(
      `${baseURL}/api/Subscription/UpdateTwoLevelClaimStatus`,
      requestoption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.message, { autoClose: 5000 });
          getLoginUserDetails();
        } else {
          toast.error(res.errorMessage, { autoClose: 6000 });
        }
      });
  };

  const mappingTypeSetting = () => {
    let mapType = {
      subscriptionID: resPassData.subscriptionId,
      clientMapType: JSON.parse(mappingType),
    };
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(mapType),
    };
    fetch(`${baseURL}/api/Subscription/UpdateClientMapType`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.message, { autoClose: 5000 });
          getLoginUserDetails();
        } else {
          toast.error(res.errorMessage, { autoClose: 6000 });
        }
      });
  };

  const updateManualEntryForInTime = () => {
    let data = {
      subscriptionID: resPassData.subscriptionId,
      status: directVisit.toString(),
    };
    let request = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(data),
    };
    dispatch(AddDirectVisitData(request));
    getLoginUserDetails();
  };

  const updateCheckInRadius = () => {
    let data = {
      subscriptionID: resPassData.subscriptionId,
      distanceRadius: baseLocationRestriction === "Yes" ? +checkInRadius : 0,
    };
    let request = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(data),
    };
    dispatch(AddCheckInData(request));
    getLoginUserDetails();
  };

  const updateCheckOutRadius = () => {
    let data = {
      subscriptionID: resPassData.subscriptionId,
      distanceRadius:
        baseLocationRestrictionForCheckOut === "Yes" ? +checkOutRadius : 0,
    };
    let request = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(data),
    };
    dispatch(AddCheckOutData(request));
    getLoginUserDetails();
  };

  const setAttachmentRestrictions = () => {
    let attachmentType = {
      subscriptionID: resPassData.subscriptionId,
      camera_Gallery_Permission: allowAttachments,
    };

    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(attachmentType),
    };
    fetch(
      `${baseURL}/api/Subscription/UpdateCameraGalleryPermission`,
      requestoption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.message, { autoClose: 5000 });
          getLoginUserDetails();
        } else {
          toast.error(res.errorMessage, { autoClose: 6000 });
        }
      });
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}

        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="user-table">
              <Row className="search-row">
                <Col md={3} sm={6} xs={12} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">ACCOUNT SETTINGS</h6>
                  </div>
                </Col>
                <Col md={9} sm={6} xs={12} className="cat-col"></Col>
              </Row>
            </div>
            {/* USER TABLE START */}
            <div className="account-settings-page">
              <div className="account-settings-page-body">
                <div className="mapping-page-added-list">
                  {loggedInRole !== 2 ? (
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 meeting-range-part mt-3">
                        <AccSettingComp
                          type={"meetingRange"}
                          setMeetingRange={setMeetingRange}
                          radioValue={meetingRange}
                          settingsUpdateOnClick={setRangeforMeetingSubmit}
                          settingName="Meeting Range"
                          subHeading="Allow the users to submit a meeting from range?"
                          settingsOptions={meetingRangeOptions}
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 claim-two-level-approval-part mt-3">
                        <AccSettingComp
                          type={"claimTwoLevel"}
                          setTwoLevelClaim={setTwoLevelClaim}
                          radioValue={twoLevelClaim}
                          settingsUpdateOnClick={twoLevelClaimApprove}
                          settingName="Claim-Two Level Approval"
                          subHeading="Do you need claim approval for Admin level?"
                          settingsOptions={claimTwoLevelOptions}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 set-client-mapping-settings mt-5">
                      <AccSettingComp
                        type={"useMappingType"}
                        setMappingType={setMappingType}
                        radioValue={mappingType}
                        settingsUpdateOnClick={mappingTypeSetting}
                        settingName="Client Mapping"
                        subHeading="Please choose below option for client mapping type"
                        settingsOptions={clientMappingOptions}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 claim-two-level-approval-part mt-5">
                      <AccSettingComp
                        type={"manualEntry"}
                        setDirectVisit={setDirectVisit}
                        radioValue={directVisit}
                        settingsUpdateOnClick={updateManualEntryForInTime}
                        settingName="Direct Visit"
                        subHeading="Do you want to allow the manual entry for in-time?"
                        settingsOptions={directVisitOptions}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 claim-two-level-approval-part mt-5">
                      <AccSettingComp
                        type={"baseLocationRestriction"}
                        setBaseLocationRestriction={setBaseLocationRestriction}
                        radioValue={baseLocationRestriction}
                        settingsUpdateOnClick={updateCheckInRadius}
                        settingName="Base Location Restriction for Check-In"
                        subHeading="Do you need to restrict the base location for
                          check-in?"
                        settingsOptions={baseLocationOptions}
                        setCheckInRadius={setCheckInRadius}
                        checkInRadius={checkInRadius}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 claim-two-level-approval-part mt-5">
                      <AccSettingComp
                        type={"baseLocationRestrictionCheckOut"}
                        setBaseLocationRestrictionForCheckOut={
                          setBaseLocationRestrictionForCheckOut
                        }
                        radioValue={baseLocationRestrictionForCheckOut}
                        settingsUpdateOnClick={updateCheckOutRadius}
                        settingName="Base Location Restriction for Check-Out"
                        subHeading="Do you need to restrict the base location for
                          check-out?"
                        settingsOptions={baseLocationOptions}
                        setCheckOutRadius={setCheckOutRadius}
                        checkOutRadius={checkOutRadius}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 set-attachmets-settings mt-5">
                      <AccSettingComp
                        type={"attachmentsRestrictions"}
                        setAllowAttachments={setAllowAttachments}
                        radioValue={allowAttachments}
                        settingsUpdateOnClick={setAttachmentRestrictions}
                        settingName="Attachments"
                        subHeading="Allow attachments from?"
                        settingsOptions={allowAttachmentOptions}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-5">
                      -
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* USER TABLE END */}

            {/* PAGE CONTENT END*/}
          </div>
        </div>
      </div>
      {/* MAIN BODY END */}
      <Footer />
    </div>
  );
}

export default AccountSettings;
