import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { baseURL } from "../BaseUrl";

export default function MappedToUsers(props) {
  const [mappedUsersData, setMappedUsersData] = useState([]);
  const [splitedUsersByMap, setSplitedUsersByMap] = useState([]);
  const [splitUsersByCountry, setSplitUsersByCountry] = useState([]);
  const [splitUsersByZone, setSplitUsersByZone] = useState([]);
  const [splitUsersByState, setSplitUsersByState] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  //  MAPPED TO USERS START

  useEffect(() => {
    fetchMappedToUsers();
  }, [props.clientId]);

  const fetchMappedToUsers = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      // `${baseURL}/api/Client/GetUserListByClient?clientID=${props.clientId}&userID${props.filterUserId}`,
      `${baseURL}/api/Client/GetClientMapping/${props.clientId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMappedUsersData(res.result[0]);
          let mappedUsersByUsers = res.result;
          setSplitedUsersByMap(mappedUsersByUsers);
          //   let mappedUsersByCountry = res.result[0].mappedUserName1.split(",");
          //   let mappedUsersByZone = res.result[0].mappedUserName2.split(",");
          //   let mappedUsersByState = res.result[0].mappedUserName3.split(",");
          setIsLoading(false);
        } else {
          setMappedUsersData("");
          setIsLoading(false);
        }
      });
  };
  //  MAPPED TO USERS END

  return (
    <div>
      <Modal
        show={props.showMappedTo}
        onHide={props.handleClose}
        className="main_model mappedto-users-model"
      >
        <div className="add-edit-client-heading">
          <h6 className="add-edit-client-head">MAPPED TO</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="details-body">
          <div className="table-responsive meetings-scrollbar">
            <table className="table meetings-list-table">
              <thead className="thead-light meetings-list-thead">
                <tr>
                  <th>MAPPED USERS</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <Spinner animation="border" variant="success" />
                  </tr>
                ) : mappedUsersData !== "" && splitedUsersByMap !== "" ? (
                  splitedUsersByMap.map((data, i) => (
                    <tr key={i}>
                      <td>{data.userFullName ?? "NA"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>-----NO DATA FOUND----</tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
}
