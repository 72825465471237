import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "../../styles/ProductsViewPage.scss";
import "../../styles/Products.scss";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/TravelReport.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import { useReactToPrint } from "react-to-print";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import SalesBee from "../../images/MatricLogo.svg";
import SchillerLogo from "../../images/schiller_logo.jpg";
import { loadSingleTravelData } from "../../redux/action";
import moment from "moment";
import { baseURL } from "../BaseUrl";
import { FormattedAmount } from "../FormattedAmount";
import Footer from "../Footer";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function ExpenseReport(props) {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let tableHeader = [
    { name: "DATE", align: "left", width: "120px" },
    { name: "EXPENSES TYPE", align: "left", width: "250px" },
    { name: "DESCRIPTION", align: "left", width: "250px" },
    { name: "NO.OF PERSONS", align: "left", width: "100px" },
    { name: "AMT. CLAIMED", align: "left", width: "100px" },
    { name: "AMT. REJECTED", align: "left", width: "100px" },
    { name: "AMT. APPROVED", align: "left", width: "100px" },
  ];

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { clientId } = useParams();
  const { getSingleTravelReportData } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const mergeRows = (accumulator, currentValue) => {
    const existingRow = accumulator.find(
      (row) =>
        row.fullName === currentValue.fullName &&
        row.totalClaimAmount === currentValue.totalClaimAmount &&
        row.meetingDate === currentValue.meetingDate &&
        row.dayName === currentValue.dayName
    );
    if (existingRow) {
      existingRow.singleTravelInfo.push({
        // meetingDate: currentValue.meetingDate,
        // dayName: currentValue.dayName,
        startLoc: currentValue.startLoc?.location,
        endLoc: currentValue.endLoc?.location,
        distanceTravelled: currentValue.distanceTravelled,
        remark: currentValue.remark,
      });
    } else {
      accumulator.push({
        fullName: currentValue.fullName,
        totalClaimAmount: currentValue.totalClaimAmount,
        meetingDate: currentValue.meetingDate,
        dayName: currentValue.dayName,
        singleTravelInfo: [
          {
            // meetingDate: currentValue.meetingDate,
            // dayName: currentValue.dayName,
            startLoc: currentValue.startLoc?.location,
            endLoc: currentValue.endLoc?.location,
            distanceTravelled: currentValue.distanceTravelled,
            remark: currentValue.remark,
          },
        ],
      });
    }
    return accumulator;
  };

  const mergedMotRows =
    getSingleTravelReportData.length > 0
      ? getSingleTravelReportData.reduce(mergeRows, [])
      : [];
  // console.log(mergedMotRows, "mergedMotRows");

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [imageClicked, setImageClicked] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState(null);

  const handleImageClicked = (imageUrl) => {
    setImageClicked(!imageClicked);
    setClickedImageUrl(imageUrl);
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  const componentRef = useRef();
  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* FOOTER START */}
      <Footer />
      {/* FOOTER START */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">
                      EXPENSE VOUCHER DETAILS
                    </h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col"></Col>
              </Row>
            </div>

            <div className="sb-table-div sb-table-setion">
              <div>
                <Paper
                  sx={{ width: "100%", overflow: "hidden" }}
                  className="table-main-div"
                >
                  <TableContainer
                    sx={{ maxHeight: 440 }}
                    // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                  >
                    <div ref={componentRef} className="table-data_n_images m-3">
                      <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className={`whatsapp-table-container`}
                      >
                        <TableHead className="custom-table-header">
                          <TableRow>
                            <TableCell
                              align="center"
                              className="b-1"
                              colSpan={2}
                            >
                              <div className="travel-report-client-image">
                                <img src={SchillerLogo} alt="Logo" />
                              </div>
                            </TableCell>

                            <TableCell
                              align="center"
                              colSpan={2}
                              className="reprot-table-hading b-1"
                            >
                              Travelling Expenses Statement/Voucher-Detail
                            </TableCell>
                            <TableCell
                              align="center"
                              className="b-1"
                              colSpan={2}
                            >
                              <div className="travel-report-client-image">
                                <img src={SalesBee} alt="" />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                          <TableBody>
                            {props.tableHeader.map((name, i) => (
                              <SkeletonTableData key={i} />
                            ))}
                          </TableBody>
                        ) : (
                          <>
                            <TableBody>
                              <TableRow align="center">
                                <TableCell className="bl-1 br-1">
                                  <b>Employee Name:</b>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Kaustubh Rahatwal
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <b>Tracker Voucher No. :</b>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    AFW053/04/23/001
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Region :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    West-1
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell className="br-1 bl-1">
                                  <div>
                                    <b>Employee Code :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    AFW053
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>NAV Voucher No. :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    HO-IM-ACC-22004105
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <b>Grade :</b>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    M-2
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell className="bl-1 br-1">
                                  <b>Tour Name:</b>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Mumbai To Pondy
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>NAV Voucher Date :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    30-04-2024
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Designation :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Accounts Assistant
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell className="br-1 bl-1">
                                  <div>
                                    <b>Tour Period :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    01/04/2023 To 10/04/2023
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Vendor No. :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    -
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Department :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Accounts & Finance
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell className="br-1 bl-1">
                                  <div>
                                    <b>Pupose of Tour :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1" colSpan={3}>
                                  <div className="table-row-head-Text color-green">
                                    For Backbone Meeting
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Branch :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Mumbai
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </>
                        )}
                      </Table>

                      <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="expense-voucher-table-container p-2"
                      >
                        <TableHead className="custom-table-header">
                          <TableRow>
                            {tableHeader.map((name, i) => (
                              <TableCell
                                key={i}
                                align={name.align}
                                style={{
                                  minWidth: name.width,
                                }}
                                className="bt-1 bg-primaryLight"
                              >
                                {name.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                          <TableBody>
                            {props.tableHeader.map((name, i) => (
                              <SkeletonTableData key={i} />
                            ))}
                          </TableBody>
                        ) : (
                          <>
                            <TableBody>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>* Lodging Expenses</strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Hotel Room Cost</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>
                                    Booked Form 01/04/2023 To 09/04/2023
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>2</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>5000</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>4950</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>11-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>
                                    Hotel Room Cost (Accommodation Arranged By
                                    Employee)
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Booked For 10/04/2023</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>2</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>* Baording Charges</strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Tour Food</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Lunch at JP Hospital</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>3</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>11-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Tour Food ( Without Bill)</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Lunch at KK Hospital</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>1</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>300</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>250</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>* Fare Paid</strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Tour Fare Paid</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Bus Fare Paid From Chennai To Pondy</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>250</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>250</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>* Transit Allowance</strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Transit Allowance</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>For 10/03/2023</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>* Tour Conveyance </strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Tour Conveyance - Own Bike</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>visited to Dr.Varma (Nair Hospital)</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Tour Car/Taxi hire Charges</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>
                                    visited to Dr.Gaurav (Saibaba Hospital)
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Tour Conveyance - Own Car</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>
                                    visited to Dr.Sanjay (Apple Hospital)
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>
                                      * Expenses with Customer/Priniciple{" "}
                                    </strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Expenses with Customer/Principle</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Food with Sharma Doctor</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>100</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>* Tour Sundry Expenses</strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Tour Sundry Expenses</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>FOR 10 Days</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>* Local Food Expenses</strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Local Food ( Without Bill )</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>200</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>200</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Local Food</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>* Local Conveynace</strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Local Conveyance Charges</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Local Conveyance Charges - Own Bike</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Local Conveyance Charges - Own Car</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Local Conveyance Charges- Taxi/Car</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Local Conveyance Charges- Auto</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>50</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={7}
                                >
                                  <div className="table-row-head-Text">
                                    <strong>* Other Expenses</strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Printing and Stationery</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Office Expenses</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Transport Charges-local</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Direct Purchase</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Installation Expenses</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell className="tr-tcell-br bl-1">
                                  <div>10-03-2024</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Courier Charges</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>0</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>500</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={4}
                                  align="right"
                                >
                                  <div className="table-row-head-Text">
                                    <strong>TOTAL</strong>
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="table-row-head-Text">
                                    <strong>21,930.00</strong>
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="table-row-head-Text">
                                    <strong>130.00</strong>
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="table-row-head-Text">
                                    <strong>21,800.00</strong>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </>
                        )}
                      </Table>
                      <div className="verified-by">
                        <div className="dflex-j-between">
                          <div className="text-center p-3">
                            <div>Checked by</div>
                            <div>
                              <b>(Regional Co-ordinator Name)</b>
                            </div>
                          </div>
                          <div className="text-center p-3">
                            <div>Approved by</div>
                            <div>
                              <b>(Final Level Authoriser Name )</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableContainer>
                </Paper>
              </div>
            </div>
            <div className="download-and-pagination">
              <SbDownloadBtn btnName="Download" onClickEffect={pdfDownload} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
