export const cashVoucherData = [
  {
    date: "02/08/2024",
    from: "Home",
    to: "Mansara",
    mot: "Auto",
    details: "Document Submission, Payment and PMS",
    customerName: "BARC",
    amount: "50.32",
  },
  {
    date: "02/08/2024",
    from: "Govandi",
    to: "Barc",
    mot: "Auto",
    details: "-",
    customerName: "BARC",
    amount: "40.26",
  },
  {
    date: "02/08/2024",
    from: "-",
    to: "-",
    mot: "-",
    details: "Lunch",
    customerName: "-",
    amount: "125",
  },
  {
    date: "02/08/2024",
    from: "Vashi",
    to: "Suraj",
    mot: "Auto",
    details: "Cheque payment",
    customerName: "Suraj",
    amount: "25.16",
  },
  {
    date: "02/08/2024",
    from: "Suraj",
    to: "Vashi",
    mot: "Auto",
    details: "-",
    customerName: "-",
    amount: "25.16",
  },
  {
    date: "02/08/2024",
    from: "Mansara",
    to: "Home",
    mot: "Auto",
    details: "-",
    customerName: "-",
    amount: "50.32",
  },
  {
    date: "03/08/2024",
    from: "Home",
    to: "Mansara",
    mot: "Auto",
    details: "Installation - Holter",
    customerName: "Glenmark",
    amount: "50.32",
  },
  {
    date: "03/08/2024",
    from: "Govandi",
    to: "Barc",
    mot: "Taxi",
    details: "-",
    customerName: "-",
    amount: "40.26",
  },
  {
    date: "03/08/2024",
    from: "Vashi",
    to: "Suraj",
    mot: "Taxi",
    details: "-",
    customerName: "-",
    amount: "25.16",
  },
  {
    date: "03/08/2024",
    from: "-",
    to: "-",
    mot: "-",
    details: "Lunch",
    customerName: "-",
    amount: "125",
  },
  {
    date: "03/08/2024",
    from: "Suraj",
    to: "Vashi",
    mot: "Auto",
    details: "-",
    customerName: "-",
    amount: "25.16",
  },
  {
    date: "03/08/2024",
    from: "Mansara",
    to: "Home",
    mot: "Auto",
    details: "-",
    customerName: "-",
    amount: "50.32",
  },
];
