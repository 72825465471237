import React from "react";
import "../styles/Topbar.scss";
import "../styles/Footer.scss";
import SalesBee from "../images/MatricLogo.svg";

function Footer(props) {
  return (
    <div className="footer">
      <span className="green-Color">Powered By : </span>
      <img
        src={SalesBee}
        alt="powered-by-metricinfo"
        width="50%"
        // className="salesbee-logo"
      />
    </div>
  );
}

export default Footer;
