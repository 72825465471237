import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import GoogleMapComp from "../GoogleMapComp";
import { baseURL } from "../BaseUrl";

export default function RouteMap(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [userName, setUserName] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientLocation, setClientLocation] = useState("");
  const [distance, setDistance] = useState("");
  const [centerLatLng, setCenterLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [clientLatLng, setClientLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [userLatLng, setUserLatLng] = useState({
    lat: null,
    lng: null,
  });

  // GET PAGE START
  useEffect(() => {
    fetchRoutePlans();
  }, [props.viewRouteID]);

  const fetchRoutePlans = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Route/GetRouteClient?RouteId=${props.viewRouteID}&clientID=${props.viewClientID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserName(res.result[0]?.userFullName);
          setUserLocation(res.result[0]?.baseLoc?.location);
          setClientName(res.result[0]?.clientName);
          setClientLocation(res.result[0]?.clientLoc?.location);
          setDistance(res.result[0]?.baseClientDistance);
          setCenterLatLng({
            lat: parseFloat(res.result[0]?.clientLoc?.latitude),
            lng: parseFloat(res.result[0]?.baseLoc?.longitude),
          });
          setClientLatLng({
            lat: parseFloat(res.result[0]?.clientLoc?.latitude),
            lng: parseFloat(res.result[0]?.clientLoc?.longitude),
          });
          setUserLatLng({
            lat: parseFloat(res.result[0]?.baseLoc?.latitude),
            lng: parseFloat(res.result[0]?.baseLoc?.longitude),
          });
        } else {
          setUserName("");
          setClientName("");
          setClientLatLng({ lat: null, lng: null });
          setUserLatLng({ lat: null, lng: null });
        }
      });
  };
  // GET PAGE END

  useEffect(() => {
    let mapUrl;
    if (clientLatLng.lat && userLatLng.lat) {
      mapUrl = `https://www.google.com/maps/dir/${userLatLng.lat},${userLatLng.lng}/${clientLatLng.lat},${clientLatLng.lng}`;
      window.open(mapUrl, "_blank");
    }
    return;
  }, [userLatLng, clientLatLng]);

  return (
    <div>
      {" "}
      {/* <Modal
        show={props.openRouteMap}
        // onHide={props.openGoogleMaps}
        className="main_model google-maps-route-model"
      >
        <div>
          <div className="add-user-heading">
            <h6 className="add-user-head">ROUTE MAP</h6>

            <i className="fa fa-times" onClick={props.closeRouteMap} />
          </div>
          <div className="route-map-table">
            <div className="row">
              <div className="col-5">
                <strong>USER DETAILS</strong>
              </div>
              <div className="col-5">
                <strong>CLIENT DETAILS</strong>
              </div>
              <div className="col">
                <strong>DISTANCE</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <p className="color-green">
                  <i className="fa fa-user" /> &nbsp;{userName}
                </p>
                <i className="fa fa-map-marker color-yellow" /> &nbsp;{" "}
                {userLocation}
              </div>
              <div className="col-5">
                <p className="color-green">
                  <i className="fa fa-user" /> &nbsp;{clientName}
                </p>
                <i className="fa fa-map-marker color-yellow" /> &nbsp;{" "}
                {clientLocation}
              </div>
              <div className="col color-green">{distance} KM</div>
            </div>
          </div>
          {}
          {clientLatLng.lat &&
            clientLatLng.lng &&
            userLatLng.lat &&
            userLatLng.lng && (
              <div className="google-map-box">
                {props.mapView ? (
                  <GoogleMapComp
                    centerLatLng={centerLatLng}
                    endLatLan={clientLatLng}
                    startLatLan={userLatLng}
                    pageType="RouteMap"
                  />
                ) : (
                  <div className="google-map-box p-2 empty-map">
                    <div className="overlay-div">
                      <div className="over-lay-text-div">
                        <h6 style={{ color: "#ffb900" }}>
                          This Feature is not available in your Subscription...!
                        </h6>
                        <p className="mt-3">
                          If you still want to view the map{" "}
                          <span
                            className="complete-status status-badge"
                            style={{ cursor: "pointer" }}
                            onClick={props.giveAccess}
                          >
                            Click Here
                          </span>
                        </p>
                      </div>
                    </div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.56659496218!2d77.46612702802196!3d12.95428023487456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1692190317657!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                )}
              </div>
            )}
        </div>
      </Modal> */}
    </div>
  );
}
