import Swal from "sweetalert2";

export const showAlert = (title, message, icons, closeAlertModel) => {
  Swal.fire({
    title: title,
    text: message,
    icon: icons,
    confirmButtonText: "OK",
  }).then((result) => {
    if (result.isConfirmed) {
      if(closeAlertModel){
        closeAlertModel()
        console.log('OK button clicked');
      }
    };
  });
};
