import React, { useEffect, useLayoutEffect, useState } from "react";
import Footer from "../../Footer";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { exportExcel } from "../../DownloadFile";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import SbDropDownBtn from "../../SbButtons/SbDropDownBtn";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";
import { optionsStatusList } from "../../DropdownsData";
import { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { vendorTableData } from "../TestData";
import VendorTable from "./VendorTable";

export default function VhdVendor() {
  const tableHeader = [
    { name: "Vendor Code", align: "left" },
    { name: "Vendor Name", align: "left" },
    { name: "R1", align: "left" },
    { name: "R2", align: "left" },
    { name: "R3", align: "left" },
    { name: "Contact Person", align: "left" },
    { name: "Mobile No", align: "left" },
    { name: "Email", align: "left" },
    { name: "Status", align: "left" },
  ];

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [filterUserId, setFilterUserId] = useState("");
  const [activeStatus, setActiveStatus] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const currentPage = 1;
  //* PAGINATION END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setActiveStatus(1);
  };
  //* CLEAR SELECT END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div>
      <>
        {/* SIDEBAR - TOPBAR - FOOTER  */}
        <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
        <Topbar
          toggleMenu={toggleMenu}
          menuCollapse={menuCollapse}
          toggleshift={toggleshift}
        />
        <Footer />
        {/* SIDEBAR - TOPBAR - FOOTER  */}
      </>
      <>
        <div className="page-wrapper">
          <div className={`main-body ${toggleshift.style}`}>
            <div className="page-content">
              {/* Filter and Header Section */}
              <div className="user-table">
                <Row className="search-row">
                  <Col md={4} sm={4} className="cat-col">
                    <div className="page-header-text-div">
                      <div className="back-btn" onClick={goToSettingPage}>
                        <img
                          src="../../../../images/DefaultImages/back-btn-arrow.svg"
                          alt=""
                        />
                      </div>
                      <h6 className="page-header-text">VENDORS</h6>
                    </div>
                  </Col>
                  <Col md={5} sm={4} className="cat-col">
                    <div className="input-div">
                      <input
                        type="search"
                        id="metricInfo_search_bar"
                        placeholder="Search Vendor"
                        className="form-control setting-input"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <i className="fa fa-search search-icon" />
                    </div>
                  </Col>
                  <Col md={3} sm={4} className="cat-col">
                    <div
                      className="search-bar-header"
                      style={{ float: "right" }}
                    >
                      <div className="page-top-bar-dropdowns page-top-Filters">
                        <div
                          className="header-filter-btn"
                          onClick={() => setFilter(!filter)}
                        >
                          <div className="filter-icon-text">
                            <i className="bi bi-sliders" />
                            <span>Filters</span>
                          </div>
                        </div>

                        <SbDropDownBtn
                          pageName="Vendor"
                          // openAddModal={handleClose}
                          // addBulkModel={handleCloseBulkUserModel}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                {filter && (
                  <div className="page-filter-section">
                    <div className="filter-row-input-boxes">
                      <div>
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          // options={userDropdown}
                          placeholder="Select Vendor"
                          // value={
                          //   filterUserId === "" ? "" : userDropdown.value
                          // }
                          // onInputChange={userDropdown.label}
                          onChange={(data) => setFilterUserId(data.value)}
                          isSearchable={true}
                        />
                      </div>

                      <div>
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={optionsStatusList}
                          placeholder="Select Status"
                          value={
                            activeStatus === 2
                              ? ""
                              : activeStatus
                              ? activeStatus
                              : optionsStatusList.value
                          }
                          onInputChange={optionsStatusList.label}
                          onChange={(data) => setActiveStatus(data.value)}
                          isSearchable={true}
                        />
                      </div>
                      <MiClearButton
                        onClickEffect={clearSerachBySelect}
                        btnName="Clear"
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* Filter and Header Section */}

              {/* TABLE START */}

              <VendorTable
                tableHeader={tableHeader}
                tableData={vendorTableData}
              />

              <div className="user-table">
                <div className="download-and-pagination">
                  <SbDownloadBtn
                    btnName="Download"
                    // onClickEffect={exportUserDetails}
                  />
                </div>
              </div>
              {/* TABLE END */}

              {/* PAGE CONTENT END*/}
            </div>
          </div>

          <></>
          {/* MAIN BODY END */}
        </div>
      </>
    </div>
  );
}
