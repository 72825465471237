import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { SkeletonMeetPurpose } from "../Skeleton Loading/SkeletonMeetPurpose";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
import { FormattedAmount } from "../FormattedAmount";
import { useDispatch, useSelector } from "react-redux";
import { loadTransactionSummary } from "../../redux/action";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useReactToPrint } from "react-to-print";

function PassBookTable(props) {
  let tableHeader = [
    { name: "NAME", align: "left", width: "200px" },
    { name: "Referral Code", align: "left", width: "150px" },
    { name: "CUSTOMER DETAILS", align: "left", width: "200px" },
    { name: "ORDER DETAILS", align: "left", width: "200px" },
    { name: "TRANSACTION ID", align: "left", width: "200px" },
    { name: "PRODUCT COUNT", align: "center", width: "120px" },
    { name: "PRODUCT CART VALUE", align: "center", width: "150px" },
    { name: "COMMISSION VALUE", align: "center", width: "150px" },
    { name: "Action", align: "center", width: "200px" },
  ];

  let dispatch = useDispatch();

  const { transactionData } = useSelector((state) => state.data);

  useEffect(() => {
    if (props.selectedUser.value) {
      dispatch(loadTransactionSummary(props.selectedUser.value));
    }
  }, [props.selectedUser])

  const componentRef = useRef();
  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="basefare-table"
              ref={componentRef}
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {tableHeader.map((name, i) => (
                    <TableCell
                      key={i}
                      align={name.align}
                      className={`thead-cell-products`}
                      style={{ minWidth: name.width }}
                    >
                      {name.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {props.isLoading ? (
                <>
                  {tableHeader.map((name, i) => (
                    <SkeletonMeetPurpose key={i} />
                  ))}
                </>
              ) : (
                <>
                  {transactionData &&
                    transactionData.length === 0 ? (
                    <TableBody>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={tableHeader.length}>
                          <div style={{ padding: "20px 20px" }}>
                            <img
                              src="../../../../images/DefaultImages/NodataFound.jpg"
                              alt=""
                              style={{ width: "10%" }}
                            />
                            <br />
                            <span>No Data Found...!</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {transactionData && transactionData
                        .map((data, index) => (
                          <TableRow
                            align="center"
                            key={index}
                            className="client-list-row-data"
                          >
                            <TableCell>
                              <span className="users-list-name">
                                <div>
                                  <img
                                    className="table-data-users-imgages"
                                    src={
                                      data?.image
                                        ? `${baseURL}/${data?.image}`
                                        : profileimg
                                    }
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <div className="table-row-head-Text">
                                    {data?.userName}
                                  </div>
                                  <div className="table-row-sub-text">
                                    {data?.addedByID}
                                  </div>
                                </div>
                              </span>
                            </TableCell>
                            <TableCell>{data.referelCode}</TableCell>
                            <TableCell>
                              <div className="table-row-head-Text">
                                {data.custName ? data.custName : "-"}
                              </div>
                              <div className="table-row-sub-text">
                                {data.custEmail}
                              </div>
                              <div className="table-row-sub-text">
                                {data?.countryCode} &nbsp;{data?.custMobile}
                              </div>
                              <div className="table-row-sub-text">
                                {data.custAddress ? data.custAddress : "-"}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="table-row-sub-text">
                                {data.orderID}
                              </div>
                              <div className="table-row-sub-text">
                                {data.orderStatus}
                              </div>
                              <div className="table-row-sub-text">
                                {data.orderDate}
                              </div>
                            </TableCell>
                            <TableCell>{data.transactionID}</TableCell>
                            <TableCell align="center">{data.productItemCount}</TableCell>
                            <TableCell align="center">
                              <span className='prime-highlight-value'>
                                <i className="fa fa-inr" aria-hidden="true"></i>{FormattedAmount(data.totalCartValue)}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className='color-secondary'>
                                <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                                {FormattedAmount(data.totalCommValue)}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span
                                className="client-edit-link"
                                onClick={() => props.handleView(data.gfRefTransSummaryID)}
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fa fa-eye"></i> View
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <div className="download-and-pagination">
        <SbDownloadBtn btnName="Download" onClickEffect={pdfDownload} />
      </div>
    </>
  );
}

export default PassBookTable;
