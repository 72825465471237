import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";
import Skeleton from "react-loading-skeleton";
import { dateFormatLocal } from "../../FormattedAmount";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";

function MeetingPurposeTable(props) {
  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.meetPurpose,
              props.totalData,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table stickyHeader aria-label="sticky table table-responsive">
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    align={name.align}
                    className={`thead-cell-products`}
                    style={{ minWidth: `${name.width}` }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <TableBody>
                {props.tableHeader.map((name, i) => (
                  <TableRow align="center" key={i}>
                    {props.tableHeader.map((name, index) => (
                      <TableCell key={index}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <>
                {props.meetPurpose && props.meetPurpose.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/no-data-found.png"
                            alt=""
                            style={{ width: "50%" }}
                          />
                          <br />
                          <span className="color-yellow">
                            --- No Data Found ---
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {props.meetPurpose &&
                      props.meetPurpose.map((meet, i) => (
                        <TableRow
                          align="center"
                          key={i}
                          className="client-list-row-data"
                        >
                          <TableCell>{meet?.purpose}</TableCell>
                          <TableCell align="center">
                            {dateFormatLocal(meet?.addedOn)}
                          </TableCell>
                          <TableCell>{meet?.addedBy}</TableCell>
                          <TableCell align="center">
                            {meet?.status === 1 ? (
                              <span className="status-badge complete-status">
                                Active
                              </span>
                            ) : (
                              <span className="status-badge pending-status">
                                InActive
                              </span>
                            )}
                          </TableCell>
                          <TableCell className="action-class">
                            <button
                              onClick={() => {
                                props.setShow(!props.show);
                                props.setPurposeId(meet?.purposeId);
                              }}
                              className="btn client-edit-link"
                            >
                              <span>
                                <i className="fa fa-edit" />
                                &nbsp;Edit
                              </span>
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default MeetingPurposeTable;
