import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";
import { useSelector } from "react-redux";
import "../../../styles/BaseFare.scss";

function BaseFareTable(props) {
  const { totalCount } = useSelector((state) => state.data);

  const mergeRows = (accumulator, currentValue) => {
    const existingRow = accumulator.find(
      (row) => row.motName === currentValue.motName
    );

    if (existingRow) {
      existingRow.designationInfo.push({
        designationLevel: currentValue.designationLevel,
        designationName: currentValue.designationName,
        motType: currentValue.motType,
        motBaseFare: currentValue.motBaseFare,
        baseFareAmount: currentValue.baseFareAmount,
        motUnit: currentValue.motUnit,
        isPolicyAvaialble: currentValue.isPolicyAvaialble,
        status: currentValue.status,
        dayLimit: currentValue.dayLimit,
        addedByUserFullName: currentValue.addedByUserFullName,
        customeBaseFareID: currentValue.customeBaseFareID,
        // Add more properties as needed
      });
    } else {
      accumulator.push({
        motName: currentValue.motName,
        designationInfo: [
          {
            designationLevel: currentValue.designationLevel,
            designationName: currentValue.designationName,
            motType: currentValue.motType,
            motBaseFare: currentValue.motBaseFare,
            baseFareAmount: currentValue.baseFareAmount,
            motUnit: currentValue.motUnit,
            isPolicyAvaialble: currentValue.isPolicyAvaialble,
            dayLimit: currentValue.dayLimit,
            status: currentValue.status,
            addedByUserFullName: currentValue.addedByUserFullName,
            customeBaseFareID: currentValue.customeBaseFareID,
            // Add more properties as needed
          },
        ],
        // Add other properties here
      });
    }
    return accumulator;
  };

  const mergedMotRows = props.baseFareList
    ? props.baseFareList.reduce(mergeRows, [])
    : [];

  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        // sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.baseFareList,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="whatsapp-table-container p-2"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    align={name.align}
                    // className={`thead-cell-products ${
                    //   name === "ACTION" || name === "STATUS"
                    //     ? "action-class"
                    //     : ""
                    // }`}
                    style={{
                      minWidth: name.width,
                      maxWidth: name.width,
                      width: name.width,
                    }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <>
                {props.tableHeader.map((name, i) => (
                  <SkeletonMeetPurpose key={i} />
                ))}
              </>
            ) : (
              <>
                {props.baseFareList && props.baseFareList.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/NodataFound.jpg"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>--- No Data Found ---</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {mergedMotRows.map((data, i) => (
                      <TableRow
                        align="center"
                        key={i}
                        className="client-list-row-data"
                      >
                        <TableCell
                          className="tr-tcell-br"
                          style={{
                            minWidth: "150px",
                            maxWidth: "150px",
                            width: "150px",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer", color: "#04a96d" }}
                            // onClick={() => props.editBasefare(data.customeBaseFareID, data.motID, 'view')}
                          >
                            <b>{data?.motName}</b>
                          </span>
                        </TableCell>
                        <TableCell colSpan={8} className="bb-none p-0">
                          <Table>
                            <TableBody>
                              {data.designationInfo.map(
                                (designation, index) => (
                                  <TableRow
                                    key={index}
                                    className="table_row-inside-tabel-cell"
                                  >
                                    <TableCell
                                      className="tr-tcell-br"
                                      style={{
                                        minWidth: "250px",
                                        maxWidth: "250px",
                                        width: "250px",
                                      }}
                                    >
                                      <div>
                                        {designation.designationLevel !== 0 &&
                                        designation.designationLevel !== "" ? (
                                          <>
                                            L{designation.designationLevel}-
                                            {designation.designationName}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      className="tr-tcell-br"
                                      style={{
                                        minWidth: "150px",
                                        maxWidth: "150px",
                                        width: "150px",
                                      }}
                                    >
                                      <div>{designation?.motType}</div>
                                    </TableCell>
                                    <TableCell
                                      className="tr-tcell-br"
                                      style={{
                                        minWidth: "150px",
                                        maxWidth: "150px",
                                        width: "150px",
                                      }}
                                    >
                                      <div>
                                        {designation.isPolicyAvaialble === false
                                          ? designation?.motBaseFare
                                          : designation?.baseFareAmount}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      className="tr-tcell-br"
                                      style={{
                                        minWidth: "150px",
                                        maxWidth: "150px",
                                        width: "150px",
                                      }}
                                    >
                                      {designation.motUnit}
                                    </TableCell>
                                    <TableCell
                                      className="tr-tcell-br"
                                      style={{
                                        minWidth: "160px",
                                        maxWidth: "160px",
                                        width: "160px",
                                      }}
                                    >
                                      {designation.isPolicyAvaialble ===
                                      false ? (
                                        <span
                                          style={{
                                            fontSize: "11px",
                                            color: "rgb(255 191 19)",
                                          }}
                                        >
                                          Policy is Not Available
                                        </span>
                                      ) : (
                                        // Render day limit only if the policy is available
                                        <>{designation?.dayLimit}</>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        minWidth: "150px",
                                        maxWidth: "150px",
                                        width: "150px",
                                      }}
                                    >
                                      {designation.addedByUserFullName}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        minWidth: "150px",
                                        maxWidth: "150px",
                                        width: "150px",
                                      }}
                                    >
                                      {designation.status === 1 ? (
                                        <span className="complete-status status-badge">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="pending-status status-badge">
                                          Inactive
                                        </span>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="bb-none"
                                      style={{
                                        minWidth: "200px",
                                        maxWidth: "200px",
                                        width: "200px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <button
                                        onClick={() =>
                                          props.editBasefare(
                                            designation.customeBaseFareID,
                                            data.motID
                                          )
                                        }
                                        className="btn client-history-link"
                                        style={{ padding: "5px 10px" }}
                                      >
                                        {designation.customeBaseFareID === 0 ? (
                                          <span className="add-policy status-badge">
                                            Add Policy
                                          </span>
                                        ) : (
                                          <span>
                                            <i className="fa fa-edit" /> Edit
                                          </span>
                                        )}
                                      </button>
                                      <button
                                        onClick={() =>
                                          props.editBasefare(
                                            designation.customeBaseFareID,
                                            designation.motID,
                                            "view"
                                          )
                                        }
                                        className="btn client-history-link"
                                        style={{ padding: "5px 10px" }}
                                      >
                                        <span>
                                          <i className="fa fa-eye" /> View
                                        </span>
                                      </button>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default BaseFareTable;
