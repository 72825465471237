import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "../styles/CheckSubscription.scss";
import salesbeelogo from "../images/MatricLogo.svg";

function CheckSubscription() {
  return (
    <div>
      <div className="subscrption-box">
        <div className="subscrption-image-data">
          <Image
            src={salesbeelogo}
            className="salesbee-logo"
            alt="SalesBee Logo"
          />
          <div className="subscription-expiry-model">
            <h5 className="expiry-heading">
              <i className="fa fa-exclamation-triangle" /> &nbsp; Your
              Subscription has expired!
            </h5>
            <p>Contact MetricInfo team to Re-Activate your account.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckSubscription;
