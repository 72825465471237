import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadAllCityList } from "../../../redux/action";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";

function RegionCityTable(props) {
    let tableHeader = [
        { name: "Country Name", align: "left", width: "150px" },
        { name: "Zone Name", align: "left", width: "150px" },
        { name: "State Name", align: "left", width: "150px" },
        { name: "City Name", align: "left", width: "150px" },
    ];

    let dispatch = useDispatch();
    const { getAllCityName } = useSelector((state) => state.data);
    const { totalCount } = useSelector((state) => state.data);

    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(30);
    const [filterArray, setFilterArray] = useState([]);

    useEffect(() => {
        fetchAllCity(filterArray, PageSize, currentPage)
    }, [filterArray, PageSize, currentPage])

    const fetchAllCity = () => {
        dispatch(loadAllCityList(filterArray, currentPage, PageSize, setIsLoading, setLoading))
    }

    return (
        <>
            {/* <h4 className="accounts-header" >Passbook</h4> */}
            <div className="sb-table-div sb-table-setion">
                <Paper
                    sx={{ width: "100%", overflow: "hidden" }}
                    className="table-main-div"
                >
                    <TableContainer sx={{ maxHeight: 440 }}
                        onScroll={(e) =>
                            handleScroll(e, getAllCityName, totalCount, setLoading, setPageSize, PageSize)
                        }
                    >
                        <Table
                            stickyHeader
                            aria-label="sticky table table-responsive"
                            className="basefare-table"
                        >
                            <TableHead className="custom-table-header">
                                <TableRow>
                                    {tableHeader.map((name, i) => (
                                        <TableCell
                                            key={i}
                                            align={name.align}
                                            className={`thead-cell-products`}
                                            style={{ minWidth: name.width }}
                                        >
                                            {name.name}
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        <SBSaveUpdateBtn
                                            btnName="Add City"
                                            onClickEffect={props.openCityModal}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {isLoading ? (
                                <>
                                    {tableHeader.map((name, i) => (
                                        <SkeletonMeetPurpose key={i} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {getAllCityName &&
                                        getAllCityName.length === 0 ? (
                                        <TableBody>
                                            <TableRow align="center">
                                                <TableCell align="center" colSpan={tableHeader.length}>
                                                    <div style={{ padding: "20px 20px" }}>
                                                        <img
                                                            src="../../../../images/DefaultImages/NodataFound.jpg"
                                                            alt=""
                                                            style={{ width: "10%" }}
                                                        />
                                                        <br />
                                                        <span>No Data Found...!</span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {getAllCityName && getAllCityName.map((data, index) => (
                                                <TableRow
                                                    align="center"
                                                    key={index}
                                                    className="client-list-row-data"
                                                >
                                                    <TableCell>
                                                        <div className="table-row-head-Text">
                                                            {data.countryName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="table-row-head-Text">
                                                            {data.zoneName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="table-row-head-Text">
                                                            {data.stateName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell colSpan={2}>
                                                        <div className="table-row-head-Text">
                                                            {data.cityName}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {loading && <OnScrollLoading />}
                                        </TableBody>
                                    )}
                                </>
                            )}
                        </Table>
                    </TableContainer>
                </Paper>
            </div >
        </>
    );
}

export default RegionCityTable;
