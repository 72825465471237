import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import { baseURL } from "../../BaseUrl";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import Select from "react-select";
import { optionsUnitList, optionsMOTTypes } from "../../DropdownsData";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { loadPostGoggleApis } from "../../../redux/action";
import * as swal from "../../Consturl/SwalAlert";

function AddGoogleCredits(props) {
  let googleCreditId = props.googleCreditId;
  const editableData = props.editableData;

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let dispatch = useDispatch();

  const [postItem, setPostItem] = useState({
    apiKey: "",
    credits: "",
    hits: "",
    updatedDate: "",
  });

  useEffect(() => {
    if (googleCreditId && editableData) {
      setPostItem({
        apiKey: editableData.GoogleApiKey,
        credits: parseInt(editableData.ApiCreadit),
        hits: parseInt(editableData.HitCount),
      });
    }
  }, [googleCreditId, editableData]);
  console.log(postItem, "postItempostItem");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name !== "apiKey") {
      setPostItem({ ...postItem, [name]: parseInt(value) });
    } else {
      setPostItem({ ...postItem, [name]: value });
    }
  };

  const closeAddModal = () => {
    props.handleClose();
    setPostItem({ apiKey: "", credits: "", hits: "", updatedDate: "" });
    props.fetchGoogleCreditdata();
    props.setGoogleCreditId("");
    props.setEditableData({});
  };

  const saveGoogleCredits = () => {
    if (postItem.apiKey && postItem.credits) {
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.postTravelizeAuth,
        body: JSON.stringify(postItem),
      };

      console.log(postItem, "postItempostItempostItem");
      dispatch(
        loadPostGoggleApis(requestOption, closeAddModal, googleCreditId)
      );
    } else {
      swal.showAlert("Fail", `Please enter all fields`, "warning");
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          {/* <h6 className="add-edit-basefare-head">{props.clickType ? "Base fare Details" : <>{setBasefareId ? "EDIT BASE FARE" : "ADD BASE FARE"}</>}</h6> */}
          <h6 className="add-edit-basefare-head">
            {googleCreditId ? "EDIT GOOGLE CREDITS" : "ADD GOOGLE CREDITS"}
          </h6>
          <i className="fa fa-times" onClick={closeAddModal} />
        </div>
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">
              <div className="mb-3" style={{ position: "relative" }}>
                <label className="float-label">Api Key</label>
                <input
                  type="text"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  name="apiKey"
                  value={postItem?.apiKey || ""}
                  placeholder="Enter Api Key"
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <label className="float-label">Credit Limit</label>
                <input
                  type="tel"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  name="credits"
                  value={postItem?.credits}
                  placeholder="Enter Api Credits"
                  onChange={handleInputChange}
                />
              </div>

              {googleCreditId && (
                <div className="mb-3" style={{ position: "relative" }}>
                  <label className="float-label">Google Hits</label>
                  <input
                    type="tel"
                    className="form-control form add-basefare-input"
                    id="exampleFormControlInput1"
                    name="hits"
                    value={postItem?.hits}
                    placeholder="Enter Api Credits"
                    onChange={handleInputChange}
                  />
                </div>
              )}

              {/* <div className="mb-3" style={{position: "relative"}}>
              <label className="float-label">From Date</label>
                <input
                    type="date"
                    className="form-control form add-basefare-input"
                    id="exampleFormControlInput1"
                    name="baseFareAmount"
                    //   value={postItem?.baseFareAmount}
                    placeholder="Select Date"
                    // onChange={(e) => setBaseFare(e.target.value)}
                    //   onChange={handleInputChange}
                    // disabled={props.clickType}
                />
              </div> */}

              {/* <div className="mb-3" style={{position: "relative"}}>
              <label className="float-label">To Date</label>
              <input
                    type="date"
                    className="form-control form add-basefare-input"
                    id="exampleFormControlInput1"
                    name="baseFareAmount"
                    //   value={postItem?.baseFareAmount}
                    placeholder="Enter Api Key"
                    // onChange={(e) => setBaseFare(e.target.value)}
                    //   onChange={handleInputChange}
                    // disabled={props.clickType}
                />
              </div> */}
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn
            onClickEffect={saveGoogleCredits}
            btnName={googleCreditId ? "Update" : "Save"}
          />
          <SbCancelBtn btnName="Cancel" onClickEffect={closeAddModal} />
        </div>
      </Modal>
    </div>
  );
}

export default AddGoogleCredits;
