import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import SkeletonTableData from '../Skeleton Loading/SkeletonTableData';
import '../../styles/Orders.scss';
import moment from 'moment';
import { FormattedAmount } from '../FormattedAmount';
import OnScrollLoading, { handleScroll } from '../OnScrollLoading';
import { useSelector } from 'react-redux';

function OrdersTable(props) {
    const { totalCount } = useSelector(state => state.data);
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer 
                sx={{ maxHeight: 440 }} 
                onScroll={(e) =>
                    handleScroll(
                      e,
                      props.oderDetails,
                      totalCount,
                      props.setLoading,
                      props.setPageSize,
                      props.PageSize
                    )
                  }
                >
                    <Table stickyHeader aria-label="sticky table table-responsive">
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell align={name.align} key={i} className={`thead-cell-products`}
                                        style={{ minWidth: `${name.width}` }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <>
                                <SkeletonTableData />
                                <SkeletonTableData />
                                <SkeletonTableData />
                                <SkeletonTableData />
                                <SkeletonTableData />
                                <SkeletonTableData />
                                <SkeletonTableData />
                                <SkeletonTableData />
                            </>
                            :
                            <>
                                {props.oderDetails.length === 0 ?
                                    <TableBody>
                                        <TableRow >
                                            <TableCell colSpan={props.tableHeader.length} align="center">
                                                <div style={{ padding: "50px 20px" }}>
                                                    No Data Found...!
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.oderDetails && props.oderDetails.map((item, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data order-row'
                                                onClick={() => props.selectOrder(item.orderID)}>
                                                <TableCell align="center" >
                                                    <span>{item.orderID}</span>
                                                </TableCell>
                                                <TableCell >
                                                    <div className='table-row-head-Text'>
                                                        {item.clientName}
                                                    </div>
                                                    {item.email &&
                                                        <div className="table-row-sub-text">
                                                            {item.email}
                                                        </div>
                                                    }
                                                    {(item.phone || item.phone !== 0) && <div>+91 {item.phone}</div>}
                                                </TableCell>
                                                <TableCell >
                                                    <span>{item.address}</span>
                                                </TableCell>
                                                <TableCell align="center" >
                                                    <span>{moment(new Date(item.orderDate)).format("DD MMM YYYY")}</span>
                                                </TableCell>
                                                <TableCell align="center" >
                                                    <span><b>{item.totalQTY}</b></span>
                                                </TableCell>
                                                <TableCell align="center" >
                                                    <span className='prime-highlight-value'><i className="fa fa-inr" aria-hidden="true"></i> {FormattedAmount(item.totalAmount)}</span>
                                                </TableCell >
                                                <TableCell align="center" >
                                                    {item.orderStatus === "Pending" ? (
                                                        <span className="pending-status status-badge">
                                                            {item.orderStatus}
                                                        </span>
                                                    ) : (
                                                        item.orderStatus === "Completed" ? (
                                                            <span className="complete-status status-badge">
                                                                {item.orderStatus}
                                                            </span>
                                                        ) : 
                                                        ( item.orderStatus === "Delivered" ? (
                                                            <span className="complete-status status-badge">
                                                                {item.orderStatus}
                                                            </span>
                                                        ) : (
                                                            <span className="common-status status-badge">
                                                                {item.orderStatus}
                                                            </span>
                                                        )
                                                        )
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <span className='client-edit-link'><i className="fa fa-eye"></i> View</span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {props.loading && <OnScrollLoading />}
                                    </TableBody>
                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default OrdersTable;
