import React, { useState } from "react";
import Topbar from "../../Topbar";
import Sidenavbar from "../../Sidenavbar";
import Footer from "../../Footer";
import { Link } from "react-router-dom";
import VHDCardComponent from "./VHDCardComponent";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";

import SummaryTable from "./SummaryTable";
import { dashboardTableData } from "../TestData";

export default function VhdDashBoard() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [recentActivity, setRecentActivity] = useState(0);

  const tableHeader = [
    { name: "Vendor Code", align: "left" },
    { name: "Vendor Name", align: "left" },
    { name: "PO", align: "left" },
    { name: "Invoice", align: "left" },
    { name: "Payments", align: "left" },
    { name: "Action", align: "center" },
  ];

  const recentSelectOptions = [
    { label: "All", value: 0 },
    { label: "Purchase Order", value: 1 },
    { label: "Invoice", value: 2 },
    { label: "Payments", value: 3 },
  ];

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  return (
    <div>
      <>
        {/* SIDEBAR START */}
        <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
        {/* SIDEBAR END */}

        {/* TOPBAR START */}
        <Topbar
          toggleMenu={toggleMenu}
          menuCollapse={menuCollapse}
          toggleshift={toggleshift}
        />
        {/* TOPBAR END */}

        {/* FOOTER START */}
        <Footer />
        {/* FOOTER END */}
      </>
      <>
        <div className="page-wrapper">
          <div className={`main-body ${toggleshift.style}`}>
            <div className="vhdpage-content-dashboard">
              <div className="vhd-card-row">
                <VHDCardComponent
                  cardHeading="ACTIVE VENDORS"
                  cardData="45"
                  cardIcon={
                    <span className="material-symbols-outlined">groups</span>
                  }
                  linkUrl={`/vhd/vendors`}
                />
                <VHDCardComponent
                  cardHeading="PO ORDERS"
                  cardData="1252"
                  cardIcon={
                    <span className="material-symbols-outlined">list_alt</span>
                  }
                  linkUrl={`/vhd/vendors`}
                />
                <VHDCardComponent
                  cardHeading="INVOICE CREATED"
                  cardData="1200"
                  cardIcon={
                    <span className="material-symbols-outlined">
                      order_approve
                    </span>
                  }
                  linkUrl={`/vhd/vendors`}
                />
                <VHDCardComponent
                  cardHeading="PAYMENT PAID"
                  cardData="995"
                  cardIcon={
                    <span className="material-symbols-outlined">payments</span>
                  }
                  linkUrl={`/vhd/vendors`}
                />
              </div>
              <div className="summary-n-recent-activity">
                <Row>
                  <Col md={7}>
                    <div className="card vendor-summary-part">
                      <div className="card-heading-search-bar dflex-j-between">
                        <div className="card-heading">Summary</div>
                        <div className="card-searchbar">
                          <div className="input-div">
                            <input
                              type="search"
                              id="metricInfo_search_bar"
                              placeholder="Vendor Name/Code"
                              className="form-control setting-input"
                              //   value={searchValue}
                              //   onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <i className="fa fa-search search-icon" />
                          </div>
                        </div>
                      </div>
                      <div className="summary-table-part">
                        <SummaryTable
                          tableHeader={tableHeader}
                          tableData={dashboardTableData}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="card recent-activity-part">
                      <div className="card-heading-search-bar dflex-j-between">
                        <div className="card-heading">Recent Activity</div>
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={recentSelectOptions}
                            placeholder="Select User"
                            value={recentSelectOptions.value}
                            onInputChange={recentSelectOptions.label}
                            onChange={(data) => setRecentActivity(data.value)}
                            isSearchable={true}
                          />
                        </div>
                      </div>
                      <div className="recent-activity-data">
                        <div className="card rcatv-card">
                          <div className="card-type po">
                            <div>PO</div>
                          </div>
                          <div className="card-type-data">
                            Portea has raised PO 12729 against Pure Produkts for
                            a value of Rs.52718
                          </div>
                        </div>
                        <div className="card rcatv-card">
                          <div className="card-type inv">
                            <div>INV</div>
                          </div>
                          <div className="card-type-data">
                            Sri Ranganatha Green gardens has created Invoice
                            137183 for a value of Rs.7080 against the PO 12409
                            raised by Portea
                          </div>
                        </div>
                        <div className="card rcatv-card">
                          <div className="card-type pp">
                            <div>PP</div>
                          </div>
                          <div className="card-type-data">
                            Portea has made partial/full payment of Rs.5265
                            against the Invoice Created by Omega Business system
                          </div>
                        </div>
                        <div className="card rcatv-card">
                          <div className="card-type pp">
                            <div>PP</div>
                          </div>
                          <div className="card-type-data">
                            Portea has made partial/full payment of Rs.5265
                            against the Invoice Created by Omega Business system
                          </div>
                        </div>
                        <div className="card rcatv-card">
                          <div className="card-type pp">
                            <div>PP</div>
                          </div>
                          <div className="card-type-data">
                            Portea has made partial/full payment of Rs.5265
                            against the Invoice Created by Omega Business system
                          </div>
                        </div>
                        <div className="card rcatv-card">
                          <div className="card-type pp">
                            <div>PP</div>
                          </div>
                          <div className="card-type-data">
                            Portea has made partial/full payment of Rs.5265
                            against the Invoice Created by Omega Business system
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
