import React from "react";
import "../../styles/BulkUploadComp.scss";
import { MiBulkUploadBtn, SbBulkUpload } from "../SbButtons/SbAddBtn";
import LoadingAnimation from "../LoadingAnimation";
import { baseURL } from "../BaseUrl";

export default function BulkUploadComp(props) {
  return (
    <div>
      {props.show && (
        <div>
          <div className="modal-dialog modal-fullscreen-xxl-down">
            <div className="modal-content add-machine-page-container">
              <div>
                <div className="bubble-image">
                  <img src="../../images/BG/about_s4_bubble.png" alt="" />
                </div>
                <div className="circle-image">
                  <img src="../../images/BG/choose_lines.svg" alt="" />
                </div>
                <div className="lines-image">
                  <img src="../../images/BG/lines.png" alt="" />
                </div>
              </div>

              <div className="animation-bulk-container">
                <div className="bubble-image-small">
                  <img src="../../images/BG/about_s4_bubble.png" alt="" />
                </div>
                <div className="circle-image-small">
                  <img src="../../images/BG/choose_lines.svg" alt="" />
                </div>
              </div>

              <div className="modal-header add-user-title">
                <div className="back-btn" onClick={props.closeModel}>
                  <img
                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                    alt=""
                  />
                </div>
                <h4 className="modal-title">{props.heading}</h4>
              </div>
              <div className="px-0 modal-body">
                <div className="container-fluid add-bulk-container">
                  <div className="row hv-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 bulk-upload-static-image">
                      <img src="../../images/BG/bulkUpload3.svg" alt="" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="upld-n-templt-downld flex-column">
                        <div className="mb-4 upload-part">
                          <input
                            className="form-control form add-meetpurpose-input"
                            id="bulkclientupload"
                            type="file"
                            name="bulkUserUpload"
                            accept=".xls, .xlsx"
                            onChange={props.handleChange}
                            placeholder="Add File"
                          />
                        </div>
                        <div className="dflex-j-between">
                          <div>
                            <a
                              href={`${baseURL}/TravelizeProData/SUB0000000001/Data/${props.downloadTemplateUrl}`}
                              className="btn template-download"
                              download={"BulkUploadTemplate"}
                            >
                              <i className="fa fa-download" /> &nbsp;Template
                              Download
                            </a>
                          </div>
                          <div>
                            <MiBulkUploadBtn
                              btnName="Bulk Upload"
                              onClickEffect={props.uploadClick}
                            />
                          </div>
                        </div>
                        <div className="bulk-temp-and-res-message">
                          {props.isLoading ? (
                            <>
                              <span className="color-green">
                                Checking excel file to upload please wait...
                              </span>
                              &nbsp;
                              <div className="m-1 pt-2">
                                <LoadingAnimation />
                              </div>
                            </>
                          ) : (
                            <span
                              className={
                                props.bulkUploadResponse.res
                                  ? "upload-res-message success"
                                  : "upload-res-message false"
                              }
                            >
                              {props.bulkUploadResponse.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
