import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import React from "react";
  import { useSelector } from "react-redux";
  
  function ValueDisplayTable(props) {
    const { totalCount } = useSelector((state) => state.data);
  
    return (
        <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
            sx={{ maxHeight: 440 }}
            // onScroll={(e) =>
            //   handleScroll(
            //     e,
            //     props.clientCategoryData,
            //     totalCount,
            //     props.setLoading,
            //     props.setPageSize,
            //     props.PageSize
            //   )
            // }
          >
            <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="client-table"
          >
              <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((header, i) => (
                  <TableCell
                    key={i}
                    align={header.align}
                    style={{ minWidth: `${header.width}` }}
                  >
                    {header.name}
                  </TableCell>
                ))}
              </TableRow>
              </TableHead>
                <TableBody >
                <TableRow
                className="client-list-row-data">
                {props.categoryData.map((val, i) => (
                  props.tableHeader.map((header, i) => (
                    <TableCell
                    >
                      {val[header.field]}
                    </TableCell>
                  ))
                ))}
              </TableRow>
                </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
  
  export default ValueDisplayTable;
  