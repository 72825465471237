import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import Rating from "react-rating";

function KpaTable(props) {

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='basefare-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                        style={{ minWidth: name.width }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <>{props.tableHeader.map((name, i) => (
                                <SkeletonMeetPurpose key={i} />
                            ))}
                            </>
                            :
                            <>
                                {props.getKpa && props.getKpa.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell align="center" colSpan={props.tableHeader.length}>
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.getKpa && props.getKpa.map((data, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data'>
                                                <TableCell>
                                                    {/* <strong onClick={() => props.viewKpa(data.salesBeeReferelID)}>{data.userName}</strong><br /> */}
                                                    <div>
                                                        <div className="table-row-head-Text">
                                                            <strong>{data?.userName}</strong>
                                                        </div>
                                                        <div className="table-row-sub-text color-yellow">
                                                            {data?.userCode}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {data.departmentName}
                                                </TableCell>
                                                <TableCell>
                                                    {data.addedOn}
                                                </TableCell>
                                                <TableCell>
                                                    {data.period}
                                                </TableCell>
                                                <TableCell>
                                                    {data.userComment ? data.userComment : "NA"}
                                                </TableCell>

                                                {/* <TableCell onMouseEnter={() => handleHover(data.userComment)}
                                                    onMouseLeave={()=>{removeModal(data.userComment)}}>
                                                    <span className="table-row-head-Text" style={{ cursor: "pointer"}}>
                                                        {data.userComment && data.userComment.length > 20
                                                            ? <><b>{data.userComment.substring(0, 20) + "..."}</b></>
                                                            : <><b>{data.userComment}</b></>}
                                                    </span>
                                                    {showModal === data.userComment  && (
                                                    <div className="modal-box">
                                                        <div style={{position: "relative"}}>
                                                            <span>{data.userComment}</span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </TableCell> */}

                                                <TableCell>
                                                    <Rating
                                                        className="user-rating"
                                                        initialRating={data.ratings}
                                                        emptySymbol="fa fa-star-o fa-2x"
                                                        fullSymbol="fa fa-star fa-2x"
                                                        fractions={2}
                                                        readonly
                                                    />
                                                </TableCell>
                                                <TableCell className='action-class'>
                                                    <div className='d-flex justify-content-evenly align-itmes-center'>
                                                        {/* <button className="btn client-edit-link" onClick={() => props.upDateStatus(data.kpaid)} >
                                                            <i className="fa fa-eye"></i> Update
                                                        </button> */}
                                                        <button className="btn client-edit-link" onClick={() => props.viewKpaTargetData(data.kpaid)} >
                                                            <i className="fa fa-eye"></i> View
                                                        </button>
                                                        <button className="btn client-edit-link" onClick={() => props.editKpa(data.kpaid)}>
                                                            <i className="bi bi-pencil-fill"></i> Edit
                                                        </button>
                                                        {/* <button className="btn client-edit-link" onClick={() => props.viewKpa(data.kpaid)}>
                                                            <i className="fa fa-eye"></i> View
                                                        </button> */}
                                                    </div>
                                                </TableCell>
                                                {/* {showModal === data.userComment  && (
                                                    <div className="modal-box">
                                                        <div style={{position: "relative"}}>
                                                            <span>{data.userComment}</span>
                                                        </div>
                                                    </div>
                                                    )} */}
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default KpaTable;
