import React, { useEffect, useState } from "react";
import "../../src/styles/SignIn.scss";
import travelizeSalesBeeLogo from "../images/MatricLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import travelize from "../images/metric-favicon.svg";
import { Form, Button, Col, Image, Row, Carousel } from "react-bootstrap";
import { baseURL } from "./BaseUrl";
import * as swal from "../Components/Consturl/SwalAlert";
import { toast } from "react-toastify";
import clientLogo from "../images/Client-Logo.svg";
import AccessDenied from "../AccessDenied";

const ResetPasswordPage = () => {
  let clientLoginUrl = window.location.hash;

  let navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // const baseURL = "https://api.travelize.in";
  // const { trackData } = useSelector(state => state.data);
  const [emailValue, setEmail] = useState("");
  const [rvValue, setRvValue] = useState("");
  const [emptyEmail, setEmptyEmail] = useState("");

  useEffect(() => {
    var urlstr = window.location.href;
    const answer_array = urlstr.split("?").slice(1);

    if (answer_array.length !== 0) {
      const splitByAnd = answer_array.toString().split("&");
      const regex = new RegExp("ue=", "g");
      const regex2 = new RegExp("rv=", "g");
      let email = splitByAnd[0].replace(regex, "");
      let rv = splitByAnd[1].replace(regex2, "");
      setEmail(email);
      setRvValue(rv);
    } else {
      setEmptyEmail(true);
    }
  }, []);

  const sessionClear = () => {
    sessionStorage.clear("forgotPassData");
  };

  const resetPassword = (e) => {
    if (password === confirmPassword) {
      const resetPassDta = {
        UserName: emailValue,
        password: password,
        resetPswdFlag: rvValue,
      };

      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(resetPassDta),
      };
      fetch(`${baseURL}/api/Authentication/ResetPassword`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            swal.showAlert("Success", `${res.message}`, "success");
            setTimeout(() => navigate("/"), sessionClear(), 1000);
          } else {
            toast.error(res.errorMessage);
          }
        });
    } else {
      toast.error("Password Must be same");
    }
    e.preventDefault();
  };

  return (
    <div className="container-fluid">
      {!emptyEmail ? (
        <div className="sigin-page-body">
          {/* <Row> */}
          <div className="dflex-flow-row">
            <Col lg={5} md={5} sm={12} className="signIn-container-left-div">
              <div className="signIn-container-logo">
                <Image
                  src={travelizeSalesBeeLogo}
                  alt="MetricInfo"
                  className="signIn-logo"
                />
              </div>
              <div className="signIn-container-gif">
                <Image
                  src="../images/sign-animate.gif"
                  alt="MetricInfo Motion Logo"
                  className="signIn-moving-img"
                />
                <div className="text-sliding-below-img">
                  <Carousel
                    style={{ width: "100%" }}
                    controls={false}
                    indicators={false}
                    interval={3000}
                  >
                    <Carousel.Item>
                      <h6>
                        <b>Geolocation Monitoring</b>
                      </h6>
                      <p>Real-time tracking to determine precise location</p>
                    </Carousel.Item>
                    <Carousel.Item>
                      <h6>
                        <b>Reporting and Analytics</b>
                      </h6>
                      <p>
                        Offering valuable insights for informed decision-making
                      </p>
                    </Carousel.Item>
                    <Carousel.Item>
                      <h6>
                        <b>Visits and Expenses</b>
                      </h6>
                      <p>
                        Ensuring optimal resource allocation and cost control
                      </p>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </Col>
            <Col lg={2} md={2} sm={12}>
              <div className="devider">
                <div className="v1"></div>
              </div>
            </Col>
            <Col lg={5} md={5} sm={12} className="signIn-container-right-div">
              <div className="metricinfo-logo">
                <Image
                  src={travelize}
                  alt="MetricInfo Logo"
                  className="sb-trv-logo"
                />
              </div>
              <div className="signin-header resetpass-part">
                <div className="resetpass-header-part">
                  <h2>Reset Password</h2>
                  <div>
                    <h6>to access MetricInfo</h6>
                  </div>
                </div>
                <Form onSubmit={resetPassword} className="resetpass-form-data">
                  {emptyEmail && (
                    <div className="form-group">
                      <div className="icon-with-input before">
                        <i className="fa fa-envelope" />
                        <input
                          type="email"
                          className="form-control signIn-form-control"
                          placeholder="Enter your email"
                          value={emailValue}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group mb20">
                    <div className="icon-with-input before">
                      <i className="fa fa-lock" />
                      <input
                        type="password"
                        className="form-control signIn-form-control"
                        placeholder="New Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="icon-with-input before">
                      <i className="fa fa-lock" />
                      <input
                        type="password"
                        className="form-control signIn-form-control"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="signin-button dflex-j-between mt5">
                    <div className="back-to-signin">
                      <Link
                        to={
                          clientLoginUrl === "#/barixforgotpassword"
                            ? "/barrix"
                            : "/"
                        }
                        className="color-yellow"
                      >
                        Remembered? Login again
                      </Link>
                    </div>
                    <Button type="submit" className="btn btn-signin-button">
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </div>
          {/* </Row> */}
        </div>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default ResetPasswordPage;
