import React, { useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Reports.scss";
import { Button, Form, Image, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { optionsPageSizeList } from "../../DropdownsData";
import { Link } from "react-router-dom";
import userImage from "../../../images/profiles/profile_img-01.jpg";
import userImage1 from "../../../images/profiles/profile_img-02.jpg";
import userImage2 from "../../../images/profiles/profile_img-05.jpg";
import userImage3 from "../../../images/profiles/profile_img-06.jpg";
import userImage4 from "../../../images/profiles/profile_img-07.jpg";
import projectReportImg from "../../../images/pms/Group_1954.jpg";
import projectReportImg1 from "../../../images/pms/Group_1969.jpg";
import projectReportImg2 from "../../../images/pms/Group_1971.jpg";
import projectReportImg3 from "../../../images/pms/Group_1972.jpg";
import projectIcon from "../../../images/products/project-objectives1.png";
import ViewProjectGraph from "./ViewProjectGraph";

export default function Reports() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editAmcModel, setEditAmcModel] = useState(false);
  const [viewProductDetail, setViewProductDetail] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [viewProjectGraph, setViewProjectGraph] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const openProjectReportGraph = () => {
    setViewProjectGraph(!viewProjectGraph);
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditAmcModel(false);
    setViewProductDetail(false);
  };
  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}
        <div className={`main-body ${toggleshift.style}`}>
          {!viewProjectGraph ? (
            <div>
              <div className="project_report_cards">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card card-project-status">
                      <div className="card-body project-report">
                        <div>
                          <p className="fz15 gryclr card-title mb-0">
                            Project Status
                          </p>
                          <p className="fz28 gryclr card-data">60</p>
                        </div>
                        <div className="card-img-part">
                          <img
                            src={projectReportImg}
                            alt="card-icon"
                            className="report-card-imag"
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card card-project-status">
                      <div className="card-body project-report">
                        <div>
                          <p className="fz15 gryclr card-title mb-0">
                            Total Projects
                          </p>
                          <p className="fz28 gryclr card-data">200</p>
                        </div>
                        <div className="card-img-part">
                          <img
                            src={projectReportImg1}
                            alt="card-icon"
                            className="report-card-imag"
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card card-project-status">
                      <div className="card-body project-report">
                        <div>
                          <p className="fz15 gryclr card-title mb-0">
                            Total Tasks
                          </p>
                          <p className="fz28 gryclr card-data">160</p>
                        </div>
                        <div className="card-img-part">
                          <img
                            src={projectReportImg2}
                            alt="card-icon"
                            className="report-card-imag"
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card card-project-status">
                      <div className="card-body project-report">
                        <div>
                          <p className="fz15 gryclr card-title mb-0">
                            Total Phases
                          </p>
                          <p className="fz28 gryclr card-data">130</p>
                        </div>
                        <div className="card-img-part">
                          <img
                            src={projectReportImg3}
                            alt="card-icon"
                            className="report-card-imag"
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-content">
                {/* TABLE START */}

                <div className="reports-table">
                  <div className="seleting-menus">
                    <div className="page-title">REPORTS</div>

                    <div className="page-top-bar-dropdowns">
                      <div className="search-input">
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          className="form-control select search-field"
                          //   onChange={(e) => setSearchval(e.target.value)}
                        />
                        <i className="fa fa-search" />
                      </div>

                      <button
                        type="button"
                        className="btn btn-search-track"
                        // onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser"> </i>
                          <h6>Clear</h6>
                        </span>
                      </button>

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsPageSizeList}
                        placeholder="Show Per Page"
                        value={optionsPageSizeList.value}
                        onInputChange={optionsPageSizeList.label}
                        onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                      />

                      {/* <button
                    type="button"
                    className="btn btn-add-project"
                    onClick={() => setShow(!show)}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-plus-square" /> &nbsp;
                      <h6>Add New</h6>
                    </span>
                  </button> */}
                    </div>
                  </div>

                  <div className="table-responsive task-scrollbar">
                    <table className="table reports-list-table">
                      <thead className="thead-light reports-list-thead">
                        <tr>
                          <th>ALL PROJECTS</th>
                          <th>CLIENT CODE</th>
                          <th>PROJECT COST</th>
                          <th>NO. OF PHASE</th>
                          <th>NO. OF TASKS</th>
                          <th>PROJECT TYPE</th>
                          <th>MEMBERS</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          className="reports-list-row-data"
                          // onClick={openProjectReportGraph}
                        >
                          <td>
                            <p className="report-list-name">
                              <input
                                className="select-single-checkbox"
                                type="checkbox"
                                value=""
                                id="01"
                                // onClick={(e) => {
                                //   setMeetingIdforRefresh(
                                //     e.target.checked,
                                //     data?.attendanceID
                                //   );
                                // }}
                                // checked={data?.inputState?.chkSelectRefresh}
                              />
                              <Image
                                src={projectIcon}
                                className="project-icon-table-row"
                                alt="project-icon"
                              />
                              <p className="fz14"> Project 01</p>
                            </p>
                          </td>
                          <td>123456789</td>
                          <td>1,00,000</td>
                          <td>10</td>
                          <td>120</td>
                          <td>Front End</td>
                          <td>
                            <p className="multiple-images-rounded">
                              <Image
                                src={userImage}
                                className="table-row-image members"
                              />
                              <Image
                                src={userImage1}
                                className="table-row-image members1"
                              />
                              <Image
                                src={userImage2}
                                className="table-row-image members2"
                              />
                              <p className="table-row-image member-count">
                                {" "}
                                20+
                              </p>
                            </p>
                          </td>
                          <td>
                            <p className="project_status completed">
                              <p>Completed </p> &nbsp;
                              <Dropdown className="dropdown-table-row">
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-in-table-row"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={openProjectReportGraph}
                                  >
                                    Project Details
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#">
                                    Delete Project
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#">
                                    Download PDF
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </p>
                          </td>
                        </tr>
                        <tr
                          className="reports-list-row-data"
                          // onClick={openProjectReportGraph}
                        >
                          <td>
                            <p className="report-list-name">
                              <input
                                className="select-single-checkbox"
                                type="checkbox"
                                value=""
                                id="01"
                                // onClick={(e) => {
                                //   setMeetingIdforRefresh(
                                //     e.target.checked,
                                //     data?.attendanceID
                                //   );
                                // }}
                                // checked={data?.inputState?.chkSelectRefresh}
                              />
                              <Image
                                src={projectIcon}
                                className="project-icon-table-row"
                                alt="project-icon"
                              />
                              <p className="fz14"> Project 01</p>
                            </p>
                          </td>
                          <td>456456789</td>
                          <td>5,00,000</td>
                          <td>15</td>
                          <td>120</td>
                          <td>Front End</td>
                          <td>
                            <p className="multiple-images-rounded">
                              <Image
                                src={userImage}
                                className="table-row-image members"
                              />
                              <Image
                                src={userImage1}
                                className="table-row-image members1"
                              />
                              <Image
                                src={userImage2}
                                className="table-row-image members2"
                              />
                              <p className="table-row-image member-count">
                                {" "}
                                20+
                              </p>
                            </p>
                          </td>
                          <td>
                            <p className="project_status pending">
                              <p>Pending </p> &nbsp;
                              <Dropdown className="dropdown-table-row">
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-in-table-row"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={openProjectReportGraph}
                                  >
                                    Project Details
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#">
                                    Delete Project
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#">
                                    Download PDF
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </p>
                          </td>
                        </tr>

                        <tr className="reports-list-row-data">
                          <td>
                            <p className="report-list-name">
                              <input
                                className="select-single-checkbox"
                                type="checkbox"
                                value=""
                                id="01"
                                // onClick={(e) => {
                                //   setMeetingIdforRefresh(
                                //     e.target.checked,
                                //     data?.attendanceID
                                //   );
                                // }}
                                // checked={data?.inputState?.chkSelectRefresh}
                              />
                              <Image
                                src={projectIcon}
                                className="project-icon-table-row"
                                alt="project-icon"
                              />
                              <p className="fz14"> Project 03</p>
                            </p>
                          </td>
                          <td>123456789</td>
                          <td>1,50,000</td>
                          <td>13</td>
                          <td>160</td>
                          <td>Front End</td>
                          <td>
                            <p className="multiple-images-rounded">
                              <Image
                                src={userImage}
                                className="table-row-image members"
                              />
                              <Image
                                src={userImage}
                                className="table-row-image members1"
                              />
                              <Image
                                src={userImage}
                                className="table-row-image members2"
                              />
                              <p className="table-row-image member-count">
                                22+
                              </p>
                            </p>
                          </td>
                          <td className="project_status ongoing">
                            <p>Ongoing </p> &nbsp;
                            <Dropdown className="dropdown-table-row">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-in-table-row"
                              >
                                <i className="fa fa-ellipsis-v" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  href="#"
                                  onClick={openProjectReportGraph}
                                >
                                  Project Details
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                  Delete Project
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                  Download PDF
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* TABLE END */}

                <div className="download-and-pagination">
                  <button
                    type="button"
                    className="btn btn-download-excel"
                    // onClick={exportExcelActiivity}
                  >
                    <i className="fa fa-download" /> &nbsp; Download
                  </button>
                  <span className="user-pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<i className="fa fa-angle-double-right" />}
                      onPageChange={handlePageClick}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<i className="fa fa-angle-double-left" />}
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      disabledClassName={"disabled"}
                    />
                  </span>
                </div>

                {/* PAGE CONTENT END*/}
              </div>
            </div>
          ) : (
            <ViewProjectGraph openProjectReportGraph={openProjectReportGraph} />
          )}
          {/* MAIN BODY END */}
        </div>
      </div>
    </div>
  );
}
