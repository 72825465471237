import React from "react";
import { Modal, Image } from "react-bootstrap";
import "../../styles/AddmeetingModel.scss";
import viewattachment from "../../images/No-Image.jpg";
import { baseURL } from "../BaseUrl";
import SbCancelBtn from "../SbButtons/SbCancelBtn";

function ViewClaimAttachmentmodel(props) {
  console.log("ATTACHMENT-URL:::", props.attachmentUrl);
  return (
    <div>
      <div className="model-view-claim-attachment">
        <Modal
          show={props.claimAttachmentmodel}
          onHide={props.handleCloseviewattachment}
          className="view-attachment-model"
        >
          <div className="view-attach-heading">
            <h6 className="view-attachment-head">VIEW ATTACHMENT</h6>
            <i
              className="fa fa-times"
              onClick={props.handleCloseviewattachment}
            ></i>
          </div>
          <div className="meeting-attachment-image">
            {props.attachmentUrl.map((data, i) => (
              <Image
                key={i}
                src={
                  props.attachmentUrl !== ""
                    ? `${baseURL}/${data}`
                    : viewattachment
                }
                alt="attachment-photo"
                className="attachment-image"
              />
            ))}
          </div>

          <div className="attach-print-button">
            <SbCancelBtn
              btnName="Close"
              onClickEffect={props.handleCloseviewattachment}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ViewClaimAttachmentmodel;
