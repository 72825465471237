import React, { useState, useRef } from "react";
import {
  OverlayTrigger,
  Tooltip,
  Form,
  FloatingLabel,
  Accordion,
} from "react-bootstrap";
import moment from "moment";
import { NumberValidation } from "../ValidationFormat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PaymentDetails from "./PaymentDetails";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import { modeOfPaymentOption } from "../DropdownsData";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { calculateDaysDifference } from "../FormattedAmount";

export default function TicketDetailsTable(props) {
  const [paymentType, setPaymentType] = useState("By MOT");
  const [modeOfPayment, setModeOfPayment] = useState(1);
  const [upiMobileNum, setUPIMobileNum] = useState(null);
  const [utrNumber, setUTRNumber] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);
  const [nameonCard, setNameonCard] = useState(null);
  const [paymentPaid, setPaymentPaid] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [nameoftheAccount, setNameoftheAccount] = useState(null);

  const handleModeOfPayemnt = (val, motName) => {
    setModeOfPayment(val);
  };

  const hiddenFileInput = useRef(null);
  const uploadClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    console.log("FILE::", event.target.files[0]);
  };

  const uploadTicketNPaymentDetails = () => {
    console.log("Submit-Data", "Data Submitted");
  };

  return (
    <div>
      {props.pageViewBy === "TicketBookingPerson" && (
        <div className="payment-type">
          <div className="radio-heading">Payment Type : </div>
          <div className="dflex-j-start mt-1">
            {["By MOT", "Single Entry"].map((item, i) => (
              <Form.Check
                inline
                label={item}
                value={item}
                className="paymentType-radio"
                name="paymentType"
                type="radio"
                id={`radio-paymentType-${i}`}
                onChange={(e) => setPaymentType(e.target.value)}
                checked={item === paymentType ? true : false}
              />
            ))}
          </div>
        </div>
      )}
      <div className="heading">TICKET DETAILS</div>
      <>
        {props.travelByOptions.map((data, i) =>
          data.name !== "Hotel" &&
          data.name !== "Advance Cash" &&
          data.name !== "Other" ? (
            <div className="ticket-details-by-travel" key={i}>
              <div className="travel-type-status">
                <div className="name-n-icon">
                  <i
                    className={`fa ${
                      data.name === "Flight"
                        ? "fa-plane"
                        : data.name === "Train"
                        ? "fa-train"
                        : data.name === "Bus"
                        ? "fa-bus"
                        : data.name === "Cab"
                        ? "fa-taxi"
                        : ""
                    }`}
                  />
                  &nbsp; &nbsp;{data.name}
                </div>
                <div className="trl-type">
                  Type : {props.travelDetails[data.name][0].tripType}
                </div>
              </div>
              {props.travelDetails[data.name].map((item) => (
                <>
                  <div className="ticket-details" key={item.id}>
                    <div className="dflex-j-start" style={{ width: "25%" }}>
                      <div>
                        <span>From :</span> <br />
                        <b className="color-yellow">
                          {props.pageName === "UserTripRequest"
                            ? item?.fromLocation[0]?.label
                            : item?.fromLocation}
                        </b>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                      <div>
                        <i className="fa fa-angle-double-right" />
                        <i className="fa fa-angle-double-right" />
                        <i className="fa fa-angle-double-right" />
                        <i className="fa fa-angle-double-right" />
                        <i className="fa fa-angle-double-right" />
                        <i className="fa fa-angle-double-right" />
                      </div>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div>
                        <span>To : </span>
                        <br />
                        <b className="color-yellow">
                          {props.pageName === "UserTripRequest"
                            ? item?.toLocation[0].label
                            : item?.toLocation}
                        </b>
                      </div>
                    </div>

                    <div className="text-center" style={{ width: "12%" }}>
                      <div>Travellers</div>
                      <div>
                        <b className="color-green">{item?.travellers}</b>
                      </div>
                    </div>
                    <div style={{ width: "24%" }}>
                      <div>
                        Departure &nbsp;
                        <i className="fa fa-arrow-right" />
                      </div>

                      <div className="dflex-j-start">
                        <div>
                          {props.pageViewBy === "User" &&
                          props.pageName !== "UserTripRequest" ? (
                            <div>
                              <div className="fz13">
                                Requested : &nbsp;
                                {moment(item?.departureDateTime).format(
                                  "DD/MM/YYYY"
                                )}
                                &nbsp;
                                {moment(item?.departureDateTime).format(
                                  "h:mm A"
                                )}
                              </div>
                              <div>
                                <b className="color-green">
                                  Actual : &nbsp;
                                  {/* {item?.departureDateTime ?? ""} */}
                                  {moment(item?.departureDateTime).format(
                                    "DD/MM/YYYY"
                                  )}
                                  &nbsp;
                                  {moment(item?.departureDateTime).format(
                                    "h:mm A"
                                  )}
                                </b>
                              </div>
                            </div>
                          ) : (
                            <>
                              <b className="color-green">
                                {/* {item?.departureDateTime ?? ""} */}
                                {moment(item?.departureDateTime).format(
                                  "DD/MM/YYYY"
                                )}
                                &nbsp;
                                {moment(item?.departureDateTime).format(
                                  "h:mm A"
                                )}
                              </b>
                              {props.pageViewBy === "TicketBookingPerson" && (
                                <div className="react-date-time-picker mt-3 mb-1">
                                  <FloatingLabel
                                    label="Actual Departure"
                                    className={`${
                                      props.paymentDate
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                    }`}
                                  >
                                    <DatePicker
                                      selected={props.paymentDate}
                                      className="select date-time-picker"
                                      id="date-time-picker"
                                      showTimeSelect
                                      dateFormat="dd/MM/yyyy"
                                      name="paymentDate"
                                      // minDate={new Date()}
                                      onChange={(date) =>
                                        props.setPaymentDate(date)
                                      }
                                      maxDate={new Date()}
                                      placeholderText="Select Date - Time"
                                      timeIntervals={15}
                                    />
                                  </FloatingLabel>
                                </div>
                              )}
                              {/* {moment(item?.departureDateTime).format(
                                "DD/MM/YYYY"
                              )}
                              &nbsp;
                              {moment(item?.departureDateTime).format("h:mm A")} */}
                            </>
                          )}
                        </div>
                        {props.pageViewBy === "User" &&
                        props.pageName !== "UserTripRequest" ? (
                          <div className="ticket-attachment">
                            <OverlayTrigger
                              placement={"top"}
                              delay={{ show: 250, hide: 300 }}
                              overlay={
                                <Tooltip id={`tooltip-remarks`}>
                                  Get Ticket
                                </Tooltip>
                              }
                            >
                              <div>
                                <i className="fa fa-paperclip" />
                              </div>
                            </OverlayTrigger>
                          </div>
                        ) : props.pageViewBy === "TicketBookingPerson" ? (
                          <div className="ticket-attachment">
                            <OverlayTrigger
                              placement={"top"}
                              delay={{ show: 250, hide: 300 }}
                              overlay={
                                <Tooltip id={`tooltip-remarks`}>
                                  Upload Ticket
                                </Tooltip>
                              }
                            >
                              <div>
                                <button
                                  type="button"
                                  className="btn upload-ticket"
                                  onClick={uploadClick}
                                >
                                  <span className="material-symbols-outlined">
                                    upload_file
                                  </span>
                                </button>
                                <input
                                  id="getFile"
                                  type="file"
                                  name="filetobase64"
                                  accept=".pdf, image/*"
                                  className="btn-primary upload"
                                  ref={hiddenFileInput}
                                  onChange={handleChange}
                                  style={{ display: "none" }}
                                />
                              </div>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div style={{ width: "24%" }}>
                      <div>
                        Return &nbsp;
                        <i className="fa fa-arrow-left" />
                      </div>
                      <div className="dflex-j-start">
                        <div>
                          {props.travelDetails[data.name][0]?.tripType ===
                          "Round" ? (
                            <>
                              {props.pageViewBy === "User" &&
                              props.pageName !== "UserTripRequest" ? (
                                <div>
                                  <div>
                                    Requested :
                                    {item.returnDateTime
                                      ? moment(item?.returnDateTime).format(
                                          "DD/MM/YYYY"
                                        )
                                      : ""}
                                    &nbsp;
                                    {item.returnDateTime
                                      ? moment(item?.returnDateTime).format(
                                          "h:mm A"
                                        )
                                      : ""}
                                  </div>
                                  <div>
                                    <>
                                      <b className="color-green">
                                        Actual :
                                        {item.returnDateTime
                                          ? moment(item?.returnDateTime).format(
                                              "DD/MM/YYYY"
                                            )
                                          : ""}
                                        &nbsp;
                                        {item.returnDateTime
                                          ? moment(item?.returnDateTime).format(
                                              "h:mm A"
                                            )
                                          : ""}
                                      </b>
                                    </>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <b className="color-green">
                                    {item.returnDateTime
                                      ? moment(item?.returnDateTime).format(
                                          "DD/MM/YYYY"
                                        )
                                      : ""}
                                    &nbsp;
                                    {item.returnDateTime
                                      ? moment(item?.returnDateTime).format(
                                          "h:mm A"
                                        )
                                      : ""}
                                  </b>
                                  {props.pageViewBy ===
                                    "TicketBookingPerson" && (
                                    <div className="react-date-time-picker mt-3 mb-1">
                                      <FloatingLabel
                                        label="Actual Return"
                                        className={`${
                                          props.paymentDate
                                            ? "float-visible-select"
                                            : "float-hidden float-select"
                                        }`}
                                      >
                                        <DatePicker
                                          selected={props.paymentDate}
                                          className="select date-time-picker"
                                          id="date-time-picker"
                                          showTimeSelect
                                          dateFormat="dd/MM/yyyy"
                                          name="paymentDate"
                                          // minDate={new Date()}
                                          onChange={(date) =>
                                            props.setPaymentDate(date)
                                          }
                                          maxDate={new Date()}
                                          placeholderText="Select Date - Time"
                                          timeIntervals={15}
                                        />
                                      </FloatingLabel>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          {props.travelDetails[data.name][0]?.tripType ===
                            "Round" &&
                          props.pageViewBy === "User" &&
                          props.pageName !== "UserTripRequest" ? (
                            <div className="ticket-attachment">
                              <OverlayTrigger
                                placement={"top"}
                                delay={{ show: 250, hide: 300 }}
                                overlay={
                                  <Tooltip id={`tooltip-remarks`}>
                                    Get Ticket
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <i className="fa fa-paperclip" />
                                </div>
                              </OverlayTrigger>
                            </div>
                          ) : props.travelDetails[data.name][0]?.tripType ===
                              "Round" &&
                            props.pageViewBy === "TicketBookingPerson" ? (
                            <div className="ticket-attachment">
                              <OverlayTrigger
                                placement={"top"}
                                delay={{ show: 250, hide: 300 }}
                                overlay={
                                  <Tooltip id={`tooltip-remarks`}>
                                    Upload Ticket
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <button
                                    type="button"
                                    className="btn upload-ticket"
                                    onClick={uploadClick}
                                  >
                                    <span className="material-symbols-outlined">
                                      upload_file
                                    </span>
                                  </button>
                                  <input
                                    id="getFile"
                                    type="file"
                                    name="filetobase64"
                                    accept=".pdf, image/*"
                                    className="btn-primary upload"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{ display: "none" }}
                                  />
                                </div>
                              </OverlayTrigger>
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    {props.loggedInBy === "Admin" && (
                      <div style={{ width: "15%" }}>
                        <div>
                          <div>
                            <Form.Check
                              inline
                              label="Approve"
                              value="Approve"
                              className="approve-radio-btn"
                              id={item.id}
                              name={`${item.id}-tripTypeOption`}
                              type="radio"
                              onChange={() =>
                                props.setMotHandle(
                                  item.id,
                                  data.name,
                                  "Approve"
                                )
                              }
                              checked={item.status && item.status === "Approve"}
                            />
                            <Form.Check
                              inline
                              label="Reject"
                              value="Reject"
                              className="reject-radio-btn"
                              name={`${item.id}-tripTypeOption`}
                              type="radio"
                              id={item.id}
                              onChange={() =>
                                props.setMotHandle(item.id, data.name, "Reject")
                              }
                              checked={item.status && item.status === "Reject"}
                            />
                          </div>
                        </div>
                        <div className="dflex-j-start">
                          {item.status && item.status === "Reject" && (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Remarks"
                              name={`${item.id}-tripTypeOption`}
                              value={item?.remarks}
                              onChange={(e) =>
                                props.setMotHandle(
                                  item.id,
                                  data.name,
                                  e.target.value,
                                  "remark"
                                )
                              }
                              // required
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {props.pageViewBy === "TicketBookingPerson" &&
                  paymentType === "By MOT" ? (
                    <div className="payment-details-for-each-mot">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="clickfor-paymentOption">
                              <span className="material-symbols-outlined">
                                paid
                              </span>{" "}
                              <span>Payment Information</span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="payment-details-part">
                              <PaymentDetails
                                motName={data.name}
                                modeOfPaymentOption={modeOfPaymentOption}
                                handleModeOfPayemnt={handleModeOfPayemnt}
                                modeOfPayment={modeOfPayment}
                                paymentDate={paymentDate}
                                setPaymentDate={setPaymentDate}
                                paymentPaid={paymentPaid}
                                setPaymentPaid={setPaymentPaid}
                                NumberValidation={NumberValidation}
                                utrNumber={utrNumber}
                                setUTRNumber={setUTRNumber}
                                upiMobileNum={upiMobileNum}
                                setUPIMobileNum={setUPIMobileNum}
                                bankName={bankName}
                                setBankName={setBankName}
                                cardNumber={cardNumber}
                                setCardNumber={setCardNumber}
                                nameonCard={nameonCard}
                                setNameonCard={setNameonCard}
                                accountNumber={accountNumber}
                                setAccountNumber={setAccountNumber}
                                nameoftheAccount={nameoftheAccount}
                                setNameoftheAccount={setNameoftheAccount}
                              />
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          ) : (
            data.name === "Hotel" && (
              <>
                <div className="ticket-details-by-travel" key={i}>
                  <div className="travel-type-status">
                    <div className="name-n-icon">
                      <i
                        className={`fa ${
                          data.name === "Hotel" ? "fa-building" : ""
                        }`}
                      />
                      &nbsp; &nbsp;{data?.name}
                    </div>
                  </div>
                  {props.travelDetails[data.name].map((item) => (
                    <div className="ticket-details" key={item.id}>
                      <div style={{ width: "20%" }}>
                        <span>Location :</span> <br />
                        <b className="color-yellow">
                          {props.pageName === "UserTripRequest"
                            ? item?.location[0].label
                            : item?.location}
                        </b>
                        &nbsp;&nbsp;&nbsp;
                      </div>
                      <div className="dflex-j-between" style={{ width: "25%" }}>
                        <div>
                          <span>
                            Check-In &nbsp; <i className="fa fa-sign-in" />
                          </span>
                          <br />
                          <b className="color-green">
                            {/* {item?.checkInDate} */}
                            {item?.checkInDate
                              ? moment(item?.checkInDate).format("DD/MM/YYYY")
                              : "NA"}
                          </b>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div>
                          <i className="fa fa-angle-double-right" />
                          <i className="fa fa-angle-double-right" />
                          <i className="fa fa-angle-double-right" />
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div>
                          <span>
                            Check-Out &nbsp; <i className="fa fa-sign-out" />
                          </span>
                          <br />
                          <b className="color-green">
                            {/* {item?.checkOutDate} */}
                            {item?.checkOutDate
                              ? moment(item?.checkOutDate).format("DD/MM/YYYY")
                              : "NA"}
                          </b>
                        </div>
                      </div>
                      <div className="text-center" style={{ width: "10%" }}>
                        <div>No. Of Days</div>
                        <div>
                          <b className="color-green">{`${calculateDaysDifference(
                            item?.checkInDate,
                            item?.checkOutDate
                          )}`}</b>
                        </div>
                      </div>

                      <div className="text-center" style={{ width: "10%" }}>
                        <div>Travellers</div>
                        <div>
                          <b className="color-green">{item?.travellers}</b>
                        </div>
                      </div>
                      <div className="text-center" style={{ width: "10%" }}>
                        <div>Rooms</div>
                        <div>
                          <b className="color-green">{item?.rooms}</b>
                        </div>
                      </div>
                      {props.loggedInBy === "Admin" && (
                        <div style={{ width: "15%" }}>
                          <div>
                            <div>
                              <Form.Check
                                inline
                                label="Approve"
                                value="Approve"
                                className="approve-radio-btn"
                                id={item.id}
                                name={`${item.id}-tripTypeOption`}
                                type="radio"
                                onChange={() =>
                                  props.setMotHandle(
                                    item.id,
                                    data.name,
                                    "Approve"
                                  )
                                }
                                checked={
                                  item.status && item.status === "Approve"
                                }
                              />
                              <Form.Check
                                inline
                                label="Reject"
                                value="Reject"
                                className="reject-radio-btn"
                                name={`${item.id}-tripTypeOption`}
                                type="radio"
                                id={item.id}
                                onChange={() =>
                                  props.setMotHandle(
                                    item.id,
                                    data.name,
                                    "Reject"
                                  )
                                }
                                checked={
                                  item.status && item.status === "Reject"
                                }
                              />
                            </div>
                          </div>
                          <div className="dflex-j-start">
                            {item.status && item.status === "Reject" && (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Remarks"
                                name={`${item.id}-tripTypeOption`}
                                value={item?.remarks}
                                onChange={(e) =>
                                  props.setMotHandle(
                                    item.id,
                                    data.name,
                                    e.target.value,
                                    "remark"
                                  )
                                }
                                // required
                              />
                            )}
                          </div>
                        </div>
                      )}
                      <div>
                        {props.pageViewBy === "User" &&
                        props.pageName !== "UserTripRequest" ? (
                          <div className="ticket-attachment">
                            <OverlayTrigger
                              placement={"top"}
                              delay={{ show: 250, hide: 300 }}
                              overlay={
                                <Tooltip id={`tooltip-remarks`}>
                                  Get Ticket
                                </Tooltip>
                              }
                            >
                              <div>
                                <i className="fa fa-paperclip" />
                              </div>
                            </OverlayTrigger>
                          </div>
                        ) : props.pageViewBy === "TicketBookingPerson" ? (
                          <div className="ticket-attachment">
                            <OverlayTrigger
                              placement={"top"}
                              delay={{ show: 250, hide: 300 }}
                              overlay={
                                <Tooltip id={`tooltip-remarks`}>
                                  Upload Receipt
                                </Tooltip>
                              }
                            >
                              <div>
                                <button
                                  type="button"
                                  className="btn upload-ticket"
                                  onClick={uploadClick}
                                >
                                  <span className="material-symbols-outlined">
                                    upload_file
                                  </span>
                                </button>
                                <input
                                  id="getFile"
                                  type="file"
                                  name="filetobase64"
                                  accept=".pdf, image/*"
                                  className="btn-primary upload"
                                  ref={hiddenFileInput}
                                  onChange={handleChange}
                                  style={{ display: "none" }}
                                />
                              </div>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          "- "
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                {props.pageViewBy === "TicketBookingPerson" &&
                paymentType === "By MOT" ? (
                  <div className="payment-details-for-each-mot">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="clickfor-paymentOption">
                            <span className="material-symbols-outlined">
                              paid
                            </span>{" "}
                            <span>Payment Information</span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="payment-details-part">
                            <PaymentDetails
                              motName={data.name}
                              modeOfPaymentOption={modeOfPaymentOption}
                              handleModeOfPayemnt={handleModeOfPayemnt}
                              modeOfPayment={modeOfPayment}
                              paymentDate={paymentDate}
                              setPaymentDate={setPaymentDate}
                              paymentPaid={paymentPaid}
                              setPaymentPaid={setPaymentPaid}
                              NumberValidation={NumberValidation}
                              utrNumber={utrNumber}
                              setUTRNumber={setUTRNumber}
                              upiMobileNum={upiMobileNum}
                              setUPIMobileNum={setUPIMobileNum}
                              bankName={bankName}
                              setBankName={setBankName}
                              cardNumber={cardNumber}
                              setCardNumber={setCardNumber}
                              nameonCard={nameonCard}
                              setNameonCard={setNameonCard}
                              accountNumber={accountNumber}
                              setAccountNumber={setAccountNumber}
                              nameoftheAccount={nameoftheAccount}
                              setNameoftheAccount={setNameoftheAccount}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                ) : (
                  ""
                )}
              </>
            )
          )
        )}
      </>
      <>
        {props.pageViewBy === "TicketBookingPerson" &&
        paymentType === "Single Entry" ? (
          <>
            <div className="booking-payment-details">
              <div className="heading">
                <h6>Payment Details</h6>
              </div>
              <PaymentDetails
                motName="allmot"
                modeOfPaymentOption={modeOfPaymentOption}
                handleModeOfPayemnt={handleModeOfPayemnt}
                modeOfPayment={modeOfPayment}
                paymentDate={paymentDate}
                setPaymentDate={setPaymentDate}
                paymentPaid={paymentPaid}
                setPaymentPaid={setPaymentPaid}
                NumberValidation={NumberValidation}
                utrNumber={utrNumber}
                setUTRNumber={setUTRNumber}
                upiMobileNum={upiMobileNum}
                setUPIMobileNum={setUPIMobileNum}
                bankName={bankName}
                setBankName={setBankName}
                cardNumber={cardNumber}
                setCardNumber={setCardNumber}
                nameonCard={nameonCard}
                setNameonCard={setNameonCard}
                accountNumber={accountNumber}
                setAccountNumber={setAccountNumber}
                nameoftheAccount={nameoftheAccount}
                setNameoftheAccount={setNameoftheAccount}
              />
            </div>
          </>
        ) : (
          " "
        )}
      </>
      <>
        {props.pageViewBy === "TicketBookingPerson" && (
          <div className="ticket-upload-submit-button mt-2">
            <SBSaveUpdateBtn
              onClickEffect={uploadTicketNPaymentDetails}
              btnName="Submit"
            />
          </div>
        )}
      </>
    </div>
  );
}
