import React, { useEffect, useState } from "react";
import "../src/styles/SignIn.scss";
import "../src/styles/CommonStyle.scss";
import "../src/MediaFiles/Media.scss";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "./Components/BaseUrl";
import { Button, Col, Image, Row, Carousel } from "react-bootstrap";
import travelize from "./images/metric-favicon.svg";
import travelizeSalesBeeLogo from "./images/MatricLogo.svg";
import InternetIssue from "./Components/InternetIssue";
import { toast } from "react-toastify";
import * as HeaderAuthentication from "../src/Components/HeaderAuthentication";
import { useDispatch } from "react-redux";
import { loadGetOTP } from "./redux/action";
import clientLogo from "./images/Client-Logo.svg";
import SignInGifImag from "./SignInGifImag";

const SignIn = () => {
  let themeColor = localStorage.getItem("theme");
  let navigate = useNavigate();
  let dispatch = useDispatch();

  let clientLoginUrl = window.location.hash;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [loginResponse, setLoginResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [rememberMe, setRememberMe] = useState(false);

  const [isValidEmail, setIsValidEmail] = useState(false);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const navigateToDashboard = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    // Check if "Remember Me" is checked and email is stored in localStorage
    const rememberedCredentials = JSON.parse(
      localStorage.getItem("rememberedCredentials")
    );

    if (rememberedCredentials) {
      setEmail(rememberedCredentials.email);
      setPassword(rememberedCredentials.pswd);
      setRememberMe(true);
    } else {
      setEmail("");
      setPassword("");
      setRememberMe(false);
    }
  }, []);

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const loginValidation = (e) => {
    if (email && password) {
      const userNamePassword = {
        UserName: email,
        Password: password,
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userNamePassword),
      };
      fetch(`${baseURL}/api/Authentication/Login`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toast.success(`Login ${res.message}`);
            setLoginStatus(`Login ${res.message}`);
            sessionStorage.setItem("usdtls", JSON.stringify(res.result));

            let requestOptionS = {
              method: "GET",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Travelize_Authentication: res.result,
              },
            };
            fetch(
              `${baseURL}/api/DashBoard/GetLoginUserDetails`,
              requestOptionS
            )
              .then((res) => res.json())
              .then((res) => {
                const subscriptionExpiry = res?.result?.subscription?.daysleft;
                if (subscriptionExpiry == 0) {
                  navigate("/subscriptionexpiry");
                } else {
                  const userLoginDetails = res.result;
                  sessionStorage.setItem("loginId", userLoginDetails.userId);
                  sessionStorage.setItem(
                    "usrlgndtls",
                    JSON.stringify(userLoginDetails)
                  );
                  if (
                    userLoginDetails?.desingation?.role?.roleName === "Admin" &&
                    userLoginDetails?.subscriptionId === "SUB0000000001"
                  ) {
                    sessionStorage.setItem("AdminRole", true);
                  }
                  if (
                    userLoginDetails?.desingation?.role?.roleName === "Admin"
                  ) {
                    sessionStorage.setItem("AdminAccess", true);
                  }
                  if (
                    userLoginDetails?.desingation?.role?.roleName === "Manager"
                  ) {
                    sessionStorage.setItem("ManagerAccess", true);
                  }
                  if (!themeColor) {
                    localStorage.setItem("theme", "light");
                  }
                  // Remember the email if "Remember Me" is checked
                  if (rememberMe) {
                    let credentials = {
                      email: email,
                      pswd: password,
                      remember: rememberMe,
                    };
                    localStorage.setItem(
                      "rememberedCredentials",
                      JSON.stringify(credentials)
                    );
                  } else {
                    localStorage.removeItem("rememberedCredentials");
                  }
                  navigateToDashboard();
                }
              })
              .catch((error) => {
                console.log("ERROR :", error);
                setErrorMessage(error.message);
              });
          } else {
            console.log(res, "res.errorMessage");
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setErrorMessage(error.message);
        });
    } else {
      toast.error("Username/Password should not be empty!");
    }
    e.preventDefault();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          <div className="container-fluid">
            <div className="sigin-page-body">
              <div className="dflex-flow-row">
                <Col
                  lg={5}
                  md={5}
                  sm={12}
                  xs={12}
                  className="signIn-container-left-div"
                >
                  <SignInGifImag />
                </Col>
                <Col lg={2} md={2}>
                  <div className="devider">
                    <div className="v1"></div>
                  </div>
                </Col>
                <Col
                  lg={5}
                  md={5}
                  sm={12}
                  xs={12}
                  className="signIn-container-right-div"
                >
                  <div className="metricinfo-logo">
                    <Image
                      src={travelizeSalesBeeLogo}
                      alt="MetricInfo"
                      className="signIn-logo-small-device"
                    />
                    <Image
                      src={travelize}
                      alt="MetricInfo Logo"
                      className="sb-trv-logo"
                    />
                  </div>
                  <div className="signin-header login-part">
                    <div className="signin-box">
                      {clientLoginUrl === "#/barrix" && (
                        <div className="whitle-labeling-logo">
                          <Image
                            src={clientLogo}
                            alt="MetricInfo"
                            className="sb-login-logo"
                          />
                        </div>
                      )}
                      <div
                        className={
                          clientLoginUrl === "#/barrix"
                            ? "login-header-part cmpny-logo"
                            : "login-header-part"
                        }
                      >
                        <h2 className="mb-1">Welcome back!</h2>
                        <h4>Please sign in to continue</h4>
                      </div>
                      <form
                        onSubmit={loginValidation}
                        className="login-form"
                        autoComplete="off"
                      >
                        <div className="form-group mb20">
                          <div className="icon-with-input before">
                            <i className="fa fa-envelope" />
                            <input
                              type="email"
                              className="form-control signIn-form-control"
                              placeholder="Enter your email"
                              value={email}
                              // onChange={(e) => setEmail(e.target.value)}
                              onChange={handleEmailChange}
                              required
                              maxLength={50}
                              name="email" // Add name attribute
                              autoComplete="username" // Add autocomplete attribute
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="icon-with-input before">
                            <i className="fa fa-lock"></i>
                            <input
                              type={passwordType}
                              className="form-control signIn-form-control"
                              placeholder="Enter your password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              maxLength={20}
                              minLength={6}
                              name="password" // Add name attribute
                              autoComplete="current-password" // Add autocomplete attribute
                            />
                            <div className="pass-hide-show">
                              {passwordType === "password" ? (
                                <i
                                  className="bi bi-eye-slash pass-hide-show"
                                  onClick={togglePassword}
                                />
                              ) : (
                                <i
                                  className="bi bi-eye pass-hide-show"
                                  onClick={togglePassword}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="forgot-pass-n-get-otp dflex-j-between">
                          <div className="remember-me dflex-j-start">
                            <input
                              type="checkbox"
                              checked={rememberMe}
                              onChange={toggleRememberMe}
                            />
                            &nbsp;&nbsp;
                            <div className="remember-text color-green">
                              Remember me
                            </div>
                          </div>

                          <div className="forget-pasword-div">
                            <Link
                              to={
                                clientLoginUrl === "#/barrix"
                                  ? "/barixforgotpassword"
                                  : "/updatedforgotPassword"
                              }
                              className="forgot-pass-text color-yellow"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                        </div>

                        <div className="signin-button mb20 hv-center">
                          <Button
                            type="submit"
                            className="btn btn-signin-button"
                          >
                            Sign In
                          </Button>
                        </div>
                      </form>

                      <div className="signIn-footer mt20">
                        <div className="create-account-div">
                          New to MetricInfo?&nbsp;
                          <Link
                            to="/signup"
                            className="login-signup-text color-green"
                          >
                            Create an Account
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignIn;
