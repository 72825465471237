import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

export default function SummaryTable(props) {
  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
        //   onScroll={(e) =>
        //     handleScroll(
        //       e,
        //       props.users,
        //       props.totalUserCount,
        //       props.setLoading,
        //       props.setPageSize,
        //       props.PageSize
        //     )
        //   }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="vendor-summary-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((data, i) => (
                  <TableCell key={i} align={data?.align}>
                    {data?.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.tableData.map((data, i) => (
                <TableRow key={i}>
                  <TableCell>{data?.code}</TableCell>
                  <TableCell>{data?.name}</TableCell>
                  <TableCell>
                    <Link to="">{data?.po}</Link>
                  </TableCell>
                  <TableCell>
                    <Link to="">{data?.invoice}</Link>
                  </TableCell>
                  <TableCell>
                    <Link to="">{data?.payment}</Link>
                  </TableCell>
                  <TableCell align="center">
                    <Link to="">View</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
