import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Products.scss";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { baseURL } from "../BaseUrl";
import { travelizeAuth } from "../HeaderAuthentication";
import * as swal from "../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddPakagingModel(props) {
  let lgnDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [productsData, setProductsData] = useState(props.productData);
  const [packagingTypeList, setPackagingTypeList] = useState([]);
  const [packagingDetails, setPackagingDetails] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const [packagingLevels, setPackagingLevels] = useState([
    {
      level: "Type-1",
      packagingName: "",
      packagingTypeID: "",
      packagingQty: 0,
    },
  ]);

  useEffect(() => {
    fectDropdownData();
  }, []);

  const fectDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/ProductPackaging/BindDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setPackagingTypeList(
            res.result.map((data) => {
              return { value: data.productID, label: data.productName };
            })
          );
        } else {
          setPackagingTypeList([]);
        }
      });
  };

  useEffect(() => {
    fetchProductPakagingDetails();
  }, [props.productData]);

  const fetchProductPakagingDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/ProductPackaging/Get/${productsData.productID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setPackagingDetails(res.result);
        } else {
          setPackagingDetails([]);
        }
      });
  };

  // ADD PACKAGING AND SET VALUE
  const addNextPackagingType = (e) => {
    e.preventDefault();
    let selectCount = packagingLevels.length + 1;
    setPackagingLevels([
      ...packagingLevels,
      {
        level: `Type-${selectCount}`,
        packagingName: "",
        packagingTypeID: "",
        packagingQty: 0,
      },
    ]);
  };

  const setPackagingTypeName = (data, packLevel) => {
    let fiterObj = packagingLevels.filter((item) => item.level == packLevel);
    fiterObj[0].packagingTypeID = data.value;
    fiterObj[0].packagingName = data.label;
    setPackagingLevels([...packagingLevels]);
  };

  const handlePakagingQty = (event) => {
    let fiterObj = packagingLevels.filter(
      (item) => item.level == event.target.name
    );
    fiterObj[0].packagingQty = +event.target.value;
    setPackagingLevels([...packagingLevels]);
    // console.log("QTY:", event.target.id, event.target.value);
  };

  const removeLevelsPackaging = (index) => {
    let finalPackagingLevels = packagingLevels.filter((data, i) => i != index);
    setPackagingLevels(finalPackagingLevels);
  };

  useEffect(() => {
    let filteredIDs = packagingLevels.map((data) => data.packagingTypeID);
    setSelectedIds(filteredIDs);
  }, [packagingLevels]);

  const addProductPackaging = (e) => {
    // console.log("POST:::::999", packagingLevels);
    e.preventDefault();
    // let postData = {
    //   productID: productsData?.productID,
    //   subscriptionID: lgnDetails.subscriptionId,
    //   clientId: 0,
    //   packagingID: packagingLevels[0].packagingTypeID,
    //   packagingName: packagingLevels[0].packagingName,
    //   packagingQTY: packagingLevels[0].packagingQty,
    //   addedByUserFullName: lgnDetails.userId,
    //   status: 1,
    // };
    // let requestOption = {
    //   method: "POST",
    //   mode: "cors",
    //   headers: travelizeAuth,
    //   body: JSON.stringify(postData),
    // };
    // console.log("DATA::", postData);
    // fetch(`${baseURL}/api/ProductPackaging/AddProductPackaging`, requestOption)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     alert(res.message);
    //     console.log("RESP:", res);
    //   });
    for (let i = 0; i < packagingLevels.length; i++) {
      let postData = {
        productID: productsData?.productID,
        subscriptionID: lgnDetails.subscriptionId,
        clientId: 0,
        packagingID: packagingLevels[i].packagingTypeID,
        packagingName: packagingLevels[i].packagingName,
        packagingQTY: packagingLevels[i].packagingQty,
        addedByUserFullName: lgnDetails.userId,
        status: 1,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(postData),
      };
      fetch(
        `${baseURL}/api/ProductPackaging/AddProductPackaging`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.closeModel();
            props.addPackagingModel();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage, { autoClose: 5000 });
          }
        });
    }
  };

  // ADD PACKAGING AND SET VALUE

  return (
    <div>
      <Modal
        show={props.addPakagingModel}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            ADD PACKAGING TO ({productsData.productName})
          </h6>
          <i className="fa fa-times" onClick={props.addPackagingModel} />
        </div>
        <form>
          <div className="container add-edit-packaging">
            {packagingLevels.map((pack, i) => (
              <div className="dflex-j-between mb-3" key={i}>
                <FloatingLabel
                  label="Packaging Type"
                  className={`${
                    pack.packagingTypeID !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                  style={{ width: "100%", paddingRight: "10px" }}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    id={pack.packagingTypeID}
                    name={pack.level}
                    options={packagingTypeList.filter((pak) =>
                      selectedIds.every((val) => {
                        if (pak.value !== val) {
                          return pak;
                        }
                      })
                    )}
                    placeholder="Select Packaging Type"
                    value={packagingTypeList.value}
                    onInputChange={packagingTypeList.label}
                    onChange={(data) => setPackagingTypeName(data, pack.level)}
                    isSearchable={true}
                  />
                </FloatingLabel>

                <FloatingLabel
                  label="Packaging Quantity"
                  className={`${
                    pack.packagingQty !== 0
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                  style={{ width: "100%", paddingRight: "10px" }}
                >
                  <input
                    type="number"
                    id={pack.packagingTypeID}
                    className="form-control form add-product-input"
                    placeholder="Eneter Number of Quantity"
                    name={pack.level}
                    value={pack.packagingQty || ""}
                    onChange={(e) => handlePakagingQty(e)}
                  />
                </FloatingLabel>
                {i != 0 ? (
                  <div
                    className="remove-icon"
                    onClick={() => removeLevelsPackaging(i)}
                  >
                    <i className="fa fa-times" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
            {packagingLevels.length < 10 ? (
              <div className="mt-1 mb-1">
                <button
                  type="button"
                  className="btn btn-primiary-color"
                  onClick={(e) => addNextPackagingType(e)}
                >
                  <i className="fa fa-plus" /> &nbsp;Add
                </button>
              </div>
            ) : (
              ""
            )}
            <div>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>SL.NO.</th>
                    <th>PACKAGING TYPE</th>
                    <th>QTY</th>
                  </tr>
                </thead>
                <tbody>
                  {packagingDetails.length > 0 ? (
                    packagingDetails?.map((data, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{data?.packagingName}</td>
                        <td>{data?.packagingQTY}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">
                        --- NO DATA ---
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn
              btnName="Save"
              onClickEffect={addProductPackaging}
            />
            <SbCancelBtn
              btnName="Cancel"
              onClickEffect={props.addPackagingModel}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddPakagingModel;
