import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Meetings.scss";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import AccessDenied from "../../AccessDenied";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import UpGradeSubscriptionModal from "./UpGradeSubscriptionModal";
import {
  loadPageBreadCome,
  loadSubscriptionDropdown,
  loadSubsrDropdown,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SubscriptionDetailsTable from "./PrivatePageTables/SubscriptionDetailsTable";
import Footer from "../Footer";
import { travelizeAuth } from "../HeaderAuthentication";
import { daysLeftdropdown } from "../DropdownsData";

function SubscriptionDetails() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let dispatch = useDispatch();

  const { subscriptionTypeDropdown } = useSelector((state) => state.data);
  const { subCrDropdown } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [allSubscriptionDetails, setAllSubscriptionDetails] = useState([]);
  const [subType, setSubType] = useState(0);
  const [clientId, setClientId] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [daysLeft, setDaysLeft] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [openFilterTab, setOpenFilterTab] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [subScribeId, setSubScribeId] = useState("");

  let tableHeader = [
    { name: "TYPE", align: "left", width: "120px" },
    { name: "COMPANY DETAILS", align: "left", width: "150px" },
    { name: "CONTACT DETAILS", align: "left", width: "250px" },
    { name: "SUBSCRIPTION DETAILS", align: "left", width: "250px" },
    { name: "EXPIRY DETAILS", align: "left", width: "250px" },
    { name: "ACTION", align: "center", width: "100px" },
  ];

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  // PAGINATION END

  // Lazy Loading
  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // let subscriptionId = JSON.parse(
  //   sessionStorage.getItem("usdtls")
  // ).subscriptionId;

  useEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "Subscription Details" };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadSubscriptionDropdown());
    dispatch(loadSubsrDropdown(subType));
  }, [subType]);

  // GET SUBSCRIPTION PAGE START
  useEffect(() => {
    fetchAllSubscriptionDetails();
  }, [
    PageSize,
    currentPage,
    fromDate,
    toDate,
    subType,
    daysLeft,
    companyName,
    email,
  ]);

  const fetchAllSubscriptionDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subscription/GetAllSubscription?SubscriptionID=&SubscriptionTypeID=${subType}&SubscriptionName=${subscriptionName}&Email=${email}&CompanyName=${companyName}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&DaysLeft=${daysLeft}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAllSubscriptionDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setAllSubscriptionDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      });
  };
  // GET SUBSCRIPTION PAGE END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  // Open Upgrade Modal
  const openModal = (subId) => {
    setSubScribeId(subId);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  // Open Upgrade Modal

  const clearSerachBySelect = () => {
    setSubType(0);
    setDaysLeft(-1);
    setCompanyName("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  // Navigate to Back Page
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // Navigate to Back Page

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="meetings-table">
                  <div style={{ position: "relative" }}>
                    <Row className="search-row">
                      <Col md={3} sm={4} xs={12} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToBackPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">
                            SUBSCRIPTION DETAILS
                          </h6>
                        </div>
                      </Col>
                      <Col md={9} sm={8} xs={12} className="cat-col">
                        <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div
                              className="header-filter-btn"
                              onClick={() => setOpenFilterTab(!openFilterTab)}
                            >
                              <div className="filter-icon-text">
                                <i className="bi bi-sliders" />
                                <span>Filters</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {openFilterTab && (
                      <div className="page-filter-section">
                        <div className="filter-row-input-boxes">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={subCrDropdown}
                              placeholder="Select Company"
                              value={
                                companyName === "" ? "" : subCrDropdown.value
                              }
                              onInputChange={subCrDropdown.label}
                              onChange={(data) => setCompanyName(data.value)}
                              isSearchable={true}
                              // isClearable={true}
                            />
                          </div>
                          <div>
                            <input
                              type="email"
                              autoComplete="off"
                              className="form-control add-user-input"
                              placeholder="Enter E-Mail"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={subscriptionTypeDropdown}
                              placeholder="Select Type"
                              value={
                                subType === ""
                                  ? ""
                                  : subscriptionTypeDropdown.value
                              }
                              onInputChange={subscriptionTypeDropdown.label}
                              onChange={(data) => setSubType(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              // defaultValue={optionsRoleList[0].label}
                              options={daysLeftdropdown}
                              placeholder="Select Expiry"
                              value={
                                daysLeft === "" ? "" : daysLeftdropdown.value
                              }
                              onInputChange={daysLeftdropdown.label}
                              onChange={(data) => setDaysLeft(data.value)}
                              isSearchable={true}
                              // isClearable={true}
                            />
                          </div>

                          <div className="from-to-date-select">
                            <div className="input-group-text" id="from-label">
                              From - To
                            </div>
                            <div className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date date-filter"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                selectsRange
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) => setSelectSearchDrop(date)}
                                // maxDate={new Date()}
                              />
                              <div>
                                <i
                                  className="fa fa-calendar-o calander-icon"
                                  style={{ right: "6px" }}
                                />
                              </div>
                            </div>
                          </div>
                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <SubscriptionDetailsTable
                    tableHeader={tableHeader}
                    allSubscriptionDetails={allSubscriptionDetails}
                    openModal={openModal}
                    loading={loading}
                    setPageSize={setPageSize}
                    PageSize={PageSize}
                    setLoading={setLoading}
                    totalData={totalData}
                  />
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* Update Subscription */}
            {showModal && (
              <UpGradeSubscriptionModal
                show={showModal}
                handleClose={closeModal}
                subscriptionId={subScribeId}
                setSubScribeId={setSubScribeId}
              />
            )}
            {/* Update Subscription */}

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default SubscriptionDetails;
