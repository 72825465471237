import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Attendance.scss";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { loggedInRole, loggedInId } from "../LoginRole";
import SkeletonAttendance from "../Skeleton Loading/SkeletonAttendance";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import SbCancelBtn, { MiClearButton } from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import Footer from "../Footer";
import AttendanceBreakUps from "./AttendanceBreakUps";
import { travelizeAuth } from "../HeaderAuthentication";
import {
  loadManagerDropdown,
  loadUserDropdownByManager,
  loadStateList,
} from "../../redux/action";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

const statusOptions = [
  { value: "present", label: "Present" },
  { value: "leaves", label: "On Leave" },
  { value: "absent", label: "Not Logged In" },
  { value: "all", label: "All" },
];

function AttendanceFullday() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  /* HEADER AUTH END */
  let dispatch = useDispatch();
  let { usrId } = useParams();

  const { managerDropdown } = useSelector((state) => state.data);
  const { userDropdownByManager } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);

  // console.log("USRID:", usrId);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [filterUserId, setFilterUserId] = useState(usrId ?? "");
  const [attendenceFullDayFilter, setAttendenceFullDayFilter] = useState(
    statusOptions[0]
  );
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const [manager, setManager] = useState({
    value: loggedInId,
    label: "Select Manager",
  });
  const [stateID, setStateID] = useState(0);
  // const [stateName, setStateName] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [attendanceByDay, setAttendanceByDay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  let tableHeader = ["NAME", "DATE", "LOGIN", "LOGOUT", "TIME SPENT"];

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const currentPage = 1;
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };
  //* PAGINATION END *//

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadManagerDropdown());
    dispatch(loadUserDropdownByManager(manager.value));
    dispatch(loadStateList(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager]);
  //* FETCH DROPDOWN DATA END *//

  //* FETCH ATTENDANCE DATA START *//
  useEffect(() => {
    fetchAttendanceByDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fromDate,
    toDate,
    filterUserId,
    currentPage,
    PageSize,
    manager,
    stateID,
    attendenceFullDayFilter,
  ]);

  const fetchAttendanceByDay = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Attendance/GetAttendanceListAll?CurrentPage=${currentPage}&PageSize=${PageSize}&UserId=${filterUserId}&IncludeFilter=${
        attendenceFullDayFilter.value
      }&FromDate=${moment(fromDate).format("DD/MM/YYYY")}&ToDate=${moment(
        toDate
      ).format("DD/MM/YYYY")}&ManagerId=${manager.value}&StateID=${stateID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAttendanceByDay(res.result);
          setTotalData(res?.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setAttendanceByDay(res.result);
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH ATTENDANCE DATA END *//

  const [showModal, setShowModal] = useState(false);
  const [meetingID, setMeetingID] = useState("");
  const [userName, setUserName] = useState("");
  const [attenDate, setAttenDate] = useState("");

  const handleView = (meetID, name, date) => {
    setShowModal(true);
    setMeetingID(meetID);
    setUserName(name);
    setAttenDate(date);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManager({ value: loggedInId, label: "Select Manager" });
    setStateID(0);
    setFromDate(new Date());
    setToDate(new Date());
    setAttendenceFullDayFilter(statusOptions[0]);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelAttendance = () => {
    exportExcel(
      `${baseURL}/api/Export/AttendanceExcelExport?UserID=${filterUserId}&ManagerId=${
        manager.value
      }&StateID=${stateID}&IncludeFilter=${
        attendenceFullDayFilter.value
      }&FromDate=${moment(fromDate).format("DD/MM/YYYY")}&ToDate=${moment(
        toDate
      ).format("DD/MM/YYYY")}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      `Attendance-FullDay${filterUserId}.xlsx`,
      setDownloadIsLoading
    );
  };
  //* EXPORT FILE END *//
  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>
                  <div className="page-content">
                    {/* Filter and Header Section */}
                    <div className="user-table">
                      <Row className="search-row">
                        <Col md={8} sm={6} xs={6} className="cat-col">
                          <div className="page-header-text-div">
                            <div className="back-btn" onClick={goToBackPage}>
                              <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                              />
                            </div>
                            <h6 className="page-header-text">
                              {" "}
                              ATTENDANCE-FULLDAY{" "}
                            </h6>
                          </div>
                        </Col>

                        <Col md={4} sm={6} xs={6} className="cat-col">
                          <div
                            className="search-bar-header"
                            style={{ float: "right" }}
                          >
                            <div className="page-top-bar-dropdowns page-top-Filters">
                              <div
                                className="header-filter-btn"
                                onClick={() => setFilter(!filter)}
                              >
                                <p className="card-head">
                                  <i className="bi bi-sliders" />
                                  &nbsp;&nbsp;Filters
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {filter && (
                        <div className="page-filter-section">
                          <div className="filter-row-input-boxes">
                            <div>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                // defaultValue={optionsRoleList[0].label}
                                options={managerDropdown}
                                placeholder="Select Manager"
                                value={manager}
                                onInputChange={managerDropdown.label}
                                onChange={(data) => setManager(data)}
                                isSearchable={true}
                              />
                            </div>
                            <div>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={userDropdownByManager}
                                placeholder="Select User"
                                value={
                                  filterUserId === ""
                                    ? ""
                                    : userDropdownByManager.value
                                }
                                onInputChange={userDropdownByManager.label}
                                onChange={(data) => setFilterUserId(data.value)}
                                isSearchable={true}
                              />
                            </div>

                            <div>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={stateList}
                                placeholder="Select State"
                                value={stateID === 0 ? "" : stateList.value}
                                onInputChange={stateList.label}
                                onChange={(data) => setStateID(data.value)}
                                isSearchable={true}
                              />
                            </div>

                            <div className="from-to-date-select">
                              <div className="input-group-text" id="from-label">
                                From - To
                              </div>
                              <div className="date-picker-with-icon">
                                <DatePicker
                                  selected={fromDate}
                                  className="select from-date date-filter"
                                  id="date-range-picker"
                                  dateFormat="dd/MM/yyyy"
                                  selectsRange
                                  startDate={fromDate}
                                  endDate={toDate}
                                  onChange={(date) => setSelectSearchDrop(date)}
                                  maxDate={new Date()}
                                />
                                <div>
                                  <i
                                    className="fa fa-calendar-o calander-icon"
                                    style={{ right: "6px" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={statusOptions}
                                placeholder="Select"
                                value={attendenceFullDayFilter}
                                onInputChange={statusOptions.label}
                                onChange={(data) =>
                                  setAttendenceFullDayFilter(data)
                                }
                                isSearchable={true}
                              />
                            </div>

                            <MiClearButton
                              onClickEffect={clearSerachBySelect}
                              btnName="Clear"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Filter and Header Section */}

                    {/* USER TABLE START */}
                    <div className="attendance-table">
                      <div
                        className="table-responsive attendance-daily-scroll"
                        onScroll={(e) =>
                          handleScroll(
                            e,
                            attendanceByDay,
                            totalData,
                            setLoading,
                            setPageSize,
                            PageSize
                          )
                        }
                      >
                        <table className="table attendance-list-table">
                          {/* <thead className="thead-light attendance-list-thead">
                            <tr>
                              <th>NAME</th>
                              <th>DATE</th>
                              <th>LOGIN</th>
                              <th>LOGOUT</th>
                              <th>TIME SPENT</th>
                            </tr>
                          </thead> */}
                          <SbTableHeader tableHeader={tableHeader} />
                          <tbody>
                            {isLoading ? (
                              <>
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                                <SkeletonAttendance />
                              </>
                            ) : attendanceByDay !== "" ? (
                              attendanceByDay?.map((data, i) => (
                                <tr
                                  className="attendance-list-row-data cursor-pointer"
                                  key={i}
                                  onClick={() =>
                                    handleView(
                                      data.attendanceID,
                                      data?.userFullName,
                                      data?.attendanceDate
                                    )
                                  }
                                >
                                  <td>
                                    <span className="attendance-list-name d-flex">
                                      <img
                                        className="table-data-attendance-imgages"
                                        src={profileimg}
                                        alt="John Doe"
                                      />
                                      <div>
                                        <div className="table-row-head-Text color-green">
                                          {data?.userFullName}
                                        </div>
                                        <div className="table-row-sub-text">
                                          {data?.userCode}
                                        </div>
                                        <div className="table-row-sub-text">
                                          {data?.stateName}
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  <td>{data?.attendanceDate}</td>
                                  <td style={{ maxWidth: "200px" }}>
                                    <span className="login-details d-flex">
                                      <div className="table-row-head-Text">
                                        <b>
                                          {data?.inTime ??
                                            data.attendanceStatus}
                                        </b>
                                      </div>
                                    </span>

                                    {data?.checkInLoc?.location}
                                    <span className="login-time-location">
                                      <div className="table-row-sub-text">
                                        <i className="bi bi-geo-alt"></i>
                                        {data?.checkInLoc?.latitude},
                                        {data?.checkInLoc?.longitude}
                                      </div>
                                    </span>
                                  </td>
                                  <td style={{ maxWidth: "200px" }}>
                                    <span className="logout-details d-flex">
                                      <div className="table-row-head-Text">
                                        <b>{data?.outTime}</b>
                                      </div>
                                    </span>

                                    {data?.checkOutLoc?.location}
                                    <span className="logout-time-location">
                                      <div className="table-row-sub-text">
                                        <i className="bi bi-geo-alt"></i>
                                        {data?.checkOutLoc?.latitude},
                                        {data?.checkOutLoc?.longitude}
                                      </div>
                                    </span>
                                  </td>
                                  <td>{data?.duration}</td>
                                </tr>
                              ))
                            ) : (
                              <tr className="no-data-found">
                                <td colSpan={5}>------ NO DATA FOUND ------</td>
                              </tr>
                            )}
                            {loading && <OnScrollLoading />}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* USER TABLE END */}

                    <div className="download-and-pagination">
                      <SbDownloadBtn onClickEffect={exportExcelAttendance} />
                    </div>

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>
                <>
                  {showModal && (
                    <AttendanceBreakUps
                      showModal={showModal}
                      meetingID={meetingID}
                      userName={userName}
                      attenDate={attenDate}
                      handleCloseModal={handleCloseModal}
                    />
                  )}
                </>

                {/* MAIN BODY END */}
              </div>
              <Footer />
              {downloadIsLoading && (
                <>
                  <DownloadLoading />
                </>
              )}
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default AttendanceFullday;
