import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "../../styles/ExpensesVouchers.scss";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/TravelReport.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import { useReactToPrint } from "react-to-print";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import SalesBee from "../../images/MatricLogo.svg";
import SchillerLogo from "../../images/schiller_logo.jpg";
import { loadSingleTravelData } from "../../redux/action";
import moment from "moment";
import { baseURL } from "../BaseUrl";
import { FormattedAmount } from "../FormattedAmount";
import Footer from "../Footer";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function NAVVoucher(props) {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let tableHeader = [
    { name: "GL Account No.", align: "left", width: "130px" },
    { name: "GL Account Name", align: "left", width: "200px" },
    { name: "Details", align: "left", width: "350px" },
    { name: "Location Code", align: "left", width: "150px" },
    { name: "Amount", align: "left", width: "200px" },
  ];

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { clientId } = useParams();
  const { getSingleTravelReportData } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const mergeRows = (accumulator, currentValue) => {
    const existingRow = accumulator.find(
      (row) =>
        row.fullName === currentValue.fullName &&
        row.totalClaimAmount === currentValue.totalClaimAmount &&
        row.meetingDate === currentValue.meetingDate &&
        row.dayName === currentValue.dayName
    );
    if (existingRow) {
      existingRow.singleTravelInfo.push({
        // meetingDate: currentValue.meetingDate,
        // dayName: currentValue.dayName,
        startLoc: currentValue.startLoc?.location,
        endLoc: currentValue.endLoc?.location,
        distanceTravelled: currentValue.distanceTravelled,
        remark: currentValue.remark,
      });
    } else {
      accumulator.push({
        fullName: currentValue.fullName,
        totalClaimAmount: currentValue.totalClaimAmount,
        meetingDate: currentValue.meetingDate,
        dayName: currentValue.dayName,
        singleTravelInfo: [
          {
            // meetingDate: currentValue.meetingDate,
            // dayName: currentValue.dayName,
            startLoc: currentValue.startLoc?.location,
            endLoc: currentValue.endLoc?.location,
            distanceTravelled: currentValue.distanceTravelled,
            remark: currentValue.remark,
          },
        ],
      });
    }
    return accumulator;
  };

  const mergedMotRows =
    getSingleTravelReportData.length > 0
      ? getSingleTravelReportData.reduce(mergeRows, [])
      : [];
  // console.log(mergedMotRows, "mergedMotRows");

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [imageClicked, setImageClicked] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState(null);

  const handleImageClicked = (imageUrl) => {
    setImageClicked(!imageClicked);
    setClickedImageUrl(imageUrl);
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  const componentRef = useRef();
  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* FOOTER START */}
      <Footer />
      {/* FOOTER START */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">
                      Expenses Voucher - GL Wise
                    </h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col"></Col>
              </Row>
            </div>

            <div className="sb-table-div sb-table-setion">
              <div>
                <Paper
                  sx={{ width: "100%", overflow: "hidden" }}
                  className="table-main-div"
                >
                  <TableContainer
                    sx={{ maxHeight: 440 }}
                    // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                  >
                    <div ref={componentRef} className="table-data_n_images">
                      <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className={`whatsapp-table-container`}
                      >
                        <TableHead className="custom-table-header">
                          <TableRow>
                            <TableCell
                              align="center"
                              className="b-1"
                              colSpan={2}
                            >
                              <div className="travel-report-client-image">
                                <img src={SchillerLogo} alt="Logo" />
                              </div>
                            </TableCell>

                            <TableCell
                              align="center"
                              colSpan={2}
                              className="reprot-table-hading b-1"
                            >
                              Travelling Expenses Statement/Voucher - GL Wise
                            </TableCell>
                            <TableCell
                              align="center"
                              className="b-1"
                              colSpan={2}
                            >
                              <div className="travel-report-client-image">
                                <img src={SalesBee} alt="" />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                          <TableBody>
                            {props.tableHeader.map((name, i) => (
                              <SkeletonTableData key={i} />
                            ))}
                          </TableBody>
                        ) : (
                          <>
                            <TableBody>
                              <TableRow align="center">
                                <TableCell className="br-1 bl-1">
                                  <div>
                                    <b>NAV Voucher No. :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    HO-IM-ACC-22004105
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <b>Tracker Voucher No. :</b>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    AFW053/04/23/001
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Tracker Voucher Date :</b>
                                    <div className="table-row-head-Text color-green">
                                      30-04-2023
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>NAV Voucher Date :</b>
                                    <div className="table-row-head-Text color-green">
                                      30-04-2023
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell className="bl-1 br-1">
                                  <b>Employee Name:</b>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Kaustubh Rahatwal
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Employee Code :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    AFW053
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Region :</b>
                                  </div>
                                  <div className="table-row-head-Text color-green">
                                    West-1
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Grade :</b>
                                    <div className="table-row-head-Text color-green">
                                      M-2
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell className="bl-1 br-1">
                                  <b>Tour Name:</b>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Mumbai To Pondy
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Tour Period :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    01/04/2023 To 10/04/2023
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Pupose of Tour :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    For Backbone Meeting
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell className="br-1 bl-1 ">
                                  <div>
                                    <b>Designation :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Accounts Assistant
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Department :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Accounts & Finance
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Branch :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Mumbai
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </>
                        )}
                      </Table>

                      <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="gl-voucher-table-container p-2"
                      >
                        <TableHead className="custom-table-header">
                          <TableRow>
                            {tableHeader.map((name, i) => (
                              <TableCell
                                key={i}
                                align={name.align}
                                style={{
                                  minWidth: name.width,
                                  maxWidth: name.width,
                                  width: name.width,
                                }}
                                className="bt-1 bg-primaryLight"
                              >
                                {name.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                          <TableBody>
                            {props.tableHeader.map((name, i) => (
                              <SkeletonTableData key={i} />
                            ))}
                          </TableBody>
                        ) : (
                          <>
                            <TableBody className="expense-voucher gl-wise">
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  style={{
                                    minWidth: "130px",
                                    maxWidth: "130px",
                                    width: "130px",
                                  }}
                                >
                                  <div>
                                    <b className="color-green">44343003</b>
                                  </div>
                                </TableCell>

                                <TableCell
                                  className="tr-tcell-br"
                                  style={{
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    width: "200px",
                                  }}
                                >
                                  <div>
                                    <b>LODGING AND BOARDING</b>
                                  </div>
                                </TableCell>

                                <TableCell
                                  colSpan={3}
                                  className="p-0 sub-table br-1"
                                >
                                  <Table>
                                    <TableBody>
                                      <TableRow className="table_row-inside-tabel-cell">
                                        {" "}
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "350px",
                                            maxWidth: "350px",
                                            width: "350px",
                                          }}
                                        >
                                          Hotel Name. ( ABC Hotel Ltd. VL000001)
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "150px",
                                            maxWidth: "150px",
                                            width: "150px",
                                          }}
                                        >
                                          -
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "200px",
                                            maxWidth: "200px",
                                            width: "200px",
                                          }}
                                        >
                                          -
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="table_row-inside-tabel-cell">
                                        {" "}
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "350px",
                                            maxWidth: "350px",
                                            width: "350px",
                                          }}
                                        >
                                          EMPLOYEE : Kaustubh Rahatwal (SRW030)
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "150px",
                                            maxWidth: "150px",
                                            width: "150px",
                                          }}
                                        >
                                          Mumbai
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "200px",
                                            maxWidth: "200px",
                                            width: "200px",
                                          }}
                                        >
                                          2500.00
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="table_row-inside-tabel-cell">
                                        {" "}
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "350px",
                                            maxWidth: "350px",
                                            width: "350px",
                                          }}
                                        >
                                          EMPLOYEE : Prathamesh Durge (SRW030 )
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "150px",
                                            maxWidth: "150px",
                                            width: "150px",
                                          }}
                                        >
                                          Mumbai
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "200px",
                                            maxWidth: "200px",
                                            width: "200px",
                                          }}
                                        >
                                          2500.00
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  style={{
                                    minWidth: "130px",
                                    maxWidth: "130px",
                                    width: "130px",
                                  }}
                                >
                                  <div>
                                    <b className="color-green">44343005</b>
                                  </div>
                                </TableCell>

                                <TableCell
                                  className="tr-tcell-br"
                                  style={{
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    width: "200px",
                                  }}
                                >
                                  <div>
                                    <b>Tour Conveyance Charges</b>
                                  </div>
                                </TableCell>

                                <TableCell
                                  colSpan={3}
                                  className="p-0 sub-table br-1"
                                >
                                  <Table>
                                    <TableBody>
                                      <TableRow className="table_row-inside-tabel-cell">
                                        {" "}
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "350px",
                                            maxWidth: "350px",
                                            width: "350px",
                                          }}
                                        >
                                          EMPLOYEE : Kaustubh Rahatwal (SRW030)
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "150px",
                                            maxWidth: "150px",
                                            width: "150px",
                                          }}
                                        >
                                          Mumbai
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "200px",
                                            maxWidth: "200px",
                                            width: "200px",
                                          }}
                                        >
                                          500.00
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="table_row-inside-tabel-cell">
                                        {" "}
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "350px",
                                            maxWidth: "350px",
                                            width: "350px",
                                          }}
                                        >
                                          EMPLOYEE : Prathamesh Durge (SRW030 )
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "150px",
                                            maxWidth: "150px",
                                            width: "150px",
                                          }}
                                        >
                                          Mumbai
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "200px",
                                            maxWidth: "200px",
                                            width: "200px",
                                          }}
                                        >
                                          500.00
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  style={{
                                    minWidth: "130px",
                                    maxWidth: "130px",
                                    width: "130px",
                                  }}
                                >
                                  <div>
                                    <b className="color-green">44370101</b>
                                  </div>
                                </TableCell>

                                <TableCell
                                  className="tr-tcell-br"
                                  style={{
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    width: "200px",
                                  }}
                                >
                                  <div>
                                    <b>Expenses with Principal & Customer</b>
                                  </div>
                                </TableCell>

                                <TableCell
                                  colSpan={3}
                                  className="p-0 sub-table br-1"
                                >
                                  <Table>
                                    <TableBody>
                                      <TableRow className="table_row-inside-tabel-cell">
                                        {" "}
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "350px",
                                            maxWidth: "350px",
                                            width: "350px",
                                          }}
                                        >
                                          EMPLOYEE : Others (Others)
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "150px",
                                            maxWidth: "150px",
                                            width: "150px",
                                          }}
                                        >
                                          Mumbai
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "200px",
                                            maxWidth: "200px",
                                            width: "200px",
                                          }}
                                        >
                                          1000.00
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  style={{
                                    minWidth: "130px",
                                    maxWidth: "130px",
                                    width: "130px",
                                  }}
                                >
                                  <div>
                                    <b className="color-green">44343007</b>
                                  </div>
                                </TableCell>

                                <TableCell
                                  className="tr-tcell-br"
                                  style={{
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    width: "200px",
                                  }}
                                >
                                  <div>
                                    <b>Tour Sundry Expenses</b>
                                  </div>
                                </TableCell>

                                <TableCell
                                  colSpan={3}
                                  className="p-0 sub-table br-1"
                                >
                                  <Table>
                                    <TableBody>
                                      <TableRow className="table_row-inside-tabel-cell">
                                        {" "}
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "350px",
                                            maxWidth: "350px",
                                            width: "350px",
                                          }}
                                        >
                                          EMPLOYEE : Kaustubh Rahatwal (SRW030)
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "150px",
                                            maxWidth: "150px",
                                            width: "150px",
                                          }}
                                        >
                                          Mumbai
                                        </TableCell>
                                        <TableCell
                                          className="tr-tcell-br"
                                          style={{
                                            minWidth: "200px",
                                            maxWidth: "200px",
                                            width: "200px",
                                          }}
                                        >
                                          500.00
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={3}
                                >
                                  <b>Amount in words :</b>&nbsp; Seven thousand
                                  five hundred and zero paisa only.
                                </TableCell>
                                <TableCell
                                  className="tr-tcell-br"
                                  align="right"
                                >
                                  <b>TOTAL</b>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <b>7,500.00</b>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </>
                        )}
                      </Table>
                      <div className="verified-by">
                        <div className="dflex-j-between">
                          <div className="text-center p-3">
                            <div>Checked by</div>
                            <div>
                              <b>(Name)</b>
                            </div>
                          </div>
                          <div className="text-center p-3">
                            <div>Authorised by</div>
                            <div>
                              <b>(Name )</b>
                            </div>
                          </div>
                          <div className="text-center p-3">
                            <div>Approved by</div>
                            <div>
                              <b>(Name )</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableContainer>
                </Paper>
              </div>
            </div>
            <div className="download-and-pagination">
              <SbDownloadBtn btnName="Download" onClickEffect={pdfDownload} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
