import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import moment from "moment";

const CustomFieldsForCompany = ({ customFields, fieldValues, 
    setCustomeLocalImage, setFieldValues, setCustomFields }) => {
    // const [fieldValues, setFieldValues] = useState({});
    const { getSingleCustomFieldsForCompany } = useSelector((state) => state.data);
    console.log(getSingleCustomFieldsForCompany, 'getSingleCustomFieldsForCompany')

    const renderField = (field) => {
        switch (field.fieldType.toLowerCase()) {
            case "text":
            case "email":
            case "phone":
                return (
                    <Form.Control
                        key={field.customeFieldID}
                        type={field.fieldType.toLowerCase()}
                        placeholder={field.placeHolder}
                        readOnly={field.readOnly}
                        maxLength={field.fieldType === "phone" && 10}
                        value={field.value}
                        onChange={(e) => handleInputChange(field.customeFieldID, e.target.value)}
                    />
                );
            case "date":
                const dateValue = field.value ? new Date(field.value) : null;
                return (
                    <DatePicker
                        key={field.customeFieldID}
                        className="form-control select-add-meeting-model meeting-date model-input"
                        selected={dateValue}
                        value={field.value}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={field.placeHolder}
                        onChange={(date) => handleDateChange(field.customeFieldID, date)}
                    />
                );
            case "text area":
                return (
                    <Form.Control
                        key={field.customeFieldID}
                        as="textarea"
                        rows={3}
                        placeholder={field.placeHolder}
                        readOnly={field.readOnly}
                        value={field.value}
                        onChange={(e) => handleInputChange(field.customeFieldID, e.target.value)}
                    />
                );
            case "radio":
                return field.fieldOptions.split(",").map((option, index) => (
                    <Form.Check
                        key={index}
                        type="radio"
                        label={option}
                        name={field.fieldName}
                        id={`${field.fieldName}-${index}`}
                        readOnly={field.readOnly}
                        checked={field.value === option}
                        onChange={() => handleRadioChange(field.customeFieldID, option)}
                    />
                ));
            case "check box":
                return field.fieldOptions.split(",").map((option, index) => (
                    <Form.Check
                        key={index}
                        type="checkbox"
                        label={option}
                        id={`${field.fieldName}-${index}`}
                        readOnly={field.readOnly}
                        checked={field.value && field.value.includes(option)}
                        onChange={(e) => handleCheckboxChange(field.customeFieldID, option, e)}
                    />
                ));
            case "drop down":
                return (
                    <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        key={field.customeFieldID}
                        options={field.fieldOptions.split(",").map((option) => ({
                            value: option,
                            label: option,
                        }))}
                        placeholder={field.placeHolder}
                        isDisabled={field.readOnly}
                        value={{ value: field.value, label: field.value }}
                        onChange={(selectedOption) =>
                            handleSelectChange(field.customeFieldID, selectedOption.value)
                        }
                    />
                );
            case "file":
                return (
                    <Form.Group key={field.customeFieldID}>
                        {/* <Form.Label>{field.fieldName}</Form.Label> */}
                        <div className="drop-area">
                            <img src="../../../images/DefaultImages/UploadImages.svg" alt="" />
                            <p>Drag and drop images here</p>
                            <p>OR</p>
                            <input type="file" onChange={(e) => handleInputChangeImage(field.customeFieldID, e.target.files[0])} />
                        </div>
                    </Form.Group>
                );
            case "button":
                return (
                    <Button
                        key={field.customeFieldID}
                        variant="primary"
                        type="submit"
                        disabled={field.readOnly}
                    >
                        {field.fieldName}
                    </Button>
                );
            default:
                return null;
        }
    };

    // const handleInputChangeImage = (customeFieldID, file) => {
    //     const updatedArray = customFields.map((item) => {
    //         if (item.customeFieldID === customeFieldID) {
    //             return { ...item, value: JSON.stringify(file) };
    //         }
    //         return item;
    //     });
    //     setCustomFields(updatedArray);
    // };

    const handleInputChangeImage = (customeFieldID, event) => {
        // Ensure event exists and has a target
        console.log(event,'formData')
        if (event.name) {
            // const file = event.target.files[0];
            // console.log(customeLocalImage,'formData')
            setCustomeLocalImage({fieldId: customeFieldID, file: event})

        }
    };

    const handleInputChange = (customeFieldID, value) => {
        let updateArray = customFields.filter(item => {
            if (item.customeFieldID === customeFieldID) {
                item.value = value;
                return item;
            }
            return item;
        })
        setCustomFields(updateArray);
    };

    const handleDateChange = (customeFieldID, date) => {
        const formattedDate = date.toISOString().split('T')[0];
        let updateArray = customFields.filter(item => {
            if (item.customeFieldID === customeFieldID) {
                item.value = formattedDate;
                return item;
            }
            return item;
        })
        setCustomFields(updateArray);
    };

    const handleRadioChange = (customeFieldID, value) => {
        let updateArray = customFields.filter(item => {
            if (item.customeFieldID === customeFieldID) {
                item.value = value;
                return item;
            }
            return item;
        })
        setCustomFields(updateArray);
        // setFieldValues({ ...fieldValues, [fieldName]: value });
    };

    const [checkedData, setCheckedData] = useState([])

    const handleCheckboxChange = (customFieldID, value, e) => {
        let checkedDataUpdated = [...checkedData];
        let updatedValueString = '';

        if (e.target.checked) {
            checkedDataUpdated.push({ fieldId: customFieldID, value: value });
        } else {
            checkedDataUpdated = checkedDataUpdated.filter(item => !(item.fieldId === customFieldID && item.value === value));
        }

        const selectedOptions = checkedDataUpdated.filter(item => item.fieldId === customFieldID);

        updatedValueString = selectedOptions.map(item => item.value).join(',');

        const updateArray = customFields.filter(item => {
            if (item.customeFieldID === customFieldID) {
                item.value = updatedValueString;
                return item;
            }
            return item;
        })

        // Update state
        setCheckedData(checkedDataUpdated);
        setCustomFields(updateArray);
    };

    const handleSelectChange = (customeFieldID, value) => {
        let updateArray = customFields.filter(item => {
            if (item.customeFieldID === customeFieldID) {
                item.value = value;
                return item;
            }
            return item;
        })
        setCustomFields(updateArray);
    };


    return (
        <Form>
            <Row>
                {Array.isArray(customFields) && customFields && customFields.map((field) => (
                    <Col md={6} >
                        <Form.Group className={`control-form-group mb-3`} key={field.customeFieldID}>
                            <Form.Label className="control-form-label">{field.fieldName}</Form.Label>
                            <div className="fullname-form-control">
                                {renderField(field)}
                            </div>
                        </Form.Group>
                    </Col>
                ))}
            </Row>
        </Form>
    );
};

export default CustomFieldsForCompany;
