import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import { baseURL } from "../../BaseUrl";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import Select from "react-select";
import { optionsUnitList, optionsMOTTypes } from "../../DropdownsData";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { FloatingLabel } from "react-bootstrap";
import { loadCountryData } from "../../../redux/action";

function AddZone(props) {

    let dispatch = useDispatch();
    const { getCountryName } = useSelector((state) => state.data);
    const [selectedCountryName, setSelectedCountryName] = useState(
        {
            value: "",
            label: "",
        })

    useEffect(() => {
        dispatch(loadCountryData())
    }, [])

    const handleZoneClose = () => {
        props.handleZoneClose();
    };

    return (
        <div>
            <Modal
                show={props.showZone}
                onHide={props.handleZoneClose}
                className="main_model add-edit-basefare-model"
            >
                <div className="add-edit-basefare-heading">
                    <h6 className="add-edit-basefare-head">
                        ADD ZONE
                    </h6>
                    <i className="fa fa-times" onClick={handleZoneClose} />
                </div>
                <form>
                    <div className="container add-edit-basefare">
                        <div className="product-inputs">
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                // label="Select Country"
                                // className={`${bankName
                                //     ? "float-visible-select"
                                //     : "float-hidden float-select"
                                //     }`}
                                >
                                    <Select
                                        className="react-select-container-list model-select"
                                        classNamePrefix="react-select-list"
                                        options={getCountryName}
                                        placeholder="Select Country"
                                        value={selectedCountryName.value && selectedCountryName}
                                        onChange={(selectedOption) => { setSelectedCountryName(selectedOption)}}
                                        isSearchable={true}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <label className="float-label">Zone Name</label>
                                <input
                                    type="text"
                                    className="form-control form add-basefare-input"
                                    id="exampleFormControlInput1"
                                    name="apiKey"
                                    // value={postItem?.apiKey || ""}
                                    placeholder="Enter Zone Name"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div className="btns-save-cancel">
                    <SBSaveUpdateBtn
                        btnName="Save"
                    />
                    <SbCancelBtn btnName="Cancel" onClickEffect={handleZoneClose} />
                </div>
            </Modal>
        </div>
    );
}

export default AddZone;
