import React, { useEffect, useLayoutEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { useState } from "react";
import * as HeaderData from "../../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import {
  loadPostHoliday,
  loadUpdateHoliday,
  loadHolidayTypes,
  loadStateList,
} from "../../../redux/action";
import moment from "moment";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

function Addholidays(props) {
  let holidayId = props.holidayId;
  let dispatch = useDispatch();
  const { singleHoliday } = useSelector((state) => state.data);
  const { holidayTypes } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);

  // console.log("stateListstateList:", stateList);

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const [holidayTypeId, setHolidayTypeId] = useState({
    value: null,
    label: "",
  });
  const [stateTypeId, setStateTypeId] = useState([]);

  useLayoutEffect(() => {
    dispatch(loadHolidayTypes());
    dispatch(loadStateList(0));
  }, []);

  const [holidayData, setHolidayData] = useState({
    holidayTypeID: 0,
    state: "",
    subscriptionID: "",
    name: "",
    fromDate: "",
    toDate: "",
    description: "",
    addedByID: "",
    addedOn: "",
    addedByUserFullName: "",
    status: 1,
  });

  const {
    holidayTypeID,
    state,
    subscriptionID,
    name,
    description,
    fromDate,
    toDate,
    addedByID,
    addedOn,
    addedByUserFullName,
    status,
  } = holidayData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHolidayData({ ...holidayData, [name]: value });
  };

  const handleSelectChange = (value, type) => {
    if (type === "holidayTypeID") {
      setHolidayTypeId(value);
      holidayData.holidayTypeID = value.value;
    }
  };

  const handleMultiSelect = (selectedList) => {
    setStateTypeId(selectedList);
    let stateNames = selectedList.map((d) => d.label).toString();
    holidayData.state = stateNames;
  };

  useEffect(() => {
    if (holidayId) {
      if (singleHoliday) {
        setHolidayData({
          holidaysID: holidayId,
          holidayTypeID: singleHoliday.holidayTypeID,
          subscriptionID: singleHoliday.subscriptionID,
          name: singleHoliday.name,
          state: singleHoliday.state,
          fromDate: moment(singleHoliday.fromDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          toDate: moment(singleHoliday.toDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          description: singleHoliday.description,
          addedByID: singleHoliday.addedByID,
          addedOn: singleHoliday.addedOn,
          addedByUserFullName: resPassData.fullName,
          status: 1,
        });
        if (singleHoliday.status === 1) {
          setStatusValue(true);
        } else {
          setStatusValue(false);
        }
        setHolidayTypeId({
          value: singleHoliday.holidayTypeID,
          label: singleHoliday.holidayTypeName,
        });
        let selectedStates = singleHoliday.state?.split(",");
        // console.log("SDATA:", selectedStates);
        setStateTypeId(
          stateList.filter((data) => {
            return selectedStates?.some((item) => {
              return item === data.label;
            });
          })
        );
      }
    } else {
      if (resPassData) {
        setHolidayData({
          holidayTypeID: 0,
          subscriptionID: resPassData.subscriptionId,
          name: "",
          fromDate: "",
          toDate: "",
          description: "",
          addedByID: resPassData.userId,
          addedOn: "",
          addedByUserFullName: resPassData.fullName,
          status: 1,
        });
      }
    }
  }, [holidayId, singleHoliday]);

  // console.log("STATES:", stateTypeId);

  const cancelModale = () => {
    props.holidayModuleClose();
    props.setHolidayId("");
  };

  const [statusValue, setStatusValue] = useState(true);
  const statusFlag = (statusValue) => {
    setStatusValue(!statusValue);
    if (!statusValue === true) {
      setHolidayData({ ...holidayData, status: 1 });
    } else if (!statusValue === false) {
      setHolidayData({ ...holidayData, status: 0 });
    }
  };

  const [fieldReq, setFieldReq] = useState(false);
  const saveHolidaData = (e) => {
    e.preventDefault();
    if (!name || !fromDate || !toDate || !description || !holidayTypeID) {
      setFieldReq(true);
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(holidayData),
      };
      dispatch(loadPostHoliday(postRequest, cancelModale));
    }
  };

  const updateHolidayData = (e) => {
    e.preventDefault();
    if (!name || !fromDate || !toDate || !description || !holidayTypeID) {
      setFieldReq(true);
    } else {
      var updateRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(holidayData),
      };
      dispatch(loadUpdateHoliday(updateRequest, cancelModale));
    }
  };

  console.log("holidayTypes", holidayTypes);

  return (
    <div>
      <Modal show={props.show} className="main_model add-edit-model">
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            {" "}
            {holidayId ? "Edit Holiday Application" : "Holiday Application"}
          </h6>
          <i className="fa fa-times" onClick={cancelModale} />
        </div>

        <Form onSubmit={holidayId ? updateHolidayData : saveHolidaData}>
          <div className="container add-edit">
            <div className="product-inputs mt-2">
              <div className="form-field mb-3">
                <FloatingLabel
                  label="Enter Name"
                  className={`${
                    name || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    value={name || ""}
                    name="name"
                    onChange={handleInputChange}
                    placeholder="Enter Name"
                    autoComplete="off"
                  />
                  {fieldReq && !name && (
                    <small id={`Error`} className="form-text text-muted ">
                      Please Enter description
                    </small>
                  )}
                </FloatingLabel>
              </div>
              <div className="form-field mb-3">
                <FloatingLabel
                  label="Holiday Type"
                  className={`${
                    holidayTypeId.value !== 0 && holidayTypeId.value !== null
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select add-basefare-input"
                    classNamePrefix="react-select-list"
                    options={holidayTypes}
                    placeholder="Holiday Type"
                    value={holidayTypeId.value && holidayTypeId}
                    onInputChange={holidayTypes.label}
                    onChange={(data) =>
                      handleSelectChange(data, "holidayTypeID")
                    }
                    isSearchable={true}
                    // isDisabled={!motDepartment.value || props.clickType}
                  />
                </FloatingLabel>
                {fieldReq && !name && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter description
                  </small>
                )}
              </div>
              <div className="form-field mb-3">
                <FloatingLabel
                  label="State"
                  className={`${
                    stateTypeId.length !== 0
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <MultiSelect
                    className="select-add-user-model"
                    options={stateList}
                    value={stateTypeId}
                    onChange={handleMultiSelect}
                    labelledBy={"Select"}
                  />
                </FloatingLabel>
                {/* {fieldReq && !stateTypeId && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please select state
                  </small>
                )} */}
              </div>
              <div className="form-field mb-3">
                <FloatingLabel
                  label="Select Start Date"
                  className={`${
                    fromDate
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Form.Control
                    type="date"
                    value={fromDate || ""}
                    name="fromDate"
                    onChange={handleInputChange}
                    placeholder="Select Date"
                    autoComplete="off"
                  />
                </FloatingLabel>
                {fieldReq && !fromDate && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter description
                  </small>
                )}
              </div>
              <div className="form-field mb-3">
                <FloatingLabel
                  label="Select End Date"
                  className={`${
                    toDate
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Form.Control
                    type="date"
                    value={toDate || ""}
                    name="toDate"
                    onChange={handleInputChange}
                    placeholder="Select Date"
                    autoComplete="off"
                  />
                </FloatingLabel>
                {fieldReq && !toDate && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter description
                  </small>
                )}
              </div>
              <div className="form-field">
                <FloatingLabel
                  label="Description"
                  className={`${
                    description || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    as="textarea"
                    type="text"
                    value={description || ""}
                    name="description"
                    onChange={handleInputChange}
                    placeholder="Description"
                    autoComplete="off"
                    className="form-control form form-textarea add-spare-input"
                  />
                </FloatingLabel>
                {fieldReq && !description && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter description
                  </small>
                )}
              </div>
              <div className="mt-3">
                <div className="form-field form-status-section">
                  <div>
                    <Form.Label className="require">Status</Form.Label>
                  </div>
                  <div className="btn-status">
                    <Form.Group className="facility-details">
                      <div
                        className={`checkbox ${
                          statusValue ? "checkbox--on" : ""
                        }`}
                        name="status"
                        onClick={(e) => statusFlag(statusValue)}
                      >
                        <div className="checkbox__ball">
                          <span
                            className={
                              statusValue ? "enable-text" : "disable-text"
                            }
                          >
                            {statusValue ? "Enable" : "Disable"}
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn btnName={holidayId ? "Update" : "Save"} />
            <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default Addholidays;
