import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useRef, useState } from "react";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedAmount } from "../FormattedAmount";
import { useSelector } from "react-redux";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";

function CommissionTable(props) {
  const { totalCount } = useSelector((state) => state.data);

  const [showTooltip, setShowTooltip] = useState(false);
  const [tootTipId, setTootTipId] = useState("");
  const buttonRef = useRef(null);

  const handleClick = (id) => {
    setShowTooltip(!showTooltip);
    setTootTipId(id);
  };

  const renderTooltip = (dynamicValue) => (
    // <Tooltip id="tooltip" className="custom-tool-tip">
    <Tooltip id={`tooltip-dashboard`}>
      {dynamicValue} {/* Replace dynamicValue with your actual dynamic value */}
    </Tooltip>
  );

  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.productsData,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="basefare-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    align={name.align}
                    className={`thead-cell-products`}
                    style={{ minWidth: name.width }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <TableBody>
                {props.tableHeader.map((name, i) => (
                  <SkeletonTableData key={i} />
                ))}
              </TableBody>
            ) : (
              <>
                {props.productsData && props.productsData.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/NodataFound.jpg"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>--- No Data Found ---</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {props.productsData &&
                      props.productsData.map((data, i) => (
                        <TableRow
                          align="center"
                          key={i}
                          className="client-list-row-data"
                        >
                          <TableCell>
                            <span className="users-list-name">
                              <div>
                                <img
                                  className="table-data-product-imgages"
                                  src={
                                    data?.ProductImageURL
                                      ? `https://www.getfaststore.com/img/product/${data?.ProductImageURL}`
                                      : "-"
                                  }
                                  alt=""
                                />
                              </div>
                              <div>
                                <div className="table-row-head-Text">
                                  {data?.ProductName}
                                </div>
                                <div className="table-row-sub-Text">
                                  {data?.ProductDescription.length > 25
                                    ? data?.ProductDescription.substring(
                                        0,
                                        25
                                      ) + "..."
                                    : data?.ProductDescription}
                                  &nbsp;&nbsp;
                                  {data?.ProductDescription.length > 25 && (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={renderTooltip(
                                        data?.ProductDescription
                                      )}
                                      show={
                                        showTooltip &&
                                        tootTipId === data?.ProductCode
                                      }
                                    >
                                      <i
                                        ref={buttonRef}
                                        id="info"
                                        className="fa fa-info-circle details-icon"
                                        onClick={(e) =>
                                          handleClick(data?.ProductCode)
                                        }
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  )}
                                </div>
                              </div>
                            </span>
                          </TableCell>
                          <TableCell>
                            <div>
                              <div className="table-row-head-Text">
                                {data?.Pid}
                              </div>
                              <div className="table-row-sub-text">
                                {data?.ProductCode}
                              </div>
                            </div>
                          </TableCell>

                          <TableCell>{data?.ProductCategory}</TableCell>
                          <TableCell>
                            <div className="price-text-div">
                              <div className="color-green">
                                <span className="price-text">
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {FormattedAmount(data?.SellingPrice)}
                                </span>
                              </div>
                              <div style={{ fontSize: "11px" }}>
                                <i className="fa fa-inr" aria-hidden="true"></i>
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  {FormattedAmount(data?.MRP)}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              className="color-green"
                              style={{ fontSize: "15px" }}
                            >
                              <span className="price-text">
                                <i className="fa fa-inr" aria-hidden="true"></i>
                                {FormattedAmount(data?.ReferalCommission)}
                              </span>
                            </div>
                          </TableCell>
                          {/* <TableCell>
                                                    {data?.ProductStatus === "Active" ? (
                                                        <span className="status-badge complete-status">
                                                            Active
                                                        </span>
                                                    ) : (
                                                        <span className="status-badge pending-status">
                                                            InActive
                                                        </span>
                                                    )}
                                                </TableCell> */}
                          {/* <TableCell>
                                                    <button
                                                        onClick={() => props.editCommissionData(data.gfRefProdCommitionID)}
                                                        className="btn client-history-link" >
                                                        <span><i className="bi bi-pencil-fill"></i> Edit</span>
                                                    </button>
                                                </TableCell> */}
                        </TableRow>
                      ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default CommissionTable;
