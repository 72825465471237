import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { loadGoogleCredits, loadSubsrDropdown } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import AddApiCredits from "./AddApiCredits";
import ApiCreditsTable from "./ApiCreditsTable";
import moment from "moment";
import DatePicker from "react-datepicker";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import Footer from "../../Footer";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";

function ApiCredits() {
  const { key } = useParams();
  const location = useLocation();
  const supportAccess = location.state?.supportAccess;
  const actionColumn = supportAccess === false ? ["ACTION"] : [];

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let tableHeader = [
    "SL.NO",
    "COMPANY",
    "API NAME",
    "API CREDIT FROM",
    "API CREDIT TO",
    "TOTAL CREDITS",
    "AVAILABLE CREDITS",
    "CONSUMED",
    "DAYS LEFT",
    // "ACTION",
    ...actionColumn,
  ];

  let dispatch = useDispatch();
  const { ApiCreditList } = useSelector((state) => state.data);
  const { subCrDropdown } = useSelector((state) => state.data);
  // const {clientDropdown} = useSelector(state => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [basefareId, setBasefareId] = useState("");
  const [EditmotID, setEditMotID] = useState("");
  const [show, setShow] = useState(false);
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [ApiCreditData, setApiCreditData] = useState([]);
  const [apiDataId, setApiDataId] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filterClientName, setFilterClientName] = useState({
    value: "",
    label: "",
  });

  const [openFilterTab, setOpenFilterTab] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(loadClientDropDown())
    dispatch(loadSubsrDropdown());
  }, []);

  useEffect(() => {
    if (key) {
      setApiCreditData(
        ApiCreditList.filter((data) => {
          return data.ApiName.includes("Google");
        })
      );
    } else {
      setApiCreditData(ApiCreditList);
    }
  }, [key, ApiCreditList]);

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter((item) => item.key !== "Key");
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "Key", value: value });
    } else {
      list = filterArray.filter((item) => item.key !== "Key");
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const setSelectSearchDrop = (data, type) => {
    let list;
    if (type === "companyName") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "SubscriptionID");
      } else {
        list = [];
      }
      list.push({ key: "SubscriptionID", value: data.value });
      setFilterClientName(data);
    } else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      } else {
        list = [];
      }
      if (start && end == null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "ToDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    fetchAPICredits();
  }, [filterArray, PageSize, currentPage, setLoading]);

  const fetchAPICredits = () => {
    let Flag = "GetApiCredits";
    dispatch(
      loadGoogleCredits(
        Flag,
        setIsLoading,
        PageSize,
        currentPage,
        filterArray,
        setLoading
      )
    );
  };

  const editAPICredit = (apiId) => {
    setApiDataId(apiId);
    setShow(true);
  };

  const clearSerachBySelect = () => {
    setFilterClientName({ value: "", label: "" });
    setSearchValue("");
    setFilterArray([]);
    setFromDate(new Date());
    setToDate(new Date());
  };

  const exportExcelBaseFare = () => {
    // exportExcel(
    //   `${baseURL}/api/Export/MotBaseFareExcelExport?PageSize=100&CurrentPage=1`,
    //   "BaseFare.xlsx"
    // );
  };

  // Page navigation
  let navigate = useNavigate();
  const onClickNavigate = () => {
    if (key) {
      navigate(`/googleapikeylist/${key}`);
    } else {
      navigate(`/googleapikeylist`);
    }
  };

  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} sm={12} xs={12} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">API CREDITS</h6>
                      </div>
                    </Col>
                    <Col md={5} sm={12} xs={12} className="cat-col">
                      <div className="input-div">
                        <input
                          type="search"
                          id="metricInfo_search_bar"
                          placeholder="Find a Key"
                          className="form-control setting-input"
                          value={searchValue}
                          onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className="fa fa-search search-icon" />
                      </div>
                    </Col>
                    <Col md={4} sm={12} xs={12} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <div className="filter-icon-text">
                              <i className="bi bi-sliders" />
                              <span>Filters</span>
                            </div>
                          </div>
                          {!supportAccess && (
                            <SbAddBtn onClickEffect={() => setShow(!show)} />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div className="filter-row-input-boxes">
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={subCrDropdown}
                            placeholder="Select Company"
                            value={filterClientName.value && filterClientName}
                            onInputChange={subCrDropdown.label}
                            onChange={(data) =>
                              setSelectSearchDrop(data, "companyName")
                            }
                            isSearchable={true}
                          />
                        </div>
                        <div className="from-to-date-select">
                          <div className="input-group-text" id="from-label">
                            From - To
                          </div>
                          <div className="date-picker-with-icon">
                            <DatePicker
                              selected={fromDate}
                              className="select from-date date-filter"
                              id="date-range-picker"
                              dateFormat="dd/MM/yyyy"
                              selectsRange
                              startDate={fromDate}
                              endDate={toDate}
                              onChange={(date) =>
                                setSelectSearchDrop(date, "date")
                              }
                              maxDate={new Date()}
                            />
                            <div>
                              <i
                                className="fa fa-calendar-o calander-icon"
                                style={{ right: "6px" }}
                              />
                            </div>
                          </div>
                        </div>
                        <MiClearButton
                          onClickEffect={clearSerachBySelect}
                          btnName="Clear"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/*TABLE START */}
                <ApiCreditsTable
                  tableHeader={tableHeader}
                  ApiCreditList={ApiCreditData}
                  isLoading={isLoading}
                  onClickNavigate={onClickNavigate}
                  editAPICredit={editAPICredit}
                  supportAccess={supportAccess}
                />
                {/*TABLE END */}

                <div className="download-and-pagination">
                  <SbDownloadBtn onClickEffect={exportExcelBaseFare} />
                </div>

                {/* ADD & EDIT PRODUCT MODEL START */}
                {show && (
                  <AddApiCredits
                    fetchAPICredits={fetchAPICredits}
                    apiDataId={apiDataId}
                    show={show}
                    EditmotID={EditmotID}
                    basefareId={basefareId}
                    setBasefareId={setBasefareId}
                    handleClose={handleClose}
                    setApiDataId={setApiDataId}
                    //   clickType={clickType} setClickType={setClickType}
                  />
                )}
                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default ApiCredits;
