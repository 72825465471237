import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/AddEditModel.scss";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Row } from "react-bootstrap";
import profileimg from "../../../images/MatricLogo.svg";

function EditPhase(props) {
  const [localImage, setLocalImage] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [date, setDate] = useState(new Date());
  const [imgFile, setImgFile] = useState("");

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <Modal
        show={props.editPhase}
        // onHide={props.handleClose}
        className="main_model add-edit-model phase-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">EDIT PHASE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit">
            <div className="add-inputs">
              <Row>
                <div className="col-md-6 col-sm-12">
                  <div className="mb-3">
                    <div className="img-upload-part">
                      <div className="upload-img-box">
                        <div className="uploaded-image">
                          <img
                            className="phase-image"
                            src={localImage ? localImage : profileimg}
                            alt="Project Image"
                          />
                          <div className="upload-btn-attachment">
                            <button
                              type="button"
                              className="btn btn-upload-img"
                              onClick={uploadClick}
                            >
                              <span className="btn-inline-items">
                                <i className="fa fa-upload" /> &nbsp;
                                <p>Upload</p>
                              </span>
                            </button>
                            <input
                              id="getFile"
                              type="file"
                              name="filetobase64"
                              accept="image/*"
                              className="btn-primary upload"
                              ref={hiddenFileInput}
                              onChange={handleChange}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <Form.Label>Project Name</Form.Label>
                    <Select
                      className="select-add-meeting-model"
                      // options={userList}
                      placeholder="Project Name"
                      // value={userList.value}
                      // onInputChange={userList.label}
                      // onChange={(data) => setFilterUserId(data.value)}
                      isSearchable={true}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Start Date</Form.Label>
                    <span className="date-picker-with-icon model-box">
                      <DatePicker
                        selected={date}
                        className="form-control form add-input meeting-date"
                        dateFormat="dd/MM/yyyy"
                        placeholder="Start Date"
                        // selectsEnd
                        // startDate={date}
                        // endDate={toDate}
                        // minDate={new Date()}
                        onChange={(date) => setDate(date)}
                        // maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                  </div>

                  <div className="mb-3">
                    <Form.Label>Duration</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control form add-input"
                      placeholder="Duration"
                      // readOnly={true}
                      // value={fullname}
                      // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Created On</Form.Label>
                    <span className="date-picker-with-icon model-box">
                      <DatePicker
                        selected={date}
                        className="form-control form add-input meeting-date"
                        dateFormat="dd/MM/yyyy"
                        placeholder="Created On"
                        // selectsEnd
                        // startDate={date}
                        // endDate={toDate}
                        // minDate={new Date()}
                        onChange={(date) => setDate(date)}
                        // maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                  </div>

                  <div className="mb-3">
                    <Form.Label>Attachment</Form.Label>
                    <input
                      id="getFile"
                      type="file"
                      name="filetobase64"
                      accept="file/*"
                      className="btn-upload"
                      // ref={hiddenFileInput}
                      // onChange={handleChange}
                      // style={{ display: "none" }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="mb-3">
                    <Form.Label>Phase Name</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="Phase Name"
                      // readOnly={true}
                      // value={fullname}
                      // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Phase Owner</Form.Label>
                    <Select
                      className="select-add-meeting-model"
                      // options={userList}
                      placeholder="Phase Owner"
                      // value={userList.value}
                      // onInputChange={userList.label}
                      // onChange={(data) => setFilterUserId(data.value)}
                      isSearchable={true}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Phase Cost</Form.Label>
                    <input
                      type="number"
                      className="form-control form add-input"
                      placeholder="Phase Cost"
                      // readOnly={true}
                      // value={fullname}
                      // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>End Date</Form.Label>
                    <span className="date-picker-with-icon model-box">
                      <DatePicker
                        selected={date}
                        className="form-control form add-input meeting-date"
                        dateFormat="dd/MM/yyyy"
                        placeholder="Instalation Date"
                        // selectsEnd
                        // startDate={date}
                        // endDate={toDate}
                        // minDate={new Date()}
                        onChange={(date) => setDate(date)}
                        // maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                  </div>

                  <div className="mb-3">
                    <Form.Label>Phase Type</Form.Label>
                    <Select
                      className="select-add-meeting-model"
                      // options={userList}
                      placeholder="Phase Type"
                      // value={userList.value}
                      // onInputChange={userList.label}
                      // onChange={(data) => setFilterUserId(data.value)}
                      isSearchable={true}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Team Members</Form.Label>
                    <Select
                      className="select-add-meeting-model"
                      // options={userList}
                      placeholder="Team Members"
                      // value={userList.value}
                      // onInputChange={userList.label}
                      // onChange={(data) => setFilterUserId(data.value)}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="products-save-button">
            <button
              //   onClick={postinvoicedata}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              <p>Save</p>
            </button>
          </div>
          <div className="products-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              <p>Cancel</p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditPhase;
