import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Topbar.scss";
import profileimg from "../images/admin-1.png";
import { baseURL } from "./BaseUrl";
import TopBarToolTip from "./TopBarToolTip";
import {
  Carousel,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import SalesBee from "../images/MatricLogo.svg";
import SbCancelBtn from "./SbButtons/SbCancelBtn";
import { SBSaveUpdateBtn } from "./SbButtons/SbAddBtn";
import { useDispatch, useSelector } from "react-redux";
import { loadCreditsCount, loadClientCategoryDrop } from "../redux/action";
import DarkMode from "./DarkMode";
import {
  MDBContainer,
  MDBCollapse,
  MDBNavbar,
  MDBNavbarToggler,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import ORModal from "./ORModal";
import MenuFormat from "./MenuFormat";

function Topbar(props) {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let themeColor = localStorage.getItem("theme");
  const userLgnDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let dispatch = useDispatch();
  const { creditCount } = useSelector((state) => state.data);
  const { categoryDropdown } = useSelector((state) => state.data);

  const companyName = userLgnDetails?.subscription?.company?.companyName;

  const { pageData } = useSelector((state) => state.data);
  const menuIconClick = props.menuIconClick;
  const menuCollapse = props.menuCollapse;
  const toggleshift = props.toggleshift;
  const toggleMenu = props.toggleMenu;

  const logout = () => {
    sessionStorage.clear();
    window.location.href = "/";
    setShowlogOut(false);
  };

  const [showLogOut, setShowlogOut] = useState(false);
  const [showNavExternal, setShowNavExternal] = useState(false);

  const logoutModal = () => {
    setShowlogOut(true);
  };

  const cancelModale = () => {
    setShowlogOut(false);
  };

  useEffect(() => {
    let Flag = "GetApiCredits";
    dispatch(loadCreditsCount(Flag, 50, 1));
    dispatch(loadClientCategoryDrop());
  }, []);

  const [remainingCredits, setRemainingCredits] = useState([]);
  // console.log(creditCount, "creditCount");

  useEffect(() => {
    if (creditCount && creditCount.length != 0) {
      let creditsData = creditCount.filter(
        (item) => item?.SubscriptionID == userLgnDetails?.subscriptionId
      );
      setRemainingCredits(creditsData);
    }
  }, [creditCount]);

  let daysLeft = sessionStorage.getItem("daysLeft");
  const [open, setOpen] = useState(false);
  const [subId, setSubId] = useState("");

  const [showRequest, setShowRequest] = useState(false);
  const [showAllCredit, setShowAllCredit] = useState(false);
  const [itemName, setItemName] = useState("");
  const [rmnBalance, setRmnBalance] = useState("");

  const menuItems = MenuFormat(resPassData?.subscription?.modules);

  const requestCredits = (itemName, rmnBlnce) => {
    setRmnBalance(rmnBlnce);
    setItemName(itemName);
    setShowRequest(true);
  };

  const subMenuOpen = (e) => {
    setSubId(e.target.id);
    setOpen(!open);
  };

  const [openQrModal, setOpenQrModal] = useState(false);
  const openAppQrCode = () => {
    setOpenQrModal(true);
  };

  const closeQrModal = () => {
    setOpenQrModal(false);
  };

  const cancelRequestModale = () => {
    setShowRequest(false);
  };

  const openAllModal = () => {
    setShowRequest(false);
    setShowAllCredit(true);
  };

  const cancelAllCreditModale = () => {
    setShowAllCredit(false);
  };

  let clientCatId = window.location.hash.split("/").pop();
  let userId = window.location.hash.split("/").pop();

  return (
    <div>
      {/* Logout modal */}
      {showLogOut && (
        <Modal
          show={showLogOut}
          onHide={cancelModale}
          className="logout-modal-div"
        >
          <div className="cat-col">
            <div>
              <div className="log-out-div">
                <h6>Are you sure want to logout?</h6>
              </div>
              <div className="sb-logout-btn">
                <SBSaveUpdateBtn onClickEffect={logout} btnName="Yes" />
                <SbCancelBtn btnName="No" onClickEffect={cancelModale} />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {/* Logout modal */}

      {/* Credit Request modal */}
      {showRequest && (
        <Modal
          show={showRequest}
          onHide={cancelRequestModale}
          className="category-modal-div Qr-modal"
        >
          <Row className="modale-header-div">
            <Col md={9} xs={9} className="cat-col">
              <h6 className="page-header-text">Credits Top Up</h6>
            </Col>
            <Col md={3} xs={3} className="cat-col" align="right">
              <button
                onClick={cancelRequestModale}
                className="bi bi-x close-popup"
              ></button>
            </Col>
          </Row>
          <div className="cat-col">
            <div className="top-image">
              <img src={`../../images/Topbar/${itemName}.gif`} alt="" />
            </div>
            <div className="top-image">
              <p className="head-text">
                Your <span className="primary-color">{itemName}</span> Credits
                Are Low
              </p>
              <p>
                Remainig balance is{" "}
                <span className="subbordinate-link">{rmnBalance}</span>
              </p>
            </div>
            <div className="top-btns">
              <SBSaveUpdateBtn btnName="Request For Credit" />
              <SbCancelBtn btnName="View All" onClickEffect={openAllModal} />
            </div>
          </div>
        </Modal>
      )}
      {/* Credit Request modal */}

      {/* Credit Request modal */}
      {showAllCredit && (
        <Modal
          show={showAllCredit}
          onHide={cancelAllCreditModale}
          className="credit-list-modal"
        >
          <Row className="modale-header-div">
            <Col md={9} xs={9} className="cat-col"></Col>
            <Col md={3} xs={3} className="cat-col" align="right">
              <button
                onClick={cancelAllCreditModale}
                className="bi bi-x close-popup"
              ></button>
            </Col>
          </Row>
          <Row>
            {remainingCredits.map((item, i) => (
              <Col md={3} className="credit-box-div">
                <div className="credit-col-box">
                  <div className="top-image">
                    <img
                      src={`../../images/Topbar/${item.ApiName}.gif`}
                      alt=""
                    />
                  </div>
                  <div className="top-image">
                    <p className="head-text">
                      Your <span className="primary-color">{item.ApiName}</span>{" "}
                      Credits
                    </p>
                    <p>
                      Remainig balance is{" "}
                      <span className="subbordinate-link">
                        {item.RemainingCreadits}
                      </span>
                    </p>
                  </div>
                  <div className="top-btns">
                    <SBSaveUpdateBtn btnName="Request For Credit" />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className="top-btns">
            <SBSaveUpdateBtn btnName="Request For All Credits" />
            <SbCancelBtn
              btnName="Close"
              onClickEffect={cancelAllCreditModale}
            />
          </div>
        </Modal>
      )}
      {/* Credit Request modal */}

      <div className="topbar">
        <nav className="navbar-custom d-block" id="navbar-custom">
          <div className="dropdown list-unstyled topbar-nav float-end">
            <span className="your-days-left">
              <Link to="/settings/account" className="go-to-accounts">
                {daysLeft < 30 ? (
                  daysLeft === 0 ? (
                    <small className="days-left-in-word">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription has Expired.
                    </small>
                  ) : daysLeft === 1 ? (
                    <small className="days-left-in-word">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end <b>Today</b>
                    </small>
                  ) : daysLeft < 10 ? (
                    <small className="days-left-in-number red">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end in <b>{daysLeft} days</b>
                    </small>
                  ) : (
                    <small className="days-left-in-number">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end in <b>{daysLeft} days</b>
                    </small>
                  )
                ) : (
                  ""
                )}
              </Link>
            </span>
            {!showRequest && (
              <div className="top-bar-popup">
                <div className="carousel-div">
                  <Carousel
                    fade
                    pause="hover"
                    autoPlay={false}
                    controls={false}
                    indicators={false}
                    interval={2000}
                  >
                    {remainingCredits.length != 0 &&
                      remainingCredits.map((item, i) => (
                        <Carousel.Item className="first-popup-div" key={i}>
                          <div className="first-popup-div-text">
                            <p className="carousel-text1 color-green">
                              {item.ApiName}
                            </p>
                            {item.RemainingCreadits <= 10 ? (
                              <p>
                                <span
                                  className="credit-rqst-btn"
                                  onClick={() =>
                                    requestCredits(
                                      item.ApiName,
                                      item.RemainingCreadits
                                    )
                                  }
                                >
                                  Need More credits?
                                </span>
                              </p>
                            ) : (
                              <p className="carousel-text1">
                                <span className="sb-remaining-credits">
                                  {item.ConsumedCreadits}
                                </span>
                                &nbsp; /{" "}
                                <span className="total-credit">
                                  {item.TotalCreadits}
                                </span>
                              </p>
                            )}
                          </div>
                        </Carousel.Item>
                      ))}
                  </Carousel>
                </div>
              </div>
            )}
            <div className="dark-light-theme-switch">
              <DarkMode />
            </div>
            <div className="dropdown header-item">
              <button
                type="button"
                className="btn navdrp"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <div style={{ width: "50px", marginRight: "10px" }}>
                    <img
                      className="sidbar logo-img rounded-circle header-profile-user"
                      style={{ width: "40px" }}
                      src={
                        userLgnDetails?.profilePic !== "" &&
                        userLgnDetails?.profilePic !== null &&
                        userLgnDetails?.profilePic !== "no_img.png"
                          ? `${baseURL}/${userLgnDetails?.profilePic}`
                          : profileimg
                      }
                      alt="profile-logo"
                    />
                  </div>

                  <div className="text-start ms-xl-2 d-flex align-items-center">
                    <span className="d-none d-xl-inline-block fw-medium user-name-text fs-16">
                      <span>
                        <span className="sb-user-name">
                          {userLgnDetails?.fullName.length > 20
                            ? userLgnDetails?.fullName.substring(0, 18) + "..."
                            : userLgnDetails?.fullName}
                        </span>
                        <br />
                        <span className="sb-company-name fz15">
                          {companyName && companyName.length > 20
                            ? companyName.substring(0, 18) + "..."
                            : companyName}
                        </span>
                      </span>
                    </span>
                    <span>
                      &nbsp;&nbsp;
                      <i
                        className="fa fa-angle-down fs-12 ms-1"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                  </div>
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-end custom-sb-drop"
                data-popper-placement="bottom-end"
              >
                <div
                  className="dropdown-item"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TopBarToolTip
                    image={`../images/Topbar/Setting${
                      themeColor ? themeColor : "light"
                    }.png`}
                    link="/settings"
                    tpName="Settings"
                  />
                  <div className="icon-divider"></div>
                  <TopBarToolTip
                    image={`../images/Topbar/Profile-image.png`}
                    link="/settings/Profile"
                    tpName="Profile"
                  />
                  <div className="icon-divider"></div>
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => <Tooltip {...props}>Log Out</Tooltip>}
                    placement="top"
                  >
                    <div onClick={logoutModal} style={{ cursor: "pointer" }}>
                      <img
                        src={`../images/Topbar/Logout.png`}
                        width="22px"
                        alt="logout"
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>

          <div className="list-unstyled topbar-nav">
            <li>
              <button
                className="nav-link button-menu-mobile nav-icon"
                id="togglemenu"
                onClick={toggleMenu}
              >
                <div
                  className={`closemenu ${toggleshift.style}`}
                  onClick={menuIconClick}
                >
                  {menuCollapse ? (
                    <span className="fa fa-indent"></span>
                  ) : (
                    <span className="fa fa-dedent"></span>
                  )}
                </div>
              </button>
            </li>
          </div>
        </nav>
        {/* CODE OF TABLET VIEW START */}
        <>
          <MDBNavbar className="hamburger-navbar">
            <MDBContainer fluid>
              <div className="webapp-logo">
                {resPassData?.subscription?.company?.isWhiteList == 1 ? (
                  <Link className="metricinfoapp_logo" to="/dashboard">
                    <img
                      src={resPassData?.subscription?.company?.isWhiteListPath}
                      alt="Company Logo"
                      className="mi-logo"
                    />
                  </Link>
                ) : (
                  <Link className="metricinfoapp_logo" to="/dashboard">
                    <img
                      src={SalesBee}
                      alt="product-title-img"
                      className="mi-logo"
                    />
                  </Link>
                )}
              </div>
              <div className="dark-light-theme-switch">
                <DarkMode />
              </div>
              <div className="dropdown header-item">
                <button
                  type="button"
                  className="btn navdrp"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="hv-center">
                    <div style={{ width: "40px", marginRight: "8px" }}>
                      <img
                        className="sidbar logo-img rounded-circle header-profile-user"
                        style={{ width: "35px" }}
                        src={
                          userLgnDetails?.profilePic !== "" &&
                          userLgnDetails?.profilePic !== null &&
                          userLgnDetails?.profilePic !== "no_img.png"
                            ? `${baseURL}/${userLgnDetails?.profilePic}`
                            : profileimg
                        }
                        alt=""
                      />
                    </div>

                    <div className="text-start ms-xl-2 d-flex align-items-center">
                      <span className="d-xl-inline-block fw-medium user-name-text fs-14">
                        <span>
                          <span className="sb-user-name">
                            {userLgnDetails?.fullName.length > 20
                              ? userLgnDetails?.fullName.substring(0, 18) +
                                "..."
                              : userLgnDetails?.fullName}
                          </span>
                          <br />
                          <span className="sb-company-name fz14">
                            {companyName && companyName.length > 20
                              ? companyName.substring(0, 18) + "..."
                              : companyName}
                          </span>
                        </span>
                      </span>
                      <span>
                        &nbsp;&nbsp;
                        <i
                          className="fa fa-angle-down ms-1"
                          style={{ fontSize: "20px" }}
                        />
                      </span>
                    </div>
                  </div>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end custom-sb-drop"
                  data-popper-placement="bottom-end"
                >
                  <div className="dropdown-item dflex-j-between">
                    <TopBarToolTip
                      image={`../images/Topbar/Setting${
                        themeColor ? themeColor : "light"
                      }.png`}
                      link="/settings"
                      tpName="Settings"
                    />
                    <div className="icon-divider"></div>
                    <TopBarToolTip
                      image={`../images/Topbar/Profile-image.png`}
                      link="/settings/Profile"
                      tpName="Profile"
                    />
                    <div className="icon-divider"></div>
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={(props) => <Tooltip {...props}>Log Out</Tooltip>}
                      placement="top"
                    >
                      <div onClick={logoutModal} style={{ cursor: "pointer" }}>
                        <img
                          src={`../images/Topbar/Logout.png`}
                          width="22px"
                          alt="logout"
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <MDBNavbarToggler
                // className="ms-auto"
                type="button"
                data-target="#navbarToggleExternalContent"
                aria-controls="navbarToggleExternalContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setShowNavExternal(!showNavExternal)}
              >
                <span className="material-symbols-outlined">menu</span>
              </MDBNavbarToggler>
            </MDBContainer>
          </MDBNavbar>

          <MDBCollapse
            show={+showNavExternal}
            className={showNavExternal ? "show-menu" : "hide-menue"}
          >
            <div className="burger-menu-bg">
              {openQrModal && (
                <ORModal
                  openQrModal={openQrModal}
                  closeQrModal={closeQrModal}
                />
              )}
              <div className="burger-menu-items">
                {menuItems &&
                  menuItems.map((menuItems, i) => (
                    <div className="expanded-nav-bar" key={i}>
                      {menuItems.name === "Dashboard" && (
                        <Link to="/dashboard">
                          <div
                            className={
                              window.location.hash === `#/dashboard` ||
                              window.location.hash === ""
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li className="tab-menu-items">
                              <div className="nav-link-item">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/desktop.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>

                                <span className="xn-text">Dashboard</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}

                      {menuItems.name == "Human Resource" && (
                        <div
                          className={
                            (subId === "Human Resource" && open) ||
                            window.location.hash === `#/HR/Users` ||
                            window.location.hash === `#/HR/Monthly` ||
                            window.location.hash === `#/HR/Leaves` ||
                            window.location.hash === `#/HR/Fullday` ||
                            window.location.hash === `#/HR/Fullday/${userId}`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="HUMANRESOURCE"
                            id="Human Resource"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "Human Resource" && open) ||
                              window.location.hash === `#/HR/Users` ||
                              window.location.hash === `#/HR/Monthly` ||
                              window.location.hash === `#/HR/Leaves` ||
                              window.location.hash === `#/HR/Fullday` ||
                              window.location.hash === `#/HR/Fullday/${userId}`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="Human Resource">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/users.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="HUMANRESOURCE"
                                id="Human Resource"
                              >
                                Human Resource
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="Human Resource"
                              />
                            </div>
                          </li>
                          {(subId === "Human Resource" && open) ||
                          window.location.hash === `#/HR/Users` ||
                          window.location.hash === `#/HR/Monthly` ||
                          window.location.hash === `#/HR/Leaves` ||
                          window.location.hash === `#/HR/Fullday` ||
                          window.location.hash === `#/HR/Fullday/${userId}` ? (
                            <>
                              <div className="sub-menu-items">
                                {menuItems.subNames.map((subMenu) => (
                                  <Link to={`/HR/${subMenu.name}`}>
                                    <div
                                      className={
                                        window.location.hash ===
                                        `#/HR/${subMenu.name}`
                                          ? `sidebar-content sub-active`
                                          : `sidebar-content`
                                      }
                                    >
                                      <div className="nav-link-item">
                                        <img
                                          src={`../images/SideBar/${subMenu.icon}`}
                                          alt=""
                                          width="100%"
                                          className="nav-item-icon-hr-module"
                                        />

                                        <span className="xn-text">
                                          {subMenu.name}
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {menuItems.name === "Travel Desk" && (
                        <Link to="/travelmanagement">
                          <div
                            className={
                              window.location.hash === `#/travelmanagement`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li className="tab-menu-items">
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <span className="material-symbols-outlined menu-symbols">
                                    travel
                                  </span>
                                </div>
                                <span className="xn-text">Travel Desk</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Client Complaint" && (
                        <Link to="/complaints">
                          <div
                            className={
                              window.location.hash === `#/complaints`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li className="tab-menu-items">
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/complaints.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Complaints</span>
                                <i
                                  className="bi-chevron-right toggle-btn"
                                  style={{ visibility: "hidden" }}
                                ></i>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}

                      {menuItems.name === "Meetings" && (
                        <Link to="/meetings">
                          <div
                            className={
                              window.location.hash === `#/meetings`
                                ? "sidebar-item plain active"
                                : "sidebar-item plain"
                            }
                          >
                            <li className="tab-menu-items">
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/meetings.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Meetings</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}

                      {menuItems.name === "Clients" && (
                        <div
                          className={
                            (subId === "Clients" && open) ||
                            window.location.hash === `#/company/${clientCatId}`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <Link to="/company/0">
                            <li
                              name="CLIENTS"
                              id="Clients"
                              onClick={(e) => subMenuOpen(e)}
                              className={
                                (subId === "Clients" && open) ||
                                window.location.hash ===
                                  `#/company/${clientCatId}`
                                  ? "nav-link-item drop-down-item active"
                                  : "nav-link-item drop-down-item drop-main"
                              }
                            >
                              <div className="sidebar-title" id="Clients">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/company.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span
                                  className="xn-text"
                                  name="CLIENTS"
                                  id="Clients"
                                >
                                  Company
                                </span>
                                <i
                                  className="bi-chevron-right toggle-btn"
                                  id="Clients"
                                />
                              </div>
                            </li>
                          </Link>

                          {(subId === "Clients" && open) ||
                            (window.location.hash ===
                              `#/company/${clientCatId}` && (
                              <div
                                style={{
                                  maxHeight: "200px",
                                  overflow: "auto",
                                }}
                                className="sub-menu-items"
                              >
                                {categoryDropdown.map((subMenu) => (
                                  <>
                                    <Link to={`/company/${subMenu.value}`}>
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/company/${subMenu.value}`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>
                                          <span className="xn-text">
                                            {subMenu.label}
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  </>
                                ))}
                              </div>
                            ))}
                        </div>
                      )}

                      {menuItems.name === "Orders" && (
                        <Link to="/orders">
                          <div
                            className={
                              window.location.hash === `#/orders`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li className="tab-menu-items">
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/products.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">
                                  {menuItems.name}
                                </span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Products" && (
                        <Link to="/products">
                          <div
                            className={
                              window.location.hash === `#/products`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li className="tab-menu-items">
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/products.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Products</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Routes" && (
                        <Link to="/routes">
                          <div
                            className={
                              window.location.hash === `#/routes`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li className="tab-menu-items">
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/routes.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Routes</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Forms" && (
                        <Link to="/forms">
                          <div
                            className={
                              window.location.hash === `#/forms`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li className="tab-menu-items">
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/forms.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Forms</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Spares" && (
                        <Link to="/spares">
                          <div
                            className={
                              window.location.hash === `#/spares`
                                ? `sidebar-item plain active`
                                : `sidebar-item plain`
                            }
                          >
                            <li className="tab-menu-items">
                              <div className="nav-link-item d-flex">
                                <div className="fa-icon-div">
                                  <img
                                    src="../images/SideBar/spares.svg"
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <span className="xn-text">Spares</span>
                              </div>
                            </li>
                          </div>
                        </Link>
                      )}
                      {menuItems.name === "Claims" && (
                        <div
                          className={
                            (subId === "claims" && open) ||
                            window.location.hash === `#/localclaim` ||
                            window.location.hash === `#/outstationclaim` ||
                            window.location.hash === `#/claimsummary` ||
                            window.location.hash === `#/travelReport`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="CLAIMS"
                            id="claims"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "claims" && open) ||
                              window.location.hash === `#/localclaim` ||
                              window.location.hash === `#/outstationclaim` ||
                              window.location.hash === `#/claimsummary` ||
                              window.location.hash === `#/travelReport`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="claims">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/claims.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="CLAIMS"
                                id="claims"
                              >
                                Claims
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="claims"
                              />
                            </div>
                          </li>

                          {(subId === "claims" && open) ||
                          window.location.hash === `#/localclaim` ||
                          window.location.hash === `#/outstationclaim` ||
                          window.location.hash === `#/claimsummary` ||
                          window.location.hash === `#/travelReport` ? (
                            <>
                              {menuItems.subNames.map((subMenu) => (
                                <div className="sub-menu-items">
                                  {subMenu.name === "Local Claims" && (
                                    <Link to="/localclaim">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/localclaim`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Local Claims
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Outstation Claims" && (
                                    <Link to="/outstationclaim">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/outstationclaim`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Outstation Claims
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Summary Claims" && (
                                    <Link to="/claimsummary">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/claimsummary`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Summary Claims
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Travel Report" && (
                                    <Link to="/travelReport">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/travelReport`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Travel Report
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {menuItems.name === "Connector" && (
                        <div
                          className={
                            (subId === "connect" && open) ||
                            window.location.hash === `#/user/referrals` ||
                            window.location.hash === `#/referenceInfo` ||
                            window.location.hash === `#/commission` ||
                            window.location.hash === `#/payment`
                              ? "sidebar-item open"
                              : "sidebar-item"
                          }
                        >
                          <li
                            name="CONNECTOR"
                            id="connector"
                            onClick={(e) => subMenuOpen(e)}
                            className={
                              (subId === "connect" && open) ||
                              window.location.hash === `#/user/referrals` ||
                              window.location.hash === `#/referenceInfo` ||
                              window.location.hash === `#/commission` ||
                              window.location.hash === `#/payment`
                                ? "nav-link-item drop-down-item active"
                                : "nav-link-item drop-down-item drop-main"
                            }
                          >
                            <div className="sidebar-title" id="connect">
                              <div className="fa-icon-div">
                                <img
                                  src="../images/SideBar/connector.svg"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                              <span
                                className="xn-text"
                                name="CONNECT"
                                id="connect"
                              >
                                Connector
                              </span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                id="connect"
                              />
                            </div>
                          </li>
                          {(subId === "connect" && open) ||
                          window.location.hash === `#/user/referrals` ||
                          window.location.hash === `#/referenceInfo` ||
                          window.location.hash === `#/commission` ||
                          window.location.hash === `#/payment` ? (
                            <>
                              {menuItems.subNames.map((subMenu, index) => (
                                <div key={index} className="sub-menu-items">
                                  {subMenu.name === "Ref User" && (
                                    <Link to="/user/referrals">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/user/referrals`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            User Profile
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Reference Info" && (
                                    <Link to="/referenceInfo">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/referenceInfo`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>
                                          <span className="xn-text">
                                            Reference Info
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Commission" && (
                                    <Link to="/commission">
                                      <div
                                        className={
                                          window.location.hash ===
                                          `#/commission`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>
                                          <span className="xn-text">
                                            Commission
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {subMenu.name === "Payment" && (
                                    <Link to="/payment">
                                      <div
                                        className={
                                          window.location.hash === `#/payment`
                                            ? `sidebar-content sub-active`
                                            : `sidebar-content`
                                        }
                                      >
                                        <div className="nav-link-item">
                                          <span className="bi bi-check-square-fill"></span>

                                          <span className="xn-text">
                                            Payment
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      <></>
                    </div>
                  ))}
                <hr />
                <div className="expanded-nav-bar">
                  <div onClick={openAppQrCode}>
                    <div className="sidebar-item plain download-app-link dflex-j-center">
                      <li>
                        <div className="nav-link-item d-flex">
                          <div className="fa-icon-div">
                            <img
                              src="../images/SideBar/downloadApp.svg"
                              alt=""
                              width="100%"
                            />
                          </div>
                          <span className="xn-text">Download App</span>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MDBCollapse>
        </>
        {/* CODE OF TABLET VIEW END */}
      </div>
    </div>
  );
}

export default Topbar;
