// Topbar page Breadcome
export const Get_BreadCome_Data = "Get_BreadCome_Data";
export const Get_Admin_Access = "Get_Admin_Access";
export const Get_Support_Access = "Get_Support_Access";
export const Get_Total_Data = "Get_Total_Data";
// Topbar page Breadcome
export const Get_Main_Modules = "Get_Main_Modules";
export const Add_Designation = "Add_Designation";
export const Get_Subscription = "Get_Subscription";
export const Get_Subscr_Dropdown = "Get_Subscr_Dropdown";
export const Get_All_Subscription = "Get_All_Subscription";
export const Get_Subscription_Module = "Get_Subscription_Module";
export const Get_All_Menus = "Get_All_Menus";
export const Get_All_Id_Subs = "Get_All_Id_Subs";
export const Get_Roles_DropDown = "Get_Roles_DropDown";

export const Post_Menus_Subscription = "Post_Menus_Subscription";

export const Get_Track_User = "Get_Track_User";

export const Get_Successs_Msg = "Get_Successs_Msg";
export const Get_Distance_Matrix = "Get_Distance_Matrix";

// Dashboard page Graph Api's
export const Get_GraphData = "Get_GraphData";
export const Get_GraphCount = "Get_GraphCount";
// Dashboard page Graph Api's

// Category page
export const Get_Client_Category = "Get_Client_Category";
export const Get_Client_Reporting_Category = "Get_Client_Reporting_Category";
export const Client_Category_DropDown = "Client_Category_DropDown";
export const Update_Client_Category = "Update_Client_Category";
export const Post_Client_Category = "Post_Client_Category";
export const Edit_Client_Category = "Edit_Client_Category";
export const Get_Single_Client_Category = "Get_Single_Client_Category";
// Category page

// Statge page
export const Get_Client_Stage = "Get_Client_Stage";
export const Stage_DropDown = "Stage_DropDown";
export const Post_Client_Stage = "Post_Client_Stage";
export const Update_Client_Stage = "Update_Client_Stage";
// Statge page

// Custom fields page
export const Post_Custom_Fields = "Post_Custom_Fields";
export const Get_Custom_Fields = "Get_Custom_Fields";
export const Get_Single_Filed = "Get_Single_Filed";
export const Update_Custom_Fields = "Update_Custom_Fields";
// Custom fields page

// Company page
export const Get_Company_Data = "Get_Company_Data";
export const Get_Reporting_Client_Data = "Get_Reporting_Client_Data";
export const Get_Single_Client = "Get_Single_Client";
export const Post_Other_Address = "Post_Other_Address";
export const Post_Other_Contact = "Post_Other_Contact";
export const Get_Other_Address = "Get_Other_Address";
export const Get_Other_Contact = "Get_Other_Contact";
export const ContactBy_Company_Drop = "ContactBy_Company_Drop";
export const Post_Comapy_Data = "Post_Comapy_Data";
export const Update_Company_Data = "Update_Company_Data";
export const Client_Dropdown = "Client_Dropdown";
// Company page

// Holidays page
export const Get_Holiday_List = "Get_Holiday_List";
export const Post_Holiday_List = "Post_Holiday_List";
export const Single_Holiday_Data = "Single_Holiday_Data";
export const Update_Holiday_Data = "Update_Holiday_Data";
export const Hliday_Types = "Hliday_Types";
// Holidays page

// Shift page
export const Get_Shift_List = "Get_Shift_List";
export const Post_Shift_List = "Post_Shift_List";
export const Get_Shift_Dropdown = "Get_Shift_Dropdown";
// Shift page

// Client complaint page
export const Get_Client_Complains = "Get_Client_Complains";
export const Post_Client_Complaint = "Post_Client_Complaint";
export const Simgle_Client_Complaint = "Simgle_Client_Complaint";
export const Update_Client_Complaint = "Update_Client_Complaint";
// Client complaint page

// Products page
export const Get_Products_List = "Get_Products_List";
export const Get_Single_Product = "Get_Single_Product";
export const Get_Product_DropDown = "Get_Product_DropDown";
export const Post_Product_Data = "Post_Product_Data";
export const Update_Product_Data = "Post_Product_Data";
export const Get_Cart_List = "Get_Cart_List";
// Products page

// Orders Page
export const Get_Orders = "Get_Orders";
export const Get_Single_Orders = "Get_Single_Orders";
export const Get_ProductsBy_Order = "Get_ProductsBy_Order";
export const Get_Order_History = "Get_Order_History";
export const Update_Product_Qty = "Update_Product_Qty";
// Orders Page

// Meetings Page
export const Get_Meetings_Data = "Get_Meetings_Data";
export const Get_MeetingsDetails_Data = "Get_MeetingsDetails_Data";
export const Get_MeetingsCount_Data = "Get_MeetingsCount_Data";
export const Get_MeetingPurpose_Data = "Get_MeetingPurpose_Data";

// Meetings Page

// Product Category page
export const Get_ProdCategory_Dropdown = "Get_ProdCategory_Dropdown";
export const Get_Prod_Sub_Category = "Get_Prod_Sub_Category";
// Product Category page

// Designation Page
export const Get_Designation_Dropdown = "Get_Designation_Dropdown";
export const Get_Designation_Department = "Get_Designation_Department";
// Designation Page

// Basefare page
// mot dropdown
export const Get_Mot_Dropdown = "Get_Mot_Dropdown";
// mot dropdown
export const Get_BaseFare_List = "Get_BaseFare_List";
export const Post_Basefare = "Post_Basefare";
export const Get_Single_BaseFare = "Get_Single_BaseFare";
export const Update_Basefare = "Update_Basefare";
// Basefare page

// Departmaent Page
export const Get_Department_Dropdown = "Get_Department_Dropdown";
// Departmaent Page

// GET USER REGION
export const Get_Country_List = "Get_Country_List";
export const Get_CountryCode_List = "Get_CountryCode_List";
// GET USER REGION

// Get Apis for Getting State, Zone, City, Country
export const Get_Zones = "Get_Zones";
export const Get_States_ByZoneId = "Get_States_ByZoneId";
export const Get_City_ByStateID = "Get_City_ByStateID";
// Get Apis for Getting State, Zone, City, Country

// Upload Single image as base64
export const Upload_Single_Image = "Upload_Single_Image";
// Upload Single image as base64

// Super Adimin Pages
// Google credits
export const Get_Google_Credits = "Get_Google_Credits";
export const Get_API_Dropdown = "Get_API_Dropdown";
export const Get_Credits_Count = "Get_Credits_Count";
export const Get_API_Credits = "Get_API_Credits";
export const GET_APT_HITS = "GET_APT_HITS";
export const Post_Google_Credits = "Post_Google_Credits";
export const Post_Api_Credits = "Post_Api_Credits";
export const Get_GoogleApiName_Drop = "Get_GoogleApiName_Drop";
export const Get_Single_ApiCredit = "Get_Single_ApiCredit";
export const Get_Exception = "Get_Exception";
export const Get_Mobile_Exception = "Get_Mobile_Exception";
export const Get_Otp_Data = "Get_Otp_Data";
// Google credits

// Leads Data
export const get_leads_card_data = "get_leads_card_data";
export const get_leads_table_data = "get_leads_table_data";
export const get_lead_gen_Subscription_Report_table_data =
  "get_lead_gen_Subscription_Report_table_data";
// Leads Data
// Super Adimin Pages

// User Page
export const Get_User_Dropdown = "Get_User_Dropdown";
export const Get_User_Dropdown_ByManager = "Get_User_Dropdown_ByManager";
export const Get_User_Dropdown_By_Company = "Get_User_Dropdown_By_Company";
export const Get_User_Regions = "Get_User_Regions";
export const Get_Allser_Details = "Get_Allser_Details";
// User Page

export const Get_LeaveType_Dropdown = "Get_LeaveType_Dropdown";

export const Get_Manager_Dropdown = "Get_Manager_Dropdown";

// Googel Hit Count
// export const Get_Hit_Count = "Get_Hit_Count";
// Googel Hit Count

// Subscription Pages
export const get_Subscription_Type_Dropdown = "get_Subscription_Type_Dropdown";
export const Update_Subscription = "Update_Subscription";
// Subscription Pages

// Refferal Pages
export const get_SalesBee_Refferal = "get_SalesBee_Refferal";
export const post_SalesBee_Refferal = "post_SalesBee_Refferal";
export const update_SalesBee_Refferal = "update_SalesBee_Refferal";
export const Get_Single_Refferal_Data = "Get_Single_Refferal_Data";
// Refferal Pagese

// ClientCategoryMapPage
export const get_clientCategory_Map = "get_clientCategory_Map";
export const post_clientCategory_Map = "post_clientCategory_Map";
// ClientCategoryMapPage

// Hierarchy Page
export const get_Hierarchy_Data = "get_Hierarchy_Data";
// Hierarchy Page

// User Activity Page
export const get_User_Activity_Data = "get_User_Activity_Data";
// User Activity Page

// ProductFilter Page
export const post_productFilter_Data = "post_productFilter_Data";
export const get_productFilter_Data = "get_productFilter_Data";
export const get_Single_productFilter_Data = "get_Single_productFilter_Data";
export const update_productFilter_Data = "update_productFilter_Data";
// ProductFilter Page

// UserReferrals Page
export const get_UserReferrals_Data = "get_UserReferrals_Data";
export const get_UserReferrals_DataDropDown = "get_UserReferrals_DataDropDown";
export const post_UserReferrals_BankDetails = "post_UserReferrals_BankDetails";
export const get_SingleUserReferrals_Data = "get_SingleUserReferrals_Data";
export const get_UserReferrals_DropDown = "get_UserReferrals_DropDown";
// UserReferrals Page

// Referral Product Page
export const get_referralProduct_Data = "get_referralProduct_Data";
// Referral Product Page

// Commission Page
export const get_commission_Data = "get_commission_Data";
export const post_commission_Data = "post_commission_Data";
export const get_ProductDropdown_Data = "get_ProductDropdown_Data";
export const get_SingleCommission_Data = "get_SingleCommission_Data";
export const upDate_Commission_Data = "upDate_Commission_Data";
// Commission Page

// Passbook Page
export const get_PassbookCard_Data = "get_PassbookCard_Data";
export const get_Transaction_Data = "get_Transaction_Data";
export const get_userBank_Data = "get_userBank_Data";
export const get_userPassbookDetails = "get_userPassbookDetails";
export const get_PassbookSummery_Data = "get_PassbookSummery_Data";
export const get_PassbookSummery_ById = "get_PassbookSummery_ById";
export const get_userTransaction_Details = "get_userTransaction_Details";
// Passbook Page

// Payment Page
export const get_Payment_Data = "get_Payment_Data";
export const add_Payment_Data = "add_Payment_Data";
export const get_Single_Payment_Data = "get_Single_Payment_Data";
// Payment Page

// SignupSubscription page
export const get_SignupSubscription_Data = "get_SignupSubscription_Data";
// SignupSubscription page

// CreateSubscription Page
export const get_ReferredBy_Dropdown = "get_ReferredBy_Dropdown";
// CreateSubscription Page

// Payroll Page
export const get_Payroll_Data = "get_Payroll_Data";
export const post_Employee_Payroll = "post_Employee_Payroll";
export const post_FixedSalary_Data = "post_FixedSalary_Data";
export const delete_Employee_Payroll = "delete_Employee_Payroll";
// Payroll Page

// Region Page
export const get_Country_Name = "get_Country_Name";
export const get_Dropdown_State_Name = "get_Dropdown_State_Name";
export const get_AllState_Name = "get_AllState_Name";
export const get_AllCity_Name = "get_AllCity_Name";
export const Add_State_Name = "Add_State_Name";
export const Add_City_Name = "Add_City_Name";
// Region Page

// OrthoAttendence page
export const get_OrthoAttendence = "get_OrthoAttendence";
// OrthoAttendence page
// TravelReport Page
export const get_TravelReport_Attachment = "get_TravelReport_Attachment";
export const get_Single_TravelReport_Data = "get_Single_TravelReport_Data";
// TravelReport Page

// Route Deviation Page
export const get_Route_Deviation_Data = "get_Route_Deviation_Data";
// Route Deviation Page

// KPA Page
export const get_Kpa_Data = "get_Kpa_Data";
export const post_Kpa_Data = "post_Kpa_Data";
export const get_Single_Kpa_Data = "get_Single_Kpa_Data";
// KPA Page
// Account Settings Page
export const post_Direct_Visit_Data = "post_Direct_Visit_Data";
export const post_BaseLocation_CheckIn_Radius =
  "post_BaseLocation_CheckIn_Radius";
export const post_BaseLocation_CheckOut_Radius =
  "post_BaseLocation_CheckOut_Radius";
// Account Settings Page

export const get_Custome_Fields_For_Company = "get_Custome_Fields_For_Company";
export const post_Custom_Fields_For_Company = "post_Custom_Fields_For_Company";
export const get_Single_Custome_Fields_For_Company =
  "get_Single_Custome_Fields_For_Company";
