import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import { addRefferalData, loadSingleRefferalData, loadUpdateSalesBeeRefferal } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";

function AddRefferal(props) {

  let dispatch = useDispatch();
  const { apiSingleRefferalData } = useSelector((state) => state.data)
  console.log(apiSingleRefferalData)
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const salesBeeReferelId = props.salesBeeReferelId;

  const [name, setName] = useState()
  const [mobileNum, setMobileNum] = useState()
  const [email, setEmail] = useState()
  const [note, setNote] = useState()

  const closeAddModal = () => {
    props.handleClose();
  };

  // code to refill the input field for edit

  useEffect(() => {
    if (salesBeeReferelId) {
      dispatch(loadSingleRefferalData(salesBeeReferelId));
    }
  }, [salesBeeReferelId]);

  useEffect(() => {
    if (salesBeeReferelId && apiSingleRefferalData) {
      if (Object.keys(apiSingleRefferalData).length !== 0) {
        setName(apiSingleRefferalData.name);
        console.log(apiSingleRefferalData.name)
        setMobileNum(apiSingleRefferalData.mobile);
        setEmail(apiSingleRefferalData.email);
        setNote(apiSingleRefferalData.note);
      }
    }
  }, [salesBeeReferelId, apiSingleRefferalData]);


  // code to post the data

  let postRefferalData = (e) => {
    e.preventDefault()

    setNameError(false);
    setMobileNumError(false);
    setEmailError(false);
  
    // Validate fields before proceeding
    let hasErrors = false;
  
    if (!name) {
      setNameError(true);
      hasErrors = true;
    }
    if (!mobileNum) {
      setMobileNumError(true);
      hasErrors = true;
    }
    if (!email) {
      setEmailError(true);
      hasErrors = true;
    }
  
    // If any field has an error, stop the submission
    if (hasErrors) {
      return;
    }

    let data = { subscriptionID: resPassData.subscriptionId, salesBeeReferelID: salesBeeReferelId, name: name, mobile: mobileNum, email: email, note: note }

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(data),
    };
    if (salesBeeReferelId) {
      dispatch(loadUpdateSalesBeeRefferal(requestOption, closeAddModal))
    } else {
      dispatch(addRefferalData(requestOption, closeAddModal))
    }
  }


  const [nameError, setNameError] = useState(false);
  const [mobileNumError, setMobileNumError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">
            REFER & EARN
          </h6>

          <i className="fa fa-times" onClick={closeAddModal} />

        </div>
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">

              <div className="mb-3" style={{ position: "relative" }}>
                <FloatingLabel
                  label="Enter Name"
                  className={`${name
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                >
                  <input
                    type="text"
                    className="form-control form add-basefare-input"
                    id="exampleFormControlInput1"
                    name="name"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value); 
                      setNameError(false)
                    }}
                    disabled={props.clickType}
                    required
                  />
                </FloatingLabel>
                {nameError && <p className="text-danger" style={{marginTop:"8px"}}>Name is required.</p>}
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <FloatingLabel
                  label="Enter Mobile Number"
                  className={`${mobileNum
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                >
                  <input
                    type="tel"
                    className="form-control form add-basefare-input"
                    id="exampleFormControlInput1"
                    placeholder="Enter Mobile Number"
                    name="mobileNumber"
                    value={mobileNum}
                    onChange={(e) => {
                      setMobileNum(e.target.value);
                      setMobileNumError(false)
                    }}
                    disabled={props.clickType}
                    maxLength={10}
                    pattern="[0-9]{10}"
                    title="Please enter a valid 10-digit mobile number"
                    required
                  />
                </FloatingLabel>
                {mobileNumError && <p className="text-danger" style={{marginTop:"8px"}}>Mobile number is required.</p>}
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <FloatingLabel
                  label="Enter Email"
                  className={`${email
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                >
                  <input
                    type="email" // Use 'email' type for email input
                    className="form-control form add-basefare-input"
                    id="exampleFormControlInput1"
                    placeholder="Enter Email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      setEmailError(false)
                    }}
                    disabled={props.clickType}
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}" 
                    title="Please enter a valid email address" 
                    required 
                  />
                </FloatingLabel>
                {emailError && <p className="text-danger" style={{marginTop:"8px"}}>Email is required.</p>}
              </div>

              <div className="mb-2">
                <FloatingLabel
                  label='Note'
                  className={`${note
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}>
                  <textarea
                    rows="3"
                    cols="20"
                    name="usrtxt"
                    wrap="hard"
                    className="form-control form form-textarea add-spare-input"
                    id="descriptionArea"
                    placeholder="Note"
                    required
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </FloatingLabel>
              </div>

            </div>
          </div>
        </form>

        <div className="btns-save-cancel">
          {!props.clickType && (
            <SBSaveUpdateBtn
              btnName={salesBeeReferelId ? "Update" : "Refer Now"}
              onClickEffect={postRefferalData} />
          )}
          <SbCancelBtn
            onClickEffect={closeAddModal}
            btnName="Cancel" />
        </div>
      </Modal>
    </div>
  );
}

export default AddRefferal;





