import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/Tracker.scss";
import profileimg from "../../images/profile-1.png";
import NoDataImg from "../../images/no-data-found.png";
import { useParams, useNavigate } from "react-router-dom";
import { baseURL } from "../BaseUrl";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import GoogleMapComp from "../GoogleMapComp";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import GoogleAccessModal from "../GoogleAccessModal";
import { useDispatch } from "react-redux";
import { loadPageBreadCome } from "../../redux/action";
import LoadingAnimation from "../LoadingAnimation";
import { travelizeAuth } from "../HeaderAuthentication";

function TrackByUser() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  /* HEADER AUTH END */

  let dispatch = useDispatch();
  let distanceMatrix = sessionStorage.getItem("distanceMatrixID");
  const [mapView, setMapView] = useState(false);

  useEffect(() => {
    if (distanceMatrix == 1) {
      setMapView(true);
    } else {
      setMapView(false);
    }
  }, [distanceMatrix]);

  const { id, checkout } = useParams();
  let navigate = useNavigate();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [date, setDate] = useState(new Date());
  const [dateTime, setDateTime] = useState("");
  const [trackUserDetails, setTrackUserDetails] = useState([]);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const currentTimeNow = `${new Date().toTimeString().slice(0, 5)}`;
  const [time, setTime] = useState(currentTimeNow);
  const [isLoading, setIsLoading] = useState(true);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  /* TIME 24HRS TO  12 HRS START */
  useEffect(() => {
    let breadCome = { mainpage: "User Location" };
    dispatch(loadPageBreadCome(breadCome));
    const currentTime = time.slice(0, 2);
    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = time.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        setDateTime("0" + exactTime + " " + "PM");
      } else {
        let str = time.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        setDateTime(exactTime + " " + "PM");
      }
    } else if (currentTime === 12) {
      setDateTime(time + " " + "PM");
    } else if (currentTime === 0) {
      let str = time.split("");
      str.splice(0, 2, 12);
      let exactTime = str.join("");
      setDateTime(exactTime + " " + "AM");
    } else {
      setDateTime(time + " " + "AM");
    }
  }, [time]);
  /* TIME 24HRS TO  12 HRS END */

  /* FETCH TRACK USER DATA START */
  useEffect(() => {
    window.scrollTo(0, 0);
    {
      id && date && fetchTrackUser();
    }
  }, [id, date, dateTime]);

  const fetchTrackUser = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Activity/GetCordsUserID?Date=${moment(date).format(
        "DD/MM/YYYY"
      )} ${dateTime}&UserID=${id}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setTrackUserDetails(res.result[0]);
          setLatLan({
            lat: parseFloat(res.result[0]?.cordLoc?.latitude),
            lng: parseFloat(res.result[0]?.cordLoc?.longitude),
          });
          setIsLoading(false);
        } else {
          setTrackUserDetails("");
          setLatLan({ lat: null, lng: null });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };
  /* FETCH TRACK USER DATA START */

  const [accessModal, setAccessModal] = useState(false);
  const giveAccess = () => {
    setAccessModal(true);
  };

  const closeAccess = () => {
    setAccessModal(false);
  };

  const mapAccesss = () => {
    setAccessModal(false);
    setMapView(true);
  };

  // Page navigation
  const goToBackPage = () => {
    sessionStorage.removeItem("userID");
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* TOPBAR END */}

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>
                  <div className="page-content">
                    <div className="user-table">
                      <Row className="search-row">
                        <Col md={3} className="cat-col">
                          <div className="page-header-text-div">
                            <div className="back-btn" onClick={goToBackPage}>
                              <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                              />
                            </div>
                            <h6 className="page-header-text">TRACK BY USER</h6>
                          </div>
                        </Col>
                        <Col md={9} className="cat-col"></Col>
                      </Row>
                    </div>
                    {/* TRACKER TABLE START */}
                    <div className="tracker-table">
                      <div className="table-responsive tracker-tabel-scroll">
                        <table className="table trackers-list-table">
                          <thead className="thead-light trackers-list-thead">
                            <tr>
                              <th>NAME</th>
                              <th>MOBILE</th>
                              <th>LOCATION</th>
                              <th>DATE</th>
                              <th>WHEN</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <tr>
                                <td
                                  colSpan={5}
                                  align="center"
                                  style={{ margin: "10px" }}
                                >
                                  <LoadingAnimation />
                                  Locating you on the map, just a moment.
                                </td>
                              </tr>
                            ) : trackUserDetails !== "" &&
                              id !== "" &&
                              date !== "" ? (
                              <tr className="track-list-row-data">
                                <td>
                                  <span className="track-list-name d-flex">
                                    <div className="active-user-image">
                                      <img
                                        className="table-data-track-imgages"
                                        // src={`${filterUserId.BaseURL}${filterUserId.profileIcon}`}
                                        src={
                                          trackUserDetails?.profileIcon
                                            ? `${baseURL}/${trackUserDetails?.profileIcon}`
                                            : profileimg
                                        }
                                        alt="John Doe"
                                      />
                                      {trackUserDetails?.fullName && (
                                        <OverlayTrigger
                                          delay={{ hide: 450, show: 300 }}
                                          overlay={(props) => (
                                            <Tooltip {...props}>
                                              {checkout === "null"
                                                ? "Active"
                                                : "In Active"}
                                            </Tooltip>
                                          )}
                                          placement="top"
                                        >
                                          <div className="blink-effect">
                                            <div
                                              className={`check-in-status ${
                                                checkout === "null"
                                                  ? "status-active"
                                                  : "status-inactive"
                                              }`}
                                            ></div>
                                          </div>
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                    <h6>{trackUserDetails?.fullName}</h6>
                                  </span>
                                </td>
                                <td>{trackUserDetails?.mobile}</td>
                                <td style={{ maxWidth: "200px" }}>
                                  {trackUserDetails?.cordLoc?.location}
                                </td>
                                <td>
                                  {moment(trackUserDetails?.date).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </td>
                                <td>{trackUserDetails?.minsHrsAgo}</td>
                              </tr>
                            ) : (
                              <tr>
                                <td
                                  className="no-data-found text-center"
                                  colSpan={5}
                                >
                                  ------ NO DATA FOUND ------
                                </td>
                              </tr>
                              // <tr className="no-data-found text-center">
                              //   <td
                              //     className="color-yellow"
                              //     colSpan={5}
                              //     style={{ fontSize: "1rem" }}
                              //   >
                              //     Activity tracking has been temporarily stopped
                              //     by Google and is currently under review.{" "}
                              //     <br />
                              //     We will update you once the review is complete
                              //     and tracking can resume.
                              //   </td>
                              // </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* TRACKER TABLE END */}
                    <div className="tracker-page-google-map">
                      {mapView ? (
                        <div className="google-map-box">
                          {trackUserDetails !== "" &&
                          id !== "" &&
                          date !== "" ? (
                            // <GoogleMapComp
                            //   className="google-component"
                            //   pageType="Tracker"
                            //   latLan={latLan}
                            //   trackUserDetails={trackUserDetails}
                            // />
                            <>MAP IS DISABLED</>
                          ) : (
                            <>
                              <div className="hv-center">
                                <img
                                  src={NoDataImg}
                                  alt="no-data-img"
                                  className="mCS_img_loaded"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="google-map-box p-2 empty-map">
                          <div className="overlay-div">
                            <div className="over-lay-text-div">
                              <h6 style={{ color: "#ffb900" }}>
                                This Feature is not available in your
                                Subscription...!
                              </h6>
                              <p className="mt-3">
                                If you still want to view the map{" "}
                                <span
                                  className="complete-status status-badge"
                                  style={{ cursor: "pointer" }}
                                  onClick={giveAccess}
                                >
                                  Click Here
                                </span>
                              </p>
                            </div>
                          </div>
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.56659496218!2d77.46612702802196!3d12.95428023487456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1692190317657!5m2!1sen!2sin"
                            width="100%"
                            height="450"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      )}
                    </div>
                    {/* PAGE CONTENT END*/}
                  </div>
                </div>
                {accessModal && latLan.lat != null && (
                  <GoogleAccessModal
                    accessModal={accessModal}
                    closeAccess={closeAccess}
                    mapAccesss={mapAccesss}
                  />
                )}
                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default TrackByUser;
