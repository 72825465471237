import React from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function PaymentDetails(props) {
  return (
    <div>
      <div className="booking-payment-details">
        <div className="mode-of-payment-selction">
          <div className="mop-selections">
            <div className="mop-heading">Select Mode of Payment :</div>
            <div className="dflex-j-start mt-1">
              {props.modeOfPaymentOption.map((item, i) => (
                <Form.Check
                  inline
                  label={item?.label}
                  value={item?.value}
                  className="modeof-payment-radio"
                  name={`${props.motName}-modeofPayment`}
                  type="radio"
                  id={`${props.motName}-inlineradio-mop-${i}`}
                  onChange={(e) =>
                    props.handleModeOfPayemnt(+e.target.value, props.motName)
                  }
                  checked={item?.value == props.modeOfPayment ? true : false}
                />
              ))}
            </div>
          </div>
          <div className="entering-payment-details mt-3">
            <div className="enter-details upi">
              <div className="input-field react-date-time-picker">
                <FloatingLabel
                  label="Payment Date"
                  className={`${
                    props.paymentDate
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <DatePicker
                    selected={props.paymentDate}
                    className="select date-time-picker"
                    id="date-time-picker"
                    // showTimeSelect
                    dateFormat="dd/MM/yyyy"
                    name="paymentDate"
                    // minDate={new Date()}
                    onChange={(date) => props.setPaymentDate(date)}
                    maxDate={new Date()}
                    placeholderText="Select Date"
                    // timeIntervals={15}
                  />
                </FloatingLabel>
              </div>
              <div className="input-field">
                <FloatingLabel
                  label="Payment Amount"
                  className={`${
                    props.paymentPaid
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Enter amount"
                    value={props.paymentPaid}
                    onChange={(e) => props.setPaymentPaid(e.target.value)}
                    required
                  />
                </FloatingLabel>
              </div>
              {(props.modeOfPayment == 1 || props.modeOfPayment == 3) && (
                <div className="input-field">
                  <FloatingLabel
                    label="UTR Number"
                    className={`${
                      props.utrNumber
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="tel"
                      autoComplete="off"
                      className="form-control"
                      placeholder="UTR Number"
                      value={props.utrNumber}
                      maxLength={12}
                      onChange={(e) => props.setUTRNumber(e.target.value)}
                      onKeyDown={props.NumberValidation}
                      required
                    />
                  </FloatingLabel>
                </div>
              )}
              {props.modeOfPayment == 1 ? (
                <>
                  <div className="input-field">
                    <FloatingLabel
                      label="Mobile Number"
                      className={`${
                        props.upiMobileNum
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="tel"
                        autoComplete="off"
                        className="form-control"
                        placeholder="UPI Mobile Number"
                        value={props.upiMobileNum}
                        maxLength={12}
                        onChange={(e) => props.setUPIMobileNum(e.target.value)}
                        onKeyDown={props.NumberValidation}
                        required
                      />
                    </FloatingLabel>
                  </div>
                </>
              ) : props.modeOfPayment == 2 || props.modeOfPayment == 3 ? (
                <>
                  <div className="input-field">
                    <FloatingLabel
                      label="Bank Name"
                      className={`${
                        props.bankName
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Enter Bank Name"
                        value={props.bankName}
                        onChange={(e) => props.setBankName(e.target.value)}
                        required
                      />
                    </FloatingLabel>
                  </div>
                  {props.modeOfPayment == 2 && (
                    <>
                      <div className="input-field">
                        <FloatingLabel
                          label="Card Number"
                          className={`${
                            props.cardNumber
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="tel"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Enter Card Number"
                            value={props.cardNumber}
                            maxLength={15}
                            onKeyDown={props.NumberValidation}
                            onChange={(e) =>
                              props.setCardNumber(e.target.value)
                            }
                            required
                          />
                        </FloatingLabel>
                      </div>

                      <div className="input-field">
                        <FloatingLabel
                          label="Name on Card"
                          className={`${
                            props.nameonCard
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Name on Card"
                            value={props.nameonCard}
                            onChange={(e) =>
                              props.setNameonCard(e.target.value)
                            }
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </>
                  )}
                  {props.modeOfPayment == 3 && (
                    <>
                      <div className="input-field">
                        <FloatingLabel
                          label="Account Number"
                          className={`${
                            props.accountNumber
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="tel"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Enter Account Number"
                            value={props.accountNumber}
                            maxLength={15}
                            onKeyDown={props.NumberValidation}
                            onChange={(e) =>
                              props.setAccountNumber(e.target.value)
                            }
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="input-field">
                        <FloatingLabel
                          label="Account Name"
                          className={`${
                            props.nameoftheAccount
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Enter Name of the Account"
                            value={props.nameoftheAccount}
                            onChange={(e) =>
                              props.setNameoftheAccount(e.target.value)
                            }
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
