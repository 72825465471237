import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import '../../../styles/googleCredits.scss'
import { useNavigate } from 'react-router-dom';

function formatDate() {
    const date = new Date();
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`
}

function GoogleCreditTable(props) {

    const [showModal, setShowModal] = useState("");

    const handleHover = (apikey) => {
        setShowModal(apikey);
    };

    const removeModal = () => {
        setShowModal("");
    };

    let navigate = useNavigate();
    const navigateToGoogleHit = (key) => {
        navigate(`/googleapikeylist/${key}`)
    }

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='credits-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell align={name.align} style={{ minWidth: `${name.width}` }} key={i} className={`thead-cell-products`} >
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <>
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                            </>
                            :
                            <>
                                {props.googleCreditList && props.googleCreditList.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell>
                                                No data found...!
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.googleCreditList && props.googleCreditList.map((data, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data credits-table'>
                                                <TableCell align="center">
                                                    {i + 1}
                                                </TableCell>

                                                <TableCell
                                                    onMouseEnter={() => handleHover(data.GoogleApiKey)}
                                                    onMouseLeave={removeModal}
                                                >
                                                    <span className="table-row-head-Text" style={{ cursor: "pointer" }} onClick={() => props.onClickCredits(data.GoogleApiKey)} >
                                                        {data.GoogleApiKey && data.GoogleApiKey.length > 25
                                                            ? <><b>{data.GoogleApiKey.substring(0, 25) + "..."}</b></>
                                                            : <><b>{data.GoogleApiKey}</b></>}
                                                    </span>

                                                </TableCell>
                                                <TableCell align="center">
                                                    <span style={{ cursor: "pointer" }} onClick={() => navigateToGoogleHit(data.GoogleApiKey)}>{data.HitCount}</span>
                                                </TableCell>
                                                <TableCell align="center" className='remaining-credits'>
                                                    {
                                                        (parseInt(data.ApiCreadit) - parseInt(data.HitCount)) <= 0 ?
                                                            <span style={{ color: "red" }}>{parseInt(data.ApiCreadit) - parseInt(data.HitCount)}</span>
                                                            : <span>{parseInt(data.ApiCreadit) - parseInt(data.HitCount)}</span>
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <span className='color-secondary'>{data.ApiCreadit}</span>
                                                </TableCell>
                                                <TableCell>
                                                    {formatDate(data.AddedOnDate)}
                                                </TableCell>
                                                <TableCell className='action-class'>
                                                    {props.supportAccess === false && <button
                                                        onClick={() => props.editGoogleCredit(data.GoogleApiKey)}
                                                        className="btn client-history-link" >
                                                        <i className="bi bi-pencil-fill"></i> Edit
                                                    </button>}
                                                </TableCell>
                                                {showModal === data.GoogleApiKey && (
                                                    <div className="modal-box">
                                                        <div style={{ position: "relative" }}>
                                                            <span>{data.GoogleApiKey}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default GoogleCreditTable;






