import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import "../../../styles/VhdPODetails.scss";

export default function PODetailsTable(props) {
  return (
    <div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
          //   onScroll={(e) =>
          //     handleScroll(
          //       e,
          //       props.users,
          //       props.totalUserCount,
          //       props.setLoading,
          //       props.setPageSize,
          //       props.PageSize
          //     )
          //   }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="po-details-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {props.tableHeader.map((data, i) => (
                    <TableCell key={i} align={data?.align}>
                      {data?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell>{data?.line_No}</TableCell>
                    <TableCell>{data?.item_Name}</TableCell>
                    <TableCell>{data?.qty}</TableCell>
                    <TableCell>{data?.discount_Percent}</TableCell>
                    <TableCell>{data?.unit_Rate}</TableCell>
                    <TableCell>{data?.line_Total}</TableCell>
                    <TableCell>{data?.tax_Code}</TableCell>
                    <TableCell>{data?.tax_Percent}</TableCell>
                    <TableCell>{data?.tax_Amount}</TableCell>
                    <TableCell>{data?.gross_Total}</TableCell>
                    <TableCell>{data?.uom}</TableCell>
                    <TableCell align="center">{data?.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
