import React from "react";
import "../styles/LoadingAnimation.scss";

export default function LoadingAnimation() {
  return (
    <div className="loading-animation-page hv-center">
      <div className="sound-wave">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
