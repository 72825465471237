import React, { useEffect, useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import Footer from "../../Footer";
import "../../../styles/CommonStyle.scss";
import "../../../styles/TravelDesk.scss";
import { baseURL } from "../../BaseUrl";
import { Col, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TripPlanner from "./TripPlanner";
import { travelByOptions, travelDetails } from "../CommonData";
import { travelingBy } from "../../DropdownsData";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { toast } from "react-toastify";
import { calculateDaysDifference } from "../../FormattedAmount";

export default function TravelRequest() {
  let navigate = useNavigate();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [travelPlanShow, setTravelPlanShow] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [travelBy, setTravelBy] = useState(1);
  const [planddedTrips, setPlanddedTrips] = useState("Upcoming Trips");
  const [selectedTravels, setSelectedTravels] = useState(null);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    setSelectedTravels(Object.keys(travelDetails));
  }, []);

  const setFromToDate = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  const handleTravelBy = (value) => {
    setTravelBy(value);
  };

  const goToTripPlannerPage = () => {
    if (!fromDate || !toDate) {
      toast.warning("Select From date and To date before proceeding to next", {
        autoClose: 5000,
      });
      return;
    }
    setTravelPlanShow(true);
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div>
      <div id="main-page">
        {/* SIDEBAR START */}
        <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
        {/* SIDEBAR END */}

        {/* TOPBAR START */}
        <Topbar
          toggleMenu={toggleMenu}
          menuCollapse={menuCollapse}
          toggleshift={toggleshift}
        />
        {/* TOPBAR END */}

        {/* MAIN BODY START */}
        <div className="page-wrapper">
          {/* PAGE CONTENT START */}

          <div className={`main-body ${toggleshift.style}`}>
            <div className="page-content">
              <div className="user-table">
                <Row className="search-row">
                  <Col md={3} className="cat-col">
                    <div className="page-header-text-div">
                      <div className="back-btn" onClick={goToBackPage}>
                        <img
                          src="../../../../images/DefaultImages/back-btn-arrow.svg"
                          alt=""
                        />
                      </div>
                      <h6 className="page-header-text">TRAVEL REQUEST</h6>
                    </div>
                  </Col>
                  <Col md={9} className="cat-col"></Col>
                </Row>
              </div>
              {/* TRAVEL DESK MAIN PAGE */}
              <div className="travel-desk-page">
                <div className="travel-desk-page-body">
                  <div className="travel-plan">
                    {/* TRAVEL PLAN DATE AND TRAVEL BY SELECTION */}
                    {!travelPlanShow ? (
                      <div className="select-date-n-travel-by">
                        <Row>
                          <Col md={6}>
                            <div className="date-n-travel-selection">
                              <div className="date-picker-part">
                                <h6 className="date-picker-header">
                                  Select dates for trip plan
                                </h6>
                                {/* <h6>From-To Date</h6> */}
                                <DatePicker
                                  inline
                                  selected={fromDate}
                                  className="select from-date date-filter"
                                  id="date-picker-for-travel-plan"
                                  dateFormat="dd/MM/yyyy"
                                  selectsRange
                                  startDate={fromDate}
                                  endDate={toDate}
                                  onChange={(date) => setFromToDate(date)}
                                  minDate={new Date()}
                                />
                                {/* <div
                                  className="react-date-time-picker mt-1"
                                  style={{ width: "80%" }}
                                >
                                  <DatePicker
                                    selected={fromDate}
                                    className="select date-time-picker"
                                    id="date-time-picker"
                                    selectsRange
                                    dateFormat="dd/MM/yyyy"
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={(date) => setFromToDate(date)}
                                    minDate={new Date()}
                                    placeholderText="Select Dates"
                                  />
                                </div> */}
                                {/* <div className="date-select-picker">
                                  <div className="calender-div">
                                    <h6>From Date</h6>
                                    <Calendar
                                      className="custom-calender"
                                      onChange={onDateFromChange}
                                      value={fromDate}
                                      showNeighboringMonth={false}
                                      locale={"en-US"}
                                      minDate={new Date()}
                                    />
                                  </div>
                                  <div style={{ width: "6%" }}>
                                    <img src="../../../../images/TravelDesk/right-arrow.png" alt="" style={{ width: "100%" }} />
                                  </div>
                                  <div className="calender-div">
                                    <h6>To Date</h6>
                                    <Calendar
                                      className="custom-calender"
                                      onChange={onDateToChange}
                                      value={toDate}
                                      showNeighboringMonth={false}
                                      locale={"en-US"}
                                      minDate={fromDate ? fromDate : new Date()}
                                    />
                                  </div>
                                </div> */}
                                <div
                                  className="pt-2"
                                  style={{ textAlign: "center" }}
                                >
                                  {fromDate && toDate && (
                                    <h6>
                                      Selected date :{" "}
                                      {moment(new Date(fromDate)).format(
                                        "DD/MM/YYYY"
                                      )}{" "}
                                      to{" "}
                                      {moment(new Date(toDate)).format(
                                        "DD/MM/YYYY"
                                      )}{" "}
                                      &nbsp; (
                                      {`${calculateDaysDifference(
                                        fromDate,
                                        toDate
                                      )}-Days`}
                                      )
                                    </h6>
                                  )}
                                </div>
                              </div>
                              <div className="traveling-selection">
                                <h6>Travel Type</h6>
                                <div className="dflex-j-start mt-2">
                                  {travelingBy.map((item, i) => (
                                    <div
                                      key={i}
                                      className="hv-center icon-n-radio"
                                    >
                                      <span className="material-symbols-outlined">
                                        {item.icon}
                                      </span>{" "}
                                      &nbsp;
                                      <div>
                                        <Form.Check
                                          inline
                                          label={item?.label}
                                          value={item?.value}
                                          className="travelBy-radio-btn"
                                          name="travelingBy"
                                          type="radio"
                                          id={`inlineradio-traveling-${i}`}
                                          onChange={(e) =>
                                            handleTravelBy(+e.target.value)
                                          }
                                          checked={
                                            item?.value === travelBy
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="planned-trips-data">
                              <div className="select-radio">
                                <div className="dflex-j-start mt-2">
                                  {["Upcoming Trips", "Trip History"].map(
                                    (item, i) => (
                                      <div key={i}>
                                        <Form.Check
                                          inline
                                          label={item}
                                          value={item}
                                          className="travelBy-radio-btn"
                                          name="plannedTripOpt"
                                          type="radio"
                                          id={`inlineradio-tripOption-${i}`}
                                          onChange={(e) =>
                                            setPlanddedTrips(e.target.value)
                                          }
                                          checked={
                                            item === planddedTrips
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="heading">
                                <h4>Your Trips</h4>
                                <div
                                  className="travel-next-step hv-center"
                                  onClick={goToTripPlannerPage}
                                >
                                  <span>Next</span>
                                  <span className="material-symbols-outlined">
                                    arrow_right
                                  </span>
                                </div>
                              </div>
                              <div className="trips-data">
                                <div className="bullet-icon-with-data">
                                  <div>
                                    <span className="material-symbols-outlined">
                                      check_circle
                                    </span>
                                  </div>
                                  <div className="data-by-date">
                                    01-04-2024 : Meeting with ClinetName at
                                    Banglore.
                                  </div>
                                </div>
                                <div className="bullet-icon-with-data">
                                  <div>
                                    <span className="material-symbols-outlined">
                                      check_circle
                                    </span>
                                  </div>
                                  <div className="data-by-date">
                                    01-04-2024 : Meeting with ClinetName at
                                    Banglore. 01-04-2024 : Meeting with
                                    ClinetName at Banglore.
                                  </div>
                                </div>
                                <div className="bullet-icon-with-data">
                                  <div>
                                    <span className="material-symbols-outlined">
                                      check_circle
                                    </span>
                                  </div>
                                  <div className="data-by-date">
                                    01-04-2024 : Meeting with ClinetName at
                                    Banglore. 01-04-2024 : Meeting with
                                    ClinetName at Banglore. 01-04-2024 : Meeting
                                    with ClinetName at Banglore.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <TripPlanner
                        goToTripPlannerPage={goToTripPlannerPage}
                        travelPlanShow={travelPlanShow}
                        travelBy={travelBy}
                        fromDate={fromDate}
                        toDate={toDate}
                        setTravelPlanShow={setTravelPlanShow}
                        // selectedTravels={selectedTravels}
                        // travelDetails={travelDetails}
                        // travelByOptions={travelByOptions}
                      />
                    )}
                    {/* TRAVEL PLAN DATE AND TRAVEL BY SELECTION */}
                  </div>
                </div>
              </div>
              {/* TRAVEL DESK MAIN PAGE */}
            </div>
            {/* MAIN BODY END */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
