import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Form } from "react-bootstrap";

function ShiftTable(props) {
  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table table-responsive">
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((header, i) => (
                  <TableCell
                    key={i}
                    align={header.align}
                    className={`table-head-cell`}
                    style={{ minWidth: header.width }}
                  >
                    {header.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!props.shiftData ? (
              <TableBody>
                <TableRow align="center">
                  <TableCell>---- No Data Found ---</TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {props.shiftData
                  .filter((val) =>
                    val?.shiftName.toLowerCase().includes(props.searchValue)
                  )
                  .map((data, i) => (
                    <TableRow
                      align="center"
                      key={i}
                      className="client-list-row-data"
                    >
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>
                        <div className="table-row-head-Text">
                          {data.shiftName}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {data.shiftCheckInTime}
                      </TableCell>
                      <TableCell align="center">
                        {data.shiftCheckOutTime}
                      </TableCell>
                      <TableCell align="center">
                        {data.shifts_CheckIn_Grace_Period_Before} Min
                      </TableCell>
                      <TableCell align="center">
                        {data.shifts_CheckIn_Grace_Period_After} Min
                      </TableCell>
                      <TableCell align="center">
                        {data.shifts_AutoCheckOut_Period} &nbsp;
                        {data.shifts_HRS_Min ?? ""}
                      </TableCell>
                      <TableCell align="center">
                        {data.shifts_MinutesToWork_FullDay} &nbsp;
                        {data.shifts_HRS_Min ?? ""}
                      </TableCell>
                      <TableCell align="center">
                        {data.shifts_MinutesToWork_HalfDay}&nbsp;
                        {data.shifts_HRS_Min ?? ""}
                      </TableCell>
                      <TableCell align="center">
                        <>
                          {data.isNightShift === 1 ? (
                            <span className="status-badge complete-status">
                              Yes
                            </span>
                          ) : (
                            <span className="status-badge pending-status">
                              No
                            </span>
                          )}
                        </>
                      </TableCell>
                      <TableCell align="center">
                        <>
                          {data.shifts_Validate === 1 ? (
                            <span className="status-badge complete-status">
                              Yes
                            </span>
                          ) : (
                            <span className="status-badge pending-status">
                              No
                            </span>
                          )}
                        </>
                      </TableCell>
                      <TableCell className="action-class">
                        <button
                          onClick={(e) => props.handleEdit(data?.shiftID)}
                          className="btn client-history-link"
                        >
                          <i className="fa fa-edit" /> Edit
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default ShiftTable;
