import React, { useState, useEffect, useRef } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import "../../styles/AddEditModel.scss";
import "../../styles/Forms.scss";
import { travelizeAuth } from "../HeaderAuthentication";
import { baseURL } from "../BaseUrl";
import SbCancelBtn, { SbPrintBtn } from "../SbButtons/SbCancelBtn";
import moment from "moment";
import { loadUserDropdown, loadCompanyData } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";

export default function ViewFormDataModel(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [formsDataUser, setFormsDataUser] = useState([]);

  useEffect(() => {
    fetchCustomFormData();
  }, [props.tableId, props.customPageId]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchCustomFormData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/CustomeFieldValue/GetFormGroup?TableID=${props.tableId}&PageSize=500&CurrentPage=1&Status=1&CustomePagesID=${props.customPageId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setFormsDataUser(res.result[0].pageDataValues.flat());
          setIsLoading(false);
        } else {
          console.log("CUS-FORM-RES::", `${res.message}`);
          setFormsDataUser([]);
          setIsLoading(false);
        }
      });
  };

  return (
    <div>
      {" "}
      <Modal
        show={props.viewFormData}
        className="main_model add-edit-model view-formdata-model"
      >
        <div ref={componentRef}>
          <div className="add-edit-heading">
            <h6 className="add-edit-head">
              FORM ({formsDataUser[0]?.customePageName}) -
              {props.userDetail.tableId}
            </h6>
            <div>
              {props.userDetail.name} - {props.userDetail.date} &nbsp; &nbsp;
              <i className="fa fa-times" onClick={props.handleClose} />
            </div>
          </div>

          <div className="container add-edit mt-2">
            <div className="view-forms-data">
              <div className="form-data-scroll">
                {/* <div className="form-heading text-center">
                <h5>{formsDataUser[0]?.customePageName}</h5>
              </div> */}
                {formsDataUser.map((data, i) => (
                  <div key={i}>
                    <div className="mb-3">
                      <div className="form-field-with-data mb-2">
                        <div className="field-label-name color-green">
                          {data.fieldName} :{" "}
                        </div>
                        {data.fieldType == "File" ? (
                          <div className="field-value">
                            <Link
                              to="#"
                              onClick={() =>
                                window.open(
                                  `${baseURL}/${data.value}`,
                                  "_blank"
                                )
                              }
                            >
                              {data.value}
                            </Link>
                          </div>
                        ) : (
                          <div className="field-value">
                            <span>{data.value}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="btns-save-cancel">
          <SbPrintBtn onClickEffect={handlePrint} btnName="Print" />
          <SbCancelBtn btnName="Close" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
