import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SkeletonPendingClaimHead = () => {
  return (
    <div className="pendingapproval-records">
      <div className="skeleton-img-text">
        <div className="d-flex">
          <Skeleton
            circle={true}
            className="pendingapproval-user-imgage"
            width={50}
            height={50}
          />
          <Skeleton
            className="pendingapproval-user-imgage"
            width={220}
            height={50}
          />
          <div
            className="claim-total-by-section1"
            style={{ marginLeft: "100px" }}
          >
            <Skeleton
              className="pendingapproval-user-imgage"
              width={140}
              height={50}
            />
          </div>
          <div
            className="claim-total-by-section2"
            style={{ marginLeft: "100px" }}
          >
            <Skeleton
              className="pendingapproval-user-imgage"
              width={140}
              height={50}
            />
          </div>
          <div className="claim-select-all" style={{ marginLeft: "100px" }}>
            <Skeleton
              className="pendingapproval-user-imgage"
              width={80}
              height={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SkeletonforClaimHead = () => {
  return (
    <div className="pendingapproval-records">
      <div className="skeleton-img-text">
        <div className="d-flex">
          <Skeleton
            circle={true}
            className="pendingapproval-user-imgage"
            width={50}
            height={50}
          />
          <Skeleton
            className="pendingapproval-user-imgage"
            width={220}
            height={50}
          />
        </div>
      </div>
    </div>
  );
};

export const SkeletonPendingTableData = () => {
  return (
    <table className="table pendingapproval-table">
      <thead className="thead-light pendingapproval-thead">
        <tr>
          <th>
            <Skeleton />
          </th>
          <th>
            <Skeleton />
          </th>
          <th>
            <Skeleton />
          </th>
          <th>
            <Skeleton />
          </th>
          <th>
            <Skeleton />
          </th>
        </tr>
        <tr>
          <th>
            <Skeleton />
          </th>
          <th>
            <Skeleton />
          </th>
          <th>
            <Skeleton />
          </th>
          <th>
            <Skeleton />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr className="pendingapproval-row-data">
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr className="pendingapproval-row-data">
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr className="pendingapproval-row-data">
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr className="pendingapproval-row-data">
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr className="pendingapproval-row-data">
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr className="pendingapproval-row-data">
          <td>
            <Skeleton />
          </td>
          <td colSpan={4}>
            <Skeleton />
          </td>
        </tr>
        <tr className="pendingapproval-row-data total-value">
          <td></td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};
