import { SBSaveUpdateBtn } from "./SbButtons/SbAddBtn";
import { loadOrthoAttendence } from "../redux/action";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SkeletonMeetPurpose } from "./Skeleton Loading/SkeletonMeetPurpose";

const OrthoAttendence = () => {
  let tableHeader = [
    { name: "Absent Count", align: "left", width: "120px" },
    { name: "Active Client Count", align: "left", width: "150px" },
    { name: "Applied Claim Count", align: "left", width: "150px" },
    { name: "Approved Claim Count", align: "left", width: "150px" },
    { name: "Attendence Count", align: "left", width: "150px" },
    { name: "Claim Count", align: "left", width: "150px" },
    { name: "Complaint Count", align: "left", width: "150px" },
    { name: "Completed Meetings", align: "left", width: "150px" },
    { name: "Direct Visit Meetings", align: "left", width: "150px" },
    { name: "Expired Meetings", align: "left", width: "150px" },
    { name: "Max Users", align: "left", width: "150px" },
    { name: "My Visit Meetings", align: "left", width: "150px" },
    { name: "Order Count", align: "left", width: "150px" },
    { name: "Pending Meetings", align: "left", width: "150px" },
    { name: "Product Count", align: "left", width: "150px" },
    { name: "Scheduled Meetings", align: "left", width: "150px" },
    { name: "Spare Part Count", align: "left", width: "150px" },
    { name: "Today Attendence Count", align: "left", width: "150px" },
    { name: "Todays New Clients", align: "left", width: "150px" },
    { name: "Total Count of Users", align: "left", width: "150px" },
    { name: "Total Meetings", align: "left", width: "150px" },
  ];

  let dispatch = useDispatch();
  const { getOrthoAttendence } = useSelector((state) => state.data);
  const [isLoading, setIsLoading] = useState(false);

  const handleButton = () => {
    dispatch(loadOrthoAttendence());
  };
  // useEffect(()=>{dispatch(loadOrthoAttendence(setIsLoading));},[])
  return (
    <>
      <div>
        <SBSaveUpdateBtn
          btnName="Send Ortho Attendence"
          onClickEffect={handleButton}
        />
      </div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="basefare-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {tableHeader.map((name, i) => (
                    <TableCell
                      key={i}
                      align={name.align}
                      className={`thead-cell-products`}
                      style={{ minWidth: name.width }}
                    >
                      {name.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <>
                  {tableHeader.map((name, i) => (
                    <SkeletonMeetPurpose key={i} />
                  ))}
                </>
              ) : (
                <>
                  {getOrthoAttendence && getOrthoAttendence.length === 0 ? (
                    <TableBody>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={tableHeader.length}>
                          <div style={{ padding: "20px 20px" }}>
                            <img
                              src="../../../../images/DefaultImages/NodataFound.jpg"
                              alt=""
                              style={{ width: "10%" }}
                            />
                            <br />
                            <span>No Data Found...!</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {/* {getOrthoAttendence && getOrthoAttendence.map((data, i) => ( */}
                      <TableRow align="center" className="client-list-row-data">
                        <TableCell>{getOrthoAttendence.absentCount}</TableCell>
                        <TableCell>
                          {getOrthoAttendence.activeClientCount}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.appliedClaimCount}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.approvedClaimCount}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.attendacneCount}
                        </TableCell>
                        <TableCell>{getOrthoAttendence.cliaimCount}</TableCell>
                        <TableCell>
                          {getOrthoAttendence.complaintCount}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.completedMeetings}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.directVisitMeetings}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.expiredMeetings}
                        </TableCell>
                        <TableCell>{getOrthoAttendence.maxUsers}</TableCell>
                        <TableCell>
                          {getOrthoAttendence.myVisitMeetings}
                        </TableCell>
                        <TableCell>{getOrthoAttendence.orderCount}</TableCell>
                        <TableCell>
                          {getOrthoAttendence.pendingMeetings}
                        </TableCell>
                        <TableCell>{getOrthoAttendence.productCount}</TableCell>
                        <TableCell>
                          {getOrthoAttendence.scheduleMeetings}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.sparePartCount}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.todayAttendanceCount}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.todaysNewClients}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.totalCountOfUsers}
                        </TableCell>
                        <TableCell>
                          {getOrthoAttendence.totalMeetings}
                        </TableCell>
                      </TableRow>
                      {/* ))} */}
                    </TableBody>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

export default OrthoAttendence;
