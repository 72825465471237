import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import { baseURL } from "../../BaseUrl";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import Select from "react-select";
import { optionsUnitList, optionsMOTTypes } from "../../DropdownsData";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { loadPostGoggleApis } from "../../../redux/action";
import * as swal from "../../Consturl/SwalAlert";

function AddCountry(props) {

    const closeAddModal = () => {
        props.handleClose();
    };

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                className="main_model add-edit-basefare-model"
            >
                <div className="add-edit-basefare-heading">
                    {/* <h6 className="add-edit-basefare-head">{props.clickType ? "Base fare Details" : <>{setBasefareId ? "EDIT BASE FARE" : "ADD BASE FARE"}</>}</h6> */}
                    <h6 className="add-edit-basefare-head">
                        ADD COUNTRY
                    </h6>
                    <i className="fa fa-times" onClick={closeAddModal} />
                </div>
                <form>
                    <div className="container add-edit-basefare">
                        <div className="product-inputs">
                            <div className="mb-3" style={{ position: "relative" }}>
                                <label className="float-label">Country Name</label>
                                <input
                                    type="text"
                                    className="form-control form add-basefare-input"
                                    id="exampleFormControlInput1"
                                    name="apiKey"
                                    // value={postItem?.apiKey || ""}
                                    placeholder="Enter Country Name"
                                // onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div className="btns-save-cancel">
                    <SBSaveUpdateBtn
                        btnName="Save"
                    />
                    <SbCancelBtn btnName="Cancel" onClickEffect={closeAddModal} />
                </div>
            </Modal>
        </div>
    );
}

export default AddCountry;
