import React, { useState } from "react";
import "../styles/Login.scss";
import { Button, Col, Image, Row } from "react-bootstrap";
import travelizeSalesBeeLogo from "../images/MatricLogo.svg";
import travelize from "../images/metric-favicon.svg";
import InternetIssue from "../Components/InternetIssue";

function UnAutherized() {
  const [errorMessage, setErrorMessage] = useState("");

  let themeColor = localStorage.getItem("theme");

  return (
    <div>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          <div
            className="main-body"
            style={{ backgroundImage: 'url("images/Login_Page/bg4.jpg")' }}
          >
            <div className="unAutherized-page">
              <div className="auauthorized-page-body">
                <div className="metricInfo-logo">
                  <Image
                    src={travelizeSalesBeeLogo}
                    alt="MetricInfo-Logo"
                    className="mi-login-logo"
                  />
                </div>
                <div className="sb-Acess-section">
                  <img
                    src="../../images/UnAuthorized/Acess.png"
                    alt="Access Denied"
                  />
                </div>
                <div className="unauthorised-error-message">
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                  >
                    This module is not accessible for your Subscription. <br />
                    Please contact MetricInfo team.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UnAutherized;
