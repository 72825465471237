import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import "../../styles/DownloadLoading.scss";

export default function DownloadLoading() {
  return (
    <div>
      {" "}
      <Modal show={true} className="download-pending-model">
        <Modal.Body>
          <div className="dflex-center">
            <Spinner animation="grow" className="color-gradient" />
            &nbsp;&nbsp;
            <div className="color-green">
              Download in progress, please wait...
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
