import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/BaseFare.scss";
import * as HeaderAuthentication from "../HeaderAuthentication";
import { addPaymentData, loadSinglePaymentData } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import Select from "react-select";
import momemt from 'moment';
import { FormattedAmount } from "../FormattedAmount";

const paymentOptions = [
    { value: 'NEFT', label: 'NEFT' },
    // { value: 'Cash', label: 'Cash' },
    // { value: 'Cheque', label: 'Cheque' },
    { value: 'UPI', label: 'UPI' },
];

function Pay(props) {

    const gfRefPassbookId = props.gfRefPassbookId;

    let dispatch = useDispatch();
    const { singlePaymentData } = useSelector((state) => state.data)
    console.log(singlePaymentData, "singlePaymentData")

    const closeAddModal = () => {
        props.fethPaymentData();
        props.handleClose();
    };

    const [editMode, setEditMode] = useState(false);

    const handleEditAmount = () => {
        setEditMode(true)
        // setShow(true)
    }

    const [selectedPayment, setSelectedPayment] = useState(null);
    const [customInput, setCustomInput] = useState('');
    const [error, setError] = useState('');

    const handlePaymentChange = (selectedOption) => {
        setSelectedPayment(selectedOption);
        setCustomInput('');
    };

    useEffect(() => {
        if (gfRefPassbookId) {
            dispatch(loadSinglePaymentData(gfRefPassbookId))
        }
    }, [gfRefPassbookId])

    useEffect(() => {
        if (singlePaymentData) {
            setClaimed(singlePaymentData.claimed)
        }
    }, [singlePaymentData])

    const [claimed, setClaimed] = useState("");
    const [Comment, setComment] = useState('');
    const [isChecked, setIsChecked] = useState(true);
    // const [whatsAppDetails, setWhatsAppDetails] = useState({});

    // useEffect(() => {
    //     if(isChecked) {
    //         setWhatsAppDetails(
    //             {
    //                 "SubscriptiondId": "",
    //                 "WebURL": "https://play.google.com/store/apps/details?id=com.lobotus.getfastconnect",
    //                 "ProjectName": "GetFast Connect",
    //                 "Notes": `Dear ${singlePaymentData.userName} \npaymentTowards- ${singlePaymentData.transactionType}/${singlePaymentData.gfRefPassbookID} has been Paid for *Rs ${singlePaymentData.claimed}* \non ${singlePaymentData.transactionDate}`,
    //                 "Mobile": "+919036436620"
    //             }
    //         )
    //     } else {
    //         setWhatsAppDetails() 
    //     }
    // }, [isChecked])

    const postPaymentData = (e) => {
        e.preventDefault()

        if (!selectedPayment) {
            setError('Please select a payment mode.');
        } else if (
            (selectedPayment.value === 'NEFT' || selectedPayment.value === 'UPI') &&
            customInput.trim() === ''
        ) {
            setError(`Please enter a ${selectedPayment.value} ID.`);
        } else {
            // setError(''); 

            let whatsAppData = {}
            if (isChecked) {
                whatsAppData =
                {
                    "SubscriptiondId": "",
                    "WebURL": "https://play.google.com/store/apps/details?id=com.lobotus.getfastconnect",
                    "ProjectName": "GetFast Connect",
                    "Notes": `Dear ${singlePaymentData.userName} \n\nPaymentTowards- ${singlePaymentData.transactionType}/${singlePaymentData.gfRefPassbookID} has been Paid for *Rs ${singlePaymentData.claimed}* \n\non ${singlePaymentData.transactionDate} \nTransaction Id: ${customInput}`,
                    "Mobile": "+919036436620"
                }
            }
            let data = {
                gfRefProfileID: singlePaymentData.gfRefProfileID,
                referelCode: singlePaymentData.referelCode,
                paid: parseFloat(claimed),
                rejected: 0,
                status: 1,
                transactionDate: momemt(new Date()).format("DD/MM/YYYY"),
                comment: "PaymentTowards-" + singlePaymentData.transactionType + '/' + singlePaymentData.gfRefPassbookID + '/' + Comment,
                entryType: singlePaymentData.entryType,
                transactionType: "PaymentTowards-" + singlePaymentData.transactionType + '/' + singlePaymentData.gfRefPassbookID,
                paymentMode: selectedPayment.label,
                // paymentStage: singlePaymentData.paymentStage,
                receiverAccUPIID: singlePaymentData.receiverAccUPIID,
                senderAccUPIID: singlePaymentData.senderAccUPIID,
                paymentPassbookEntryID: singlePaymentData.gfRefPassbookID,
                paymentStage: "paid",
                bankTransactionID: customInput,
                WhatsAppDetails: [whatsAppData],
            }

            let postData = {
                method: "POST",
                mode: "cors",
                headers: HeaderAuthentication.postTravelizeAuth,
                body: JSON.stringify(data)
            }
            // console.log(data, "send-data")
            dispatch(addPaymentData(postData, closeAddModal))
        }
    }

    const postRejectedData = (e) => {
        e.preventDefault()

        let data = {
            gfRefProfileID: singlePaymentData.gfRefProfileID,
            referelCode: singlePaymentData.referelCode,
            paid: 0,
            rejected: parseFloat(claimed),
            status: 1,
            transactionDate: momemt(new Date()).format("DD/MM/YYYY"),
            comment: Comment,
            entryType: singlePaymentData.entryType,
            transactionType: "rejectedTowards-" + singlePaymentData.transactionType,
            paymentMode: selectedPayment.label,
            paymentStage: singlePaymentData.paymentStage,
            receiverAccUPIID: singlePaymentData.receiverAccUPIID,
            senderAccUPIID: singlePaymentData.senderAccUPIID,
            paymentPassbookEntryID: singlePaymentData.gfRefPassbookID,
            // gfRefPassbookID: singlePaymentData.gfRefPassbookID,
        }

        let postData = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(data)
        }
        dispatch(addPaymentData(postData, closeAddModal))
        console.log(data, "data")
    }



    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                className="main_model add-edit-basefare-model"
            >
                <div className="add-edit-basefare-heading">
                    <div>
                        <h6 className="add-edit-basefare-head">
                            PAYING {singlePaymentData.userName}
                        </h6>
                        <label
                            htmlFor="exampleFormControlInput"
                            className="form-label input-model-label"
                            style={{ fontSize: "10px" }}
                        >
                            Request Payment Id: {singlePaymentData.gfRefPassbookID}
                        </label>
                    </div>

                    <i className="fa fa-times" onClick={closeAddModal} />

                </div>
                <form>
                    <div className="container add-edit-basefare">
                        <div className="product-inputs">

                            <div style={{ display: "flex", alignItems: "center" }}>
                                {/* <div className="col-md-4 mb-3">
                                    <label
                                        htmlFor="exampleFormControlInput"
                                        className="form-label input-model-label"
                                    >
                                        Request Payment Id: {singlePaymentData.gfRefPassbookID}
                                    </label>
                                </div> */}
                                <div className="col-md-4 mb-3">

                                    <label
                                        htmlFor="exampleFormControlInput"
                                        className="form-label input-model-label"
                                    >
                                        Withdrawl Amount :
                                    </label>
                                </div>
                                <div className="mb-3" style={{ position: "relative" }}>
                                    {/* <FloatingLabel
                                label="Enter Price"
                                className={`${product && product.SellingPrice
                                    ? "float-input-visible"
                                    : "float-hidden float-input"
                                    }`}
                                > */}
                                    {!editMode ?
                                        <p className="green-Color" style={{ fontWeight: "600" }}><i className="fa fa-inr" aria-hidden="true"></i> {claimed}</p>
                                        :
                                        <input
                                            type="number"
                                            // className="form-control form add-basefare-input"
                                            className={`${editMode ? "form-control add-product-input" : "text-format-input"}`}
                                            id="exampleFormControlInput1"
                                            placeholder="Enter Amount"
                                            name="amount"
                                            value={claimed}
                                            onChange={(e) => {
                                                setClaimed(e.target.value);
                                            }}
                                            // disabled={!editMode}
                                            required
                                        />
                                    }
                                    {/* </FloatingLabel> */}
                                </div>
                                {/* <div style={{ padding: "10px", marginBottom: "1rem", fontSize: "14px" }}>
                                    <p onClick={handleEditAmount} className="add-bank-upi" >Edit</p>
                                </div> */}
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                // label="Select Product"
                                // className={`${product.value !== ""
                                //     ? "float-visible-select"
                                //     : "float-hidden float-select"
                                //     }`}
                                >
                                    <Select
                                        className="react-select-container-list model-select"
                                        classNamePrefix="react-select-list"
                                        options={paymentOptions}
                                        placeholder="Select Payment Mode"
                                        value={selectedPayment}
                                        onChange={handlePaymentChange}
                                        isSearchable={true}
                                        required
                                    />
                                </FloatingLabel>
                                {/* {productError && (
                                    <p className="text-danger" style={{ marginTop: "8px" }}>
                                        Select ProductName.
                                    </p>
                                )} */}
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                {/* <FloatingLabel
                                label="Enter Price"
                                className={`${product && product.SellingPrice
                                    ? "float-input-visible"
                                    : "float-hidden float-input"
                                    }`}
                                > */}
                                {selectedPayment && (
                                    <>
                                        {selectedPayment.value === 'NEFT' && (
                                            <input
                                                className="form-control form add-basefare-input"
                                                id="exampleFormControlInput1"
                                                type="text"
                                                placeholder="NEFT ID"
                                                value={customInput}
                                                onChange={(e) => {
                                                    setError('');
                                                    setCustomInput(e.target.value);
                                                }}
                                            />
                                        )}
                                        {selectedPayment.value === 'UPI' && (
                                            <input
                                                className="form-control form add-basefare-input"
                                                id="exampleFormControlInput1"
                                                type="text"
                                                placeholder="Transaction ID"
                                                value={customInput}
                                                onChange={(e) => {
                                                    setError('');
                                                    setCustomInput(e.target.value);
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                                {/* </FloatingLabel> */}
                            </div>
                            {error && <p className="text-danger">{error}</p>}
                            <div className="mb-3 d-flex">
                                <div style={{ width: "100%" }}>
                                    {/* <FloatingLabel label='Description' className={`${description ? "float-input-visible" : "float-hidden float-input"}`}> */}
                                    <textarea
                                        rows="3"
                                        cols="20"
                                        name="description"
                                        wrap="hard"
                                        className="form-control form-textarea add-product-input"
                                        id="descriptionArea"
                                        placeholder="Comment"
                                        value={Comment}
                                        onChange={(e) => { setComment(e.target.value) }}
                                    />
                                    {/* </FloatingLabel> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="modal-footer-btns-j-between">
                    <div>
                        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                        <span style={{ padding: "10px" }}>
                            <i className="fa-brands fa-whatsapp green-Color"></i> send WhatsApp
                        </span>
                    </div>
                    <SBSaveUpdateBtn
                        btnName={`Pay ₹${singlePaymentData.claimed}`}
                        onClickEffect={postPaymentData}
                    />
                    {/* <SbCancelBtn
                        // onClickEffect={closeAddModal}
                        btnName="Reject"
                        onClickEffect={postRejectedData}
                    /> */}

                </div>

            </Modal>
        </div>
    );
}

export default Pay;





