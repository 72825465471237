import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import "../../styles/livechat.scss";
import { FormattedAmount } from "../FormattedAmount";
import { useSelector } from "react-redux";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";

function TravelReportTable(props) {
  const { totalCount } = useSelector((state) => state.data);
  const mergeRows = (accumulator, currentValue) => {
    const existingRow = accumulator.find(
      (row) =>
        row.fullName === currentValue.fullName &&
        row.localClaimAmount === currentValue.localClaimAmount &&
        row.foodClaimAmount === currentValue.foodClaimAmount &&
        row.hotelClaimAmount === currentValue.hotelClaimAmount &&
        row.othersClaimAmount === currentValue.othersClaimAmount &&
        row.totalClaimAmount === currentValue.totalClaimAmount &&
        row.meetingDate === currentValue.meetingDate &&
        row.userCode === currentValue.userCode &&
        row.dayName === currentValue.dayName &&
        row.claimID === currentValue.claimID
    );

    if (existingRow) {
      existingRow.travelInfo.push({
        startLoc: currentValue.startLoc?.location,
        startLatitude: currentValue.startLoc?.latitude,
        startLongitude: currentValue.startLoc?.longitude,
        endLoc: currentValue.endLoc?.location,
        endLatitude: currentValue.endLoc?.latitude,
        endLongitude: currentValue.endLoc?.longitude,
        clientID: currentValue.clientID,
        clientName: currentValue.clientName,
        meetingType: currentValue.meetingType,
        meetingTime: currentValue.meetingTime,
        modeOfTravel: currentValue.modeOfTravel,
        distanceTravelled: currentValue.distanceTravelled,
      });
    } else {
      accumulator.push({
        fullName: currentValue.fullName,
        userCode: currentValue.userCode,
        dayName: currentValue.dayName,
        localClaimAmount: currentValue.localClaimAmount,
        foodClaimAmount: currentValue.foodClaimAmount,
        hotelClaimAmount: currentValue.hotelClaimAmount,
        othersClaimAmount: currentValue.othersClaimAmount,
        totalClaimAmount: currentValue.totalClaimAmount,
        meetingDate: currentValue.meetingDate,
        claimID: currentValue.claimID,
        travelInfo: [
          {
            startLoc: currentValue.startLoc?.location,
            startLatitude: currentValue.startLoc?.latitude,
            startLongitude: currentValue.startLoc?.longitude,
            endLoc: currentValue.endLoc?.location,
            endLatitude: currentValue.endLoc?.latitude,
            endLongitude: currentValue.endLoc?.longitude,
            clientID: currentValue.clientID,
            clientName: currentValue.clientName,
            meetingType: currentValue.meetingType,
            meetingTime: currentValue.meetingTime,
            modeOfTravel: currentValue.modeOfTravel,
            distanceTravelled: currentValue.distanceTravelled,
          },
        ],
      });
    }
    return accumulator;
  };

  const mergedMotRows = props.getTravelAttachments
    ? props.getTravelAttachments.reduce(mergeRows, [])
    : [];

  return (
    <div className="sb-table-div sb-table-setion whatsapp-module-main-div ">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.getTravelAttachments,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className={`whatsapp-table-container`}
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    align={name.align}
                    style={{ minWidth: name.width }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <TableBody>
                {props.tableHeader.map((name, i) => (
                  <SkeletonTableData key={i} />
                ))}
              </TableBody>
            ) : (
              <>
                {props.getTravelAttachments &&
                props.getTravelAttachments.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/NodataFound.jpg"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>--- No Data Found ---</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {mergedMotRows.length > 0 &&
                      mergedMotRows.map((data, i) => (
                        <TableRow
                          align="center"
                          key={i}
                          className="client-list-row-data"
                        >
                          <TableCell className="tr-tcell-br">
                            <div className="color-green">
                              <b>{data.meetingDate}</b>
                            </div>
                            <div className="color-yellow">{data.dayName}</div>
                          </TableCell>
                          <TableCell className="tr-tcell-br">
                            <div className="color-green">
                              <b>{data.fullName}</b>
                            </div>
                            <div className="color-yellow">{data.userCode}</div>
                          </TableCell>
                          <TableCell colSpan={8}>
                            {data.travelInfo.map((item, index) => (
                              <TableRow
                                key={index}
                                className="table_row-inside-tabel-cell"
                              >
                                <TableCell className="tr-tcell-br">
                                  <div className="w-250px">
                                    {item?.startLoc ?? "NA"}
                                    <br />
                                    <i className="bi bi-geo-alt" />
                                    &nbsp;
                                    {item?.startLatitude ?? "NA"},
                                    {item?.startLongitude ?? "NA"}
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="w-250px">
                                    {item?.endLoc ?? "NA"}
                                    <br />
                                    <i className="bi bi-geo-alt" />
                                    &nbsp;
                                    {item?.endLatitude ?? "NA"},
                                    {item?.endLongitude ?? "NA"}
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="w-100px">
                                    #C{item?.clientID}
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="w-180px">
                                    {item?.clientName}
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="status-badge complete-status w-120 text-center">
                                    {item?.meetingType}
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="w-80px">
                                    {item?.meetingTime}
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="w-100px">
                                    {item.modeOfTravel ?? "NA"}
                                  </div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div className="w-100px">
                                    {item?.distanceTravelled ?? "NA"}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableCell>
                          <TableCell className="tr-tcell-br">
                            <div className="price-text-div">
                              <div className="price-div">
                                <span className="price-text">
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {FormattedAmount(
                                    data?.localClaimAmount ?? "NA"
                                  )}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="tr-tcell-br">
                            <div className="price-text-div">
                              <div className="price-div">
                                <span className="price-text">
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {FormattedAmount(
                                    data?.foodClaimAmount ?? "NA"
                                  )}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="tr-tcell-br">
                            <div className="price-text-div">
                              <div className="price-div">
                                <span className="price-text">
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {FormattedAmount(
                                    data?.hotelClaimAmount ?? "NA"
                                  )}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="tr-tcell-br">
                            <div className="price-text-div">
                              <div className="price-div">
                                <span className="price-text">
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {FormattedAmount(
                                    data?.othersClaimAmount ?? "NA"
                                  )}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="tr-tcell-br">
                            <div className="price-text-div">
                              <div className="price-div">
                                <span className="price-text">
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {FormattedAmount(
                                    data?.totalClaimAmount ?? "NA"
                                  )}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="travel-report-view-icons">
                              {data.claimID !== 0 ? (
                                <i
                                  className="fa fa-download"
                                  style={{ fontSize: "15px" }}
                                  onClick={() =>
                                    props.handleDownloadIconClick(data.claimID)
                                  }
                                />
                              ) : (
                                <Tooltip title="Claim is Zero">
                                  <i
                                    className="fa fa-download disabled"
                                    style={{
                                      fontSize: "15px",
                                      color: "#999",
                                    }}
                                  ></i>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default TravelReportTable;
