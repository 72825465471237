import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import * as HeaderData from "../../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import "../../../styles/category.scss";
import Select from "react-select";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import {
  loadPostCustomfields,
  loadSingleField,
  loadUpdateCustomfields,
} from "../../../redux/action";
import { customPageNames } from "../../DropdownsData";

function AddCustomField(props) {
  let dispatch = useDispatch();
  let editId = props.editId;
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const { singleField } = useSelector((state) => state.data);

  const [optionCount, setOptionCount] = useState([]);

  const fieldTypes = [
    { value: "Text", label: "Text" },
    { value: "Email", label: "Email" },
    { value: "Text Area", label: "Text Area" },
    { value: "Drop Down", label: "Drop Down" },
    { value: "Check Box", label: "Check Box" },
    { value: "Radio", label: "Radio" },
    { value: "File", label: "File" },
    { value: "Date", label: "Date" },
  ];

  const [selectedData, setSelectedData] = useState({ value: "", label: "" });
  const [selectedPageData, setSelectedPageData] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    if (editId) {
      dispatch(loadSingleField(editId));
    }
  }, [editId]);

  const [customFields, setCustomFields] = useState({
    subscriptionID: resPassData.subscriptionId,
    status: 1,
    addedByID: resPassData.userId,
    customePageID: 0,
    customePageName: "",
    fieldName: "",
    fieldType: "",
    fieldOptions: "",
    placeHolder: "",
    isRequired: 0,
  });

  const {
    subscriptionID,
    status,
    addedByID,
    customePageID,
    customePageName,
    fieldName,
    fieldType,
    fieldOptions,
    placeHolder,
    isRequired,
  } = customFields;

  const OptionCount = () => {
    setOptionCount([...optionCount, { value: "text", label: "Text" }]);
  };

  const removeOption = (i) => {
    optionCount.splice(i, 1);
    setOptionCount([...optionCount]);
  };

  const setOptionsVal = (e, index) => {
    let optionData = optionCount.filter((data, i) => i == index);
    optionData[0].label = e.target.value;
    optionData[0].value = e.target.value;
    setOptionCount([...optionCount]);
  };

  useEffect(() => {
    let newOptionsData = optionCount.map((data) => data.label).toString();
    customFields.fieldOptions = newOptionsData;
  }, [optionCount]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomFields({ ...customFields, [name]: value });
  };

  const selectedOption = (data, page) => {
    if (page == "fieldType") {
      setSelectedData(data);
      setCustomFields({ ...customFields, fieldType: data.value });
    } else if (page == "customePageName") {
      setSelectedPageData(data);
      setCustomFields({
        ...customFields,
        customePageName: data.label,
        customePageID: data.value,
      });
    }
  };

  const cancelModale = () => {
    props.addFieldModuleClose();
    props.setEditId("");
    props.fetchCustomFields();
    setCustomFields({
      subscriptionID: "",
      status: "",
      addedByID: "",
      customePageID: "",
      customePageName: "",
      fieldName: "",
      fieldType: "",
      fieldOptions: "",
      placeHolder: "",
      isRequired: "",
    });
  };

  const [statusValue, setStatusValue] = useState(true);

  const statusFlag = () => {
    if (!statusValue) {
      if (props.customFieldsActiveLength >= 5) {
        setFieldReq(true);
        return;
      }
    }
    setStatusValue(!statusValue);
    if (!statusValue == true) {
      setCustomFields({ ...customFields, status: 1 });
    } else if (!statusValue == false) {
      setCustomFields({ ...customFields, status: 0 });
    }
  };

  const [fieldTypeValue, setFieldTypeValue] = useState(false);
  const fieldStatusType = (statusValue) => {
    setFieldTypeValue(!statusValue);
    if (!statusValue == true) {
      setCustomFields({ ...customFields, isRequired: 1 });
    } else if (!statusValue == false) {
      setCustomFields({ ...customFields, isRequired: 0 });
    }
  };

  useEffect(() => {
    if (editId) {
      if (singleField && Object.keys(singleField)?.length !== 0) {
        console.log("COMMING-DATA:", singleField);
        setCustomFields(singleField);
        let options = singleField.fieldOptions.split(",");
        let optData = [];
        for (let i = 0; i < options?.length; i++) {
          optData.push({ id: i + 1, value: options[i], label: options[i] });
        }
        setOptionCount(optData);
        let pageNames = [...customPageNames];
        pageNames.map((page) => {
          if (singleField.customePageName == page.label) {
            setSelectedPageData(page);
          }
        });
        let typeArray = [...fieldTypes];
        typeArray.map((type) => {
          if (singleField?.fieldType === type.value) {
            setSelectedData(type);
          }
        });
        if (singleField.status === 1) {
          setStatusValue(true);
        } else {
          setStatusValue(false);
        }
        if (singleField.isRequired === 1) {
          setFieldTypeValue(true);
        } else {
          setFieldTypeValue(false);
        }
      }
    }
  }, [singleField, editId]);

  const [fieldReq, setFieldReq] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !fieldName ||
      !fieldType ||
      !placeHolder ||
      ((fieldType === "Drop Down" ||
        fieldType === "Check Box" ||
        fieldType === "Radio") &&
        !fieldOptions)
    ) {
      setFieldReq(true);
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(customFields),
      };
      dispatch(loadPostCustomfields(postRequest, cancelModale));
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    if (
      !fieldName ||
      !fieldType ||
      !placeHolder ||
      ((fieldType === "Drop Down" ||
        fieldType === "Check Box" ||
        fieldType === "Radio") &&
        !fieldOptions)
    ) {
      setFieldReq(true);
    } else {
      let updateRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(customFields),
      };
      dispatch(loadUpdateCustomfields(updateRequest, cancelModale));
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        // onHide={props.addFieldModuleClose}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            {editId ? "Edit Custom Field" : "Add Custom Field"}
          </h6>
          <i className="fa fa-times" onClick={cancelModale} />
        </div>

        <Form onSubmit={editId ? handleUpdate : handleSubmit}>
          <div className="container add-edit">
            <div className="product-inputs mt-2">
              <div className="form-field mb-3">
                <FloatingLabel
                  label="Page Name"
                  className={`${
                    selectedPageData.value !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={customPageNames}
                    name="customePageName"
                    placeholder="Page Name"
                    value={
                      selectedPageData.value
                        ? selectedPageData
                        : customPageNames.label
                    }
                    onInputChange={customPageNames.label}
                    onChange={(data) => selectedOption(data, "customePageName")}
                    isSearchable={true}
                    required
                  />
                </FloatingLabel>

                {fieldReq && !customePageName && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Page Name
                  </small>
                )}
              </div>
              <div className="form-field mb-3">
                {/* <Form.Group className=" health" controlId="exampleForm.Health"> */}
                <FloatingLabel
                  label="Label Name"
                  className={`${
                    fieldName || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    value={fieldName || ""}
                    name="fieldName"
                    onChange={handleInputChange}
                    placeholder="Enter Field Name"
                    autoComplete="off"
                    required
                  />
                </FloatingLabel>
                {fieldReq && !fieldName && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Field Name
                  </small>
                )}
                {/* </Form.Group> */}
              </div>
              <div className="form-field mb-3">
                {/* <Form.Group
                className="mb-3 health"
                controlId="exampleForm.Health"
              > */}
                <FloatingLabel
                  label="Place Holder"
                  className={`${
                    placeHolder
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    value={placeHolder || ""}
                    name="placeHolder"
                    onChange={handleInputChange}
                    placeholder="Enter Place Holder"
                    autoComplete="off"
                    required
                  />
                </FloatingLabel>
                {fieldReq && !placeHolder && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Field Name
                  </small>
                )}
                {/* </Form.Group> */}
              </div>
              <div className="form-field mb-3">
                {/* <Form.Group
                className="mb-3 health"
                controlId="exampleForm.Health"
              > */}
                {/* <Form.Label className="require">Field Type</Form.Label> */}
                <FloatingLabel
                  label="Field Type"
                  className={`${
                    selectedData.value !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    // defaultValue={fieldTypes}
                    options={fieldTypes}
                    name="fieldType"
                    placeholder="Select Field Type"
                    value={selectedData.value ? selectedData : fieldTypes.label}
                    onInputChange={fieldTypes.label}
                    onChange={(data) => selectedOption(data, "fieldType")}
                    isSearchable={true}
                    required
                  />
                </FloatingLabel>
                {fieldReq && !fieldTypes && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Field Name
                  </small>
                )}
                {/* </Form.Group> */}
              </div>
              {(fieldType === "Drop Down" ||
                fieldType === "Check Box" ||
                fieldType === "Radio") && (
                <div className="form-field options-part">
                  <Form.Group
                    className="mb-3_health"
                    controlId="exampleForm.Health"
                  >
                    {optionCount.map((item, i) => (
                      <div className="model-inputs dflex-align-center mb-3">
                        <div className="option-label">
                          <FloatingLabel
                            label={`Option-${i + 1}`}
                            className={`${
                              item.value
                                ? "float-input-visible"
                                : "float-hidden float-input"
                            }`}
                          >
                            <Form.Control
                              type="text"
                              value={item.value}
                              name="fieldOptions"
                              onChange={(event) => setOptionsVal(event, i)}
                              placeholder=""
                              autoComplete="off"
                              required
                            />
                          </FloatingLabel>
                        </div>
                        <div className="remove-option">
                          <i
                            className="fa fa-times"
                            onClick={() => removeOption(i)}
                          />
                        </div>
                      </div>
                    ))}
                    {fieldReq &&
                      (fieldType === "Drop Down" ||
                        fieldType === "Check Box" ||
                        fieldType === "Radio") &&
                      !fieldOptions && (
                        <small id={`Error`} className="form-text text-muted ">
                          Please Enter Field Options
                        </small>
                      )}
                    <div className="add-option-icon" onClick={OptionCount}>
                      <span className="plus-icon">
                        <i className="fa fa-plus" />
                      </span>
                      <span className="add-option-text">Add Option</span>
                    </div>
                  </Form.Group>
                </div>
              )}
              {editId && (
                <div className="mt-3">
                  <div className="form-field form-status-section">
                    <Row style={{ width: "100%" }}>
                      <Col md={6} className="cat-col">
                        <div>
                          <Form.Label className="require">Status</Form.Label>
                        </div>
                      </Col>
                      <Col md={6} className="cat-col">
                        <div className="btn-status">
                          <Form.Group className="facility-details">
                            <div
                              className={`checkbox1 ${
                                statusValue ? "checkbox--on" : ""
                              }`}
                              name="status"
                              onClick={(e) => statusFlag()}
                            >
                              <div className="checkbox__ball">
                                <span
                                  className={
                                    statusValue ? "enable-text" : "disable-text"
                                  }
                                >
                                  {statusValue ? "Enable" : "Disable"}
                                </span>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        {fieldReq && props.customFieldsActiveLength >= 5 ? (
                          <small id={`Error`} className="form-text text-muted ">
                            You Can't Active more than 5 Fields
                          </small>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
              <div className="mt-3">
                <div className="form-field form-status-section">
                  <Row style={{ width: "100%" }}>
                    <Col md={6} className="cat-col">
                      <div>
                        <Form.Label className="require">IsRequired</Form.Label>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="btn-status">
                        <Form.Group className="facility-details">
                          <div
                            className={`checkbox1 ${
                              fieldTypeValue ? "checkbox--on" : ""
                            }`}
                            name="status"
                            onClick={(e) => fieldStatusType(fieldTypeValue)}
                          >
                            <div className="checkbox__ball">
                              <span
                                className={
                                  fieldTypeValue
                                    ? "enable-text"
                                    : "disable-text"
                                }
                              >
                                {fieldTypeValue ? "Mandatory" : "Optional"}
                              </span>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn btnName={editId ? "Update" : "Save"} />
            <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default AddCustomField;
