import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/Leavetype.scss";
import { baseURL } from "../../BaseUrl";
import { Form, FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { travelizeAuth } from "../../HeaderAuthentication";
import { leaveSettingsTrueFalseOptions } from "../../DropdownsData";

function AddLeaveType(props) {
  const subscriptionID = JSON.parse(
    sessionStorage.getItem("usrlgndtls")
  ).subscriptionId;

  const [enableStatus, setEnableStatus] = useState(1);
  const [openMoreSettings, setOpenMoreSettings] = useState(false);

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [leaveTypeSettingData, setLeaveTypeSettingData] = useState({
    subscriptionID: "",
    leaveTypeID: 0,
    leaveTypeName: "",
    leaveTypeSortName: "",
    description: "",
    maxNoOfDays: null,
    allowHalfDay: true,
    status: 1,
    isPaidLeave: true,
    isCancellable: true,
    autoApprove: false,
    notifyReporting: true,
    notifyUser: true,
    notifySubOrdinate: false,
    excludeWeekEnd: true,
    excludeHolidays: true,
    allowUpdateAttachment: true,
    allowCarryforword: false,
    allowPrevDaysLeave: true,
    reqBeforeDays: 0,
    attachmentReqForDays: 2,
    maxCarryforword: 5,
  });

  const {
    leaveTypeID,
    leaveTypeName,
    leaveTypeSortName,
    description,
    maxNoOfDays,
    allowHalfDay,
    status,
    isPaidLeave,
    isCancellable,
    autoApprove,
    notifyReporting,
    notifyUser,
    notifySubOrdinate,
    excludeWeekEnd,
    excludeHolidays,
    allowUpdateAttachment,
    allowCarryforword,
    allowPrevDaysLeave,
    reqBeforeDays,
    attachmentReqForDays,
    maxCarryforword,
  } = leaveTypeSettingData;

  const handleChangeFields = (e) => {
    const { name, value } = e.target;
    setLeaveTypeSettingData({ ...leaveTypeSettingData, [name]: value });
  };

  const handleChangeTrueFalse = (e) => {
    const { name, value } = e.target;
    setLeaveTypeSettingData({
      ...leaveTypeSettingData,
      [name]: JSON.parse(value),
    });
  };

  useEffect(() => {
    fetchLeaveTypeByID();
  }, [props.editLeaveTypeID]);

  const fetchLeaveTypeByID = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/LeaveType/Get/${props.editLeaveTypeID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLeaveTypeSettingData({ ...res.result });
        } else {
          setLeaveTypeSettingData({
            subscriptionID: "",
            leaveTypeID: 0,
            leaveTypeName: "",
            leaveTypeSortName: "",
            description: "",
            maxNoOfDays: null,
            allowHalfDay: true,
            status: 1,
            isPaidLeave: true,
            isCancellable: true,
            autoApprove: false,
            notifyReporting: true,
            notifyUser: true,
            notifySubOrdinate: false,
            excludeWeekEnd: true,
            excludeHolidays: true,
            allowUpdateAttachment: true,
            allowCarryforword: false,
            allowPrevDaysLeave: true,
            reqBeforeDays: 0,
            attachmentReqForDays: 2,
            maxCarryforword: 5,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  };

  //  ADDING LEAVE TYPE START
  const addLeaveTypes = () => {
    if (
      leaveTypeName !== "" &&
      leaveTypeSortName !== "" &&
      maxNoOfDays !== null
    ) {
      const leaveTypeData = {
        subscriptionID: subscriptionID,
        leaveTypeName: leaveTypeName,
        leaveTypeSortName: leaveTypeSortName,
        description: description,
        maxNoOfDays: +maxNoOfDays,
        allowHalfDay: allowHalfDay,
        status: enableStatus,
        isPaidLeave: isPaidLeave,
        isCancellable: isCancellable,
        autoApprove: autoApprove,
        notifyReporting: notifyReporting,
        notifyUser: notifyUser,
        notifySubOrdinate: notifySubOrdinate,
        excludeWeekEnd: excludeWeekEnd,
        excludeHolidays: excludeHolidays,
        allowUpdateAttachment: allowUpdateAttachment,
        allowCarryforword: allowCarryforword,
        allowPrevDaysLeave: allowPrevDaysLeave,
        reqBeforeDays: reqBeforeDays,
        attachmentReqForDays: attachmentReqForDays,
        maxCarryforword: maxCarryforword,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(leaveTypeData),
      };

      fetch(`${baseURL}/api/LeaveType/AddLeaveType`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchLeaveTypesData();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };
  //  ADDING LEAVE TYPE END

  //  EDIT LEAVE TYPE START
  const editLeaveTypes = () => {
    if (
      leaveTypeName !== "" &&
      leaveTypeSortName !== "" &&
      maxNoOfDays !== null
    ) {
      const leaveTypeData = {
        leaveTypeID: props.editLeaveTypeID,
        subscriptionID: subscriptionID,
        leaveTypeName: leaveTypeName,
        leaveTypeSortName: leaveTypeSortName,
        description: description,
        maxNoOfDays: +maxNoOfDays,
        allowHalfDay: allowHalfDay,
        status: enableStatus,
        isPaidLeave: isPaidLeave,
        isCancellable: isCancellable,
        autoApprove: autoApprove,
        notifyReporting: notifyReporting,
        notifyUser: notifyUser,
        notifySubOrdinate: notifySubOrdinate,
        excludeWeekEnd: excludeWeekEnd,
        excludeHolidays: excludeHolidays,
        allowUpdateAttachment: allowUpdateAttachment,
        allowCarryforword: allowCarryforword,
        allowPrevDaysLeave: allowPrevDaysLeave,
        reqBeforeDays: +reqBeforeDays,
        attachmentReqForDays: +attachmentReqForDays,
        maxCarryforword: +maxCarryforword,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(leaveTypeData),
      };

      fetch(`${baseURL}/api/LeaveType/UpdateLeaveType`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchLeaveTypesData();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };
  //  EDIT LEAVE TYPE END

  return (
    <div>
      <Modal show={props.show} className="main_model add-leavetype-model">
        <div className="add-leavetype-heading">
          <h6 className="add-edit-head">
            {props.editLeaveTypeID ? "EDIT LEAVE TYPE" : "ADD LEAVE TYPE"}
          </h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-leavetype">
            <div className="add-edit-leavetypes-details">
              <div className="row mb-3 p-0">
                <div className="col-md-8 col-sm-8 col-6 pl-0">
                  <FloatingLabel
                    label="Leave Type Name"
                    className={`${
                      leaveTypeName
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control model-input"
                      placeholder="Leave Type Name"
                      name="leaveTypeName"
                      value={leaveTypeName}
                      onChange={handleChangeFields}
                    />
                  </FloatingLabel>
                  {fieldReq && leaveTypeName == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4 col-sm-4 col-6 pr-0">
                  <FloatingLabel
                    label="Short Name"
                    className={`${
                      leaveTypeSortName
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control model-input"
                      placeholder="Short Name"
                      name="leaveTypeSortName"
                      value={leaveTypeSortName}
                      onChange={handleChangeFields}
                    />
                  </FloatingLabel>
                  {fieldReq && leaveTypeSortName == "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mb-4">
                <FloatingLabel
                  label="Description"
                  className={`${
                    description
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <textarea
                    name="description"
                    wrap="hard"
                    rows="2"
                    cols="20"
                    className="form-control form-textarea add-leavetype-input"
                    placeholder="Description"
                    value={description}
                    onChange={handleChangeFields}
                  />
                </FloatingLabel>
              </div>
              <div className="row mb-3 p-0">
                <div className="col-md-7 col-sm-7 col-6 pl-0">
                  <FloatingLabel
                    label="Max No. Of Days"
                    className={`${
                      maxNoOfDays
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="number"
                      className="form-control model-input"
                      placeholder="Max No. Of Days"
                      name="maxNoOfDays"
                      value={maxNoOfDays}
                      onChange={handleChangeFields}
                    />
                  </FloatingLabel>
                  {fieldReq && (maxNoOfDays == null || maxNoOfDays == 0) ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-5 col-sm-5 col-6 pr-0">
                  <div className="set-true-false">
                    <div className="color-gray">Allow Half Day Leave</div>

                    <div className="enable-radio-yes-no">
                      {leaveSettingsTrueFalseOptions.map((item, i) => (
                        <div key={i}>
                          <Form.Check
                            inline
                            type="radio"
                            label={item?.label}
                            value={item?.value}
                            name="allowHalfDay"
                            id={`inlineradio-allowHalfDay`}
                            onChange={handleChangeTrueFalse}
                            checked={item.value == allowHalfDay ? true : false}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="open-leave-type-2nd-part"
                  onClick={() => setOpenMoreSettings(!openMoreSettings)}
                >
                  {/* <i className="fa fa-chevron-down" /> */}
                  <div className="row-line">
                    ------------------------------------------ &nbsp; More
                    Settings
                  </div>
                  &nbsp; &nbsp;
                  <i
                    className={
                      openMoreSettings
                        ? "fa fa-chevron-down"
                        : "fa fa-chevron-right"
                    }
                  />
                </div>
              </div>
              {openMoreSettings && (
                <div className="more-settings-part">
                  <div className="row mb-4 p-0">
                    <div className="col-md-4 col-sm-4 col-12 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Is Paid Leave</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="isPaidLeave"
                                id={`inlineradio-isPaidLeave`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == isPaidLeave ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Is Cancellable</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="isCancellable"
                                id={`inlineradio-isCancellable`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == isCancellable ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12 pr-0">
                      <div className="set-true-false">
                        <div className="color-gray">Auto Approve</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="autoApprove"
                                id={`inlineradio-autoApprove`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == autoApprove ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4 p-0">
                    <div className="col-md-4 col-sm-4 col-12 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Notify Reporting</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="notifyReporting"
                                id={`inlineradio-notifyReporting`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == notifyReporting ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Notify User</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="notifyUser"
                                id={`inlineradio-notifyUser`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == notifyUser ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12 pr-0 ">
                      <div className="set-true-false">
                        <div className="color-gray">Notify SubOrdinate</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="notifySubOrdinate"
                                id={`inlineradio-notifySubOrdinate`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == notifySubOrdinate ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4 p-0">
                    <div className="col-md-4 col-sm-4 col-12 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Exclude WeekEnd</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="excludeWeekEnd"
                                id={`inlineradio-excludeWeekEnd`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == excludeWeekEnd ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Exclude Holidays</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="excludeHolidays"
                                id={`inlineradio-excludeHolidays`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == excludeHolidays ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12 pr-0">
                      <div className="set-true-false">
                        <div className="color-gray">Allow Attachment</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="allowUpdateAttachment"
                                id={`inlineradio-allowUpdateAttachment`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == allowUpdateAttachment
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4 p-0">
                    <div className="col-md-4 col-sm-4 col-12 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Allow CarryForword</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="allowCarryforword"
                                id={`inlineradio-allowCarryforword`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == allowCarryforword ? true : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12 pr-0 pr-border">
                      <div className="set-true-false">
                        <div className="color-gray">Allow PrevDaysLeave</div>

                        <div className="enable-radio-yes-no">
                          {leaveSettingsTrueFalseOptions.map((item, i) => (
                            <div key={i}>
                              <Form.Check
                                inline
                                type="radio"
                                label={item?.label}
                                value={item?.value}
                                name="allowPrevDaysLeave"
                                id={`inlineradio-allowPrevDaysLeave`}
                                onChange={handleChangeTrueFalse}
                                checked={
                                  item.value == allowPrevDaysLeave
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12 pr-0">
                      <FloatingLabel
                        label="Req. Before Days"
                        className={`${
                          reqBeforeDays !== null
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="number"
                          className="form-control model-input"
                          placeholder="Req. Before Days"
                          name="reqBeforeDays"
                          value={reqBeforeDays}
                          onChange={handleChangeFields}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row mb-2 p-0">
                    <div className="col-md-6 col-sm-6 col-6 pl-0 ">
                      <FloatingLabel
                        label="Attachment Req. Days"
                        className={`${
                          attachmentReqForDays
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="number"
                          className="form-control model-input"
                          placeholder="Attachment Req. Days"
                          name="attachmentReqForDays"
                          value={attachmentReqForDays}
                          onChange={handleChangeFields}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6 pr-0">
                      <FloatingLabel
                        label="Max CarryForword"
                        className={`${
                          maxCarryforword
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="number"
                          className="form-control model-input"
                          placeholder="Max CarryForword"
                          name="maxCarryforword"
                          value={maxCarryforword}
                          onChange={handleChangeFields}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn
            onClickEffect={
              props.editLeaveTypeID ? editLeaveTypes : addLeaveTypes
            }
            btnName={props.editLeaveTypeID ? "Update" : "Save"}
          />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}

export default AddLeaveType;
