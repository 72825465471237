import React, { useState, useEffect } from "react";
import { Modal, Form, FloatingLabel } from "react-bootstrap";
import "../../../styles/AddEditModel.scss";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";

export default function AddSubCategory(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  // console.log("SUBSCRIPTION:", resPassData.subscriptionId);

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryID] = useState(props.parentCategoryID);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);
  const [categoryVal, setCategoryVal] = useState("");
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Category/BindParentCategoryDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setCategoryList(
            res.result.map((data) => {
              return {
                value: data.categoryID,
                label: data.name,
              };
            })
          );
        } else {
          setCategoryList([]);
        }
      });
  };

  useEffect(() => {
    if (categoryId !== "" && categoryId !== null) {
      categoryList.map((catList) => {
        if (categoryId == catList.value) {
          setCategoryVal(catList);
        }
      });
    }
  }, [categoryId, categoryList]);

  const addSubCategory = () => {
    if (subCategoryName !== "" && subCategoryName !== null) {
      const subCategoryDetails = {
        // categoryID: 0,
        subscriptionID: resPassData.subscriptionId,
        name: subCategoryName,
        description: categoryDescription,
        addedByID: resPassData.userId,
        parentCategoryID: categoryId,
        // parentCategoryName: "string",
        // countSubCategory: 0,
        // countProduct: 0,
        status: enableStatus,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(subCategoryDetails),
      };
      fetch(`${baseURL}/api/Category/AddCategory`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchSubCategoryById();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            props.handleClose();
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Enter Sub-Category Name",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.addSubCategory}
        onHide={props.handleClose}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">ADD SUB-CATEGORY</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit">
            <div className="product-inputs mt-2">
              <div className="mb-3">
                <FloatingLabel
                  label="Under Warranty"
                  className={`${
                    categoryList.value || categoryId
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={categoryList}
                    placeholder="Select Category"
                    value={categoryVal}
                    onInputChange={categoryList.label}
                    onChange={(data) => setCategoryID(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
              </div>
              <div className="mb-3">
                <FloatingLabel
                  label="Sub-Category Name"
                  className={`${
                    subCategoryName
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <input
                    type="text"
                    className="form-control form add-input"
                    placeholder="Sub-Category Name"
                    onChange={(e) => setSubCategoryName(e.target.value)}
                  />
                </FloatingLabel>
                {fieldReq && subCategoryName == "" ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <FloatingLabel
                  label="Description"
                  className={`${
                    categoryDescription
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    as="textarea"
                    rows="2"
                    cols="20"
                    name="usrtxt"
                    wrap="hard"
                    className="form-control form form-textarea add-input-area"
                    id="addressArea"
                    placeholder="Description"
                    required
                    // value={location}
                    onChange={(e) => setCategoryDescription(e.target.value)}
                  />
                </FloatingLabel>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={addSubCategory} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
