import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Attendance.scss";
import { baseURL } from "../BaseUrl";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import {
  SkeletonStdTableBody,
  SkeletonStdTableHead,
} from "../Skeleton Loading/SkeletonStdTable";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import { useNavigate } from "react-router-dom";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SbCancelBtn, { MiClearButton } from "../SbButtons/SbCancelBtn";
import {
  loadPageBreadCome,
  loadManagerDropdown,
  loadUserDropdownByManager,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import Footer from "../Footer";
import { travelizeAuth } from "../HeaderAuthentication";
import { loggedInId } from "../LoginRole";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

const statusOptions = [
  { value: "present", label: "Present" },
  { value: "leave", label: "On Leave" },
  { value: "notLoggedIn", label: "Not Logged In" },
  { value: "all", label: "All" },
];

function AttendanceMonthly() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  /* HEADER AUTH END */
  let dispatch = useDispatch();
  const { managerDropdown } = useSelector((state) => state.data);
  const { userDropdownByManager } = useSelector((state) => state.data);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [filterUserId, setFilterUserId] = useState("");
  const [managerID, setManagerID] = useState({
    value: loggedInId,
    label: "Select Manager",
  });
  const [monthList, setMonthList] = useState([]);
  const [days, setDays] = useState([]);
  const [attendanceMonthly, setAttendanceMonthly] = useState([]);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const currentPage = 1;
  const [totalData, setTotalData] = useState(0);
  //* PAGINATION END *//

  //* SET MONTH AND YEAR START *//
  const current = new Date();
  const currentYear = current.getFullYear();
  const currentMonth = current.getMonth() + 1;
  const newMonth = new Date().toLocaleString("en-us", {
    month: "short",
    year: "numeric",
  });

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [monthYear, setMonthYear] = useState(newMonth);
  const [currentMonthYear, setCurrentMonthYear] = useState("");
  //* SET MONTH AND YEAR END *//

  const [userAttendance, setUserAttendance] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[3]);

  useEffect(() => {
    console.log(attendanceMonthly, "selectedStatus");
    if (attendanceMonthly && selectedStatus) {
      let filterData;
      let usersWithLeave = [];

      if (selectedStatus.value === "present") {
        filterData = attendanceMonthly.filter((dayData) => {
          // Check if any date key has a value containing time information
          const hasTimeInformation = Object.keys(dayData).some((key) => {
            const isDateKey = /^\d+$/.test(key);

            return (
              isDateKey &&
              typeof dayData[key] === "string" &&
              dayData[key] !== "-" &&
              dayData[key].includes(":")
            );
          });

          return hasTimeInformation;
        });
      } else if (selectedStatus.value === "leave") {
        // Filter based on the existence of leave details
        filterData = attendanceMonthly.map((data) => {
          const filteredObj = {};
          let hasLeave = false;
          Object.keys(data).forEach((key) => {
            if (
              key !== "RowNumber" &&
              key !== "UserId" &&
              key !== "UserFullName" &&
              key.endsWith("_Leave") &&
              (data[key.replace("_Leave", "_LeaveType")] !== "-" ||
                data[key.replace("_Leave", "_LeaveRemark")] !== "-")
            ) {
              filteredObj[key] = data[key];
              hasLeave = true;
            }
          });
          // Include UserId and UserFullName in the filtered object if there's a leave value
          if (hasLeave) {
            filteredObj.UserId = data.UserId;
            filteredObj.UserFullName = data.UserFullName;
            usersWithLeave.push({
              UserId: data.UserId,
              UserFullName: data.UserFullName,
            });
          }
          return filteredObj;
        });
      } else if (selectedStatus.value === "notLoggedIn") {
        filterData = attendanceMonthly.filter((dayData) => {
          // Check if any date key has a value equal to '-'
          const hasHyphenValue = Object.keys(dayData).some((key) => {
            const isDateKey = /^\d+$/.test(key);
            return isDateKey && dayData[key] === "-";
          });

          return hasHyphenValue;
        });
      } else if (selectedStatus.value === "all") {
        filterData = attendanceMonthly;
      }

      // Remove empty objects from filterData
      filterData = filterData.filter((obj) => Object.keys(obj).length !== 0);

      setUserAttendance(filterData);
      console.log(filterData, "filterData");
    }
  }, [selectedStatus, attendanceMonthly]);

  //* GET DAY MONTH START *//
  useEffect(() => {
    getDaysByMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthYear]);

  const getDaysByMonth = () => {
    const nDate = new Date(monthYear);
    const nMonth = nDate.getMonth() + 1;
    const nYear = nDate.getFullYear();
    setMonth(nMonth);
    setYear(nYear);
  };
  //* GET DAY MONTH START *//

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadManagerDropdown());
    dispatch(loadUserDropdownByManager(managerID.value));
    fetchDropdownData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerID]);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(`${baseURL}/api/Attendance/BindMonthYearDropDown/0`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMonthList(
            res.result.map((data) => {
              return { value: data, label: data };
            })
          );
        } else {
          setMonthList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DROPDOWN DATA END *//

  //* FETCH DAYS BY MONTH START *//
  useEffect(() => {
    fetchDaysByMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year]);

  const fetchDaysByMonth = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Attendance/GetAllDatesOfMonth?Month=${month}&Year=${year}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDays(res?.result);
          setIsLoading(false);
          setLoading(false);
        } else {
          setDays("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DAYS BY MONTH END *//

  //* FETCH ATTENDANCE DATA START *//
  useEffect(() => {
    let breadCome = { mainpage: "Complaints" };
    dispatch(loadPageBreadCome(breadCome));
    fetchAttendanceMonthly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PageSize, currentPage, month, year, filterUserId, managerID]);

  const fetchAttendanceMonthly = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Attendance/GetMonthlyAttendance?CurrentPage=${currentPage}&PageSize=${PageSize}&userId=${filterUserId}&ManagerID=${managerID.value}&Month=${month}&Year=${year}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAttendanceMonthly(res?.result);
          setTotalData(res?.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setAttendanceMonthly("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH ATTENDANCE DATA END *//

  //* DEFAULT SELECT SET START *//
  useEffect(() => {
    if (monthYear !== null && monthYear !== "") {
      monthList?.forEach((mntyr) => {
        if (monthYear === mntyr?.value) {
          setCurrentMonthYear(mntyr);
        }
      });
    }
  }, [monthYear, monthList]);
  //* DEFAULT SELECT SET START *//

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManagerID({
      value: loggedInId,
      label: "Select Manager",
    });
    setMonthYear(newMonth);
    setUserAttendance([]);
    setSelectedStatus(statusOptions[3]);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelAttendance = () => {
    exportExcel(
      `${baseURL}/api/Export/MonthlyAttendanceExcelExport?UserId=${filterUserId}&Month=${month}&Year=${year}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      `Monthly_Attendance-${filterUserId}-M-${month}-Y-${year}.xlsx`,
      setDownloadIsLoading
    );
  };
  //* EXPORT FILE END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const [help, setHelp] = useState(false);

  const openHelpCont = () => {
    setHelp(!help);
  };

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>
                  <div className="page-content">
                    {/* Filter and Header Section */}
                    <div className="user-table">
                      <Row className="search-row">
                        <Col md={8} sm={6} xs={6} className="cat-col">
                          <div className="page-header-text-div">
                            <div className="back-btn" onClick={goToSettingPage}>
                              <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                              />
                            </div>
                            <h6 className="page-header-text">
                              ATTENDANCE-MONTHLY
                            </h6>
                          </div>
                        </Col>

                        <Col md={4} sm={6} xs={6} className="cat-col">
                          <div
                            className="search-bar-header"
                            style={{ float: "right" }}
                          >
                            <div className="page-top-bar-dropdowns page-top-Filters">
                              <div
                                className="header-filter-btn"
                                onClick={() => setFilter(!filter)}
                              >
                                <p className="card-head">
                                  <i className="bi bi-sliders" />
                                  &nbsp;&nbsp;Filters
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {filter && (
                        <div className="page-filter-section">
                          <div className="filter-row-input-boxes">
                            <div>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={managerDropdown}
                                placeholder="Select Manager"
                                value={managerID}
                                onInputChange={managerDropdown.label}
                                onChange={(data) => setManagerID(data)}
                                isSearchable={true}
                              />
                            </div>
                            <div>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={userDropdownByManager}
                                placeholder="Select User"
                                value={
                                  filterUserId === ""
                                    ? ""
                                    : userDropdownByManager.value
                                }
                                onInputChange={userDropdownByManager.label}
                                onChange={(data) => setFilterUserId(data.value)}
                                isSearchable={true}
                              />
                            </div>
                            <div>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                defaultValue={monthList[null]}
                                options={monthList}
                                placeholder="Select Month"
                                value={currentMonthYear}
                                onInputChange={monthList.label}
                                onChange={(data) => setMonthYear(data.value)}
                                isSearchable={true}
                              />
                            </div>
                            {/* <div
                                    style={{
                                      width: "100%",
                                      padding: "0px 5px",
                                    }}
                                  >
                                    <Select
                                      className="react-select-container-list"
                                      classNamePrefix="react-select-list"
                                      options={statusOptions}
                                      placeholder="Select"
                                      // value={
                                      //   userAttendance === ""
                                      //     ? ""
                                      //     : statusOptions.value
                                      // }
                                      value={selectedStatus}
                                      onInputChange={statusOptions.label}
                                      onChange={(selectedOption) => {
                                        setSelectedStatus(selectedOption);
                                      }}
                                      isSearchable={true}
                                    />
                                  </div> */}
                          </div>

                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      )}
                    </div>
                    {/* Filter and Header Section */}

                    {/* USER TABLE START */}
                    <div className="attendance-table">
                      <div
                        className="table-responsive attendance-monthly-scroll"
                        onScroll={(e) =>
                          handleScroll(
                            e,
                            attendanceMonthly,
                            totalData,
                            setLoading,
                            setPageSize,
                            PageSize
                          )
                        }
                      >
                        <table className="table attendance-list-table atten-monthly">
                          <thead className="thead-light attendance-list-thead">
                            {isLoading ? (
                              <SkeletonStdTableHead />
                            ) : (
                              <tr>
                                <th>NAME</th>
                                <th>DEPARTMENT</th>
                                <th>DESIGNATION</th>
                                <th>REPORTING MANAGER</th>
                                {days !== "" ? (
                                  days?.map((day, i) => (
                                    <th key={i}>{day?.onlyDate}</th>
                                  ))
                                ) : (
                                  <th>Please refresh the page</th>
                                )}
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <>
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                              </>
                            ) : userAttendance !== "" &&
                              userAttendance.length !== 0 ? (
                              userAttendance?.map((data) => (
                                <tr
                                  key={data?.RowNumber}
                                  className="attendance-list-row-data"
                                >
                                  <td>
                                    <div>
                                      <div className="table-row-head-Text">
                                        <strong>{data?.UserFullName}</strong>
                                      </div>
                                      <div className="table-row-sub-text color-yellow">
                                        {data?.UserId}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {data.Department ? data.Department : "NA"}
                                  </td>
                                  <td>{data.Designation}</td>
                                  <td>
                                    <div>
                                      <div className="table-row-head-Text">
                                        <strong>
                                          {data?.ReportingManager}
                                        </strong>
                                      </div>
                                      <div className="table-row-sub-text color-yellow">
                                        {data?.ManagerID}
                                      </div>
                                    </div>
                                  </td>
                                  {days.map((day, i) => (
                                    <React.Fragment key={i}>
                                      <td>
                                        {data[day?.onlyDate] !== "Holiday" &&
                                        data[day?.onlyDate] !== "-" ? (
                                          <div>
                                            {/* <div>
                                              {data[`${day?.onlyDate}`]
                                                ?.split("-")
                                                ?.map((time, i) => (
                                                  <div key={i}>{time}</div>
                                                ))}
                                            </div> */}
                                            {data[`${day?.onlyDate}`]?.includes(
                                              "-"
                                            ) && (
                                              <div>
                                                <div>
                                                  {/* Parsing and displaying checkIn */}
                                                  <span className="green-Color">
                                                    Check In
                                                  </span>
                                                  :{" "}
                                                  <span className="color-green">
                                                    {
                                                      data[
                                                        `${day?.onlyDate}`
                                                      ]?.split(" - ")[0]
                                                    }
                                                  </span>
                                                </div>
                                                <div>
                                                  {/* Parsing and displaying checkOut */}
                                                  <span className="color-secondary">
                                                    Check Out
                                                  </span>
                                                  :{" "}
                                                  <span className="color-green">
                                                    {
                                                      data[
                                                        `${day?.onlyDate}`
                                                      ]?.split(" - ")[1]
                                                    }
                                                  </span>
                                                </div>
                                                <div>
                                                  {/* Parsing and displaying timeSpent */}
                                                  <span className="color-green">
                                                    Duration
                                                  </span>
                                                  :{" "}
                                                  <span className="color-green">
                                                    {
                                                      data[
                                                        `${day?.onlyDate}`
                                                      ]?.split(" - ")[2]
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                            {`${day?.onlyDate}_Leave` !== "-" &&
                                              data[day?.onlyDate] !== "Sun" && (
                                                <strong
                                                  className={
                                                    data[
                                                      `${day?.onlyDate}_Leave`
                                                    ] === "HDLR" ||
                                                    data[
                                                      `${day?.onlyDate}_Leave`
                                                    ] === "FDLR" ||
                                                    data[
                                                      `${day?.onlyDate}_Leave`
                                                    ] === "Applied"
                                                      ? "leave-type-request"
                                                      : "leave-type-approved"
                                                  }
                                                >
                                                  {
                                                    data[
                                                      `${day?.onlyDate}_Leave`
                                                    ]
                                                  }
                                                </strong>
                                              )}
                                          </div>
                                        ) : data[`${day?.onlyDate}_Leave`] ===
                                            "HDLR" ||
                                          data[`${day?.onlyDate}_Leave`] ===
                                            "FDLR" ||
                                          data[`${day?.onlyDate}_Leave`] ===
                                            "Applied" ? (
                                          <span className="leave-type-request">
                                            <strong>
                                              {data[`${day?.onlyDate}_Leave`]}
                                            </strong>
                                          </span>
                                        ) : data[`${day?.onlyDate}_Leave`] ===
                                            "HDLA" ||
                                          data[`${day?.onlyDate}_Leave`] ===
                                            "FDLA" ||
                                          data[`${day?.onlyDate}_Leave`] ===
                                            "Applied" ? (
                                          <span className="leave-type-approved">
                                            <strong>
                                              {data[`${day?.onlyDate}_Leave`]}
                                            </strong>
                                          </span>
                                        ) : data[
                                            `${day?.onlyDate}_HolidayName`
                                          ] !== "-" ? (
                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 300 }}
                                            overlay={
                                              <Tooltip id={`tooltip-remarks`}>
                                                {
                                                  data[
                                                    `${day?.onlyDate}_HolidayName`
                                                  ]
                                                }
                                              </Tooltip>
                                            }
                                          >
                                            <span className="status-badge complete-status">
                                              H
                                            </span>
                                          </OverlayTrigger>
                                        ) : (
                                          <span>
                                            {data[`${day?.onlyDate}`]}
                                          </span>
                                        )}
                                      </td>
                                    </React.Fragment>
                                  ))}
                                </tr>
                              ))
                            ) : (
                              <tr className="no-data-found">
                                ------ NO DATA FOUND ------
                              </tr>
                            )}
                            {loading && <OnScrollLoading />}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* USER TABLE END */}

                    {/* PAGINATION START */}
                    <div className="download-and-pagination dflex-j-between">
                      <div className="holiday-help-container">
                        <h6>Note : </h6>
                        <div className="holiday-abbreviation dflex-j-start">
                          <div className="note-leave-rejected">
                            <div className="dflex-j-start">
                              <strong className="color-yellow">FDLR :</strong>{" "}
                              &nbsp;Full Day Leave Rejected
                            </div>{" "}
                            <div className="dflex-j-start">
                              <strong className="color-yellow">HDLR :</strong>{" "}
                              &nbsp;Half Day Leave Rejected
                            </div>
                          </div>
                          <div className="note-leave-approved">
                            <div className="dflex-j-start">
                              <strong className="color-green">FDLA :</strong>{" "}
                              &nbsp;Full Day Leave Applied
                            </div>{" "}
                            <div className="dflex-j-start">
                              <strong className="color-green">HDLA :</strong>{" "}
                              &nbsp;Half Day Leave Applied
                            </div>
                          </div>
                        </div>
                      </div>
                      <SbDownloadBtn onClickEffect={exportExcelAttendance} />
                    </div>
                    {/* PAGINATION END */}

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>

                {/* MAIN BODY END */}
              </div>
              <Footer />
              {downloadIsLoading && (
                <>
                  <DownloadLoading />
                </>
              )}
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default AttendanceMonthly;
