import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Department.scss";
import { baseURL } from "../../BaseUrl";
import moment from "moment";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { optionsStatusList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import AddDepartment from "./AddDepartment";
import { SkeletonDepartment } from "../../Skeleton Loading/SkeletonDepartment";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";
import { loadPageBreadCome } from "../../../redux/action";
import { useDispatch } from "react-redux";
import Footer from "../../Footer";
import DownloadLoading from "../../ReUseComponents/DownloadLoading";
import { travelizeAuth } from "../../HeaderAuthentication";

export default function Department() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let dispatch = useDispatch();

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [activeStatus, setActiveStatus] = useState(2);
  const [departmentId, setDepartmentId] = useState(0);
  const [deptId, setDeptId] = useState(0);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setDeptId(0);
  };

  // GET PAGE START

  useEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "Department" };
    dispatch(loadPageBreadCome(breadCome));
    fetchDepartments();
  }, [departmentId, activeStatus]);

  const fetchDepartments = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Department/Get?DepartmentID=${departmentId}&Status=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDepartments(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setDepartments("");
          setIsLoading(false);
        }
      });
  };
  // GET PAGE END

  const clearSerachBySelect = () => {
    setDepartmentId(0);
    setActiveStatus(2);
  };

  const exportExcelDownload = () => {
    exportExcel(
      `${baseURL}/api/Export/DepartmentExcelExport?DepartmentID=${departmentId}&StatusID=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Departments.xlsx",
      setDownloadIsLoading
    );
  };

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="page-filter-section">
                    <Col md={4} sm={4} xs={12} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToSettingPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">DEPARTMENT</h6>
                      </div>
                    </Col>
                    <Col md={8} sm={8} xs={12} className="cat-col">
                      <div className="filter-row-input-boxes-end">
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            // defaultValue={optionsRoleList[0].label}
                            options={optionsStatusList}
                            placeholder="Select Status"
                            value={
                              activeStatus == 2 ? "" : optionsStatusList.value
                            }
                            onInputChange={optionsStatusList.label}
                            onChange={(data) => setActiveStatus(data.value)}
                            isSearchable={true}
                          />
                        </div>
                        <MiClearButton
                          onClickEffect={clearSerachBySelect}
                          btnName="Clear"
                        />
                        <SbAddBtn onClickEffect={() => setShow(!show)} />
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Filter and Header Section */}
                {/* USER TABLE START */}
                <div className="department-table">
                  <div className="table-responsive department-scrollbar">
                    <table className="table department-table-data">
                      <thead className="thead-light department-thead">
                        <tr>
                          <th>SL.NO.</th>
                          <th>DEPARTMENT</th>
                          <th>ADDED ON</th>
                          <th>ADDED BY</th>
                          <th>STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                          </>
                        ) : departments !== "" ? (
                          departments.map((data, i) => (
                            <tr key={i} className="department-table-row-data">
                              <td>{i + 1}</td>
                              <td>
                                <div className="table-row-head-Text">
                                  {data?.name}
                                </div>
                              </td>
                              <td>
                                {moment(data?.addedOnDate).format(
                                  "DD MMM YYYY"
                                )}
                              </td>
                              <td>{data?.userFullName}</td>
                              <td>
                                {data?.status === "Active" ? (
                                  <span className="status-badge complete-status">
                                    Active
                                  </span>
                                ) : (
                                  <span className="status-badge pending-status">
                                    InActive
                                  </span>
                                )}
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    setShow(!show);
                                    setDeptId(JSON.parse(data.id));
                                  }}
                                  className="btn department-edit-link"
                                >
                                  <i className="bi bi-pencil-fill" /> Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>--- NO DATA FOUND --- </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <SbDownloadBtn onClickEffect={exportExcelDownload} />
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* ADD / EDIT PRODUCT MODEL START */}
                {show ? (
                  <AddDepartment
                    show={show}
                    deptId={deptId}
                    handleClose={handleClose}
                    fetchDepartments={fetchDepartments}
                  />
                ) : (
                  ""
                )}
                {/* ADD / EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
          {downloadIsLoading && (
            <>
              <DownloadLoading />
            </>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
