import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../../../styles/ViewClient.scss";
import { useDispatch, useSelector } from "react-redux";
import { loadSingleKpaData } from "../../../redux/action";

function ViewKpaData(props) {

    let dispatch = useDispatch();
    const { getSingleKpaData } = useSelector((state) => state.data)
    console.log(getSingleKpaData, 'getSingleKpaData')

    const kpaId = props.kpaId;
    console.log(kpaId, 'kpaId')
    useEffect(() => {
        if (kpaId) {
            dispatch(loadSingleKpaData(kpaId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, kpaId])

    const handleCloseModal = () => {
        props.setShowModal(false);
    };


    return (
        <div>
            {props.showModal && (
                <div className="modal-backdrop">
                    <div className="modal-right-fade-bankdetails">
                        <Modal.Dialog className="right-fade-modal-dialog">
                            <div>
                                <Modal.Header closeButton onHide={handleCloseModal}>
                                    <Modal.Title className="client-modal-header card-head">
                                        <button
                                            onClick={() => props.editKpa(kpaId)}
                                            className="btn client-edit-link"
                                        >
                                            <i className="bi bi-pencil-fill" /> {getSingleKpaData && Object.keys(getSingleKpaData).length === 0 ? "Add" : "Edit"}
                                        </button>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {getSingleKpaData && Object.keys(getSingleKpaData).length === 0 ?
                                        <div style={{ padding: "20px 20px" }}>
                                            <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                            <span>No Data Found...!</span>
                                        </div>
                                        :
                                        <div style={{ display: "flex" }}>
                                            <div className="passbook-second-div" >
                                                <div className="card-details">
                                                    <div className="pb-2">
                                                        <h4 className="accounts-header" >KPA Details</h4>
                                                    </div>

                                                    <div className="user-card-details">
                                                        <div className="bank-details">
                                                            <div className="view-kpa-datails">
                                                                <span className="pan-name">
                                                                    <p>Department Name</p> :{" "}
                                                                    <p><b>{getSingleKpaData?.departmentName}</b></p>
                                                                </span>
                                                                <span className="pan-name">
                                                                    <p>User Name</p> :{" "}
                                                                    <p><b>{getSingleKpaData?.userName}</b></p>
                                                                </span>
                                                                <span className="pan-name">
                                                                    <p>Performance-Period</p> :{" "}
                                                                    <p><b>{getSingleKpaData?.period}</b></p>
                                                                </span>
                                                                <span className="pan-name">
                                                                    <p>Meetings per Day</p> :{" "}
                                                                    <p><b>{getSingleKpaData?.areaId1_DailyTarget}</b></p>
                                                                </span>
                                                                <span className="pan-name">
                                                                    <p>Hours to be worked</p> :{" "}
                                                                    <p><b>{getSingleKpaData?.areaId2_DailyTarget}</b></p>
                                                                </span>
                                                                <span className="pan-name">
                                                                    <p>Physical visits per day</p> :{" "}
                                                                    <p><b>{getSingleKpaData?.areaId3_DailyTarget}</b></p>
                                                                </span>
                                                                <span className="pan-name">
                                                                    <p>Sales revenue</p> :{" "}
                                                                    <p><b>{getSingleKpaData?.areaId4_DailyTarget}</b></p>
                                                                </span>
                                                                <span className="pan-name">
                                                                    <p>TAT for complaint resolution</p> :{" "}
                                                                    <p><b>{getSingleKpaData?.areaId5_DailyTarget}</b></p>
                                                                </span>
                                                                <span className="pan-name">
                                                                    <p>Distance to be travelled</p> :{" "}
                                                                    <p><b>{getSingleKpaData?.areaId6_DailyTarget}</b></p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </Modal.Body>
                            </div>
                        </Modal.Dialog>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ViewKpaData;
