import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import { addRefferalData, loadSingleRefferalData, loadUpdateSalesBeeRefferal } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import Select from "react-select";

function UpdateRefferal(props) {

  let dispatch = useDispatch();
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const salesBeeReferelId = props.salesBeeReferelId;

  let statusOptions = [
    {value: 0, label: "Reffered"},
    {value: 1, label: "Discussion"},
    {value: 2, label: "Qualified"},
  ]

  const [value, setValue] = useState()
  const [referStatus, setReferStatus] = useState()

  const [selectChange, handleSelectChange] = useState({value: "", label: ""})
 
  const closeAddModal = () => {
    props.handleClose();
  };

  // code to refill the input field for edit

  // code to post the data
  const [valueError, setValueError] = useState(false);
  const [statusError, setStatusError] = useState(false);

  let postRefferalData = (e) => {
    e.preventDefault()

    setValueError(false);
    setStatusError(false);
  
    // Validate fields before proceeding
    let hasErrors = false;
  
    if (!value) {
        setValueError(true);
      hasErrors = true;
    }
    if (!referStatus) {
        setStatusError(true);
      hasErrors = true;
    }
  
    // If any field has an error, stop the submission
    if (hasErrors) {
      return;
    }

    let data = { subscriptionID: resPassData.subscriptionId, salesBeeReferelID: salesBeeReferelId, name: value, mobile: referStatus }

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(data),
    };
    if (salesBeeReferelId) {
      dispatch(loadUpdateSalesBeeRefferal(requestOption, closeAddModal))
    } else {
      dispatch(addRefferalData(requestOption, closeAddModal))
    }
  }


  

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-basefare-model"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">
            Status Update
          </h6>

          <i className="fa fa-times" onClick={closeAddModal} />

        </div>
        <form>
          <div className="container add-edit-basefare">
            <div className="product-inputs">

            <div className="mb-3" style={{ position: "relative" }}>
                <FloatingLabel
                  label="Select Status"
                  className={`${
                    selectChange.value !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select add-basefare-input"
                    classNamePrefix="react-select-list"
                    options={statusOptions}
                    placeholder="Select Status"
                    value={selectChange.value && selectChange}
                    onInputChange={selectChange.label}
                    onChange={(data) => handleSelectChange(data)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {statusError && !selectChange.value && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Status
                  </small>
                )}
              </div>

              <div className="mb-3" style={{ position: "relative" }}>
                <FloatingLabel
                  label="Enter Value"
                  className={`${value
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                >
                  <input
                    type="text"
                    className="form-control form add-basefare-input"
                    id="exampleFormControlInput1"
                    name="name"
                    placeholder="Enter Name"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value); 
                      setValueError(false)
                    }}
                    disabled={props.clickType}
                    required
                  />
                </FloatingLabel>
                {valueError && (
                  <small id={`Error`} className="form-text text-muted ">
                    Value is required.
                  </small>
                )}
                </div>

              

            </div>
          </div>
        </form>

        <div className="btns-save-cancel">
          {!props.clickType && (
            <SBSaveUpdateBtn
              btnName={salesBeeReferelId ? "Update" : "Refer Now"}
              onClickEffect={postRefferalData} />
          )}
          <SbCancelBtn
            onClickEffect={closeAddModal}
            btnName="Cancel" />
        </div>
      </Modal>
    </div>
  );
}

export default UpdateRefferal;





