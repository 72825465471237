import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";

function OTPTimer({
  seconds,
  onTimerExpire,
  showTimer,
  getOtpForSuperAdmin,
  setSeconds,
}) {
  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      onTimerExpire();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, onTimerExpire]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const remainingSeconds = timeInSeconds % 60;
    const formattedTime =
      minutes.toString().padStart(2, "0") +
      ":" +
      remainingSeconds.toString().padStart(2, "0");
    return formattedTime;
  };

  return (
    <div className="text-center">
      <SBSaveUpdateBtn
        btnName=" Resend OTP ?"
        onClickEffect={getOtpForSuperAdmin}
        disabled={showTimer}
      />
      {/* <Button
        className="resend-otp"
        onClick={getOtpForSuperAdmin}
        disabled={showTimer}
      >
        Resend OTP ?
      </Button> */}
      &nbsp;{showTimer && <span>{formatTime(seconds)}</span>}
    </div>
  );
}

export default OTPTimer;
