import React, { useState } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import AccessDenied from "../../../AccessDenied";
import Footer from "../../Footer";
import AddCountry from "./AddCountry";
import RegionCountryTable from "./RegionCountryTable";
import RegionZoneTable from "./RegionZoneTable";
import AddZone from "./AddZone";
import RegionStateTable from "./RegionStateTable";
import AddState from "./AddState";
import RegionCityTable from "./RegionCityTable";
import AddCity from "./AddCity";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadAllStateList } from "../../../redux/action";

function Region() {

    let dispatch = useDispatch();
    const { getAllStateName } = useSelector((state) => state.data);
    
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(loadAllStateList())
    }, [])

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });

    const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

    const toggleMenu = () => {
        if (menuCollapse) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    // Page navigation
    let navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    };
    // page navigation

    const [activeTab, setActiveTab] = useState('Company');

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    const [show, setShow] = useState(false)
    const openModal = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    const [showZone, setShowZone] = useState(false)
    const openZoneModal = () => {
        setShowZone(true)
    }

    const handleZoneClose = () => {
        setShowZone(false)
    }

    const [showState, setShowState] = useState(false)
    const openStateModal = () => {
        setShowState(true)
    }

    const handleStateClose = () => {
        setShowState(false)
    }

    const [showCity, setShowCity] = useState(false)
    const openCityModal = () => {
        setShowCity(true)
    }

    const handleCityClose = () => {
        setShowCity(false)
    }

    return (

        <div id="main-page">
            {userDetails !== null && userDetails !== "" ? (
                <>
                    {/* SIDEBAR START */}
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />

                    {/* TOPBAR START */}
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    {/* TOPBAR END */}

                    {/* MAIN BODY START */}
                    <div className="page-wrapper">
                        {/* PAGE CONTENT START */}

                        <div className={`main-body ${toggleshift.style}`}>
                            <div className="page-content">
                                {/* USER TABLE START */}
                                <div className="claims-table">
                                    <Tabs
                                        defaultActiveKey="Country"
                                        id="uncontrolled-tab-example"
                                        className="local-claim-nav-tab"
                                        onSelect={handleTabSelect}
                                    >
                                        <Tab
                                            eventKey="back"
                                            title={
                                                <div className="back-btn" onClick={goToBackPage}>
                                                    <img
                                                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                                        alt=""
                                                    />
                                                </div>
                                            }
                                        >
                                            Back
                                        </Tab>
                                        <Tab eventKey="Country" title="Country" >
                                            <RegionCountryTable
                                                show={show}
                                                openModal={openModal}
                                                handleClose={handleClose}
                                            />
                                        </Tab>
                                        <Tab eventKey="Zone" title="Zone" >
                                            {activeTab === "Zone" && <RegionZoneTable
                                                showZone={showZone}
                                                openZoneModal={openZoneModal}
                                                handleZoneClose={handleZoneClose}
                                            />}
                                        </Tab>
                                        <Tab eventKey="State" title="State" >
                                            {activeTab === "State" && <RegionStateTable
                                                showState={showState}
                                                openStateModal={openStateModal}
                                                handleStateClose={handleStateClose}
                                            />}
                                        </Tab>
                                        <Tab eventKey="City" title="City" >
                                            {activeTab === "City" && <RegionCityTable
                                                showCity={showCity}
                                                openCityModal={openCityModal}
                                                handleCityClose={handleCityClose}
                                            />}
                                        </Tab>
                                    </Tabs>
                                </div>
                                {/* USER TABLE END */}

                                {/* PAGE CONTENT END*/}
                            </div>
                        </div>
                        {/* MAIN BODY END */}

                        {show && <AddCountry
                            show={show}
                            // openModal={openModal}
                            handleClose={handleClose}
                        />}

                        {showZone && <AddZone
                            showZone={showZone}
                            openZoneModal={openZoneModal}
                            handleZoneClose={handleZoneClose}
                        />}

                        {showState && <AddState
                            showState={showState}
                            openStateModal={openStateModal}
                            handleStateClose={handleStateClose}
                            getAllStateName={getAllStateName}
                        />}

                        {showCity && <AddCity
                            showCity={showCity}
                            openCityModal={openCityModal}
                            handleCityClose={handleCityClose}
                            getAllStateName={getAllStateName}
                        />}

                    </div>
                    <Footer />
                </>
            ) : (
                <AccessDenied />
            )}
        </div>
    );
}

export default Region;