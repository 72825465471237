import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Leaves.scss";
import { FloatingLabel } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import { optionsLeaveStatusUpdate } from "../DropdownsData";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { travelizeAuth } from "../HeaderAuthentication";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Leaveupdatemodel(props) {
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");

  // UPDATE LEAVE STATUS START
  const updateLeaveStatus = (e) => {
    e.preventDefault();
    const leaveStatus = {
      leaveID: props.leaveId,
      status: status,
      approval_Remark: remarks,
    };
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(leaveStatus),
    };
    fetch(`${baseURL}/api/Leave/UpdateLeaveStatus`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          props.handleClose();
          props.fetchLeaveDetails();
          swal.showAlert("Success", `${res.message}`, "success");
        } else {
          toast.error(res.errorMessage);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };
  // UPDATE LEAVE STATUS END

  return (
    <div>
      <Modal show={props.leaveUpdatestatus} className="main_model">
        <form>
          <div className="add-meeting-heading">
            <h6 className="add-lead-head">UPDATE STATUS</h6>
            <i className="fa fa-times" onClick={props.handleClose} />
          </div>
          <div className="container px-4 py-3">
            <div className="update-leave-status">
              <div className="select-status mb-3">
                <FloatingLabel
                  label="Select Status"
                  className={`${
                    status
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={optionsLeaveStatusUpdate}
                    placeholder="Select Status"
                    value={optionsLeaveStatusUpdate.value}
                    onInputChange={optionsLeaveStatusUpdate.label}
                    onChange={(data) => setStatus(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
              </div>
              <div className="select-status mb-2">
                <FloatingLabel
                  label="Remarks"
                  className={`${
                    remarks ? "float-input-visible" : "float-hidden float-input"
                  }`}
                >
                  <textarea
                    rows="3"
                    cols="20"
                    name="remarks"
                    wrap="hard"
                    className="form-control form-textarea add-product-input"
                    id="descriptionArea"
                    placeholder="Remarks"
                    value={remarks}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn
              onClickEffect={updateLeaveStatus}
              btnName="Update"
            />
            <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Leaveupdatemodel;
