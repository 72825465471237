import React, { useEffect, useState } from "react";
import { Modal, FloatingLabel, Form, Row, Col } from "react-bootstrap";
import "../../../styles/AddEditModel.scss";
import Select from "react-select";

export default function EditButton(props) {
  let newData = props.data;
  const [selectedData, setSelectedData] = useState(newData.inputData.type);
  const [variantColor, setVariantColor] = useState(newData.inputData.variant);
  const [labelName, setLabelName] = useState(newData.inputData.label);

  const fieldTypes = [
    { value: "button", label: "Button" },
    { value: "submit", label: "Submit" },
    { value: "reset", label: "Reset" },
  ];

  const variantTypes = [
    { value: "primary", label: "Primary" },
    { value: "secondary", label: "Secondary" },
    { value: "success", label: "Success" },
    { value: "warning", label: "Warning" },
    { value: "danger", label: "Danger" },
    { value: "info", label: "Info" },
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" },
  ];

  const [typeVal, setTypeVal] = useState("");
  const [variantVal, setVariantVal] = useState("");

  useEffect(() => {
    if (selectedData !== "" && selectedData !== null) {
      fieldTypes.map((item) => {
        if (selectedData == item.value) {
          setTypeVal(item);
        }
      });
    }
    if (variantColor !== "" && variantColor !== null) {
      variantTypes.map((item) => {
        if (variantColor == item.value) {
          setVariantVal(item);
        }
      });
    }
  }, [selectedData, variantColor]);

  const changeValue = (itemId) => {
    const newInputData = {
      id: newData.id,
      name: "Button",
      icon: newData.icon,
      inputData: {
        type: selectedData,
        name: "button-field",
        label: labelName,
        variant: variantColor,
        className: "btn-default",
      },
    };
    const newArray = props.addedFieldNames.map((item) => {
      if (item.id == itemId) {
        return newInputData;
      }
      return item;
    });
    props.setAddedFieldNames(newArray);
    props.handleClose();
  };
  return (
    <div>
      {" "}
      <Modal show={props.editButton} className="main_model add-edit-model">
        <div className="add-edit-heading">
          <h6 className="add-edit-head">EDIT BUTTON</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>

        <form>
          <div className="container add-edit">
            <div className="change-text-fields-part">
              <div className="model-inputs mb-3">
                <FloatingLabel
                  label="Label Name"
                  className={`${
                    labelName
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    id={props.data.id}
                    type="text"
                    className="change-model"
                    placeholder="Label Name"
                    autoComplete="off"
                    value={labelName}
                    required
                    onChange={(e) => setLabelName(e.target.value)}
                  />
                </FloatingLabel>
              </div>
              <div className="model-inputs mb-3">
                <FloatingLabel
                  label="Type"
                  className={`${
                    props.data.id || ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                  style={{ width: "100%" }}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    defaultValue={fieldTypes[null]}
                    options={fieldTypes}
                    placeholder="Type"
                    value={typeVal}
                    onInputChange={fieldTypes.label}
                    onChange={(data) => setSelectedData(data.value)}
                    isSearchable={true}
                    required
                  />
                </FloatingLabel>
              </div>
              <div className="model-inputs mb-3">
                <FloatingLabel
                  label="Type"
                  className={`${
                    props.data.id || ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                  style={{ width: "100%" }}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    defaultValue={variantTypes[null]}
                    options={variantTypes}
                    placeholder="Type"
                    value={variantVal}
                    onInputChange={variantTypes.label}
                    onChange={(data) => setVariantColor(data.value)}
                    isSearchable={true}
                    required
                  />
                </FloatingLabel>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="model-save-button">
            <button
              onClick={() => changeValue(props.data.id)}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="model-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
