import React, { useEffect, useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Products.scss";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { loadSingleKpaData } from "../../../redux/action";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";

function ViewKpaTarget() {
    let tableHeader = [
        { name: "Parameters", align: "left", width: "100px" },
        { name: "Targets", align: "left", width: "100px" },
        { name: "Achived", align: "left", width: "100px" },
    ]

    let dispatch = useDispatch();
    const { kpaId } = useParams();
    const { getSingleKpaData } = useSelector(state => state.data)
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (kpaId) {
            dispatch(loadSingleKpaData(kpaId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kpaId]);

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    // Page navigation
    let navigate = useNavigate();
    const goToBackPage = () => {
        navigate(-1);
    }

    return (
        <div id="main-page">
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                        <div className="user-table">
                            <Row className='search-row'>
                                <Col md={3} className='cat-col'>
                                    <div className='page-header-text-div'>
                                        <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                                        <h6 className='page-header-text' >KPA TARGETS</h6>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                        <div className="clients-table">
                            <Row className='search-row order-details-row'>
                                <Col md={6} className='cat-col'>
                                    <ul className="order-detail-list">
                                        <li><div className="span1">User Name:</div> &nbsp;&nbsp;<div className="span2"><b>{getSingleKpaData.userName}</b></div></li>
                                        <li><div className="span1">Code:</div> &nbsp;&nbsp;<div className="span2"><b>{getSingleKpaData.userCode}</b></div></li>
                                        <li><div className="span1">Department Name:</div> &nbsp;&nbsp;<div className="span2"><b>{getSingleKpaData.departmentName}</b></div></li>
                                        <li><div className="span1">Designation:</div> &nbsp;&nbsp;<div className="span2"><b>{getSingleKpaData.designationName}</b></div></li>
                                    </ul>
                                </Col>
                            </Row>


                        </div>
                        <div className="sb-table-div sb-table-setion">
                            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table table-responsive" className='basefare-table'>
                                        <TableHead className='custom-table-header'>
                                            <TableRow>
                                                {tableHeader.map((name, i) => (
                                                    <TableCell align={name.align} key={i} style={{ minWidth: name.width }}>
                                                        {name.name}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        {isLoading ? (
                                            <>
                                                {tableHeader.map((name, i) => (
                                                    <SkeletonMeetPurpose key={i} />
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {getSingleKpaData.length === 0 ? (
                                                    <TableBody>
                                                        <TableRow align="center">
                                                            <TableCell align="center" colSpan={tableHeader.length}>
                                                                <div style={{ padding: "20px 20px" }}>
                                                                    <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                                    <span>No Data Found...!</span>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                ) : (
                                                    <>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Meetings per day</TableCell>
                                                                <TableCell>{getSingleKpaData.areaId1_DailyTarget}</TableCell>
                                                                <TableCell>2</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Hours to be worked</TableCell>
                                                                <TableCell>{getSingleKpaData.areaId2_DailyTarget}</TableCell>
                                                                <TableCell>8</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Physical visits per day</TableCell>
                                                                <TableCell>{getSingleKpaData.areaId3_DailyTarget}</TableCell>
                                                                <TableCell>2</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Sales revenue</TableCell>
                                                                <TableCell>{getSingleKpaData.areaId4_DailyTarget}</TableCell>
                                                                <TableCell>5000</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>TAT for complaint resolution</TableCell>
                                                                <TableCell>{getSingleKpaData.areaId5_DailyTarget}</TableCell>
                                                                <TableCell>2</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Distance to be travelled</TableCell>
                                                                <TableCell>{getSingleKpaData.areaId6_DailyTarget}</TableCell>
                                                                <TableCell>5</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>

                    </div>

                </div>

            </div>

        </div >
    );
}

export default ViewKpaTarget;
