import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import { loadDepartmentDrop, loadSingleKpaData, loadUserDropdown, postKpaData } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import Select from "react-select";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import "../../../styles/Kpa.scss"

const options = [
    { key: "areaId1_DailyTarget", label: "Meetings per Day" },
    { key: "areaId2_DailyTarget", label: "Hours To be worked" },
    { key: "areaId3_DailyTarget", label: "Physical visits per day" },
    { key: "areaId4_DailyTarget", label: "Sales Revenue" },
    { key: "areaId5_DailyTarget", label: "TAT for Complaints resolution" },
    { key: "areaId6_DailyTarget", label: "Distance to be Travelled" },
]

const PerformancePeriodoptions = [
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Yearly', label: 'Yearly' },
];
function AddKpaModel(props) {

    let dispatch = useDispatch();
    const kpaId = props.kpaId;

    useEffect(() => {
        if (kpaId) {
            dispatch(loadSingleKpaData(kpaId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kpaId])

    const { userDropdown } = useSelector((state) => state.data);
    const { departmentDrop } = useSelector((state) => state.data);
    const { getSingleKpaData } = useSelector((state) => state.data);
    console.log(getSingleKpaData,'getSingleKpaData')

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [departmentId, setDepartmentId] = useState({ value: "", label: "" });
    const [userId, setUserId] = useState({ value: "", label: "" });
    const [performancePeriod, setPerformancePeriod] = useState({ value: "", label: "" })
    const [meetings, setMeetings] = useState("");
    const [hoursLogged, setHoursLogged] = useState("");
    const [dailyVisits, setDailyVisits] = useState("");
    const [salesRevenue, setSalesRevenue] = useState("");
    const [complaintsTAT, setComplaintsTAT] = useState("");
    const [kmsTravelled, setKmsTravelled] = useState("");

    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            // If the option is already selected, remove it
            setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option));
        } else {
            // If the option is not selected, add it
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const handleInputChange = (e, optionKey) => {
        // Update state variables directly based on the option key
        switch (optionKey) {
            case 'areaId1_DailyTarget':
                setMeetings(e.target.value);
                break;
            case 'areaId2_DailyTarget':
                setHoursLogged(e.target.value);
                break;
            case 'areaId3_DailyTarget':
                setDailyVisits(e.target.value);
                break;
            case 'areaId4_DailyTarget':
                setSalesRevenue(e.target.value);
                break;
            case 'areaId5_DailyTarget':
                setComplaintsTAT(e.target.value);
                break;
            case 'areaId6_DailyTarget':
                setKmsTravelled(e.target.value);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        dispatch(loadUserDropdown());
        dispatch(loadDepartmentDrop());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let addKpaData = (e) => {
        e.preventDefault();
        // Get current date
        const currentDate = new Date();
        const formattedCurrentDate = `${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`;

        // Calculate end date based on the selected period
        let endDate;
        if (performancePeriod.value === "Monthly") {
            // For monthly period, set the end date as the last day of the current month
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            endDate = `${lastDayOfMonth.getDate().toString().padStart(2, '0')}/${(lastDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}/${lastDayOfMonth.getFullYear()}`;
        } else if (performancePeriod.value === "Quarterly") {
            // For quarterly period, set the end date as the last day of the current quarter
            const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3); // Calculate current quarter
            const lastMonthOfQuarter = currentQuarter * 3;
            const lastDayOfQuarter = new Date(currentDate.getFullYear(), lastMonthOfQuarter, 0);
            endDate = `${lastDayOfQuarter.getDate().toString().padStart(2, '0')}/${(lastDayOfQuarter.getMonth() + 1).toString().padStart(2, '0')}/${lastDayOfQuarter.getFullYear()}`;
        } else if (performancePeriod.value === "Yearly") {
            // For yearly period, set the end date as December 31st of the current year
            endDate = `31/12/${currentDate.getFullYear()}`;
        }

        let data = {
            "kpaid": kpaId,
            "startDate": formattedCurrentDate,
            "endDate": endDate,
            "departmentID": departmentId.value,
            "userID": userId.value,
            "period": performancePeriod.value,
            "areaId1_DailyTarget": meetings,
            "areaId2_DailyTarget": hoursLogged,
            "areaId3_DailyTarget": dailyVisits,
            "areaId4_DailyTarget": salesRevenue,
            "areaId5_DailyTarget": complaintsTAT,
            "areaId6_DailyTarget": kmsTravelled,
        }
        let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(data)
        }
        dispatch(postKpaData(requestOption, closeAddModal, kpaId))
        props.applyFilters()
    }

    useEffect(() => {
        if (kpaId && getSingleKpaData) {
            // eslint-disable-next-line array-callback-return
            departmentDrop?.map((dept) => {
                if (dept.label === getSingleKpaData.departmentName)
                    setDepartmentId(dept)
            })
            // eslint-disable-next-line array-callback-return
            userDropdown?.map((user) => {
                if (user.label === getSingleKpaData.userName)
                    setUserId(user)
            })
            // eslint-disable-next-line array-callback-return
            PerformancePeriodoptions?.map((perperiod) => {
                if (perperiod.label === getSingleKpaData.period)
                    setPerformancePeriod(perperiod)
            })
            setMeetings(getSingleKpaData.areaId1_DailyTarget)
            setHoursLogged(getSingleKpaData.areaId2_DailyTarget)
            setDailyVisits(getSingleKpaData.areaId3_DailyTarget)
            setSalesRevenue(getSingleKpaData.areaId4_DailyTarget)
            setComplaintsTAT(getSingleKpaData.areaId5_DailyTarget)
            setKmsTravelled(getSingleKpaData.areaId6_DailyTarget)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kpaId, getSingleKpaData, departmentDrop, userDropdown, PerformancePeriodoptions])

    const closeAddModal = () => {
        // setDepartmentId("");
        // setUserId("");
        // setPerformancePeriod("");
        // setMeetings("");
        // setHoursLogged("");
        // setDailyVisits("");
        // setSalesRevenue("");
        // setComplaintsTAT("");
        // setKmsTravelled("");
        props.setKpaId("")
        props.handleClose();
    };

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                className="main_model "
            >
                <div className="add-user-heading">
                    <h6 className="add-user-head">
                        {kpaId ? "UPDATE KPA" : "ADD KPA"}
                    </h6>

                    <i className="fa fa-times" onClick={closeAddModal} />

                </div>
                <form>
                    <div className="container model-add-user-body">
                        <div className="row add-user">
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Department"
                                    className={`${departmentId.value !== ""
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                >
                                    <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={departmentDrop}
                                        placeholder="Department"
                                        value={departmentId.value && departmentId}
                                        onChange={(data) =>
                                            setDepartmentId(data)
                                        }
                                        isSearchable={true}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Select User"
                                    className={`${userId.value !== ""
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                >
                                    <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={userDropdown}
                                        placeholder="Select User"
                                        value={userId?.value && userId}
                                        onChange={(data) =>
                                            setUserId(data)
                                        }
                                        isSearchable={true}
                                    />
                                </FloatingLabel>
                            </div>

                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Performance-Period"
                                    className={`${performancePeriod.value !== ""
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                >
                                    <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={PerformancePeriodoptions}
                                        placeholder="Performance-Period"
                                        value={performancePeriod?.value && performancePeriod}
                                        onChange={(data) => { setPerformancePeriod(data) }}
                                        isSearchable={true}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="kpa-heading">
                                <h6 className="add-edit-kpa-head">
                                    KEY PERFORMANCE AREA
                                </h6>
                            </div>
                            <div className="mb-3 kpa-add-container">
                                {options.map((option, index) => (
                                    <div key={index} className="kpa-add-inner-container">
                                        <Form.Check
                                            type="checkbox"
                                            label={option.label} // Ensure 'label' property is accessed correctly
                                            checked={selectedOptions.includes(option.key)}
                                            onChange={() => handleCheckboxChange(option.key)}
                                        />
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Target"
                                                    name={option.key}
                                                    disabled={!selectedOptions.includes(option.key)}
                                                    value={option.key === 'areaId1_DailyTarget' ? meetings :
                                                        option.key === 'areaId2_DailyTarget' ? hoursLogged :
                                                            option.key === 'areaId3_DailyTarget' ? dailyVisits :
                                                                option.key === 'areaId4_DailyTarget' ? salesRevenue :
                                                                    option.key === 'areaId5_DailyTarget' ? complaintsTAT :
                                                                        option.key === 'areaId6_DailyTarget' && kmsTravelled
                                                    }
                                                    onChange={(e) => handleInputChange(e, option.key)}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </form>

                <div className="btns-save-cancel">
                    {!props.clickType && (
                        <SBSaveUpdateBtn
                            btnName={kpaId ? "Update" : "Save"}
                            onClickEffect={addKpaData}
                        />
                    )}
                    <SbCancelBtn
                        onClickEffect={closeAddModal}
                        btnName="Cancel" />
                </div>
            </Modal>
        </div >
    );
}

export default AddKpaModel;



