import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import TripPreviewTable from "../TripPreviewTable";

export default function TripPlanPreview(props) {
  console.log("PREVIEW-DATA:", props.travelDetails);
  const saveTripPlanData = () => {
    // console.log("POST-DATA:", props.travelDetails);
  };
  return (
    <div>
      {props.tripPlanPreview ? (
        <div className="plan-details-preview">
          <div className="page-header">
            <div
              className="travel-next-back-step dflex-j-start"
              onClick={() => props.setTripPlanPreview(false)}
            >
              <span className="material-symbols-outlined">arrow_left</span>
              <span>Back</span>
            </div>
            <h6>PREVIEW TRIP PLAN</h6>
            <div className="travel-next-back-step hv-center color-green">
              <SBSaveUpdateBtn
                onClickEffect={saveTripPlanData}
                btnName="Submit"
              />
            </div>
          </div>
          <div className="container-fluid preview-data">
            <TripPreviewTable
              userNames={props.userNames}
              clientName={props.clientName}
              tripApproxAmount={props.tripApproxAmount}
              mobilenum={props.mobilenum}
              remarks={props.remarks}
              advanceCash={props.advanceCash}
              otherOption={props.otherOption}
              travelByOptions={props.travelByOptions}
              travelDetails={props.travelDetails}
              fromDate={props.fromDate}
              toDate={props.toDate}
              pageViewBy="User"
              pageName="UserTripRequest"
              // fromDate="24/04/2024 2:45 PM"
              // toDate="24/04/2024 2:45 PM"
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
