import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { cashVoucherData } from "./SampleData";

export default function CashVoucherDetails() {
  const groupedData = Object.values(
    cashVoucherData.reduce((acc, item) => {
      if (!acc[item.date]) {
        acc[item.date] = { date: item.date, details: [] };
      }
      acc[item.date].details.push({
        from: item.from,
        to: item.to,
        mot: item.mot,
        details: item.details,
        customerName: item.customerName,
        amount: item.amount,
      });
      return acc;
    }, {})
  );

  let tableHeader = [
    { name: "Date", align: "left", width: "100px" },
    { name: "From ", align: "left", width: "200px" },
    { name: "To", align: "left", width: "200px" },
    { name: "Mode", align: "left", width: "100px" },
    { name: "Reason / Details", align: "left", width: "200px" },
    { name: "Customer's Name", align: "left", width: "150px" },
    { name: "Amount", align: "left", width: "100px" },
  ];
  return (
    <div>
      {" "}
      <Table
        stickyHeader
        aria-label="sticky table table-responsive"
        className="whatsapp-table-container p-2"
      >
        <TableHead className="custom-table-header">
          <TableRow>
            {tableHeader.map((name, i) => (
              <TableCell
                key={i}
                align={name.align}
                style={{
                  minWidth: name.width,
                  maxWidth: name.width,
                  width: name.width,
                }}
                className="bt-1 bg-primaryLight"
              >
                {name.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <>
          <TableBody>
            {groupedData.map((data, i) => (
              <TableRow align="center" className="client-list-row-data" key={i}>
                <TableCell
                  className="tr-tcell-br bl-1"
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    width: "100px",
                  }}
                >
                  <div className="table-row-head-Text">{data.date}</div>
                </TableCell>
                <TableCell colSpan={6} className="bb-none p-0">
                  <Table>
                    <TableBody>
                      {data.details.map((item, i) => (
                        <TableRow
                          className="table_row-inside-tabel-cell"
                          key={i}
                        >
                          <TableCell
                            className="tr-tcell-br"
                            style={{
                              minWidth: "200px",
                              maxWidth: "200px",
                              width: "200px",
                            }}
                          >
                            <div>{item.from}</div>
                          </TableCell>
                          <TableCell
                            className="tr-tcell-br"
                            style={{
                              minWidth: "200px",
                              maxWidth: "200px",
                              width: "200px",
                            }}
                          >
                            <div>{item.to}</div>
                          </TableCell>
                          <TableCell
                            className="tr-tcell-br"
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              width: "100px",
                            }}
                          >
                            <div>{item.mot}</div>
                          </TableCell>
                          <TableCell
                            className="tr-tcell-br"
                            style={{
                              minWidth: "200px",
                              maxWidth: "200px",
                              width: "200px",
                            }}
                          >
                            <div>{item.details}</div>
                          </TableCell>
                          <TableCell
                            className="tr-tcell-br"
                            style={{
                              minWidth: "150px",
                              maxWidth: "150px",
                              width: "150px",
                            }}
                          >
                            <div>{item.customerName}</div>
                          </TableCell>
                          <TableCell
                            className="tr-tcell-br"
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              width: "100px",
                            }}
                          >
                            <div>{item.amount}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            ))}
            <TableRow align="center" className="client-list-row-data">
              <TableCell className="tr-tcell-br bl-1" colSpan={5}>
                <div>
                  <b>Amount in words :</b>&nbsp; Six hundred and thirty two
                  rupees sixty two pisa only.
                </div>
              </TableCell>
              <TableCell className="tr-tcell-br" style={{ textAlign: "right" }}>
                <div>
                  <b>TOTAL</b>
                </div>
              </TableCell>
              <TableCell className="tr-tcell-br">
                <div>
                  <b>632.62</b>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </>
      </Table>
    </div>
  );
}
