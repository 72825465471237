import React, { useEffect, useState, useRef } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Pivottable.scss";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
// import { dataPivotTable } from "./pivotData";
import { baseURL } from "../BaseUrl";
import { travelizeAuth } from "../HeaderAuthentication";
import InternetIssue from "../InternetIssue";
import AccessDenied from "../../AccessDenied";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
const PlotlyRenderers = createPlotlyRenderers(Plot);

// create Plotly React component via dependency injection
// const Plot = createPlotlyComponent(window.Plotly);
// create Plotly renderers via dependency injection

export default function Pivottable() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const pivotTableNames = [
    {
      label: "Meetings",
      value: "Meetings",
    },
    {
      label: "Claims",
      value: "Claims",
    },
  ];

  const pivotTableYears = [
    {
      label: new Date().getFullYear(),
      value: new Date().getFullYear(),
    },
    {
      label: new Date().getFullYear() - 1,
      value: new Date().getFullYear() - 1,
    },
    {
      label: new Date().getFullYear() - 2,
      value: new Date().getFullYear() - 2,
    },
  ];

  /* HEADER AUTH END */
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [dataPivotTable, setDataPivotTable] = useState([]);
  const [settings, setSettings] = useState({});
  const [tableName, setTableName] = useState("Meetings");
  const [tableYear, setTableYear] = useState(new Date().getFullYear());

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  let piviotRef = useRef(null);
  const handlePrint = useReactToPrint({
    bodyClass: "print-file",
    content: () => piviotRef.current,
  });

  const [yearVal, setYearval] = useState("");
  const [tableNameVal, setTableNameVal] = useState("");

  useEffect(() => {
    if (tableYear !== "" && tableYear !== null) {
      pivotTableYears.map((data) => {
        if (data.value == tableYear) {
          setYearval(data);
        }
      });
    }
    if (tableName !== "" && tableName !== null) {
      pivotTableNames.map((data) => {
        if (data.value == tableName) {
          setTableNameVal(data);
        }
      });
    }
  }, [tableYear, tableName]);
  // pvtOutput;

  // console.log("ON-CHANGE-SETTINGS:", settings.cols);

  useEffect(() => {
    fetchPivotTableData();
  }, [tableYear, tableName]);

  const fetchPivotTableData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/PivotGenerator/${tableName}?year=${tableYear}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDataPivotTable(res.result);
        } else {
          setDataPivotTable(res.result);
        }
      });
  };

  // console.log("ORDERS-DATA:", dataPivotTable);

  return (
    <div>
      <div id="main-page">
        {errorMessage ? (
          <InternetIssue />
        ) : (
          <>
            {userDetails !== null && userDetails !== "" ? (
              <>
                {/* SIDEBAR START */}
                <Sidenavbar
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />
                {/* SIDEBAR END */}

                {/* TOPBAR START */}
                <Topbar
                  toggleMenu={toggleMenu}
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />
                {/* TOPBAR END */}

                {/* MAIN BODY START */}
                <div className="page-wrapper">
                  {/* PAGE CONTENT START */}

                  <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                      {/* Filter and Header Section */}
                      <div className="pivottable-header">
                        <Row className="search-row p-2">
                          <Col md={3} className="cat-col">
                            <div className="page-header-text-div">
                              <div
                                className="back-btn"
                                // onClick={goToSettingPage}
                              >
                                <img
                                  src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                  alt=""
                                />
                              </div>
                              <h6 className="page-header-text">
                                DYNAMIC REPORTS
                              </h6>
                            </div>
                          </Col>
                          <Col md={5} className="cat-col">
                            <div
                              className="page-top-bar-dropdowns"
                              style={{ float: "left" }}
                            >
                              <div className="tables-dropdown tableNames">
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={pivotTableNames}
                                  placeholder="Select Table Name"
                                  value={tableNameVal}
                                  onInputChange={pivotTableNames.label}
                                  onChange={(data) => setTableName(data.value)}
                                  isSearchable={true}
                                />
                              </div>
                              <div className="tables-dropdown">
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={pivotTableYears}
                                  placeholder="Select Year"
                                  value={yearVal}
                                  onInputChange={pivotTableYears.label}
                                  onChange={(data) => setTableYear(data.value)}
                                  isSearchable={true}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={4} className="cat-col">
                            <div
                              className="hv-center"
                              style={{ float: "right" }}
                            >
                              <SbDownloadBtn onClickEffect={handlePrint} />
                              {/* <div
                                className="header-filter-btn"
                                onClick={() => setFilter(!filter)}
                              >
                                <p className="card-head">
                                  <i className="bi bi-sliders" />
                                  &nbsp;&nbsp;Filters
                                </p>
                              </div> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* Filter and Header Section */}

                      {/* USER TABLE START */}
                      <div className="pivottable-table">
                        <div className="table-responsive pivottable-scrollbar">
                          {dataPivotTable && dataPivotTable.length > 0 ? (
                            <PivotTableUI
                              data={dataPivotTable}
                              ref={piviotRef}
                              onChange={(s) => setSettings(s)}
                              // cols={["year", "month"]}
                              // rows={["companyname", "countryname"]}
                              // vals={["aos_pax_total"]}
                              aggregatorName="Count"
                              renderers={Object.assign(
                                {},
                                TableRenderers,
                                PlotlyRenderers
                              )}
                              {...settings}
                              hiddenAttributes={[
                                "pvtRenderers",
                                "pvtAxisContainer",
                                "pvtVals",
                                "pvtAxisContainer",
                              ]}
                              // hiddenFromAggregators={["id", "companyid"]}
                            />
                          ) : (
                            <div className="text-center">
                              --- NO DATA FOUND ---
                            </div>
                          )}
                        </div>
                      </div>
                      {/* TABLE END */}
                      {/* PAGE CONTENT END*/}
                    </div>
                  </div>

                  {/* MAIN BODY END */}
                </div>
              </>
            ) : (
              <AccessDenied />
            )}
          </>
        )}
      </div>
    </div>
  );
}
