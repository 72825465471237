import React, { useEffect, useState } from "react";
import "../../styles/subscriptionmodule.scss";
import "../../styles/SignupSubscription.scss";
import "../../styles/CommonStyle.scss";
import {
  loadAllSubscriptions,
  loadMainModules,
  loadPageBreadCome,
  loadPostMenuItems,
  loadRolesDropDown,
  loadSignupSubscriptionData,
  loadSubscription,
  loadSubscriptionBySubsId,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, OverlayTrigger, Row, Tooltip, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";

function SignupSubscriberPage() {
  let dispatch = useDispatch();

  const { signUpSubscriptionData } = useSelector((state) => state.data);
  const { distanceMatrix } = useSelector((state) => state.data);
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const location = useLocation();

  const resPassData = location.state;

  const [selectedSubscribe, setSelectedSubscribe] = useState({
    value: "",
    label: "",
  });

  const [selectedRole, setSelectedRole] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    if (resPassData) {
      setSelectedSubscribe({
        label: resPassData.subscriptionId,
        value: resPassData.subscriptionId,
      });
      setSelectedRole({
        value: resPassData?.desingation?.role?.roleID,
        label: resPassData?.desingation?.role?.roleName,
      });
    }
  }, [resPassData]);

  const { allSubcriptions } = useSelector((state) => state.data);
  const { mainModules } = useSelector((state) => state.data);
  const { allSubsById } = useSelector((state) => state.data);

  const [menuItems, setMenuItems] = useState([]);
  const [submenuAtt, setSubmenuAtt] = useState([]);
  const [submenuClm, setSubmenuClm] = useState([]);
  const [submenuStg, setSubmenuStg] = useState([]);
  const [submenuCtnr, setSubmenuCtnr] = useState([]);

  const [mainModuleList, setMainModuleList] = useState([]);

  useEffect(() => {
    let activities = [];
    let subActAtt = [];
    let subActClm = [];
    let subActStng = [];
    let subActCntr = [];

    let subscriptionList = [...signUpSubscriptionData];
    if (subscriptionList?.length != 0) {
      subscriptionList?.map((item) => {
        if (item.parentModuleName === null) {
          activities.push({ items: item, subItem: [] });
        } else if (item.parentModuleName === "Attendance") {
          subActAtt.push(item);
        } else if (item.parentModuleName === "Claims") {
          subActClm.push(item);
        } else if (item.parentModuleName === "Settings") {
          subActStng.push(item);
        } else if (item.parentModuleName === "Connector") {
          subActCntr.push(item);
        }
      });
    } else {
      activities.push({ items: { moduleName: "Dashboard" }, subItem: [] });
    }
    setMenuItems(activities);
    setSubmenuAtt(subActAtt);
    setSubmenuClm(subActClm);
    setSubmenuStg(subActStng);
    setSubmenuCtnr(subActCntr);
  }, [allSubcriptions, signUpSubscriptionData]);

  useEffect(() => {
    if (menuItems) {
      menuItems.map((activities) => {
        if (activities?.items?.moduleName === "Attendance") {
          activities.subItem = submenuAtt;
        }
        if (activities?.items?.moduleName === "Claims") {
          activities.subItem = submenuClm;
        }
        if (activities?.items?.moduleName === "Settings") {
          activities.subItem = submenuStg;
        }
        if (activities?.items?.moduleName === "Connector") {
          activities.subItem = submenuCtnr;
        }
      });
    }
  }, [menuItems, submenuAtt, submenuClm, submenuStg, submenuCtnr]);

  useEffect(() => {
    if (menuItems.length !== 0 && mainModules) {
      mainModules.forEach((item1) => {
        const matchingItem = menuItems.filter(
          (item2) => item1.mainModuleID === item2.items.mainModuleID
        );

        let isFound = matchingItem.some((element) => {
          if (element.items.enable === 1) {
            return true;
          }
          return false;
        });
        const ActiveCount = matchingItem.filter(
          (menus) => menus.items.enable === 1
        );
        item1.aMenuCount = ActiveCount.length;

        if (matchingItem.length !== 0 && isFound) {
          item1.enableStatus = 1;
        } else {
          item1.enableStatus = 0;
        }
      });
      setMainModuleList(mainModules);
    }
  }, [menuItems, mainModules]);

  const { subcriptionList } = useSelector((state) => state.data);
  const { rolesDropdown } = useSelector((state) => state.data);

  const [userList, setUserList] = useState([]);
  // let sortedArray;
  if (userList.length != 0) {
    let sortedArray = userList.sort(
      (a, b) => parseInt(a.subscriptionId) - parseInt(b.subscriptionId)
    );
  }
  useEffect(() => {
    if (subcriptionList) {
      let optiondata = [];
      subcriptionList.map((data) => {
        if (data.subscriptionId != null) {
          optiondata.push({
            value: data.subscriptionId,
            label: data.subscriptionId,
            client: data?.company?.companyName,
          });
        }
      });
      setUserList(optiondata);
    }
  }, [subcriptionList]);

  const getOptionValue = ({ label }) => (
    <div>
      <div>{label.value}</div>
    </div>
  );

  const formatOptionLabel = ({ label, client }) => (
    <div className="menu-item-column">
      <div style={{ fontSize: "11px" }}>
        <b>{label}</b>
      </div>
      <div style={{ fontSize: "10px" }}>{client}</div>
    </div>
  );

  const setFilterUserId = (selected) => {
    setSelectedSubscribe(selected);
  };

  const setFilterRoleId = (selected) => {
    setSelectedRole(selected);
  };

  useEffect(() => {
    let breadCome = {
      mainpage: "Subscription",
    };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadSubscription());
    dispatch(loadAllSubscriptions());
    if (selectedSubscribe) {
      dispatch(loadMainModules(selectedSubscribe.value));
      dispatch(loadRolesDropDown(selectedSubscribe.value));
      dispatch(loadSubscriptionBySubsId(selectedSubscribe.value));
    }
  }, [selectedSubscribe]);

  const [moduleData, setModuleData] = useState("");
  const [moduleId, setModuleId] = useState("");

  const navigateToModules = (data, moduleId) => {
    setModuleData(data);
    setModuleId(moduleId);
  };

  const [menusStatus, setMenusStatus] = useState([]);
  useEffect(() => {
    if (allSubsById)
      allSubsById.some((item) => {
        if (item.mainModuleID === 1 && item.enable === 1) {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: true },
          ]);
        } else {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: false },
          ]);
        }
        if (item.mainModuleID === 2 && item.enable === 1) {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: true },
          ]);
        } else {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: false },
          ]);
        }
        if (item.mainModuleID === 3 && item.enable === 1) {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: true },
          ]);
        } else {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: false },
          ]);
        }
        if (item.mainModuleID === 4 && item.enable === 1) {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: true },
          ]);
        } else {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: false },
          ]);
        }
        if (item.mainModuleID === 5 && item.enable === 1) {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: true },
          ]);
        } else {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: false },
          ]);
        }
        if (item.mainModuleID === 6 && item.enable === 1) {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: true },
          ]);
        } else {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: false },
          ]);
        }
        if (item.mainModuleID === 7 && item.enable === 1) {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: true },
          ]);
        } else {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: false },
          ]);
        }
        if (item.mainModuleID === 8 && item.enable === 1) {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: true },
          ]);
        } else {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: false },
          ]);
        }
        if (item.mainModuleID === 9 && item.enable === 1) {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: true },
          ]);
        } else {
          setMenusStatus([
            ...menusStatus,
            { id: item.mainModuleID, status: false },
          ]);
        }
      });
  }, [allSubsById]);

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  const [matrixValue, setMatrixValue] = useState(2);
  const [notifValue, setNotifValue] = useState([]);

  useEffect(() => {
    if (selectedSubscribe.value && selectedRole.value) {
      dispatch(
        loadSignupSubscriptionData(selectedSubscribe.value, selectedRole.value)
      );
    }
    if (distanceMatrix) {
      let notifArray = [];
      setMatrixValue(distanceMatrix.distanceMatrix);
      if (distanceMatrix.allowEmail === 1) {
        notifArray.push("AllowEmail");
      }
      if (distanceMatrix.allowWhatsApp === 1) {
        notifArray.push("AllowWhatsApp");
      }
      if (distanceMatrix.allowOTP === 1) {
        notifArray.push("AllowOTP");
      }
      if (distanceMatrix.smsNoticification === 1) {
        notifArray.push("SMSNoticification");
      }
      setNotifValue(notifArray);
    }
  }, [dispatch, selectedSubscribe, distanceMatrix, selectedRole.value]);

  const handleInputChange = (i, status, name) => {
    let list = [...menuItems];
    let statusChange;
    if (status === 1) {
      statusChange = 0;
    } else if (status === 0) {
      statusChange = 1;
    }
    list[i].items.enable = statusChange;
    let attMenu = [...submenuAtt];
    let clmMenu = [...submenuClm];
    let stgMenu = [...submenuStg];
    let cntrMenu = [...submenuCtnr];
    if (name === "Attendance") {
      if (statusChange === 1) {
        attMenu.map((item) => {
          item.enable = 1;
        });
      } else {
        attMenu.map((item) => {
          item.enable = 0;
        });
      }
    }
    if (name === "Claims") {
      if (statusChange === 1) {
        clmMenu.map((item) => {
          item.enable = 1;
        });
      } else {
        clmMenu.map((item) => {
          item.enable = 0;
        });
      }
    }
    if (name === "Settings") {
      if (statusChange === 1) {
        stgMenu.map((item) => {
          item.enable = 1;
        });
      } else {
        stgMenu.map((item) => {
          item.enable = 0;
        });
      }
    }
    if (name === "Connector") {
      if (statusChange === 1) {
        cntrMenu.map((item) => {
          item.enable = 1;
        });
      } else {
        cntrMenu.map((item) => {
          item.enable = 0;
        });
      }
    }
    setMenuItems(list);
  };

  const subMenuInputChange = (i, status, parent) => {
    let menulist = [...menuItems];
    let statusChange;
    if (status === 1) {
      statusChange = 0;
    } else if (status === 0) {
      statusChange = 1;
    }
    if (parent === "Attendance") {
      let list = [...submenuAtt];

      list[i].enable = statusChange;
      setSubmenuAtt(list);
      let isFound = list.some((element) => {
        if (element.enable === 1) {
          return true;
        }
        return false;
      });

      if (isFound) {
        menulist.map((item) => {
          if (item.items.moduleName === parent && item.items.enable === 0) {
            item.items.enable = 1;
          }
        });
      } else {
        menulist.map((item) => {
          if (item.items.moduleName === parent && item.items.enable === 1) {
            item.items.enable = 0;
          }
        });
      }
    } else if (parent === "Claims") {
      let list = [...submenuClm];
      list[i].enable = statusChange;
      setSubmenuClm(list);
      let isFound = list.some((element) => {
        if (element.enable === 1) {
          return true;
        }
        return false;
      });

      if (isFound) {
        menulist.map((item) => {
          if (item.items.moduleName === parent && item.items.enable === 0) {
            item.items.enable = 1;
          }
        });
      } else {
        menulist.map((item) => {
          if (item.items.moduleName === parent && item.items.enable === 1) {
            item.items.enable = 0;
          }
        });
      }
    } else if (parent === "Settings") {
      let list = [...submenuStg];
      list[i].enable = statusChange;
      setSubmenuStg(list);
      let isFound = list.some((element) => {
        if (element.enable === 1) {
          return true;
        }
        return false;
      });

      if (isFound) {
        menulist.map((item) => {
          if (item.items.moduleName === parent && item.items.enable === 0) {
            item.items.enable = 1;
          }
        });
      } else {
        menulist.map((item) => {
          if (item.items.moduleName === parent && item.items.enable === 1) {
            item.items.enable = 0;
          }
        });
      }
    } else if (parent === "Connector") {
      let list = [...submenuCtnr];
      list[i].enable = statusChange;
      setSubmenuStg(list);
      let isFound = list.some((element) => {
        if (element.enable === 1) {
          return true;
        }
        return false;
      });

      if (isFound) {
        menulist.map((item) => {
          if (item.items.moduleName === parent && item.items.enable === 0) {
            item.items.enable = 1;
          }
        });
      } else {
        menulist.map((item) => {
          if (item.items.moduleName === parent && item.items.enable === 1) {
            item.items.enable = 0;
          }
        });
      }
    }
    setMenuItems(menulist);
  };

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [mainMenuList, setMainMenuList] = useState([]);
  useEffect(() => {
    if (menuItems.length !== 0 && signUpSubscriptionData.lenght !== 0) {
      let mainMenu = [];
      signUpSubscriptionData.filter((element) => {
        menuItems.map((item) => {
          if (item.items.moduleName === element.moduleName) {
            mainMenu.push(element);
          }
        });
      });
      setMainMenuList(mainMenu);
    }
  }, [menuItems, signUpSubscriptionData]);

  let submitMenu = [
    ...mainMenuList,
    ...submenuAtt,
    ...submenuClm,
    ...submenuStg,
  ];
  const sortSubmitData = [...submitMenu].sort(
    (a, b) => a.moduleID - b.moduleID
  );

  const SaveModules = (e) => {
    e.preventDefault();
    var postData = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(sortSubmitData),
    };
    dispatch(loadPostMenuItems(postData, closeAlertModel));
  };

  const closeAlertModel = () => {
    navigate("/login");
  };

  const handleMainManuInputChange = (enableStatus, mainModuleID) => {
    if (enableStatus === 0) {
      let updatedMenuItems = menuItems.map((menuItem) => {
        if (menuItem.items.mainModuleID === mainModuleID) {
          menuItem.items.enable = 1;

          if (menuItem.subItem) {
            menuItem.subItem.map((subMenu) => {
              subMenu.enable = 1;
            });
          }
        }

        return menuItem;
      });

      setMenuItems(updatedMenuItems);
    } else {
      let updatedMenuItems = menuItems.map((menuItem) => {
        if (menuItem.items.mainModuleID === mainModuleID) {
          menuItem.items.enable = 0;

          if (menuItem.subItem) {
            menuItem.subItem.map((subMenu) => {
              subMenu.enable = 0;
            });
          }
        }

        return menuItem;
      });

      setMenuItems(updatedMenuItems);
    }
  };

  const [mainModuleDropdowns, setMainModuleDropdowns] = useState({});

  const handleOpen = (mainModuleID) => {
    setMainModuleDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [mainModuleID]: !prevDropdowns[mainModuleID],
    }));
  };

  return (
    <div id="main-page">
      {!moduleData ? (
        <div className="page-wrapper">
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={7} className="cat-col">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <h6 className="page-header-text">
                      Choose Your Desired Module
                    </h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <label>
                        Subscription Id : <b>{resPassData.subscriptionId}</b>{" "}
                      </label>
                      &nbsp;&nbsp;&nbsp;
                      <label>
                        Role : <b>{resPassData?.desingation?.role?.roleName}</b>{" "}
                      </label>
                      {/* <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={userList}
                          placeholder="Select Subscription Id"
                          getOptionValue={getOptionValue}
                          value={selectedSubscribe.label && selectedSubscribe}
                          // isDisabled
                          formatOptionLabel={formatOptionLabel}
                          onChange={setFilterUserId}
                          isSearchable={true}
                        /> */}
                      {/* <div
                        className="page-top-bar-dropdowns"
                        style={{ position: "relative", zIndex: "2" }}
                      >
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={rolesDropdown}
                          placeholder="Select Roles"
                          // isDisabled
                          value={selectedRole.value && selectedRole}
                          // onInputChange={userList.label}
                          onChange={setFilterRoleId}
                          isSearchable={true}
                          // isDisabled={!selectedSubscribe.value}
                        />
                      </div> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              {selectedSubscribe.value && selectedRole.value ? (
                <div>
                  <div>
                    <div className="signup-subscription-module">
                      {mainModules &&
                        mainModuleList
                          .filter((modules) => modules.pMenuCount !== 0)
                          .map((modules, i) => (
                            <div className="signup-subscription-main-module">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="signup-sbscription-img">
                                  <Card.Img
                                    className="signup-module-card-img"
                                    variant="top"
                                    src={modules.mainModuleIcon}
                                  />
                                </div>
                                <div className="signup-subscription-title">
                                  {modules.mainModuleName}
                                </div>
                                <div style={{ padding: "10px" }}>
                                  <i className="fa fa-desktop sb-menu-count-icon"></i>
                                  &nbsp;&nbsp;{modules.aMenuCount}/
                                  {modules.pMenuCount}
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip">
                                      Click here to select all modules and
                                      submodules
                                    </Tooltip>
                                  }
                                >
                                  <div className="signup-subscription-main-module-toggle-btn-div">
                                    <div className="subacribe-toggle w-100">
                                      <Form.Group className="facility-details">
                                        <div
                                          className={`checkbox ${
                                            modules.enableStatus === 1
                                              ? "checkbox--on"
                                              : ""
                                          }`}
                                          name="status"
                                          onClick={(e) =>
                                            handleMainManuInputChange(
                                              modules.enableStatus,
                                              modules.mainModuleID
                                            )
                                          }
                                        >
                                          <div className="checkbox__ball"></div>
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </OverlayTrigger>
                                <div className="signup-sub-dropdown-icon">
                                  <i
                                    className={`fa fa-chevron-circle-${
                                      mainModuleDropdowns[modules.mainModuleID]
                                        ? "down"
                                        : "right"
                                    }`}
                                    onClick={() =>
                                      handleOpen(modules.mainModuleID)
                                    }
                                  ></i>
                                </div>
                              </div>
                              <hr />
                              {mainModuleDropdowns[modules.mainModuleID] &&
                                menuItems.map((menuItem, index) => (
                                  <React.Fragment key={index}>
                                    {modules.mainModuleID ===
                                      menuItem?.items.mainModuleID && (
                                      <div className="signup-subscription-menu-container-first-inner-div">
                                        <div className="menu-item-div">
                                          <div className="signup-subscription-menu-container-second-inner-div">
                                            <div className="signup-subscription-menus">
                                              {menuItem?.items?.moduleName}
                                            </div>
                                            <div className="signup-subscription-toggle-btn-div">
                                              <div className="subacribe-toggle w-100">
                                                <Form.Group className="facility-details">
                                                  <div
                                                    className={`checkbox ${
                                                      menuItem?.items
                                                        ?.enable === 1
                                                        ? "checkbox--on"
                                                        : ""
                                                    }`}
                                                    name="status"
                                                    onClick={(e) =>
                                                      handleInputChange(
                                                        index,
                                                        menuItem?.items?.enable,
                                                        menuItem?.items
                                                          ?.moduleName
                                                      )
                                                    }
                                                  >
                                                    <div className="checkbox__ball"></div>
                                                  </div>
                                                </Form.Group>
                                              </div>
                                            </div>
                                          </div>
                                          {menuItem.subItem.length !== 0 && (
                                            <React.Fragment>
                                              <div className="signup-subscription-menu-container-sub-menu-div">
                                                {menuItem.subItem.map(
                                                  (subMenu, subIndex) => (
                                                    <div className="signup-subscription-menu-container-sub-menu-first-inner-div">
                                                      <div className="signup-subscription-sub-menus">
                                                        <span
                                                          style={{
                                                            padding: "10px",
                                                          }}
                                                        >
                                                          <img
                                                            src="../images/turn-right.png"
                                                            alt=""
                                                          />
                                                        </span>
                                                        {subMenu.moduleName}
                                                      </div>
                                                      <div
                                                        id="submenu-section-div"
                                                        className="signup-subscription-toggle-btn-div"
                                                      >
                                                        <div className="subacribe-toggle w-100">
                                                          <Form.Group className="facility-details">
                                                            <div
                                                              className={`checkbox ${
                                                                subMenu?.enable ===
                                                                1
                                                                  ? "checkbox--on"
                                                                  : ""
                                                              }`}
                                                              name="status"
                                                              onClick={(e) =>
                                                                subMenuInputChange(
                                                                  subIndex,
                                                                  subMenu?.enable,
                                                                  menuItem
                                                                    ?.items
                                                                    ?.moduleName
                                                                )
                                                              }
                                                            >
                                                              <div className="checkbox__ball"></div>
                                                            </div>
                                                          </Form.Group>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </React.Fragment>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                ))}
                            </div>
                          ))}
                    </div>

                    <div className="signup-module-btn-div" align="center">
                      <div className="user-save-button">
                        <SBSaveUpdateBtn
                          btnName="Save"
                          onClickEffect={SaveModules}
                        />
                      </div>
                      <div className="user-save-button">
                        <SbCancelBtn btnName="Reset" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Row className="sb-subscription-module">
                    <Col md={12}>
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="sb-product-details-div default-img">
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="../../../images/DefaultImages/Subscription.svg"
                              alt=""
                            />
                          </div>
                          <br />
                          <div className="d-flex align-items-center justify-content-center">
                            <p>Please Select Role to Get list of Modules</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
          {/* </div> */}
        </div>
      ) : (
        <>
          {/* {moduleData && (
            <SignupMeetingManagement
              moduleId={moduleId}
              selectedSubscribe={selectedSubscribe}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              setModuleData={setModuleData}
              moduleData={moduleData}
            />
          )} */}
          {/* {moduleData === "Project" &&
                        <ProjectManagement />} */}
        </>
      )}
      <Footer />
      {/* page content end */}
    </div>
  );
}

export default SignupSubscriberPage;
