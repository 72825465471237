import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { SkeletonClients } from "../Skeleton Loading/SkeletonClients";

const CustomeFieldTable = (props) => {
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: "100%", overflow: "hidden" }} className="table-main-div">
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table table-responsive" className="client-table">
                        <TableHead className="custom-table-header">
                            <TableRow>
                                {props.getSingleCustomFieldsForCompany.map((header, i) => (
                                    <TableCell key={i} align={header.align} style={{ minWidth: `${header.width}` }}>
                                        {header.fieldName}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.isLoading ? (
                                <>
                                    {props.getSingleCustomFieldsForCompany.map((header, i) => (
                                        <SkeletonClients key={i} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {props.getSingleCustomFieldsForCompany.length === 0 ? (
                                        <TableRow>
                                            <TableCell align="center" colSpan={props.getSingleCustomFieldsForCompany.length}>
                                                --- NO DATA FOUND ---
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <TableRow className="client-list-row-data">
                                            {props.getSingleCustomFieldsForCompany.map((header, j) => (
                                                <TableCell key={j}>
                                                    {getFieldValue(header.fieldName, props.getSingleCustomFieldsForCompany)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

// Function to get the value for a particular field name
const getFieldValue = (fieldName, data) => {
    const field = data.find(item => item.fieldName === fieldName);
    return field ? field.value : ''; // Return the value if field is found, otherwise return an empty string
}

export default CustomeFieldTable;
