import React from 'react'
import { Modal } from 'react-bootstrap';
import ActivityGoogleMap from './ActivityGoogleMap';
import { useEffect } from 'react';
import { useState } from 'react';

function ActivityListModal(props) {

    let activitiesByUser = props.activitiesByUser;

    const [locationArray, setLocationArray] = useState([])
    useEffect(() => {
        if(activitiesByUser.length !== 0){
            let latLngArray = []
            activitiesByUser.map(activ => {
                latLngArray.push({lat: parseFloat(activ.cordLoc.latitude), lng: parseFloat(activ.cordLoc.longitude)})
            })
            setLocationArray(latLngArray)
        }
    }, [activitiesByUser])
    console.log(locationArray, "activitiesByUser")

    const closeAddModal = () => {
        props.handleClose()
    }

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model activiy-direction-modal"
      >
        <div className="add-edit-basefare-heading">
          <h6 className="add-edit-basefare-head">ADD BASE FARE</h6>
          <i className="fa fa-times" onClick={closeAddModal} />
        </div>
        <ActivityGoogleMap locationArray={locationArray} />
        </Modal>
    </div>
  )
}

export default ActivityListModal;
