import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../styles/AlertModel.scss";

function AlertModel(props) {
  return (
    <div>
      <div>
        <Modal
          show={props.showAlert}
          // onHide={props.closeAlertModel}
          className="alert-model"
        >
          <div className="alert-heading">
            {props.alertMessage.alertType === "success" ? (
              <h6 className="success">
                <i className="fa fa-check-square" />
                {props.alertMessage.message}
              </h6>
            ) : (
              <h6 className="fails">
                <i className="fa fa-exclamation-triangle" />
                {props.alertMessage.message}
              </h6>
            )}
          </div>
          <div className="alert-yes-no">
            <div>
              <Button
                type="button"
                className="alert-btn no"
                onClick={props.closeAlertModel}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default AlertModel;
