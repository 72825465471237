import React, { useState, useEffect } from "react";
import "../../styles/Attendance.scss";
import profileimg from "../../images/profile-1.png";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { baseURL } from "../BaseUrl";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { SkeletonforClaimHead } from "../Skeleton Loading/SkeletonPendingClaim";
import SkeletonRejected from "../Skeleton Loading/SkeletonRejected";
import { Col, Row } from "react-bootstrap";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { travelizeAuth } from "../HeaderAuthentication";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

function RejectedClaimAdmin(props) {
  const [fromDate, setFromDate] = useState(new Date(props.fromDate));
  const [toDate, setToDate] = useState(new Date(props.toDate));
  const [rejectedClaimDeatils, setRejectedClaimDeatils] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  useEffect(() => {
    {
      fromDate && toDate && fetchRejectedClaimData();
    }
  }, [fromDate, toDate, PageSize, currentPage]);

  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS START
  const fetchRejectedClaimData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/Claim/Get?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format("DD/MM/YYYY")}&UserID=${
        props.claimUserId
      }&StatusIDList=44,47&SearchStatusType=Admin&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("REJECTED-CLAIM-DATA::", res.result);
        if (res.success) {
          setRejectedClaimDeatils(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setRejectedClaimDeatils("");
          setIsLoading(false);
          setLoading(false);
        }
      });
  };
  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFromDate(new Date(props.fromDate));
    setToDate(new Date(props.toDate));
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/ClaimDetailExcelExport?UserID=${
        props.claimUserId
      }&StatusIDList=44,47&SearchStatusType=Claim&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Rejected-ClaimData.xlsx",
      setDownloadIsLoading
    );
  };

  return (
    <div id="main-page">
      {props.rejectedClaim ? (
        <>
          {/* MAIN BODY START */}
          <div>
            {/* PAGE CONTENT START */}

            {/* USER TABLE START */}
            <div className="claims-table">
              <Row className="page-filter-section">
                <Col md={4} sm={4} xs={12} className="cat-col">
                  <div className="page-header-text-div">
                    <div
                      className="back-btn"
                      onClick={props.goBackToClaimfromRejectedPage}
                    >
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">
                      Rejected Claim by Admin
                    </h6>
                  </div>
                </Col>
                <Col md={8} sm={8} xs={12} className="cat-col">
                  <div className="filter-row-input-boxes-end">
                    <div className="from-to-date-select">
                      <div className="input-group-text" id="from-label">
                        From - To
                      </div>
                      <div
                        className="date-picker-with-icon"
                        style={{ minWidth: "180px" }}
                      >
                        <DatePicker
                          selected={fromDate}
                          className="select from-date date-filter"
                          id="date-range-picker"
                          dateFormat="dd/MM/yyyy"
                          selectsRange
                          startDate={fromDate}
                          endDate={toDate}
                          onChange={(date) => setSelectSearchDrop(date)}
                          maxDate={new Date()}
                        />
                        <div>
                          <i
                            className="fa fa-calendar-o calander-icon"
                            style={{ right: "6px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <MiClearButton
                      onClickEffect={clearSerachBySelect}
                      btnName="Clear"
                    />
                  </div>
                </Col>
              </Row>

              {isLoading ? (
                <SkeletonforClaimHead />
              ) : (
                <div className="pendingapproval-records">
                  <div className="claim-user-details-img">
                    <img
                      className="pendingapproval-user-imgage"
                      src={profileimg}
                      alt="User Image"
                    />
                    <div className="pendingapproval-username-details">
                      <span>
                        <b>User Name :</b> &nbsp;{" "}
                        {rejectedClaimDeatils[0]?.fullName}
                      </span>
                      <span>
                        <b>Email ID :</b> &nbsp;
                        {rejectedClaimDeatils[0]?.email}
                      </span>
                      <span>
                        <b>Contact No.:</b> &nbsp;{" "}
                        {rejectedClaimDeatils[0]?.countryCode}{" "}
                        {rejectedClaimDeatils[0]?.contact}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="table-responsive rejected-claim-scroll"
                onScroll={(e) =>
                  handleScroll(
                    e,
                    rejectedClaimDeatils,
                    totalData,
                    setLoading,
                    setPageSize,
                    PageSize
                  )
                }
              >
                <table className="table attendance-list-table">
                  <thead className="thead-light attendance-list-thead">
                    <tr>
                      <th>Sl.No.</th>
                      <th>ClaimedDate</th>
                      <th>CheckIn</th>
                      <th>CheckOut</th>
                      <th>HrsOnField</th>
                      <th>KMs</th>
                      <th>Visits</th>
                      <th>Amount</th>
                      <th>Rejected </th>
                      <th>RejectedDate</th>
                      <th>Status</th>
                      <th>RejectedBy</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <>
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                      </>
                    ) : rejectedClaimDeatils !== null &&
                      rejectedClaimDeatils.length > 0 ? (
                      rejectedClaimDeatils.map((data, i) => (
                        <tr className="attendance-list-row-data" key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {/* {moment(data?.claimDate).format("DD/MM/YYYY")} */}
                            {data?.claimDate}
                          </td>
                          <td>{data?.checkInTime ?? "NA"}</td>
                          <td>{data?.checkOutTime ?? "NA"}</td>
                          <td>{data?.hrsOnField ?? "NA"}</td>
                          <td>{data?.kMsTravelled}</td>
                          <td>{data?.noOfVisit}</td>
                          <td>{data?.totalAmount}</td>
                          <td>
                            {data?.byAdmin?.rejectedTotalClaimAmount ?? 0}
                          </td>
                          <td>
                            {/* {moment(data?.byAdmin?.date).format("DD/MM/YYYY")} */}
                            {data?.byAdmin?.date}
                          </td>
                          <td>{data?.byAdmin?.status}</td>
                          <td>{data?.byAdmin?.fullName}</td>
                        </tr>
                      ))
                    ) : (
                      <div>
                        <span className="text-center" style={{ width: "100%" }}>
                          --- NO DATA FOUND ---
                        </span>
                      </div>
                    )}
                    {loading && <OnScrollLoading />}
                  </tbody>
                </table>
              </div>
            </div>
            {/* USER TABLE END */}

            <div className="download-and-pagination">
              <SbDownloadBtn onClickEffect={exportExcelClaim} />
            </div>

            {/* PAGE CONTENT END*/}

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        ""
      )}
      {downloadIsLoading && (
        <>
          <DownloadLoading />
        </>
      )}
    </div>
  );
}

export default RejectedClaimAdmin;
