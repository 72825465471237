import React, { useState, useEffect, useMemo } from "react";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { loadLeaveTypeDropdown, loadUserDropdown } from "../../redux/action";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import * as swal from "../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { travelizeAuth } from "../HeaderAuthentication";
import AddEditFormComponent from "../ReUseComponents/AddEditFormComponent";

export default function AddLeaves(props) {
  let dispatch = useDispatch();

  const currentYear = new Date().getFullYear();

  const { userDropdown } = useSelector((state) => state.data);
  const { leaveTypeDropdown } = useSelector((state) => state.data);

  const [filterUserId, setFilterUserId] = useState("");
  const [leaveTypeID, setLeaveTypeID] = useState(0);
  const [leaveYear, setLeaveYear] = useState(currentYear);
  const [leaveAllowed, setLeaveAllowed] = useState(null);
  const [leaveTaken, setLeaveTaken] = useState(null);
  const [leaveRemain, setLeaveRemain] = useState(null);
  const [enableStatus, setEnableStatus] = useState(1);
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useEffect(() => {
    dispatch(loadUserDropdown());
    dispatch(loadLeaveTypeDropdown());
  }, []);

  useMemo(() => {
    setLeaveRemain(leaveAllowed - leaveTaken);
  }, [leaveAllowed, leaveTaken]);

  useEffect(() => {
    {
      props.leaveBalaneID && fetchLeaveBalanceDetails();
    }
  }, [props.leaveBalaneID]);

  const fetchLeaveBalanceDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/LeaveBalance/Get/${props.leaveBalaneID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setFilterUserId(res.result?.userID);
          setEnableStatus(res.result?.status);
          setLeaveYear(res.result?.year);
          setLeaveAllowed(+res.result?.allowed);
          setLeaveTaken(+res.result?.taken);
          setLeaveTypeID(+res.result?.leaveTypeID);
        } else {
          setFilterUserId("");
          setEnableStatus("");
          setLeaveYear("");
          setLeaveAllowed("");
          setLeaveTaken("");
          setLeaveTypeID(null);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  const [userVal, setUserVal] = useState("");
  const [leaveTypeVal, setLeaveTypeVal] = useState("");

  useEffect(() => {
    if (filterUserId !== "" && filterUserId !== null) {
      userDropdown.map((users) => {
        if (filterUserId == users.value) {
          setUserVal(users);
        }
      });
    }
    if (leaveTypeID !== 0 && leaveTypeID !== null) {
      leaveTypeDropdown.map((leaves) => {
        if (leaveTypeID == leaves.value) {
          setLeaveTypeVal(leaves);
        }
      });
    }
  }, [filterUserId, userDropdown, leaveTypeDropdown, leaveTypeID]);

  // ADD LEAVES START
  const addLeaveBalance = (e) => {
    e.preventDefault();
    if (
      filterUserId !== "" &&
      leaveYear !== null &&
      leaveYear !== "" &&
      leaveTypeID !== 0 &&
      leaveAllowed !== 0 &&
      leaveTaken !== null &&
      leaveTaken !== ""
    ) {
      const leavesData = {
        leaveTypeID: leaveTypeID,
        userID: filterUserId,
        status: enableStatus,
        year: leaveYear,
        allowed: leaveAllowed,
        taken: leaveTaken,
        remain: leaveAllowed - leaveTaken,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(leavesData),
      };
      fetch(`${baseURL}/api/LeaveBalance/AddLeaveBalance`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };
  // ADD LEAVES END

  // UPDATE LEAVES START
  const updateLeaves = (e) => {
    e.preventDefault();
    if (
      filterUserId !== "" &&
      leaveYear !== null &&
      leaveYear !== "" &&
      leaveTypeID !== 0 &&
      leaveAllowed !== 0 &&
      leaveTaken !== null &&
      leaveTaken !== ""
    ) {
      const leavesData = {
        leaveBalanceID: props.leaveBalaneID,
        leaveTypeID: leaveTypeID,
        userID: filterUserId,
        status: enableStatus,
        year: leaveYear,
        allowed: leaveAllowed,
        taken: leaveTaken,
        remain: leaveAllowed - leaveTaken,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(leavesData),
      };
      fetch(`${baseURL}/api/LeaveBalance/UpdateLeaveBalance`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };
  // UPDATE LEAVES END

  const closeModel = () => {
    props.handleClose();
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={props.leaveBalaneID ? "Edit Leaves" : "Add Leaves"}
        formSubTitle={
          props.leaveBalaneID
            ? "Edit the leave balance."
            : "Please add the leave balance for individual users."
        }
        sidebarItems={props.leaveBalaneID ? ["Edit Leaves"] : ["Add Leaves"]}
        childrens={
          <form>
            <div className="update-leave-balance">
              <div className="select-status">
                <div className="row">
                  <div className="col my-3">
                    <FloatingLabel
                      label="Select User"
                      className={`${
                        filterUserId
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        defaultValue={userDropdown[null]}
                        options={userDropdown}
                        placeholder="Select User"
                        value={userVal}
                        onInputChange={userDropdown.label}
                        onChange={(data) => setFilterUserId(data.value)}
                        isSearchable={true}
                        required
                      />
                    </FloatingLabel>
                    {fieldReq && filterUserId == "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {`${alertMessage.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col my-3">
                    <FloatingLabel
                      label="Year"
                      className={`${
                        leaveYear
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-meetpurpose-input"
                        id="exampleFormControlInput1"
                        placeholder="Year"
                        value={leaveYear}
                        onChange={(e) => setLeaveYear(+e.target.value)}
                        required
                      />
                    </FloatingLabel>
                    {fieldReq && leaveYear == "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {`${alertMessage.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col my-3">
                    <FloatingLabel
                      label="Leave Type"
                      className={`${
                        leaveTypeVal
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={leaveTypeDropdown}
                        placeholder="Leave Type"
                        value={leaveTypeVal}
                        onInputChange={leaveTypeDropdown.label}
                        onChange={(data) => setLeaveTypeID(data.value)}
                        isSearchable={true}
                        required
                      />
                    </FloatingLabel>
                    {fieldReq && leaveTypeID == "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {`${alertMessage.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col my-3">
                    <FloatingLabel
                      label="Allotted"
                      className={`${
                        leaveAllowed || leaveAllowed == 0
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-meetpurpose-input"
                        id="exampleFormControlInput1"
                        placeholder="Allotted"
                        value={leaveAllowed}
                        onChange={(e) => setLeaveAllowed(+e.target.value)}
                        required
                      />
                    </FloatingLabel>
                    {fieldReq && leaveAllowed == "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {`${alertMessage.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col my-3">
                    <FloatingLabel
                      label="Availed"
                      className={`${
                        leaveTaken || leaveTaken == 0
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-meetpurpose-input"
                        id="exampleFormControlInput1"
                        placeholder="Availed"
                        value={leaveTaken}
                        onChange={(e) => setLeaveTaken(+e.target.value)}
                        required
                      />
                    </FloatingLabel>
                    {fieldReq && (leaveTaken === "" || leaveTaken === null) ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {`${alertMessage.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col my-3">
                    <FloatingLabel
                      label="Available"
                      className={`${
                        leaveRemain || leaveRemain == 0
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        className="form-control form add-meetpurpose-input"
                        id="exampleFormControlInput1"
                        placeholder="Available"
                        value={leaveRemain}
                        // value={leaveAllowed - leaveTaken}
                        // onChange={(e) => setLeaveRemain(+e.target.value)}
                        required
                        readOnly
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
              <div className="add-page-save-cancel">
                <SBSaveUpdateBtn
                  onClickEffect={
                    props.leaveBalaneID ? updateLeaves : addLeaveBalance
                  }
                  btnName={props.leaveBalaneID ? "Update" : "Save"}
                />
                <SbCancelBtn
                  btnName="Cancel"
                  onClickEffect={props.handleClose}
                />
              </div>
            </div>
          </form>
        }
      />
    </div>
  );
}
