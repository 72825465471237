import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import { useReactToPrint } from "react-to-print";
import "../../styles/Tracker.scss";
import profileimg from "../../images/profile-1.png";
import meetrouteimg from "../../images/destination.png";
import NoDataImg from "../../images/no-data-found.png";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoogleMapComp from "../GoogleMapComp";
import Select from "react-select";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Col, Row, Form } from "react-bootstrap";
import { dateFormatLocal } from "../FormattedAmount";
import GoogleAccessModal from "../GoogleAccessModal";
import OnScrollLoading from "../OnScrollLoading";
import OpenStreenMap from "../OpenStreenMap";
import { useDispatch, useSelector } from "react-redux";
import { loadMeetingCountData, loadUserDropdown } from "../../redux/action";
import { travelizeAuth } from "../HeaderAuthentication";

function TrackMeetings(props) {
  let dispatch = useDispatch();
  const { meetingCountsData } = useSelector((state) => state.data);
  const { userDropdown } = useSelector((state) => state.data);

  let distanceMatrix = sessionStorage.getItem("distanceMatrixID");
  const [mapView, setMapView] = useState(false);
  const [sliderValue, setSliderValue] = useState(15);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (distanceMatrix == 1) {
      setMapView(true);
    } else {
      setMapView(false);
    }
  }, [distanceMatrix]);

  const [accessModal, setAccessModal] = useState(false);
  const [showRouteMoving, setShowRouteMoving] = useState(false);

  const giveAccess = () => {
    setAccessModal(true);
  };

  const closeAccess = () => {
    setAccessModal(false);
  };

  const mapAccesss = () => {
    setAccessModal(false);
    setMapView(true);
  };

  // const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState(props.userID);
  const [date, setDate] = useState(new Date(props.meetingdetailsDate));
  // const [time, setTime] = useState("12:00");
  // const [trackMeetingDetails, setTrackMeetingDetails] =
  //   useState(meetingCountsData);
  // const [trackMeetingDetails, setTrackMeetingDetails] = useState([]);
  const [checkInLocation, setCheckInLocation] = useState({
    clientName: "",
    lat: null,
    lng: null,
    location: null,
    time: null,
  });
  const [checkOutLocation, setCheckOutLocation] = useState({
    clientName: "",
    lat: null,
    lng: null,
    location: null,
    time: null,
  });

  const [meetingCordinates, setMeetingCordinates] = useState([]);

  const [trackClientCenLatLng, setTrackClientCenLatLng] = useState({
    lat: null,
    lng: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(
      loadMeetingCountData(
        moment(date).format("DD/MM/YYYY"),
        filterUserId,
        setIsLoading
      )
    );
  }, [filterUserId, date]);

  useEffect(() => {
    if (meetingCountsData) {
      let meetingsCoordinate = [];
      for (let i = 0; i < meetingCountsData?.mapCoordinate?.length; i++) {
        if (
          meetingCountsData.mapCoordinate[i].meetingType === "CheckIn" ||
          meetingCountsData.mapCoordinate[i].meetingType === "CheckIn-CheckOut"
        ) {
          let checkInLat =
            meetingCountsData.mapCoordinate[i].checkInLoc.latitude;
          let checkInLng =
            meetingCountsData.mapCoordinate[i].checkInLoc.longitude;
          let checkOutLat =
            meetingCountsData.mapCoordinate[i].checkOutLoc.latitude;
          let checkOutLng =
            meetingCountsData.mapCoordinate[i].checkOutLoc.longitude;
          if (
            checkInLat !== "0.0" &&
            checkInLng !== "0.0" &&
            checkInLat !== null &&
            checkInLng !== null &&
            checkOutLat !== "0.0" &&
            checkOutLng !== "0.0" &&
            checkOutLat !== null &&
            checkOutLng !== null
          ) {
            setCheckInLocation({
              clientName: "Check-In",
              lat: +meetingCountsData.mapCoordinate[i].checkInLoc.latitude,
              lng: +meetingCountsData.mapCoordinate[i].checkInLoc.longitude,
              location: meetingCountsData.mapCoordinate[i].checkInLoc.location,
              time: meetingCountsData.mapCoordinate[i].checkinTime,
            });
            setCheckOutLocation({
              clientName: "Check-Out",
              lat: +meetingCountsData.mapCoordinate[i].checkOutLoc.latitude,
              lng: +meetingCountsData.mapCoordinate[i].checkOutLoc.longitude,
              location: meetingCountsData.mapCoordinate[i].checkOutLoc.location,
              time: meetingCountsData.mapCoordinate[i].checkoutTime,
            });
          } else if (
            checkInLat !== "0.0" &&
            checkInLng !== "0.0" &&
            checkInLat !== null &&
            checkInLng !== null
          ) {
            setCheckInLocation({
              clientName: "Check-In",
              lat: +meetingCountsData.mapCoordinate[i].checkInLoc.latitude,
              lng: +meetingCountsData.mapCoordinate[i].checkInLoc.longitude,
              location: meetingCountsData.mapCoordinate[i].checkInLoc.location,
              time: meetingCountsData.mapCoordinate[i].checkinTime,
            });
          }

          setTrackClientCenLatLng({
            lat: +meetingCountsData.mapCoordinate[i].checkInLoc.latitude,
            lng: +meetingCountsData.mapCoordinate[i].checkInLoc.longitude,
          });
        } else if (meetingCountsData.mapCoordinate[i].meetingID != null) {
          meetingsCoordinate.push({
            lat: +meetingCountsData.mapCoordinate[i].clientLoc.latitude,
            lng: +meetingCountsData.mapCoordinate[i].clientLoc.longitude,
            location: meetingCountsData.mapCoordinate[i].clientLoc.location,
            clientName: meetingCountsData.mapCoordinate[i].clientName,
            meetingType: meetingCountsData.mapCoordinate[i].meetingType,
            meetingDate: meetingCountsData.mapCoordinate[i].date,
          });
        }
      }
      setMeetingCordinates(meetingsCoordinate);
    }
  }, [filterUserId, date, meetingCountsData]);

  // FUNTION DISTANCE BETWEEN 2 COORDINATES START

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180; // Difference in latitude in radians
    const dLon = ((lon2 - lon1) * Math.PI) / 180; // Difference in longitude in radians
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  }

  // FUNTION DISTANCE BETWEEN 2 COORDINATES END

  /// MEETINGS ON MAP  (TEMPORARY) START

  // useEffect(() => {
  //   if (meetingDetailsData.length > 0) {
  //     fetchAllMeetingsCords(meetingDetailsData);
  //   }
  // }, [meetingDetailsData, filterUserId, date]);

  // const fetchAllMeetingsCords = (meetingDetailsData) => {
  //   let meetCordsFinal = [];
  //   for (let meetCord of meetingDetailsData) {
  //     if (meetCord?.clientName !== "Check-Out") {
  //       meetCordsFinal.push({
  //         lat: +meetCord?.clientLoc?.latitude,
  //         lng: +meetCord?.clientLoc?.longitude,
  //         location: meetCord?.clientLoc?.location,
  //         clientName: meetCord?.clientName ?? "My Visit",
  //         dateTime: meetCord?.date,
  //       });
  //     }
  //   }
  //   setMeetingCordinates([...new Set(meetCordsFinal)]);
  //   setTrackClientCenLatLng({
  //     lat: +meetingDetailsData[0]?.clientLoc.latitude,
  //     lng: +meetingDetailsData[0]?.clientLoc.longitude,
  //   });
  // };
  /// MEETINGS ON MAP  (TEMPORARY) START

  // TEST POLYLINE PLOTTING START
  // useEffect(() => {
  //   getActivitiesByRange();
  // }, [sliderValue, date, filterUserId]);

  // const getActivitiesByRange = () => {
  //   let requestOption = {
  //     method: "GET",
  //     mode: "cors",
  //     headers: travelizeAuth,
  //   };
  //   fetch(
  //     `${baseURL}/api/Activity/GetActivities?Date=${moment(date).format(
  //       "DD/MM/YYYY"
  //     )}&userId=${filterUserId}&PageSize=1000&CurrentPage=1`,
  //     requestOption
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.success) {
  //         console.table(
  //           res.result.sort((a, b) => a.cordID - b.cordID).map((data) => data)
  //         );
  //         let polyCordsinmtr = [];
  //         for (let i = 0; i < res.result.length; i++) {
  //           let lat1, lon1, lat2, lon2;
  //           if (
  //             res.result[i].cordLoc.latitude &&
  //             res.result[i].cordLoc.longitude
  //           ) {
  //             lat1 = +res.result[i]?.cordLoc?.latitude;
  //             lon1 = +res.result[i]?.cordLoc?.longitude;
  //             lat2 = +res.result[i + 1]?.cordLoc?.latitude;
  //             lon2 = +res.result[i + 1]?.cordLoc?.longitude;
  //             const distance = calculateDistance(lat1, lon1, lat2, lon2);
  //             const distanceInMtr = Math.round(distance * 1000);
  //             // console.log(
  //             //   `Distance-Offline: ${Math.round(distance * 1000)} meters`
  //             // );

  //             // Remove Co-ordinates from Meter Range --->>
  //             if (distanceInMtr >= sliderValue) {
  //               polyCordsinmtr.push(res.result[i]);
  //             }
  //           }
  //         }
  //         setTrackMeetingDetails(polyCordsinmtr.reverse());
  //         // funcPolylineCords(res.result.reverse());
  //         setIsLoading(false);
  //       } else {
  //         setTrackMeetingDetails("");
  //         setIsLoading(false);
  //       }
  //     });
  // };

  // TEST POLYLINE PLOTTING END

  // const funcPolylineCords = (trackMeetingDetails) => {
  //   if (trackMeetingDetails.length > 0) {
  //     let meetingCordinatesFilters = trackMeetingDetails.filter(
  //       (data) => data.meetingID != ""
  //     );
  //     let meetCordsFinal = [];
  //     for (let i = 0; i < meetingCordinatesFilters.length; i++) {
  //       meetCordsFinal.push({
  //         lat: +meetingCordinatesFilters[i]?.cordLoc?.latitude,
  //         lng: +meetingCordinatesFilters[i]?.cordLoc?.longitude,
  //         location: meetingCordinatesFilters[i]?.cordLoc?.location,
  //         clientName: meetingCordinatesFilters[i].clientName ?? "My Visit",
  //         dateTime: meetingCordinatesFilters[i].onlyDate,
  //       });
  //     }
  //     setMeetingCordinates([...new Set(meetCordsFinal)]);
  //     setCheckInLocation({
  //       lat: +trackMeetingDetails[0]?.cordLoc.latitude,
  //       lng: +trackMeetingDetails[0]?.cordLoc.longitude,
  //       location: trackMeetingDetails[0]?.cordLoc.location,
  //       time: trackMeetingDetails[0].onlyTime,
  //     });
  //     setCheckOutLocation({
  //       lat: +trackMeetingDetails[trackMeetingDetails.length - 1]?.cordLoc
  //         .latitude,
  //       lng: +trackMeetingDetails[trackMeetingDetails.length - 1]?.cordLoc
  //         .longitude,
  //       location:
  //         trackMeetingDetails[trackMeetingDetails.length - 1]?.cordLoc.location,
  //       time: trackMeetingDetails[trackMeetingDetails.length - 1].onlyTime,
  //     });
  //     setTrackClientCenLatLng({
  //       lat: +trackMeetingDetails[0]?.cordLoc.latitude,
  //       lng: +trackMeetingDetails[trackMeetingDetails.length - 1]?.cordLoc
  //         .longitude,
  //     });
  //   }
  // };

  // console.log("meetingCordinates:", meetingCordinates);

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setDate(new Date());
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {/* MAIN BODY START */}
      <div className="page-wrapper1">
        {/* PAGE CONTENT START */}
        {props.viewonmap ? (
          <div className={`main-body1_${props.toggleshift.style}`}>
            <div className="page-content1">
              {/* Filter and Header Section */}
              <div className="user-table">
                <Row className="search-row">
                  <Col md={3} className="cat-col">
                    <div className="page-header-text-div">
                      <div
                        className="back-btn"
                        onClick={props.goBacktomeetings}
                      >
                        <img
                          src="../../../../images/DefaultImages/back-btn-arrow.svg"
                          alt=""
                        />
                      </div>
                      <h6 className="page-header-text">TRACK MEETINGS</h6>
                    </div>
                  </Col>
                  <Col md={9} className="cat-col">
                    <div
                      className="search-bar-header hv-center"
                      style={{ float: "right" }}
                    >
                      <div className="page-top-bar-dropdowns page-top-Filters">
                        <div
                          className="header-filter-btn"
                          onClick={() => setFilter(!filter)}
                        >
                          <p className="card-head">
                            <i className="bi bi-sliders" />
                            &nbsp;&nbsp;Filters
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* {filter && (
                  <div className="page-filter-section">
                    <div style={{ width: "100%" }}>
                      <Row>
                        <Col md={12}>
                          <div className="clients-filter-input-boxs filter-box-div">
                            <div style={{ width: "100%", padding: "0px 5px" }}>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={userDropdown}
                                placeholder="Select User"
                                value={userDropdown.value}
                                onInputChange={userDropdown.label}
                                onChange={(data) => setFilterUserId(data.value)}
                                isSearchable={true}
                                // isClearable={true}
                              />
                            </div>
                            <div style={{ width: "100%", padding: "0px 5px" }}>
                              <div className="date-picker-with-icon">
                                <DatePicker
                                  selected={date}
                                  className="select single-date"
                                  dateFormat="dd/MM/yyyy"
                                  // selectsEnd
                                  // startDate={date}
                                  // endDate={toDate}
                                  // minDate={fromDate}
                                  onChange={(date) => setDate(date)}
                                  maxDate={new Date()}
                                />
                                <i className="fa fa-calendar-o calander-icon" />
                              </div>
                            </div>
                            <div style={{ width: "100%", padding: "0px 5px" }}>
                              <div className="hv-center">
                                <Form.Label className="m-1">
                                  Meter Range
                                </Form.Label>
                                <Form.Range
                                  value={sliderValue}
                                  name="meterRange"
                                  onChange={(e) =>
                                    setSliderValue(e.target.value)
                                  }
                                  className="custom-range"
                                  min={5}
                                  max={60}
                                  step={5}
                                />
                                <p className="m-1">{sliderValue}</p>
                              </div>
                            </div>
                            <div
                              style={{ width: "100%", padding: "0px 5px" }}
                              className="input-group from-to-date-select"
                            >
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )} */}
              </div>
              {/* Filter and Header Section */}

              {/* TRACKER TABLE START */}
              <div className="tracker-table">
                <div className="table-responsive tracker-meetings-tabel-scroll">
                  <table className="table trackers-list-table mb-0">
                    <thead className="thead-light trackers-list-thead">
                      <tr>
                        <th>NAME</th>
                        <th>DATE</th>
                        <th>NO. OF VISITS</th>
                        <th>ACTIONS</th>
                        {/* <th>PLAY/PAUSE</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <>
                          <OnScrollLoading />
                        </>
                      ) : meetingCountsData !== "" &&
                        meetingCountsData !== null ? (
                        <tr className="track-list-row-data">
                          <td>
                            <span className="track-list-name d-flex">
                              <img
                                className="table-data-track-imgages"
                                src={profileimg}
                                alt="Profile Image"
                              />
                              <div className="table-row-head-Text">
                                {/* {trackMeetingDetails[0]?.fullName} */}
                                {meetingCountsData?.userFullName}
                              </div>
                            </span>
                          </td>
                          <td>
                            {/* {meetingCordinates[0]?.dateTime} */}
                            {moment(
                              meetingCountsData?.mapCoordinate[0]?.date
                            ).format("DD MMM YYYY")}
                          </td>
                          {/* <td>{meetingCordinates?.length}</td> */}
                          <td>
                            {meetingCountsData?.directVisitCount +
                              meetingCountsData?.scheduledCount}
                          </td>

                          <td>
                            <span
                              className="td-print-column d-flex"
                              onClick={handlePrint}
                            >
                              <i className="bi bi-printer"></i>{" "}
                              &nbsp;&nbsp;Print
                            </span>
                          </td>
                          <td>
                            {/* <div className="route-play-pause-button cursor-pointer">
                              {!showRouteMoving ? (
                                <div
                                  onClick={() => setShowRouteMoving(true)}
                                  title="Play"
                                >
                                  <span className="material-symbols-outlined">
                                    play_circle
                                  </span>
                                </div>
                              ) : (
                                <div
                                  onClick={() => setShowRouteMoving(false)}
                                  title="Stop"
                                >
                                  <span className="material-symbols-outlined">
                                    stop_circle
                                  </span>
                                </div>
                              )}
                            </div> */}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="no-data-found">
                            ------ NO DATA FOUND ------
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* TRACKER TABLE END */}
              <div className="track-user-meeting-page m-1">
                {!isLoading &&
                meetingCordinates &&
                meetingCordinates.length > 0 &&
                trackClientCenLatLng.lat &&
                trackClientCenLatLng.lng ? (
                  <div className="row view-on-map" ref={componentRef}>
                    <div className="col-md-9">
                      {/* {mapView ? ( */}
                      <div className="google-map-box" ref={componentRef}>
                        {/* <GoogleMapComp
                            trackClientCenLatLng={trackClientCenLatLng}
                            pageType="TrackMeetings"
                            markerPositions={meetingCordinates}
                            checkInLocation={checkInLocation}
                            checkOutLocation={checkOutLocation}
                            trackMeetingDetails={trackMeetingDetails}
                            showRouteMoving={showRouteMoving}
                            setShowRouteMoving={setShowRouteMoving}
                          /> */}
                        <OpenStreenMap
                          centerLatLng={trackClientCenLatLng}
                          pageType="TrackMeetings"
                          markerPositions={meetingCordinates}
                          checkInLocation={checkInLocation}
                          checkOutLocation={checkOutLocation}
                        />
                      </div>
                      {/* ) : (
                      <div className="google-map-box p-2 empty-map">
                        <div className="overlay-div">
                          <div className="over-lay-text-div">
                            <h6 style={{ color: "#ffb900" }}>
                              This Feature is not available in your
                              Subscription...!
                            </h6>
                            <p className="mt-3">
                              If you still want to view the map{" "}
                              <span
                                className="complete-status status-badge"
                                style={{ cursor: "pointer" }}
                                onClick={giveAccess}
                              >
                                Click Here
                              </span>
                            </p>
                          </div>
                        </div>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.56659496218!2d77.46612702802196!3d12.95428023487456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1692190317657!5m2!1sen!2sin"
                          width="100%"
                          height="450"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                      )} */}
                    </div>

                    {accessModal && (
                      <GoogleAccessModal
                        accessModal={accessModal}
                        closeAccess={closeAccess}
                        mapAccesss={mapAccesss}
                      />
                    )}

                    <div className="col-md-3 trak-user-meeting-details-table track-user-meet-scroll">
                      {meetingCordinates != "" && meetingCordinates != null
                        ? meetingCordinates.map((data, i) => (
                            <div className="card view-on-map-card" key={i}>
                              <div className="card-header ">
                                <div className="meeting-detail-name dflex-j-start">
                                  <div>
                                    {/* <i className="bi bi-caret-right-fill" /> */}
                                    <img
                                      className="table-data-meeting-track-user-img"
                                      src={profileimg}
                                      alt="profile image"
                                    />
                                  </div>
                                  <div className="meeting-track-user-detail">
                                    <h6 className="color-green">
                                      <b>{data?.clientName}</b>
                                    </h6>
                                    {/* <p>
                                      {data?.meetingType} -{i + 1}
                                    </p> */}
                                  </div>
                                  <img
                                    className="meet-route-img"
                                    src={meetrouteimg}
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div className="card-body meeting-full-address">
                                <p>
                                  <b>Type :</b> &nbsp; {data?.meetingType}
                                </p>
                                <p>
                                  <b>Date-Time :</b> &nbsp;{" "}
                                  {/* {moment(
                                    data?.dateTime,
                                    "hh:mm:ss:SSS A"
                                  ).format("hh:mm A")} */}
                                  {moment(data?.meetingDate).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </p>
                                <p>
                                  <b>Location :</b>
                                  &nbsp;
                                  {data?.location}
                                </p>
                              </div>
                            </div>
                          ))
                        : "--- NO DATA FOUND ---"}
                    </div>
                  </div>
                ) : (
                  <div className="hv-center">
                    <img
                      src={NoDataImg}
                      alt="no-data-img"
                      className="mCS_img_loaded"
                    />
                  </div>
                )}
              </div>
              {/* PAGE CONTENT END*/}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* MAIN BODY END */}
      </div>
    </div>
  );
}

export default TrackMeetings;
