import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import { baseURL } from "../BaseUrl";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { getRandomColor } from "../FormattedAmount";
import userImage from "../../images/DefaultImages/Default_User.jpg";
import { loggedInRole } from "../LoginRole";

function UserTable(props) {
  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.users,
              props.totalUserCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="client-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((header, i) => (
                  <TableCell
                    key={i}
                    align={header.align}
                    style={{ minWidth: `${header.width}` }}
                  >
                    {header.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <>
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
                <SkeletonTableData />
              </>
            ) : (
              <>
                {props.users.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        --- No Data Found ---
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {props.users &&
                      props.users.map((user, i) => (
                        <TableRow
                          align="center"
                          key={i}
                          className="client-list-row-data"
                        >
                          <TableCell>
                            <div className="users-list-name">
                              <div>
                                {user?.profilePic ? (
                                  <img
                                    className="table-data-users-imgages"
                                    src={`${baseURL}/${user?.profilePic}`}
                                    alt="user-profile"
                                  />
                                ) : (
                                  <img
                                    className="table-data-users-imgages"
                                    src={userImage}
                                    alt="user-profile"
                                  />
                                  // <div
                                  //   className="table-data-users-imgages default-image"
                                  //   style={{
                                  //     backgroundColor: getRandomColor(i),
                                  //   }}
                                  // >
                                  //   {user?.fullName.charAt(0).toUpperCase()}
                                  // </div>
                                )}
                              </div>

                              <div>
                                <div className="table-row-head-Text">
                                  <strong>{user?.fullName}</strong>
                                </div>
                                {/* <div className="table-row-sub-text">
                                  {user?.desingation?.role?.roleName}
                                </div> */}
                                <div className="table-row-sub-text color-yellow">
                                  {user?.code}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="color-green">
                              {user?.countryCode} &nbsp;{user?.contact}
                            </div>
                            <div>{user?.email}</div>
                          </TableCell>
                          <TableCell>
                            <span className="color-yellow">
                              L{user?.desingation?.designationLevel}
                            </span>
                            -{" "}
                            <strong>
                              {user?.desingation?.designationName}
                            </strong>
                          </TableCell>
                          {/* <TableCell>
                            {user?.desingation?.role?.roleName}
                          </TableCell> */}
                          <TableCell>
                            {user?.desingation?.departmentName}
                          </TableCell>
                          <TableCell>
                            {user?.hasSubordinates > 0 ? (
                              <p className="subbordinate-link">
                                {user?.managerName}
                                <Link
                                  to="#"
                                  onClick={() => {
                                    props.setManagerId(user?.userId);
                                    props.setManagerName(user?.fullName);
                                    props.goBacktoUser();
                                  }}
                                >
                                  <OverlayTrigger
                                    placement={"right"}
                                    delay={{ show: 200, hide: 250 }}
                                    overlay={
                                      <Tooltip id={`tooltip-title`}>
                                        View Subordinates
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      <i className="fa fa-users user-icon" />
                                    </span>
                                  </OverlayTrigger>
                                </Link>
                              </p>
                            ) : (
                              user?.managerName
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {user?.enableStatus == 1 ? (
                              <span className="status-badge complete-status">
                                Active
                              </span>
                            ) : (
                              <span className="status-badge cancel-status">
                                InActive
                              </span>
                            )}
                          </TableCell>
                          <TableCell className="action-class">
                            <span className="user-action-td">
                              {loggedInRole === 1 && (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    props.showEditModel();
                                    props.setEditUserId(user?.userId);
                                  }}
                                  className="user-edit-link"
                                >
                                  <i className="fa fa-edit"></i> Edit
                                </Link>
                              )}

                              <Link
                                to="#"
                                className="user-view-link"
                                onClick={() => {
                                  props.setEditUserId(user?.userId);
                                  props.userHistoryView();
                                }}
                              >
                                <i className="fa fa-eye" /> History
                              </Link>
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default UserTable;
