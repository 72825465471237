import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/AddEditModel.scss";
import { Form, Image, Row } from "react-bootstrap";
import phaseImg from "../../../images/pms/Group_1956.jpg";

export default function ViewPhaseDetailsModel(props) {
  const [localImage, setLocalImage] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [date, setDate] = useState(new Date());
  const [imgFile, setImgFile] = useState("");

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };
  return (
    <div>
      {" "}
      <div>
        <Modal
          show={props.graphPhaseDetails}
          // onHide={props.handleClose}
          className="main_model add-edit-model phase-details-model"
        >
          <div className="add-edit-heading">
            <h6 className="add-edit-head">Phase 01</h6>
            <i className="fa fa-times" onClick={props.openPhaseDetails} />
          </div>
          <div className="container add-edit">
            <div className="phase-details">
              <div className="phase-detail-summary">
                <h6>Summary</h6>
                <div className="summary-details">
                  <p>
                    <span className="p-head">Start Date :</span>{" "}
                    <span>03-01-2023</span>
                  </p>
                  <p>
                    <span className="p-head">End Date :</span>{" "}
                    <span>16-03-2023</span>
                  </p>
                  <p>
                    <span className="p-head">Overall Status :</span>{" "}
                    <span className="completed">Completed</span>
                  </p>
                  <p>
                    <span className="p-head">Time Line :</span>{" "}
                    <span>03-01-2023</span>
                  </p>
                </div>
              </div>
              <div className="phase-detail-task">
                <h6>Tasks</h6>
                <div className="task-details">
                  <p>
                    <span className="p-head">No. Of Phases :</span>{" "}
                    <span>123</span>
                  </p>
                  <p>
                    <span className="p-head">Completed Phases :</span>{" "}
                    <span>60</span>
                  </p>
                  <p>
                    <span className="p-head">Pending Phases :</span>{" "}
                    <span>0</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
