import React from "react";
import travelizeSalesBeeLogo from "./images/MatricLogo.svg";
import { Image, Carousel } from "react-bootstrap";
import "../src/styles/SignIn.scss";
import "../src/styles/CommonStyle.scss";
import "../src/MediaFiles/Media.scss";

export default function SignInGifImag() {
  return (
    <>
      <div className="signIn-container-logo">
        <Image
          src={travelizeSalesBeeLogo}
          alt="MetricInfo"
          className="signIn-logo"
        />
      </div>
      <div className="signIn-container-gif">
        <Image
          src="../images/sign-animate.gif"
          alt="MetricInfo Motion Logo"
          className="signIn-moving-img"
        />
        <div className="text-sliding-below-img">
          <Carousel
            style={{ width: "100%" }}
            controls={false}
            indicators={false}
            interval={3000}
          >
            <Carousel.Item>
              <h6>
                <b>Geolocation Monitoring</b>
              </h6>
              <p>
                Real-time tracking to determine <br />
                precise location
              </p>
            </Carousel.Item>
            <Carousel.Item>
              <h6>
                <b>Reporting and Analytics</b>
              </h6>
              <p>
                Offering valuable insights for informed <br />
                decision-making
              </p>
            </Carousel.Item>
            <Carousel.Item>
              <h6>
                <b>Visits and Expenses</b>
              </h6>
              <p>
                Ensuring optimal resource allocation <br />
                and cost control
              </p>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </>
  );
}
