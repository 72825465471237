import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/AddEditModel.scss";
import { Form, Image, Row } from "react-bootstrap";
import phaseImg from "../../../images/pms/Group_1956.jpg";

export default function AddPhasesGraphModel(props) {
  const [localImage, setLocalImage] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [date, setDate] = useState(new Date());
  const [imgFile, setImgFile] = useState("");

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };
  return (
    <div>
      {" "}
      <div>
        <Modal
          show={props.selectGraphPhase}
          // onHide={props.handleClose}
          className="main_model add-edit-model graph-phase-model"
        >
          <div className="add-edit-heading chat">
            <h6 className="add-edit-head">Add Phases</h6>
            <i
              className="fa fa-times"
              onClick={props.openSelectShowGraphPhase}
            />
          </div>
          <div className="container add-edit">
            <p className="note">Select Maximum 4 Phase & Minimum 3 Phase</p>
            <div className="phase-img-name-checkbox">
              <div className="phase-img-name">
                <Image
                  src={phaseImg}
                  alt="Phase-Image"
                  className="phase_image"
                />
                <p className="gryclr">
                  <strong>Phase Name :</strong> Phase 01
                </p>
              </div>
              <Form.Check
                className="add-phase-checkbox"
                name="Phase-01"
                type="checkbox"
                id={`phase-1`}
              />
            </div>
            <div className="phase-img-name-checkbox">
              <div className="phase-img-name">
                <Image
                  src={phaseImg}
                  alt="Phase-Image"
                  className="phase_image"
                />
                <p className="gryclr">
                  <strong>Phase Name :</strong> Phase 02
                </p>
              </div>
              <Form.Check
                className="add-phase-checkbox"
                name="Phase-01"
                type="checkbox"
                id={`phase-1`}
              />
            </div>
            <div className="phase-img-name-checkbox">
              <div className="phase-img-name">
                <Image
                  src={phaseImg}
                  alt="Phase-Image"
                  className="phase_image"
                />
                <p className="gryclr">
                  <strong>Phase Name :</strong> Phase 03
                </p>
              </div>
              <Form.Check
                className="add-phase-checkbox"
                name="Phase-01"
                type="checkbox"
                id={`phase-1`}
              />
            </div>
            <div className="phase-img-name-checkbox">
              <div className="phase-img-name">
                <Image
                  src={phaseImg}
                  alt="Phase-Image"
                  className="phase_image"
                />
                <p className="gryclr">
                  <strong>Phase Name :</strong> Phase 04
                </p>
              </div>
              <Form.Check
                className="add-phase-checkbox"
                name="Phase-01"
                type="checkbox"
                id={`phase-1`}
              />
            </div>
            <div className="phase-img-name-checkbox">
              <div className="phase-img-name">
                <Image
                  src={phaseImg}
                  alt="Phase-Image"
                  className="phase_image"
                />
                <p className="gryclr">
                  <strong>Phase Name :</strong> Phase 05
                </p>
              </div>
              <Form.Check
                className="add-phase-checkbox"
                name="Phase-01"
                type="checkbox"
                id={`phase-1`}
              />
            </div>
          </div>

          <div className="btns-save-cancel">
            <div className="products-save-button">
              <button
                //   onClick={postinvoicedata}
                type="button"
                className="btn btn-save-up"
                data-bs-dismiss="modal"
              >
                Add
              </button>
            </div>
            <div className="products-cancel-button">
              <button
                onClick={props.openSelectShowGraphPhase}
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
