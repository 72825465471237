import React, { useEffect, useLayoutEffect, useState } from "react";
import Footer from "../../Footer";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/VhdPayments.scss";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { paymentDetailsTableData } from "../TestData";
import DataDisplayByColumn from "../DataDisplayByColumn";
import PaymentDetailTable from "./PaymentDetailTable";

export default function VhdPaymentDetails() {
  const tableHeader = [
    { name: "SI DocNum", align: "left" },
    { name: "AP DocNum", align: "left" },
    { name: "Invoice Amount", align: "left" },
    { name: "Remarks", align: "left" },
  ];

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div>
      <>
        {/* SIDEBAR - TOPBAR - FOOTER  */}
        <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
        <Topbar
          toggleMenu={toggleMenu}
          menuCollapse={menuCollapse}
          toggleshift={toggleshift}
        />
        <Footer />
        {/* SIDEBAR - TOPBAR - FOOTER  */}
      </>
      <>
        <div className="page-wrapper">
          <div className={`main-body ${toggleshift.style}`}>
            <div className="page-content">
              {/* Filter and Header Section */}
              <div className="payment-details-part">
                <Row>
                  <Col md={5} sm={12} className="cat-col">
                    <div className="page-header-text-div">
                      <div className="back-btn" onClick={goToSettingPage}>
                        <img
                          src="../../../../images/DefaultImages/back-btn-arrow.svg"
                          alt=""
                        />
                      </div>
                      <h6 className="page-header-text">
                        Payment Status Details
                      </h6>
                    </div>
                  </Col>
                  <Col md={7} sm={12} className="cat-col"></Col>
                </Row>
                <div className="card payment-details-card">
                  <Row>
                    <Col md={6} sm={12} className="br-gray">
                      <DataDisplayByColumn
                        dataHead={"PAYMENT NUMBER"}
                        dataBody={138466}
                      />
                      <DataDisplayByColumn
                        dataHead={"VENDOR CODE"}
                        dataBody={"V03875"}
                      />
                      <DataDisplayByColumn
                        dataHead={"PAYMENT DATE"}
                        dataBody={"2024/08/28"}
                      />
                    </Col>
                    <Col md={6} sm={12} className="bl-gray">
                      <DataDisplayByColumn
                        dataHead={"BRANCH"}
                        dataBody={" Karnataka"}
                      />
                      <DataDisplayByColumn
                        dataHead={"PAYMENT AMOUNT"}
                        dataBody={5900}
                      />
                      <DataDisplayByColumn
                        dataHead={"UTR NUMBER"}
                        dataBody={"UTBBHJ5446544"}
                      />
                    </Col>
                  </Row>
                </div>
                {/* TABLE START */}
                <div className="payment-details-table-part">
                  <PaymentDetailTable
                    tableHeader={tableHeader}
                    tableData={paymentDetailsTableData}
                  />
                </div>
              </div>
              {/* TABLE END */}

              {/* PAGE CONTENT END*/}
            </div>
          </div>

          {/* MAIN BODY END */}
        </div>
      </>
    </div>
  );
}
