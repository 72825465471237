import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { SkeletonMeetPurpose } from "../Skeleton Loading/SkeletonMeetPurpose";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import { useSelector } from "react-redux";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";

function UserRefferalsTable(props) {
  const { totalCount } = useSelector((state) => state.data);

  return (
    <div className="sb-table-div sb-table-setion">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          sx={{ maxHeight: 440 }}
          onScroll={(e) =>
            handleScroll(
              e,
              props.userReferralsData,
              totalCount,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="basefare-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    align={name.align}
                    className={`thead-cell-products`}
                    style={{ minWidth: name.width }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ? (
              <>
                {props.tableHeader.map((name, i) => (
                  <SkeletonMeetPurpose key={i} />
                ))}
              </>
            ) : (
              <>
                {props.userReferralsData &&
                props.userReferralsData.length === 0 ? (
                  <TableBody>
                    <TableRow align="center">
                      <TableCell
                        align="center"
                        colSpan={props.tableHeader.length}
                      >
                        <div style={{ padding: "20px 20px" }}>
                          <img
                            src="../../../../images/DefaultImages/NodataFound.jpg"
                            alt=""
                            style={{ width: "10%" }}
                          />
                          <br />
                          <span>---No Data Found---</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {props.userReferralsData &&
                      props.userReferralsData.map((data, i) => (
                        <TableRow
                          align="center"
                          key={i}
                          className="client-list-row-data"
                        >
                          <TableCell>
                            <span className="users-list-name">
                              <div>
                                <img
                                  className="table-data-users-imgages"
                                  src={
                                    data?.image
                                      ? `${baseURL}/${data?.image}`
                                      : profileimg
                                  }
                                  alt=""
                                />
                              </div>
                              <div>
                                <div className="table-row-head-Text">
                                  {data?.userName}
                                </div>
                                <div className="table-row-sub-text">
                                  {data?.userID}
                                </div>
                              </div>
                            </span>
                          </TableCell>
                          <TableCell>
                            <div>{data?.email}</div>
                            <div className="table-row-sub-text">
                              {data?.countryCode} &nbsp;{data?.mobileNumber}
                            </div>
                          </TableCell>
                          <TableCell>
                            <p>{data.projectName}</p>
                          </TableCell>
                          <TableCell align="center">
                            <p className="status-badge pending-status">
                              {data.referelCode}
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            {moment(data.addedOn, "DD/MM/YYYY").format(
                              "DD MMM YYYY"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {data?.status == 1 ? (
                              <span className="status-badge complete-status">
                                Active
                              </span>
                            ) : (
                              <span className="status-badge pending-status">
                                InActive
                              </span>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {/* <button className="btn client-edit-link"
                                                    onClick={()=>{props.addBankDetails(data.gfRefProfileID)}}
                                                    >
                                                        <i class="fa-solid fa-building-columns" /> Bank/UPI
                                                    </button> */}
                            <button
                              className="btn client-edit-link"
                              onClick={() => {
                                props.handleBankDetails(data.gfRefProfileID);
                              }}
                            >
                              <i class="fa-solid fa-building-columns" />{" "}
                              Bank/UPI
                            </button>

                            <button
                              className="btn client-edit-link"
                              onClick={() => {
                                props.goToBRefferalInfo(data.gfRefProfileID);
                              }}
                            >
                              <i class="fa-solid fa-building-columns" />{" "}
                              Referrals
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    {props.loading && <OnScrollLoading />}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default UserRefferalsTable;
