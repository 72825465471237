import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../styles/livechat.scss";
import { baseURL } from "./BaseUrl";
import { useRef } from "react";

function LiveChatBoot(props) {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const handleCloseModal = () => {
    props.closeChatBoot();
  };

  const [messages, setMessages] = useState([]);

  const [input, setInput] = useState({ message: "", type: "" });
  const [inputValue, setInputValue] = useState("");
  const [typingLoader, setTypingLoader] = useState(false);

  const handleInputChange = (e) => {
    setInput({ message: e.target.value, type: "user" });
  };

  const handleEmplty = () => {};

  const chatboxRef = useRef(null);
  const chatBodyRef = useRef(null);

  // useEffect(() => {
  //   chatBodyRef.current.scrollIntoView();
  // }, [props.liveChatModal]);

  const handleSendMessage = () => {
    if (input.message !== "") {
      // const newUserMessage = { type: 'user', content: input };
      setInputValue(input.message);
      setMessages([...messages, input]);

      // Clear the input field
      setTimeout(() => {
        setTypingLoader(true);
      }, 500);
      setInput("");
      // scrollToBottom()
    }
  };
  // Function to scroll to the bottom of the chatbox
  const scrollToBottom = () => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollIntoView();
    }
  };

  // Automatically scroll to the bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (inputValue) {
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      };

      let inputText = JSON.stringify(inputValue);

      fetch(
        `https://chatbot.metricinfo.com/dashboard/chatbot?input_text=${inputText}&sbn_id=${resPassData.subscriptionId}`,
        requestoption
      )
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.response == null || resp.response == "") {
            setMessages([
              ...messages,
              {
                message: "Sorry, we are not able understand your question.",
                type: "metricBot",
              },
            ]);
            setTypingLoader(false);
            setInputValue("");
          } else {
            setMessages([
              ...messages,
              { message: resp.response, type: "metricBot" },
            ]);
            setTypingLoader(false);
            setInputValue("");
          }
        })
        .catch((err) => {
          setMessages([
            ...messages,
            {
              message: "Sorry, we are not able understand your question.",
              type: "metricBot",
            },
          ]);
          setTypingLoader(false);
          setInputValue("");
        });
    }
  }, [inputValue, messages, resPassData]);

  return (
    <div>
      {/* {props.liveChatModal && ( */}
      <div className={`modal-livechat`}>
        <div
          className={`modal-right ${
            props.liveChatModal ? "show-chat" : "close-chat"
          }`}
        >
          <Modal.Dialog className="right-fade-modal-dialog">
            <div>
              <Modal.Header closeButton onHide={handleCloseModal}>
                <Modal.Title className="client-modal-header card-head">
                  <img src="../../metric-favicon.svg" alt="" />
                  <div className="chot-bot-head">
                    <h6 className="chat-header-text">MetricInfo Chatbot</h6>
                    <p>Intelligent Guide</p>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <div className="chat-body" ref={chatBodyRef}>
                <div className="chat-box-section">
                  <div className="message-box-row-left">
                    <img src="../../metric-favicon.svg" alt="" />
                    <div className={`chat-text-box-left`}>
                      <p>
                        Hello 👋 Nice to see you here!, I'm MetricInfo Chatbot,
                        How can I help you
                      </p>
                    </div>
                  </div>
                  {messages.map((message, index) => (
                    <div
                      className={`${
                        message.type == "user"
                          ? "message-box-row-right"
                          : "message-box-row-left"
                      }`}
                    >
                      {message.type == "metricBot" && (
                        <img src="../../metric-favicon.svg" alt="" />
                      )}
                      <div
                        className={`${
                          message.type == "user"
                            ? "chat-text-box-right"
                            : "chat-text-box-left"
                        }`}
                        key={index}
                      >
                        <p>{message.message}</p>
                      </div>
                      {message.type == "user" && (
                        <img
                          src={`${baseURL}/${resPassData.profilePic}`}
                          alt=""
                        />
                      )}
                    </div>
                  ))}
                  {typingLoader && (
                    <div className="message-box-row-left">
                      <img src="../../metric-favicon.svg" alt="" />
                      <div className={`chat-text-box-typing`}>
                        <p>
                          <span className="type-text">Typing</span>
                          <span className="typing typing-1"></span>
                          <span className="typing typing-2"></span>
                          <span className="typing typing-3"></span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div ref={chatboxRef} />
              <Modal.Footer className="client-modal-footer">
                <textarea
                  type="text"
                  rows="1" // Set the number of visible rows
                  cols="30"
                  autoComplete="off"
                  className="chat-input"
                  id="exampleFormControlInput1"
                  placeholder="Write your message"
                  value={input.message || ""}
                  onChange={handleInputChange}
                  //   onKeyDown={(e) => textNameValidation(e)}
                />
                <img
                  src="../../images/send.svg"
                  onClick={typingLoader ? handleEmplty : handleSendMessage}
                  alt=""
                />
              </Modal.Footer>
            </div>
          </Modal.Dialog>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

export default LiveChatBoot;
