import { Link, useNavigate } from "react-router-dom";
import '../../styles/Payroll.scss'
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect, useState } from "react";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import AccessDenied from "../../AccessDenied";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { loggedInRole } from "../LoginRole";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { deleteEmployeePayrollData, loadPayrollData, loadUserDropdown, postEmploeePayrollData } from "../../redux/action";
import * as HeaderAuthentication from "../HeaderAuthentication";

const earnings = [
    { value: 'BASIC', label: 'BASIC' },
    { value: 'HRA', label: 'HRA' },
    { value: 'Conveyance', label: 'Conveyance' },
    { value: 'Medical Reimbursment', label: 'Medical Reimbursment' },
    { value: 'Food Coupons', label: 'Food Coupons' },
    { value: 'Special Allowance', label: 'Special Allowance' },
    { value: 'Variable Pay', label: 'Variable Pay' },
    { value: 'Night Shift Allowance', label: 'Night Shift Allowance' },
    { value: 'Incentive', label: 'Incentive' },
    { value: 'PT', label: 'PT' },
    { value: 'Employee PF', label: 'Employee PF' },
    { value: 'Empolyer PF', label: 'Empolyer PF' },
    { value: 'TDS', label: 'TDS' },
    { value: 'Other Deduction', label: 'Other Deduction' },
];

const operations = [
    { value: 'ADD', label: 'ADD' },
    { value: 'SUBSTRACT', label: 'SUBSTRACT' },
]

const types = [
    { value: 'Percentage', label: 'Percentage' },
    { value: 'Value', label: 'Value' },
]

const ons = [
    { value: 'Gross Salary', label: 'Gross Salary' },
    { value: 'Basic', label: 'Basic' },
]

const Payroll = () => {

    let tableHeader = [
        { name: "EMPLOYEE ID", align: "left", width: "150px" },
        { name: "FIELD NAME", align: "left", width: "200px" },
        { name: "OPERATION", align: "left", width: "150px" },
        { name: "TYPE", align: "left", width: "150px" },
        { name: "WEIGHTAGE", align: "left", width: "150px" },
        { name: "VALUE", align: "left", width: "150px" },
        { name: "TOTAL", align: "left", width: "150px" },
        { name: "BAL IN VALUE", align: "left", width: "150px" },
        { name: "BAL IN %", align: "left", width: "150px" },
        { name: "ACTION", align: "left", width: "150px" },
    ]

    let dispatch = useDispatch();
    const { userDropdown } = useSelector((state) => state.data);
    const { getPayrollData } = useSelector((state) => state.data);
    console.log(getPayrollData, 'getPayrollData')
    let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

    const [selectedUserID, setSelectedUserID] = useState(null);

    const handleChange = (selectedOption) => {
        setSelectedUserID(selectedOption);
    }

    useEffect(() => {
        dispatch(loadUserDropdown());
        if (selectedUserID)
        fetchPayrollData()
    }, [selectedUserID])

    const fetchPayrollData = () => {
        dispatch(loadPayrollData(selectedUserID.value));
    }

    const formatOptionLabel = ({ value, label }) => (
        <div>
            <span>{value}</span>
            <span style={{ padding: "2px" }}>-</span>
            <span>{label}</span>
        </div>
    );

    const [isLoading, setIsLoading] = useState(false);
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const [grossSalary, setGrossSalary] = useState()

    useEffect(() => {
        if (selectedUserID && getPayrollData && getPayrollData.fixedIncome) {
            setGrossSalary(getPayrollData.fixedIncome.grossSalary);
        }
        else {
            setGrossSalary(0)
        }
    }, [selectedUserID, getPayrollData]);

    const [selectedFieldName, setSelectedFieldName] = useState({ value: "", label: "" })
    const [selectedOperation, setSelectedOperation] = useState({ value: "", label: "" })
    const [selectedType, setSelectedType] = useState({ value: "", label: "" })
    const [weightage, setWeightage] = useState('')
    const [selectedOn, setSelectedOn] = useState({ value: "", label: "" })
    const [value, setValue] = useState("")

    useEffect(() => {
        if (getPayrollData && getPayrollData.fixedIncome) {
            if (selectedType.value && selectedOn.value) {
                if (selectedType.value === 'Value' && selectedOn.value === 'Gross Salary') {
                    const calculatedTotalper = (value / getPayrollData.fixedIncome.grossSalary) * 100;
                    setWeightage(calculatedTotalper.toFixed(2));
                } else if (selectedType.value === 'Value' && selectedOn.value === 'Basic') {
                    const calculatedTotalper = (value / getPayrollData.salaryBreakUp[0].sbValue) * 100;
                    setWeightage(calculatedTotalper.toFixed(2));
                } else if (selectedType.value === 'Percentage') {
                    const calculatedTotalval = (weightage * getPayrollData.fixedIncome.grossSalary) / 100;
                    setValue(calculatedTotalval.toFixed(2));
                }
            }
        }
    }, [selectedType, selectedOn, value, weightage, getPayrollData]);


    const postPayrollData = (e) => {
        e.preventDefault()

        let data = {
            "status": 1,
            "salaryBreakUpID": 0,
            "userID": getPayrollData.userID,
            "sbFieldName": selectedFieldName,
            "sbOperator": selectedOperation,
            "sbOperatorByType": selectedType.value,
            "sbPercentage": parseFloat(weightage),
            "sbValue": parseFloat(value),
            "sbFieldToCalculate": selectedOn.value,
        }

        let postData = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(data)
        }
        dispatch(postEmploeePayrollData(postData, fetchPayrollData))
    }

    const deletePayrollData = (e) => {
        e.preventDefault()

        let deleteUserData = {
            "salaryBreakUpID": getPayrollData.salaryBreakUp[0].salaryBreakUpID,
            "userID": getPayrollData.userID,
        }

        let postData = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(deleteUserData)
        }
        dispatch(deleteEmployeePayrollData(postData, fetchPayrollData))
    }

    // Page navigation
    let navigate = useNavigate();
    const goToSettingPage = () => {
        navigate(-1);
    };
    // page navigation

    return (
        <div id="main-page">
            {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
                <>
                    {/* SIDEBAR START */}
                    <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
                    {/* SIDEBAR END */}

                    {/* TOPBAR START */}
                    <Topbar
                        toggleMenu={toggleMenu}
                        menuCollapse={menuCollapse}
                        toggleshift={toggleshift}
                    />
                    {/* TOPBAR END */}

                    {/* MAIN BODY START */}
                    <div className="page-wrapper">
                        {/* PAGE CONTENT START */}
                        <div className={`main-body ${toggleshift.style}`}>

                            <div className="page-content">
                                <div style={{ position: "relative" }}>
                                    <Row className='search-row'>
                                        <Col md={3} className='cat-col'>
                                            <div className='page-header-text-div'>
                                                <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                                                <h6 className='page-header-text' >PayRoll</h6>
                                            </div>
                                        </Col>
                                        {/* <Col md={5} className='cat-col'>
                                            <div className='input-div'>
                                                <input type="search" placeholder='Find a Product' className='form-control setting-input'
                                                    id="metricInfo_search_bar" value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                                                />
                                                <i className='fa fa-search search-icon' ></i>
                                            </div>
                                        </Col> */}
                                        {/* <Col md={4} className='cat-col'>
                                        <div className="search-bar-header" style={{ float: "right" }}>
                                            <div className="page-top-bar-dropdowns page-top-Filters">
                                                <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                                                    <p className="card-head">
                                                        <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                                                    </p>
                                                </div>
                                                <SbAddBtn onClickEffect={() => setShow(!show)} />
                                            </div>
                                        </div>
                                    </Col> */}
                                    </Row>
                                </div>
                                {/* PAGE CONTENT END*/}
                                <div className="payrole-container">
                                    {/* <div className="payrole-container-header-div">
                                        <div>
                                            <b>PaySlip-HR</b>
                                        </div>
                                        <div>
                                            <Link><i className="fa fa-pencil"></i></Link>
                                        </div>
                                    </div> */}
                                    <div className="payroll-first-container">
                                        <div className="payroll-first-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>EMPLOYEE ID & NAME</span>
                                            </div>
                                            <div className="payrole-input-div">
                                                <div
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={userDropdown}
                                                        placeholder="Select Employee ID"
                                                        value={selectedUserID}
                                                        onInputChange={userDropdown.label}
                                                        onChange={handleChange}
                                                        isSearchable={true}
                                                        formatOptionLabel={formatOptionLabel}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payroll-first-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>SALARY</span>
                                            </div>
                                            <div>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={grossSalary}
                                                />
                                            </div>
                                        </div>
                                        <div className="payroll-first-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>BAL SALARY</span>
                                            </div>
                                            <div>
                                                <span>-2200</span>
                                            </div>
                                        </div>
                                        <div className="payroll-first-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>BAL</span>
                                            </div>
                                            <div>
                                                <span>-8.8</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="payroll-second-container">
                                        <div className="payroll-second-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>EARNINGS/DEDUCTIONS</span>
                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={earnings}
                                                        placeholder="Select Earnings/Ded.."
                                                        value={selectedFieldName.value && selectedFieldName}
                                                        onInputChange={earnings.label}
                                                        onChange={(selectedOption) => setSelectedFieldName(selectedOption.value)}
                                                        isSearchable={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payroll-second-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>OPERATION</span>
                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={operations}
                                                        placeholder="Select Operations"
                                                        value={selectedOperation.value && selectedOperation}
                                                        onInputChange={operations.label}
                                                        onChange={(selectedOption) => setSelectedOperation(selectedOption.value)}
                                                        isSearchable={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payroll-second-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>TYPE</span>
                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={types}
                                                        placeholder="Select Type"
                                                        value={selectedType.value && selectedType}
                                                        onInputChange={types.label}
                                                        onChange={(selectedOption) => setSelectedType(selectedOption)}
                                                        isSearchable={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payroll-second-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>WEIGHTAGE</span>
                                            </div>
                                            <div>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter Percentage"
                                                    value={weightage}
                                                    onChange={(e) => setWeightage(e.target.value)}
                                                disabled={selectedType.value === 'Value'}
                                                />
                                            </div>
                                        </div>
                                        <div className="payroll-second-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>ON</span>
                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Select
                                                        className="react-select-container-list model-select"
                                                        classNamePrefix="react-select-list"
                                                        options={ons}
                                                        placeholder="Select ON"
                                                        value={selectedOn.value && selectedOn}
                                                        onInputChange={ons.label}
                                                        onChange={(selectedOption) => setSelectedOn(selectedOption)}
                                                        isSearchable={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payroll-second-inner-container">
                                            <div className="payroll-first-inner-container-header">
                                                <span>VALUE</span>
                                            </div>
                                            <div>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={value}
                                                    placeholder="Enter Value"
                                                    onChange={(e) => setValue(e.target.value)}
                                                disabled={selectedType.label === 'Percentage' || selectedOn.value === ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btns-save-cancel" >
                                        <SBSaveUpdateBtn btnName="Add" onClickEffect={postPayrollData} />
                                        <SbCancelBtn btnName="Close" />
                                    </div>
                                    <hr />
                                    <div className="sb-table-div sb-table-setion">
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                                            <TableContainer sx={{ maxHeight: 440 }}>
                                                <Table stickyHeader aria-label="sticky table table-responsive" className='basefare-table'>
                                                    <TableHead className='custom-table-header'>
                                                        <TableRow>
                                                            {tableHeader.map((name, i) => (
                                                                < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                                                    style={{ minWidth: name.width }}>
                                                                    {name.name}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    {isLoading ? (
                                                        <TableBody>
                                                            {tableHeader.map((name, i) => (
                                                                <SkeletonTableData key={i} />
                                                            ))}
                                                        </TableBody>
                                                    ) : (
                                                        <>
                                                            {getPayrollData && getPayrollData.payrolEntry && getPayrollData.payrolEntry.length === 0 ?
                                                                <TableBody>
                                                                    <TableRow align="center" >
                                                                        <TableCell align="center" colSpan={tableHeader.length}>
                                                                            <div style={{ padding: "20px 20px" }}>
                                                                                <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                                                <span>No Data Found...!</span>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                                :
                                                                <TableBody>
                                                                    {getPayrollData && getPayrollData.salaryBreakUp && getPayrollData.salaryBreakUp.map((data, i) => (
                                                                        <TableRow align="center" key={i} className='client-list-row-data'>
                                                                            <TableCell>
                                                                                {data.userID}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {data.sbFieldName}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {data.sbOperator ? data.sbOperator : '-'}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {data.sbOperatorByType}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {data.sbPercentage}%
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {data.sbValue}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {'-'}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {'-'}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {'-'}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <i className="fa fa-trash" style={{fontSize:"20px"}} onClick={deletePayrollData} ></i>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            }
                                                        </>
                                                    )}
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </div >
                                </div >
                            </div>
                        </div>

                        {/* MAIN BODY END */}
                    </div>
                    <Footer />
                </>
            ) : (
                <AccessDenied />
            )
            }
        </div >
    );
}

export default Payroll;