import React, { useState, useEffect, useLayoutEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/MeetingPurpose.scss";
import { Col, Row } from "react-bootstrap";
import AddPurosemodel from "./AddPurosemodel";
import { baseURL } from "../../BaseUrl";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { optionsStatusList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import {
  loadMeetingPurposeData,
  loadPageBreadCome,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbCancelBtn, { MiClearButton } from "../../SbButtons/SbCancelBtn";
import MeetingPurposeTable from "./MeetingPurposeTable";
import Footer from "../../Footer";
import DownloadLoading from "../../ReUseComponents/DownloadLoading";
import { travelizeAuth } from "../../HeaderAuthentication";

function MeetingPurpose() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let dispatch = useDispatch();

  const { meetingPurposeData } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [meetPurposeId, setMeetPurposeId] = useState(0);
  const [activeStatus, setActiveStatus] = useState(2);
  const [purposeId, setPurposeId] = useState(null);
  const [meetPurpose, setMeetPurpose] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  let tableHeader = [
    { name: "PURPOSE", align: "left", width: "200px" },
    { name: "ADDED ON", align: "center", width: "150px" },
    { name: "ADDED BY", align: "left", width: "150px" },
    { name: "STATUS", align: "center", width: "100px" },
    { name: "ACTION", align: "center", width: "100px" },
  ];

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(100);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadMeetingPurposeData());
  }, []);

  useEffect(() => {
    let breadCome = { mainpage: "Meeting Purpose" };
    dispatch(loadPageBreadCome(breadCome));
    fetchMeetingPurposeData();
  }, [meetPurposeId, activeStatus, PageSize, currentPage]);

  //  MEETING PURPOSE TABLE START
  const fetchMeetingPurposeData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/MeetingPurpose/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&PurposeId=${meetPurposeId}&Status=${activeStatus}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMeetPurpose(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setMeetPurpose("");
          setIsLoading(false);
          setLoading(false);
        }
      });
  };
  //  MEETING PURPOSE TABLE END
  const [openFilterTab, setOpenFilterTab] = useState(false);

  const clearSerachBySelect = () => {
    setMeetPurposeId(0);
    setActiveStatus(2);
  };

  const exportExcelMeetingPurpose = () => {
    exportExcel(
      `${baseURL}/api/Export/MeetingPurposeExcelExport?PurposeId=${meetPurposeId}&Status=${activeStatus}&PageSize=${PageSize}&CurrentPage=1`,
      "MeetingPurpose.xlsx",
      setDownloadIsLoading
    );
  };

  const handleClose = () => {
    setShow(false);
    setPurposeId(null);
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Header and Filter Section */}
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} sm={4} xs={12} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">MEETING PURPOSES</h6>
                      </div>
                    </Col>
                    <Col md={9} sm={8} xs={12} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <div className="filter-icon-text">
                              <i className="bi bi-sliders" />
                              <span>Filters</span>
                            </div>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div className="filter-row-input-boxes">
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={meetingPurposeData}
                            placeholder="Select Purpose"
                            value={
                              meetPurposeId === 0
                                ? ""
                                : meetingPurposeData.value
                            }
                            onInputChange={meetingPurposeData.label}
                            onChange={(data) => setMeetPurposeId(data.value)}
                            isSearchable={true}
                          />
                        </div>
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={optionsStatusList}
                            placeholder="Select Status"
                            value={
                              activeStatus === 2 ? "" : optionsStatusList.value
                            }
                            onInputChange={optionsStatusList.label}
                            onChange={(data) => setActiveStatus(data.value)}
                            isSearchable={true}
                          />
                        </div>
                        <MiClearButton
                          onClickEffect={clearSerachBySelect}
                          btnName="Clear"
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* Header and Filter Section */}

                {/* USER TABLE START */}
                <MeetingPurposeTable
                  tableHeader={tableHeader}
                  meetPurpose={meetPurpose}
                  setShow={setShow}
                  show={show}
                  setPurposeId={setPurposeId}
                  loading={loading}
                  setPageSize={setPageSize}
                  PageSize={PageSize}
                  setLoading={setLoading}
                  totalData={totalData}
                />

                {/* USER TABLE END */}

                <div className="download-and-pagination">
                  <SbDownloadBtn onClickEffect={exportExcelMeetingPurpose} />
                </div>

                {/* ADD PURPOSE MODEL START */}
                <AddPurosemodel
                  show={show}
                  purposeId={purposeId}
                  handleClose={handleClose}
                  fetchMeetingPurposeData={fetchMeetingPurposeData}
                />
                {/* ADD PURPOSE MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
          <Footer />
          {downloadIsLoading && (
            <>
              <DownloadLoading />
            </>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default MeetingPurpose;
