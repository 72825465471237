import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/Designation.scss";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { FloatingLabel, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { travelizeAuth } from "../../HeaderAuthentication";
import {
  loadDepartmentDrop,
  loadDesignationDrop,
  loadRolesDropDown,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";

export default function AddDesignation(props) {
  let dispatch = useDispatch();

  let subscriptionId = JSON.parse(
    sessionStorage.getItem("usrlgndtls")
  ).subscriptionId;

  const { departmentDrop } = useSelector((state) => state.data);
  const { rolesDropdown } = useSelector((state) => state.data);
  const { desigationdrop } = useSelector((state) => state.data);

  const [designation, setDesignation] = useState("");
  const [departmentId, setDepartmentId] = useState(0);
  const [reportingRole, setReportingRole] = useState(0);
  const [reportDesignation, setReportDesignation] = useState(0);
  const [designationDescription, setDesignationDescription] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [reportingVal, setReportingVal] = useState("");
  const [departmentVal, setDepartmentVal] = useState("");
  const [reportDesigVal, setReportDesigVal] = useState("");
  const [roleVal, setRoleVal] = useState("");

  useEffect(() => {
    dispatch(loadDepartmentDrop());
    dispatch(loadDesignationDrop(0));
    dispatch(loadRolesDropDown());
  }, []);

  useEffect(() => {
    fetchDropdownsData();
  }, [props.editDesignationID]);

  const fetchDropdownsData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Designation/Get/${props.editDesignationID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignation(res.result.designationName);
          setDepartmentId(res.result.departmentID);
          setReportingRole(res.result.role.roleID);
          setReportDesignation(res.result.reportingDesignationID);
          setDesignationDescription(res.result.description);
          setEnableStatus(res.result.status);
        } else {
        }
      });
  };

  useEffect(() => {
    if (departmentId !== null) {
      departmentDrop.map((dept) => {
        if (departmentId == dept.value) {
          setDepartmentVal(dept);
        }
      });
    }

    if (reportingRole !== null) {
      rolesDropdown.map((role) => {
        if (reportingRole == role.value) {
          setRoleVal(role);
        }
      });
    }
    if (reportDesignation !== null) {
      desigationdrop.map((designation) => {
        if (reportDesignation == designation.value) {
          setReportDesigVal(designation);
        }
      });
    }
  }, [
    departmentId,
    departmentDrop,
    rolesDropdown,
    reportingRole,
    reportDesignation,
    desigationdrop,
  ]);

  const addDesignation = () => {
    if (
      designation !== "" &&
      departmentId !== 0 &&
      reportingRole !== 0 &&
      reportDesignation !== 0 &&
      subscriptionId !== ""
    ) {
      const designationDetails = {
        designationName: designation,
        departmentID: departmentId,
        role: {
          roleID: reportingRole,
        },
        reportingDesignationID: reportDesignation,
        subscriptionID: subscriptionId,
        description: designationDescription,
        status: enableStatus,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(designationDetails),
      };
      fetch(`${baseURL}/api/Designation/AddDesignation`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchDesignations();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          console.log("ERROR:", error);
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required!",
      });
    }
  };

  const editDesignation = () => {
    if (
      designation !== "" &&
      departmentId !== 0 &&
      reportingRole !== 0 &&
      reportDesignation !== 0 &&
      subscriptionId !== ""
    ) {
      const designationDetails = {
        designationID: props.editDesignationID,
        designationName: designation,
        departmentID: departmentId,
        role: {
          roleID: reportingRole,
        },
        reportingDesignationID: reportDesignation,
        subscriptionID: subscriptionId,
        description: designationDescription,
        status: enableStatus,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(designationDetails),
      };
      fetch(`${baseURL}/api/Designation/UpdateDesignation`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchDesignations();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          console.log("ERROR:", error);
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required!",
      });
    }
  };

  return (
    <div>
      <Modal show={props.show} className="main_model add-edit-model">
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            {props.editDesignationID !== 0
              ? "EDIT DESIGNATION"
              : "ADD DESIGNATION"}
          </h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <Form>
          <div className="container add-edit mt-3">
            <div className="mb-3">
              <FloatingLabel
                label="Designation Name"
                className={`${
                  designation
                    ? "float-input-visible"
                    : "float-hidden float-input"
                }`}
              >
                <input
                  type="text"
                  className="form-control add-basefare-input"
                  placeholder="Designation Name"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  required
                />
              </FloatingLabel>
              {fieldReq && designation == "" ? (
                <small
                  id={`Error${alertMessage.alertType}`}
                  className="form-text text-muted "
                >
                  {`${alertMessage.message}`}
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <FloatingLabel
                label="Select Department"
                className={`${
                  departmentDrop.value || departmentId
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={departmentDrop}
                  placeholder="Select Department"
                  value={departmentVal}
                  onInputChange={departmentDrop.label}
                  onChange={(data) => setDepartmentId(data.value)}
                  isSearchable={true}
                />
              </FloatingLabel>
              {fieldReq && departmentId == 0 ? (
                <small
                  id={`Error${alertMessage.alertType}`}
                  className="form-text text-muted "
                >
                  {`${alertMessage.message}`}
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <FloatingLabel
                label="Role"
                className={`${
                  rolesDropdown.value || reportingRole
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={rolesDropdown}
                  placeholder="Role"
                  value={roleVal}
                  onInputChange={rolesDropdown.label}
                  onChange={(data) => setReportingRole(data.value)}
                  isSearchable={true}
                />
              </FloatingLabel>
              {fieldReq && reportingRole === 0 && (
                <small
                  id={`Error${alertMessage.alertType}`}
                  className="form-text text-muted "
                >
                  {`${alertMessage.message}`}
                </small>
              )}
            </div>
            <div className="mb-3">
              <FloatingLabel
                label="Designation Description"
                className={`${
                  designationDescription
                    ? "float-input-visible"
                    : "float-hidden float-input"
                }`}
              >
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form add-input"
                  placeholder="Designation Description"
                  value={designationDescription}
                  onChange={(e) => setDesignationDescription(e.target.value)}
                />
              </FloatingLabel>
            </div>
            <div className="mb-3">
              <FloatingLabel
                label="Reporting Designation"
                className={`${
                  desigationdrop.value || reportDesignation
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  options={desigationdrop}
                  placeholder="Reporting Designation"
                  value={reportDesigVal}
                  onInputChange={desigationdrop.label}
                  onChange={(data) => setReportDesignation(data.value)}
                  isSearchable={true}
                />
              </FloatingLabel>
              {fieldReq && reportDesignation == 0 ? (
                <small
                  id={`Error${alertMessage.alertType}`}
                  className="form-text text-muted "
                >
                  {`${alertMessage.message}`}
                </small>
              ) : (
                ""
              )}
            </div>

            {props.editDesignationID !== 0 && (
              <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div>
            )}
          </div>

          <div className="btns-save-cancel">
            <SBSaveUpdateBtn
              onClickEffect={
                props.editDesignationID !== 0 ? editDesignation : addDesignation
              }
              btnName={props.editDesignationID !== 0 ? "Update" : "Save"}
            />
            <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
          </div>
        </Form>
      </Modal>
    </div>
  );
}
