import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Leaves.scss";
import "../../styles/ViewSubordinates.scss";
import profileimg from "../../images/profile-1.png";
import Users from "./Users";
import UpdateManagerModel from "./UpdateManagerModel";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import InternetIssue from "../InternetIssue";
import { Col, Row } from "react-bootstrap";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { travelizeAuth } from "../HeaderAuthentication";

function ViewSubordinates(props) {
  const [subordinatesList, setSubordinatesList] = useState([]);
  const [managerSubordinates, setManagerSubordinates] = useState([]);
  const [userID, setUserID] = useState("");
  const [updateUserId, setUpdateUserId] = useState("");
  const [activeStatus, setActiveStatus] = useState(2);
  const [totalSubordinates, setTotalSubordinates] = useState("");
  const [updateManagerModel, setUpdateManagerModel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const handleClose = () => setUpdateManagerModel(!updateManagerModel);

  // FETCH SELECT DROPDOWN START
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subordinates/BindUsersDropDown/${props.managerId}`,
      requestOptionZone
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          let subbordinateUsers = res.result.filter(
            (r) => r.userId != props.managerId
          );
          setSubordinatesList(
            subbordinateUsers.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setSubordinatesList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // FETCH SELECT DROPDOWN END

  // FETCH  SUBORDINATES START
  useEffect(() => {
    getManagerSubordinates();
  }, [userID, activeStatus, PageSize]);

  const getManagerSubordinates = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subordinates/GetSubordinates?managerId=${props.managerId}&userId=${userID}&EnableStatus=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOptionZone
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setTotalSubordinates(res.recordCount[1]);
          setManagerSubordinates(
            res.result.filter((data) => data.managerId == props.managerId)
          );
          setPageDataSize(res.result.length);
          setIsLoading(false);
          setLoading(false);
        } else {
          setManagerSubordinates([]);
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // FETCH  SUBORDINATES START

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setUserID("");
    setActiveStatus(2);
  };
  //* CLEAR SELECT END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          <div id="main-page">
            <div>
              {/* MAIN BODY START */}
              {/* PAGE CONTENT START */}
              {props.subordinateActive ? (
                <div className={`main-body ${props.toggleshift.style}`}>
                  <div className="page-content">
                    {/* Filter and Header Section */}
                    <div className="user-table">
                      <Row className="search-row">
                        <Col md={5} sm={6} xs={6} className="cat-col">
                          <div className="page-header-text-div">
                            <div
                              className="back-btn"
                              onClick={props.goBacktoUser}
                            >
                              <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                              />
                            </div>
                            <h6 className="page-header-text">
                              {props.managerName} ({totalSubordinates})
                            </h6>
                          </div>
                        </Col>
                        <Col md={7} sm={6} xs={6} className="cat-col">
                          <div
                            className="search-bar-header"
                            style={{ float: "right" }}
                          >
                            <div className="page-top-bar-dropdowns page-top-Filters">
                              <div
                                className="header-filter-btn"
                                onClick={() => setFilter(!filter)}
                              >
                                <p className="card-head">
                                  <i className="bi bi-sliders" />
                                  &nbsp;&nbsp;Filters
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {filter && (
                        <div className="page-filter-section">
                          <div className="filter-row-input-boxes">
                            <div>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={subordinatesList}
                                placeholder="Select User"
                                value={
                                  userID == "" ? "" : subordinatesList.value
                                }
                                onInputChange={subordinatesList.label}
                                onChange={(data) => setUserID(data.value)}
                                isSearchable={true}
                              />
                            </div>
                            <div>
                              <Select
                                className="react-select-container-list"
                                classNamePrefix="react-select-list"
                                options={optionsStatusList}
                                placeholder="Select Status"
                                value={optionsStatusList.value}
                                onInputChange={optionsStatusList.label}
                                onChange={(data) => setActiveStatus(data.value)}
                                isSearchable={true}
                              />
                            </div>
                          </div>

                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      )}
                    </div>
                    {/* Filter and Header Section */}

                    {/* USER TABLE START */}

                    <div className="viewsubbordinate-table">
                      <div
                        className="table-responsive subbordinate-scroll"
                        onScroll={(e) =>
                          handleScroll(
                            e,
                            managerSubordinates,
                            totalData,
                            setLoading,
                            setPageSize,
                            PageSize
                          )
                        }
                      >
                        <table className="table subbordinate-table">
                          <thead className="thead-light leaves-thead">
                            <tr>
                              <th>NAME</th>
                              <th>CONTACT</th>
                              <th>DESIGNATION</th>
                              <th>DEPARTMENT</th>
                              <th>STATUS</th>
                              <th>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <>
                                <SkeletonTableData />
                                <SkeletonTableData />
                                <SkeletonTableData />
                              </>
                            ) : managerSubordinates != "" ? (
                              managerSubordinates.map((item, i) => (
                                <tr className="leaves-list-row-data" key={i}>
                                  <td>
                                    <span className="users-list-name">
                                      <img
                                        className="table-data-users-imgages"
                                        src={profileimg}
                                        alt="user-img"
                                      />
                                      <div className="table-row-head-Text">
                                        {item.userFullName}
                                      </div>
                                    </span>
                                  </td>
                                  <td>
                                    <div className="table-row-sub-text">
                                      {item?.email}
                                    </div>
                                    <div className="table-row-sub-text">
                                      {item?.contact}
                                    </div>
                                  </td>
                                  <td>{item?.designation?.designationName}</td>
                                  <td>{item?.designation?.departmentName}</td>
                                  <td>
                                    {item?.enableStatus == 0 ? (
                                      <span className="status-badge pending-status">
                                        InActive
                                      </span>
                                    ) : (
                                      <span className="status-badge complete-status">
                                        Active
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn remove-manger"
                                      onClick={() => {
                                        setUpdateManagerModel(
                                          !updateManagerModel
                                        );
                                        setUpdateUserId(item.userId);
                                      }}
                                    >
                                      <i className="fa fa-users" />
                                      Assign To
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5} className="no-data-found">
                                  ------ NO DATA FOUND ------
                                </td>
                              </tr>
                            )}
                            {loading && <OnScrollLoading />}
                          </tbody>
                        </table>
                      </div>
                      <div className="download-and-pagination"></div>
                    </div>
                    {/* USER TABLE END */}

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>
              ) : (
                <Users />
              )}
            </div>
          </div>
          {/* UPDATE MANAGER SUBBORDINATE MODEL START */}
          {updateManagerModel ? (
            <UpdateManagerModel
              updateManagerModel={updateManagerModel}
              handleClose={handleClose}
              updateUserId={updateUserId}
              getManagerSubordinates={getManagerSubordinates}
            />
          ) : (
            ""
          )}
          {/* /* UPDATE MANAGER SUBBORDINATE MODEL END */}
        </>
      )}
    </>
  );
}

export default ViewSubordinates;
