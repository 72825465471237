import React, { useState, useEffect, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/MeetingPurpose.scss";
import { baseURL } from "../../BaseUrl";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { travelizeAuth } from "../../HeaderAuthentication";

export default function AddPurosemodel(props) {
  const [meetPurpose, setMeetPurpose] = useState("");
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });
  const [enableStatus, setEnableStatus] = useState(null);

  useLayoutEffect(() => {
    setFieldReq(false);
    setMeetPurpose("");
  }, []);

  useEffect(() => {
    {
      props.purposeId !== 0 &&
        props.purposeId !== null &&
        fetchByEditpurposeId();
    }
  }, [props.purposeId]);

  const fetchByEditpurposeId = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/MeetingPurpose/Get/${props.purposeId}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMeetPurpose(res.result.purpose);
          setEnableStatus(res.result.status);
        } else {
          setMeetPurpose("");
          setEnableStatus(null);
        }
      });
  };

  //  ADDING MEETING PURPOSE START
  const addMeetingPurpose = (e) => {
    e.preventDefault();
    if (meetPurpose) {
      const purposeData = {
        purpose: meetPurpose,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(purposeData),
      };
      fetch(`${baseURL}/api/MeetingPurpose/AddPurpose`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchMeetingPurposeData();
            swal.showAlert(
              "Success",
              `Meeting Purpose Added Successfully`,
              "success"
            );
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required!",
      });
    }
  };
  //  ADDING MEETING PURPOSE START END

  //  EDIT MEETING PURPOSE START
  const editMeetingPurpose = (e) => {
    e.preventDefault();
    if (meetPurpose) {
      const purposeData = {
        purpose: meetPurpose,
        purposeId: props.purposeId,
        status: JSON.parse(enableStatus),
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(purposeData),
      };
      fetch(`${baseURL}/api/MeetingPurpose/EditPurpose`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchMeetingPurposeData();
            swal.showAlert(
              "Success",
              `Meeting Purpose Updated Successfully`,
              "success"
            );
          } else {
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required!",
      });
    }
  };
  //  EDIT MEETING PURPOSE START END

  return (
    <div>
      <Modal show={props.show} className="main_model add-meet-purpose-model">
        <div className="add-meet-purpose-heading">
          <h6 className="page-header-text">
            {props.purposeId ? "EDIT PURPOSE" : "ADD PURPOSE"}
          </h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-meetpurpose">
            <div className="company-name mt-2">
              <div className="mb-2">
                <FloatingLabel
                  label="Meeting Purpose"
                  className={`${
                    meetPurpose
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <input
                    type="text"
                    className="form-control form add-input"
                    id="exampleFormControlInput1"
                    placeholder="Meeting Purpose"
                    value={meetPurpose}
                    onChange={(e) => setMeetPurpose(e.target.value)}
                    required
                  />
                </FloatingLabel>
                {fieldReq && meetPurpose == "" ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>
              {props.purposeId !== 0 && props.purposeId !== null && (
                <div className="mt-3">
                  <span className="enable-status d-flex">
                    <h6>Enable</h6>
                    <span className="enable-radio-client d-flex">
                      <div className="form-check enable-radio-status">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={enableStatus == 1 ? true : false}
                          onChange={() => {
                            setEnableStatus(1);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check enable-radio-status">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={enableStatus == 0 ? true : false}
                          onChange={() => {
                            setEnableStatus(0);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          No
                        </label>
                      </div>
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn
            onClickEffect={
              props.purposeId !== 0 && props.purposeId !== null
                ? editMeetingPurpose
                : addMeetingPurpose
            }
            btnName={props.purposeId ? "Update" : "Save"}
          />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
