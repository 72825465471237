import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSingleUserRefferalData } from "../../redux/action";
import PanBankDetailsComp from "../ReUseComponents/PanBankDetailsComp";

function UserBankDetailsModal(props) {
  let dispatch = useDispatch();
  const { singleUserReferralsData } = useSelector((state) => state.data);

  const gfReferelProfileId = props.gfReferelProfileId;

  useEffect(() => {
    if (gfReferelProfileId) {
      dispatch(loadSingleUserRefferalData(gfReferelProfileId));
    }
  }, [gfReferelProfileId]);

  const handleCloseModal = () => {
    props.setShowModal(false);
  };

  // modal - right - fade - bankdetails;

  return (
    <div>
      {props.showModal && (
        <PanBankDetailsComp
          pageName="UserBankDetails"
          showModal={props.showModal}
          handleCloseModal={handleCloseModal}
          singleUserReferralsData={singleUserReferralsData}
          addEditButtonClick={props.addBankDetails}
          gfReferelProfileId={gfReferelProfileId}
        />
      )}
    </div>
  );
}

export default UserBankDetailsModal;
