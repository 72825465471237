import React, { useState, useEffect, useLayoutEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Leaves.scss";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import { SkeletonStdTableBody } from "../Skeleton Loading/SkeletonStdTable";
import { Col, Row } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";
import { useNavigate } from "react-router-dom";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { exportExcel } from "../DownloadFile";
import { travelizeAuth } from "../HeaderAuthentication";
import { loggedInId } from "../LoginRole";
import { useDispatch, useSelector } from "react-redux";
import {
  loadManagerDropdown,
  loadUserDropdownByManager,
} from "../../redux/action";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

export default function LeaveBalance(props) {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  /* HEADER AUTH END */

  let dispatch = useDispatch();
  const { managerDropdown } = useSelector((state) => state.data);
  const { userDropdownByManager } = useSelector((state) => state.data);

  const [bulkUploadModel, setBulkUploadModel] = useState(false);
  const [filterUserId, setFilterUserId] = useState("");
  const [status, setStatus] = useState(2);
  const [leaveTypeID, setLeaveTypeID] = useState(0);
  const [leaveBalanceDetails, setLeaveBalanceDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const [yearDropdown, setYearDropdown] = useState([]);
  const [filterYear, setFilterYear] = useState({
    value: 2024,
    label: 2024,
  });
  const [managerId, setManagerId] = useState({
    value: loggedInId,
    label: "Select Manager",
  });

  let tableHeader = [
    "NAME",
    "YEAR",
    "LEAVE TYPE",
    "ALLOTTED",
    "AVAILED",
    "AVAILABLE",
    "ACTIONS",
  ];

  const handleBulkUpload = () => {
    setBulkUploadModel(!bulkUploadModel);
    props.setUploadModule(!props.uploadModule);
    props.setAddModules(!props.addModules);
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);

  var pageDataFrom, pageDataTill;

  pageDataFrom = currentPage * PageSize - PageSize + 1;
  pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  useLayoutEffect(() => {
    const currentYear = new Date().getFullYear();
    setYearDropdown([
      { value: currentYear - 2, label: currentYear - 2 },
      { value: currentYear - 1, label: currentYear - 1 },
      { value: currentYear, label: currentYear },
      { value: currentYear + 1, label: currentYear + 1 },
      { value: currentYear + 2, label: currentYear + 2 },
    ]);
  }, []);

  const handleOpenClose = (id) => {
    props.handleAddEditModal();
    props.setLeaveBalaneID(id);
  };

  // FETCH SELECT DROPDOWN DATA
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadManagerDropdown());
    dispatch(loadUserDropdownByManager(managerId.value));
  }, [managerId]);

  // FETCH SELECT DROPDOWN DATA

  // FETCH LEAVE DETAILS START
  useEffect(() => {
    fetchLeaveBalanceDetails();
  }, [
    filterUserId,
    status,
    currentPage,
    PageSize,
    leaveTypeID,
    filterYear,
    managerId,
  ]);

  const fetchLeaveBalanceDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/LeaveBalance/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&Status=${status}&ManagerId=${managerId.value}&UserId=${filterUserId}&LeaveTypeID=${leaveTypeID}&Year=${filterYear.value}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLeaveBalanceDetails(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setLeaveBalanceDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };
  // FETCH LEAVE DETAILS END

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManagerId({ value: loggedInId, label: "Select Manager" });
    setStatus(2);
    setLeaveTypeID(0);
    setFilterYear({
      value: 2024,
      label: 2024,
    });
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelDownload = () => {
    exportExcel(
      `${baseURL}/api/Export/LeaveBalanceExcelExport?ManagerId=${managerId.value}&UserID=${filterUserId}&LeaveTypeID=${leaveTypeID}&Status=${status}&Year=${filterYear.value}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "LeaveBalance.xlsx",
      setDownloadIsLoading
    );
  };
  //* EXPORT FILE END *//

  // Page navigation
  let navigate = useNavigate();
  const goToPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div>
      <div id="main-page">
        {errorMessage ? (
          <InternetIssue />
        ) : (
          <>
            {userDetails !== null && userDetails !== "" ? (
              <>
                {!props.addModules ? (
                  <div>
                    {/* PAGE CONTENT START */}

                    {/* Filter and Header Section */}
                    <div className="user-table">
                      <div className="page-filter-section">
                        <div className="filter-row-input-boxes">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={managerDropdown}
                              placeholder="Select Manager"
                              value={managerId}
                              onInputChange={managerDropdown.label}
                              onChange={(data) => setManagerId(data)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={userDropdownByManager}
                              placeholder="Select User"
                              value={
                                filterUserId === ""
                                  ? ""
                                  : userDropdownByManager.value
                              }
                              onInputChange={userDropdownByManager.label}
                              onChange={(data) => setFilterUserId(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={yearDropdown}
                              placeholder="Select Year"
                              value={filterYear}
                              onInputChange={yearDropdown.label}
                              onChange={(data) => setFilterYear(data)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={optionsStatusList}
                              placeholder="Select Status"
                              value={
                                status === 2 ? "" : optionsStatusList.value
                              }
                              onInputChange={optionsStatusList.label}
                              onChange={(data) => setStatus(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                          <SbDropDownBtn
                            openAddModal={() => handleOpenClose(null)}
                            pageName="Leave"
                            addBulkModel={handleBulkUpload}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Filter and Header Section */}

                    {/* USER TABLE START */}
                    <div className="leaves-table">
                      <div
                        className="table-responsive leaves-scroll"
                        onScroll={(e) =>
                          handleScroll(
                            e,
                            leaveBalanceDetails,
                            totalData,
                            setLoading,
                            setPageSize,
                            PageSize
                          )
                        }
                      >
                        <table className="table leaves-table">
                          <SbTableHeader tableHeader={tableHeader} />
                          <tbody>
                            {isLoading ? (
                              <>
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                              </>
                            ) : leaveBalanceDetails !== "" ? (
                              leaveBalanceDetails?.map((data, i) => (
                                <tr className="leaves-list-row-data" key={i}>
                                  <td>
                                    <span className="leaves-list-names d-flex">
                                      <img
                                        className="table-data-leaves-imgages"
                                        src={profileimg}
                                        alt="profile-img"
                                      />
                                      <div className="table-row-head-Text">
                                        {data?.userFullName}
                                      </div>
                                    </span>
                                  </td>
                                  <td>{data?.year}</td>
                                  <td>{data?.leaveTypeName}</td>
                                  <td>{data?.allowed}</td>
                                  <td>{data?.taken}</td>
                                  <td>{data?.remain}</td>
                                  {/* <td>{data?.carriedForward}</td> */}

                                  <td>
                                    <button
                                      className="btn leave-update-status"
                                      onClick={() => {
                                        handleOpenClose(data.leaveBalanceID);
                                      }}
                                    >
                                      <i className="bi bi-pencil-fill" />
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="no-data-found">
                                <td colSpan={8}>------ NO DATA FOUND ------</td>
                              </tr>
                            )}
                            {loading && <OnScrollLoading />}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* USER TABLE END */}

                    <div className="download-and-pagination">
                      <SbDownloadBtn
                        btnName="Download"
                        onClickEffect={exportExcelDownload}
                      />
                    </div>
                    {/* PAGE CONTENT END*/}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <AccessDenied />
            )}
            <>
              {downloadIsLoading && (
                <>
                  <DownloadLoading />
                </>
              )}
            </>
          </>
        )}
      </div>
    </div>
  );
}
