import uuid from "react-uuid";

export const travelByOptions = [
  {
    name: "Flight",
    value: "Flight",
    icon: "flight",
    travel: 1,
  },
  {
    name: "Train",
    value: "Train",
    icon: "train",
    travel: 2,
  },
  {
    name: "Bus",
    value: "Bus",
    icon: "directions_bus",
    travel: 3,
  },
  {
    name: "Cab",
    value: "Cab",
    icon: "local_taxi",
    travel: 1,
  },
  {
    name: "Hotel",
    value: "Hotel",
    icon: "hotel",
    travel: 1,
  },
  {
    name: "Advance Cash",
    value: "Advance_Cash",
    icon: "payments",
    travel: 1,
  },
  {
    name: "Other",
    value: "Other",
    icon: "other_admission",
    travel: 1,
  },
];

export const travelDetails = {
  Flight: [
    {
      id: uuid(),
      tripType: "One Way",
      fromLocation: "Hubli ",
      toLocation: "Delhi",
      departureDateTime:
        "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      returnDateTime: "",
      travellers: "4",
    },
  ],
  Train: [
    {
      id: uuid(),
      tripType: "Round",
      fromLocation: "Hubli",
      toLocation: "Bengaluru",
      departureDateTime:
        "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      returnDateTime: "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      travellers: "6",
    },
  ],
  Bus: [
    {
      id: uuid(),
      tripType: "Multi City",
      fromLocation: "Bengaluru",
      toLocation: "Mysore",
      departureDateTime:
        "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      returnDateTime: "",
      travellers: "3",
    },
    {
      tripType: 3,
      fromLocation: "Bengaluru",
      toLocation: "Kolar",
      departureDateTime:
        "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      returnDateTime: "",
      travellers: "3",
      id: uuid(),
    },
    {
      tripType: 3,
      fromLocation: "Mysore",
      toLocation: "Bengaluru",
      departureDateTime:
        "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      returnDateTime: "",
      travellers: "3",
      id: uuid(),
    },
    {
      tripType: 3,
      fromLocation: "Kolar",
      toLocation: "Bengaluru",
      departureDateTime:
        "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      returnDateTime: "",
      travellers: "3",
      id: uuid(),
    },
  ],
  Cab: [
    {
      id: uuid(),
      tripType: "One Way",
      fromLocation: "Delhi Airport",
      toLocation: "Client Location New Delhi",
      departureDateTime:
        "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      returnDateTime: "",
      travellers: "4",
    },
  ],
  Hotel: [
    {
      id: uuid(),
      location: "New Delhi",
      checkInDate: "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      checkOutDate: "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      travellers: "4",
      rooms: "4",
    },
    {
      location: "Bengaluru",
      checkInDate: "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      checkOutDate: "Sat May 18 2024 16:15:00 GMT+0530 (India Standard Time)",
      travellers: "6",
      rooms: "1",
      id: uuid(),
    },
  ],
};
