import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "../../styles/ProductsViewPage.scss";
import "../../styles/Products.scss";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/TravelReport.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import { useReactToPrint } from "react-to-print";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import SalesBee from "../../images/MatricLogo.svg";
import SchillerLogo from "../../images/schiller_logo.jpg";
import { loadSingleTravelData } from "../../redux/action";
import moment from "moment";
import { baseURL } from "../BaseUrl";
import { FormattedAmount } from "../FormattedAmount";
import Footer from "../Footer";
import CashVoucherDetails from "./CashVoucherDetails";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function CashVoucher(props) {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let tableHeader = [
    { name: "SR. No.", align: "left", width: "80px" },
    { name: "Account Head ", align: "left", width: "auto" },
    { name: "Particulars", align: "left", width: "auto" },
    { name: "Amount", align: "left", width: "auto" },
  ];

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { clientId } = useParams();
  const { getSingleTravelReportData } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const mergeRows = (accumulator, currentValue) => {
    const existingRow = accumulator.find(
      (row) =>
        row.fullName === currentValue.fullName &&
        row.totalClaimAmount === currentValue.totalClaimAmount &&
        row.meetingDate === currentValue.meetingDate &&
        row.dayName === currentValue.dayName
    );
    if (existingRow) {
      existingRow.singleTravelInfo.push({
        // meetingDate: currentValue.meetingDate,
        // dayName: currentValue.dayName,
        startLoc: currentValue.startLoc?.location,
        endLoc: currentValue.endLoc?.location,
        distanceTravelled: currentValue.distanceTravelled,
        remark: currentValue.remark,
      });
    } else {
      accumulator.push({
        fullName: currentValue.fullName,
        totalClaimAmount: currentValue.totalClaimAmount,
        meetingDate: currentValue.meetingDate,
        dayName: currentValue.dayName,
        singleTravelInfo: [
          {
            // meetingDate: currentValue.meetingDate,
            // dayName: currentValue.dayName,
            startLoc: currentValue.startLoc?.location,
            endLoc: currentValue.endLoc?.location,
            distanceTravelled: currentValue.distanceTravelled,
            remark: currentValue.remark,
          },
        ],
      });
    }
    return accumulator;
  };

  const mergedMotRows =
    getSingleTravelReportData.length > 0
      ? getSingleTravelReportData.reduce(mergeRows, [])
      : [];
  // console.log(mergedMotRows, "mergedMotRows");

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [imageClicked, setImageClicked] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState(null);

  const handleImageClicked = (imageUrl) => {
    setImageClicked(!imageClicked);
    setClickedImageUrl(imageUrl);
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  const componentRef = useRef();
  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* FOOTER START */}
      <Footer />
      {/* FOOTER START */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">CASH VOUCHER</h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col"></Col>
              </Row>
            </div>

            <div className="sb-table-div sb-table-setion">
              <div>
                <Paper
                  sx={{ width: "100%", overflow: "hidden" }}
                  className="table-main-div"
                >
                  <TableContainer
                    sx={{ maxHeight: 440 }}
                    // onScroll={(e) => handleScroll(e, props.productsData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                  >
                    <div ref={componentRef} className="table-data_n_images">
                      <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className={`whatsapp-table-container`}
                      >
                        <TableHead className="custom-table-header">
                          <TableRow>
                            <TableCell
                              align="center"
                              className="b-1"
                              colSpan={2}
                            >
                              <div className="travel-report-client-image">
                                <img src={SchillerLogo} alt="Logo" />
                              </div>
                            </TableCell>

                            <TableCell
                              align="center"
                              colSpan={2}
                              className="reprot-table-hading b-1"
                            >
                              CASH VOUCHER
                            </TableCell>
                            <TableCell
                              align="center"
                              className="b-1"
                              colSpan={2}
                            >
                              <div className="travel-report-client-image">
                                <img src={SalesBee} alt="" />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                          <TableBody>
                            {props.tableHeader.map((name, i) => (
                              <SkeletonTableData key={i} />
                            ))}
                          </TableBody>
                        ) : (
                          <>
                            <TableBody>
                              <TableRow align="center">
                                <TableCell className="bl-1 br-1">
                                  <b>Payee Name :</b>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Maqsood
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <b>Voucher No. :</b>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    AFW053/04/23/001
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Voucher Date :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    30-04-2023
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow align="center">
                                <TableCell className="br-1 bl-1">
                                  <div>
                                    <b>Employee No. :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    AFW053
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div>
                                    <b>Department :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1" colSpan={3}>
                                  <div className="table-row-head-Text color-green">
                                    Accounts & Finance
                                  </div>
                                </TableCell>
                              </TableRow>

                              <TableRow align="center">
                                <TableCell className="br-1 bl-1 ">
                                  <div>
                                    <b>Branch :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1">
                                  <div className="table-row-head-Text color-green">
                                    Mumbai
                                  </div>
                                </TableCell>

                                <TableCell className="br-1">
                                  <div>
                                    <b>Particulars :</b>
                                  </div>
                                </TableCell>
                                <TableCell className="br-1" colSpan={3}>
                                  <div className="table-row-head-Text color-green">
                                    Travel voucher, Xerox and Tools.
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </>
                        )}
                      </Table>

                      <Table
                        stickyHeader
                        aria-label="sticky table table-responsive"
                        className="whatsapp-table-container p-2"
                      >
                        <TableHead className="custom-table-header">
                          <TableRow>
                            {tableHeader.map((name, i) => (
                              <TableCell
                                key={i}
                                align={name.align}
                                style={{
                                  minWidth: name.width,
                                }}
                                className="bt-1 bg-primaryLight"
                              >
                                {name.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                          <TableBody>
                            {props.tableHeader.map((name, i) => (
                              <SkeletonTableData key={i} />
                            ))}
                          </TableBody>
                        ) : (
                          <>
                            <TableBody>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  style={{ width: "80px" }}
                                >
                                  <div>1</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Travel Voucher</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>5-8-24 to 3-9-24</div>
                                </TableCell>

                                <TableCell className="tr-tcell-br">
                                  <div>10535.00</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  style={{ width: "80px" }}
                                >
                                  <div>2</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Train Pass</div>
                                  <div>Panvel-Bonivali</div>
                                  <div>Kunla-Kanjunmang</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>2-9-24 to 1-10-24</div>
                                </TableCell>

                                <TableCell className="tr-tcell-br">
                                  <div>1455.00</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  style={{ width: "80px" }}
                                >
                                  <div>3</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Xerox</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>

                                <TableCell className="tr-tcell-br">
                                  <div>32.00</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  style={{ width: "80px" }}
                                >
                                  <div>4</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>RJ-14 Connector - Tools</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>

                                <TableCell className="tr-tcell-br">
                                  <div>218.00</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  style={{ width: "80px" }}
                                >
                                  <div>5</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>Phone Repair</div>
                                </TableCell>
                                <TableCell className="tr-tcell-br">
                                  <div>-</div>
                                </TableCell>

                                <TableCell className="tr-tcell-br">
                                  <div>1400.00</div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={2}
                                >
                                  <div>
                                    <b>Amount in words :</b>&nbsp; Thirteen
                                    thousand seven hundred and fourty rupees
                                    only.
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="tr-tcell-br"
                                  style={{ textAlign: "right" }}
                                >
                                  <div>
                                    <b>TOTAL</b>
                                  </div>
                                </TableCell>

                                <TableCell className="tr-tcell-br">
                                  <div>
                                    <b>13740.00</b>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                align="center"
                                className="client-list-row-data"
                              >
                                <TableCell
                                  className="tr-tcell-br bl-1"
                                  colSpan={4}
                                >
                                  <div className="verified-by">
                                    <div className="dflex-j-between">
                                      <div className="text-center p-3">
                                        <div>Prepared by</div>
                                        <div>
                                          <b>(Name)</b>
                                        </div>
                                      </div>
                                      <div className="text-center p-3">
                                        <div>Checked by</div>
                                        <div>
                                          <b>(Name)</b>
                                        </div>
                                      </div>
                                      <div className="text-center p-3">
                                        <div>Authorised by</div>
                                        <div>
                                          <b>(Name)</b>
                                        </div>
                                      </div>
                                      <div className="text-center p-3">
                                        <div>Received by</div>
                                        <div>
                                          <b>(Name)</b>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </>
                        )}
                      </Table>
                      <CashVoucherDetails />
                    </div>
                  </TableContainer>
                </Paper>
              </div>
            </div>
            <div className="download-and-pagination">
              <SbDownloadBtn btnName="Download" onClickEffect={pdfDownload} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
