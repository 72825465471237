import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../styles/category.scss';
import { useDispatch, useSelector } from 'react-redux';
import Sidenavbar from './Sidenavbar';
import Topbar from './Topbar';

function TextForm() {
    let dispatch = useDispatch();
  
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
  
    const toggleMenu = () => {
      if (menuCollapse === true) {
        setMenuCollapse(false);
        setToggleshift({ style: "" });
      } else {
        setMenuCollapse(true);
        setToggleshift({ style: "toggle-shift-left" });
      }
    };
  
  
    return (
      <div id="main-page">
        
        {/* TOPBAR END */}
        <div className="page-wrapper">
            <div>
                <div className="page-content">
                <div className='page-head-section'>
                <Row className='search-row'>
                    <Col md={2} className='cat-col'>
                    <div className='page-header-text-div'>
                        <h6 className='page-header-text'>Developers Api</h6>
                    </div>
                    </Col>
                    <Col md={10} className='cat-col'>
                    
                    </Col>
                </Row>
                </div>
                <div className='page-content-section'>

                </div>
                </div>
            </div>
        </div>
      </div>
    )
  }

export default TextForm;
