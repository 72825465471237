import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSingleUserRefferalData } from "../../redux/action";
import PanBankDetailsComp from "../ReUseComponents/PanBankDetailsComp";

function BankDetailsModal(props) {
  let dispatch = useDispatch();
  const { singleUserReferralsData } = useSelector((state) => state.data);

  useEffect(() => {
    if (props.selectedUser.value) {
      dispatch(loadSingleUserRefferalData(props.selectedUser.value));
    }
  }, [props.selectedUser]);

  const handleCloseModal = () => {
    props.setShowModal(false);
  };

  return (
    <div>
      {props.showModal && (
        <PanBankDetailsComp
          pageName="PassBook"
          showModal={props.showModal}
          handleCloseModal={handleCloseModal}
          singleUserReferralsData={singleUserReferralsData}
        />
      )}
    </div>
  );
}

export default BankDetailsModal;
