import React, { useState, useEffect, useLayoutEffect } from "react";
import { baseURL } from "../../BaseUrl";
import "../../../styles/CommonStyle.scss";
import "../../../styles/TrackClients.scss";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NoDataImg from "../../../images/no-data-found.png";
import Select from "react-select";
import { optionsDistanceList } from "../../DropdownsData";
import AccessDenied from "../../../AccessDenied";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";
import { useDispatch, useSelector } from "react-redux";
import { loadUserDropdown } from "../../../redux/action";
import Footer from "../../Footer";
import OpenStreenMap from "../../OpenStreenMap";
import { travelizeAuth } from "../../HeaderAuthentication";

function TrackClients() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let dispatch = useDispatch();

  const { userDropdown } = useSelector((state) => state.data);

  const [filterUserId, setFilterUserId] = useState("");
  const [searchval, setSearchval] = useState("");
  const [distance, setDistance] = useState(1);
  const [trackClientDetails, setTrackClientDetails] = useState([]);
  const [clientCordinates, setClientCordinates] = useState([]);
  const [clientsCount, setClientsCount] = useState(0);
  const [recentLocation, setRecentLocation] = useState({
    lat: null,
    lng: null,
  });
  const [baseLocation, setBaseLocation] = useState({
    lat: null,
    lng: null,
    location: null,
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadUserDropdown());
  }, []);

  useEffect(() => {
    searchClient();
  }, [filterUserId, distance]);

  const searchClient = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Client/GetNearByClientMap?UserId=${filterUserId}&Radius=${distance}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("TRACK-CLIENT-RES::", res.result);
          setTrackClientDetails(res.result);
          setClientsCount(res.recordCount - 2);
        } else {
          setTrackClientDetails("");
        }
      });
  };

  useEffect(() => {
    if (trackClientDetails) {
      let clientCoordinate = [];
      // let coordinates = trackClientDetails.mapCoordinate;
      for (var i = 0; i < trackClientDetails.length; i++) {
        if (trackClientDetails[i].clientName.trim() === "RecentLocation") {
          setRecentLocation({
            lat: JSON.parse(trackClientDetails[i].clientLoc.latitude),
            lng: JSON.parse(trackClientDetails[i].clientLoc.longitude),
          });
        } else if (trackClientDetails[i].clientName.trim() === "BaseLocation") {
          setBaseLocation({
            lat: JSON.parse(trackClientDetails[i].clientLoc.latitude),
            lng: JSON.parse(trackClientDetails[i].clientLoc.longitude),
            location: trackClientDetails[i].clientLoc.location,
          });
        } else if (trackClientDetails[i].addedbyUserFullName.trim() != "NA") {
          clientCoordinate.push({
            lat: JSON.parse(trackClientDetails[i].clientLoc.latitude),
            lng: JSON.parse(trackClientDetails[i].clientLoc.longitude),
            location: trackClientDetails[i].clientLoc.location,
            clientName: trackClientDetails[i].clientName,
            distnace: trackClientDetails[i].distance,
            contactPerson: trackClientDetails[i].contactPersonName,
          });
        }
      }
      setClientCordinates(clientCoordinate);
    }
  }, [filterUserId, distance, trackClientDetails]);

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setDistance(1);
    setClientsCount(0);
  };

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          <div>
            {/* Filter and Header Section */}
            <div className="user-table">
              <Row className="search-row">
                <Col md={3} sm={4} xs={12} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToSettingPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text"> TRACK CLIENT </h6>
                  </div>
                </Col>
                <Col md={3} sm={4} xs={12} className="cat-col">
                  <div className="table-row-head-Text">
                    CLIENTS COUNT : {clientsCount}
                  </div>
                </Col>
                <Col md={6} sm={4} xs={12} className="cat-col">
                  <div className="search-bar-header" style={{ float: "right" }}>
                    {/* <SbDownloadBtn onClickEffect={exportExcel} /> */}
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div
                        className="header-filter-btn"
                        onClick={() => setFilter(!filter)}
                      >
                        <p className="card-head">
                          <i className="bi bi-sliders" />
                          &nbsp;&nbsp;Filters
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {filter && (
                <div className="page-filter-section">
                  <div className="filter-row-input-boxes">
                    <div>
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={userDropdown}
                        placeholder="Select User"
                        value={filterUserId === "" ? "" : userDropdown.value}
                        onInputChange={userDropdown.label}
                        onChange={(data) => setFilterUserId(data.value)}
                        isSearchable={true}
                      />
                    </div>
                    <div>
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsDistanceList}
                        placeholder="Select Distance"
                        value={distance === 1 ? "" : optionsDistanceList.value}
                        onInputChange={optionsDistanceList.label}
                        onChange={(data) => setDistance(data.value)}
                        isSearchable={true}
                      />
                    </div>
                    <MiClearButton
                      onClickEffect={clearSerachBySelect}
                      btnName="Clear"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Filter and Header Section */}
            {/* TRACKER TABLE END */}
            {trackClientDetails !== "" && trackClientDetails !== null ? (
              <div className="row track-client view-on-map">
                <div className="col-md-9 col-sm-12 col-12 track-client-page-google-map">
                  <div className="google-map-box">
                    {/* <GoogleMapComp
                          pageType="TrackClients"
                          recentLocation={recentLocation}
                          clientCordinates={clientCordinates}
                          baseLocation={baseLocation}
                        /> */}
                    <OpenStreenMap
                      pageType="TrackClients"
                      // recentLocation={recentLocation}
                      clientCordinates={clientCordinates}
                      centerLatLng={baseLocation}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-12 track-client-page-client-visits">
                  <div className="input-group mt-1 client-track-search">
                    <input
                      type="search"
                      placeholder="Search"
                      className="form-control search"
                      onChange={(e) => setSearchval(e.target.value)}
                    />
                    <i className="fa fa-search" />
                  </div>
                  <div className="track-client-meet-detail-scroll">
                    {trackClientDetails
                      .filter((cl) =>
                        cl.clientName.toLowerCase().includes(searchval)
                      )
                      .map((data, i) => (
                        <div className="card view-on-map-card" key={i}>
                          <div className="card-body track-client-meeting-address">
                            <span className="track-client-meeting-detail-name">
                              <i className="bi bi-caret-right-fill color-yellow" />
                              <span className="meeting-track-client-user-detail">
                                <h6>
                                  <b className="color-yellow">Name :</b>{" "}
                                  <span className="color-green">
                                    {data?.clientName}
                                  </span>
                                </h6>
                              </span>
                            </span>
                            <p>
                              <b>Location :</b>{" "}
                              <sapn>{data?.clientLoc?.location}</sapn>
                            </p>
                            <p>
                              <b className="color-yellow">Distance :</b>{" "}
                              <span className="color-green">
                                {data?.distance}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="hv-center">
                <img
                  src={NoDataImg}
                  alt="no-data-img"
                  className="mCS_img_loaded"
                />
              </div>
            )}
          </div>

          {/* MAIN BODY END */}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default TrackClients;
