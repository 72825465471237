import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import '../../../styles/Dashboard.scss';
import { withScriptjs, withGoogleMap, Polygon, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from "react-google-maps";
import { compose, withProps, withState, withHandlers } from "recompose";
// import mapStyles from './mapStyles.json';
import { React_Google_API_Key } from "../../../GoogleApiKey";
import { DirectionsService } from '@react-google-maps/api';

window.google = window.google || {};
// const directionsService = new window.google.maps.DirectionsService();

function ActivityGoogleMap(props) {

    let locationArray = props.locationArray;
    const refMap = useRef(null);
    const [zoom, setZoom] = useState(10);
    // const defaultMapOptions = {
    //     styles: mapStyles
    // };
    const [centerValue, setCenterValue] = useState({ lat: "", lng: "" })

    const handleZoomChanged = () => {
        if (refMap.current) {
            const map = refMap.current;
            const bounds = map.getBounds();
            const center = bounds.getCenter();
            setCenterValue({ lat: center.lat(), lng: center.lng() })
        }
        const newZoom = refMap.current.getZoom(); //get map Zoom
        setZoom(newZoom);
    };

    useEffect(() => {
        if(locationArray.length !== 0){
            setCenterValue({ lat: locationArray[4].lat, lng: locationArray[4].lng })
        }
    }, [locationArray])

    const [directions, setDirections] = React.useState(null);

    const directionsCallback = (response) => {
        if (response !== null) {
          if (response.status === 'OK') {
            setDirections(response);
          } else {
            console.log('Directions request failed:', response.status);
          }
        }
      };

//   useEffect(() => {
//     if(locationArray.length !== 0){
//     const waypoints = locationArray.map(location => ({ location }));
//     const origin = waypoints.shift().locationArray;
//     const destination = waypoints.pop().locationArray;

//     directionsService.route(
//       {
//         origin,
//         destination,
//         travelMode: window.google.maps.TravelMode.DRIVING,
//         waypoints,
//       },
//       directionsCallback
//     );
//     }
//   }, [locationArray]);

    // const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
    // const [markIndex, setMarkIndex] = useState([]);

    // const handleMarkerClick = (i) => {
    //     setIsInfoWindowOpen(!isInfoWindowOpen);
    //     if (!isInfoWindowOpen === true) {
    //         setMarkIndex([...markIndex, i])
    //     } else {
    //         setMarkIndex(markIndex.filter(item => item !== i))
    //     }

    // };

    const containerStyle = {
        flex: '1',
        height: '100%'
    };

    const MyMapComponent = compose(
        withProps({
            googleMapURL: "https://maps.googleapis.com/maps/api/js?key=" + React_Google_API_Key + "&libraries=geometry,drawing,places",
            loadingElement: <div />,
            containerElement: <div className='map-container' style={{ height: "100%", width: "100%" }} />,
            mapElement: <div className='map-element' />,
        },),
        withScriptjs,
        withGoogleMap)((props) =>
            <GoogleMap
                {...props}
                mapContainerStyle={containerStyle}
                ref={refMap}
                defaultZoom={zoom}
                defaultCenter={{ lat: 20.5937, lng: 78.9629 }}
                center={centerValue.lat !== "" ? centerValue : { lat: 20.5937, lng: 78.9629 }}
                // defaultOptions={zoom < 8 ? defaultMapOptions : ""}
                onZoomChanged={handleZoomChanged}
            >

                {locationArray.length !== 0 &&
                locationArray.map((location, index) => (
                        <Marker key={index} position={location} />
                        ))}
                {locationArray.length !== 0 && <DirectionsService
                        options={{
                            origin: locationArray[0],
                            destination: locationArray[locationArray.length - 1],
                            waypoints: locationArray.slice(1, -1),
                            travelMode: 'DRIVING',
                        }}
                        callback={directionsCallback}
                        />
                    }
                {directions && <DirectionsRenderer directions={directions} />}
                {/* {activityData && activityData.map((item, i) => (
                    <React.Fragment key={i}>
                        {item.checkOutTime == null &&
                            <Marker
                                position={{ lat: parseFloat(item.cordLoc.latitude), lng: parseFloat(item.cordLoc.longitude) }}
                                icon={{
                                    url: '../images/dashboard/marker-icon.png',
                                    scaledSize: new window.google.maps.Size(6 + zoom, 6 + zoom)
                                }}
                                className="pin bounce"
                                onClick={(e) => handleMarkerClick(i)}
                            >
                                {markIndex.includes(i) && (<div className="my-map-container">
                                    <InfoWindow
                                        onCloseClick={(e) => handleMarkerClick(i)}
                                        position={{ lat: parseFloat(item.cordLoc.latitude), lng: parseFloat(item.cordLoc.longitude) }}
                                    >
                                        <div className="info-window-content d-flex align-items-center">
                                            <div className='mrkr-img-div'>
                                            </div>&nbsp;&nbsp;
                                            <div><b>{item.fullName}</b></div>
                                        </div>
                                    </InfoWindow>
                                </div>)}
                            </Marker>
                        }
                    </React.Fragment>
                ))} */}


            </GoogleMap>);

    return (
        <div className='map-conrtainer'>
            <MyMapComponent style={{ height: '100%' }} />
        </div>
    )
}

export default ActivityGoogleMap;
