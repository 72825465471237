import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../styles/ViewClient.scss";
import { loadOrderHistory } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { dateFormatIST } from "../FormattedAmount";
import OrderStatus from "./OrderStatus";
import Skeleton from "react-loading-skeleton";

function OrderHistory(props) {
  let orderId = props.orderId;
  let prodId = props.prodId;
  let dispatch = useDispatch();
  const { orderHistory } = useSelector((state) => state.data);
  const handleCloseModal = () => {
    props.setShowModal(false);
    // dispatch(loadOrderHistory(null));
  };

  const [tableLoading, setTableLoading] = useState(true);
  useEffect(() => {
    if (props.orderId && props.prodId) {
      dispatch(loadOrderHistory(orderId, props.prodId, setTableLoading));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orderId, props.prodId]);

  const [itemName, setItemName] = useState("");
  useEffect(() => {
    if (prodId && props.productItems.length != 0) {
      props.productItems.map((item) => {
        if (item.orderItemID === prodId) {
          setItemName(item.product_Name);
        }
      });
    }
  }, [prodId, props.productItems]);

  return (
    <div>
      {props.showModal && (
        <div className="modal-backdrop">
          <div className="modal-right-fade">
            <Modal.Dialog className="right-fade-modal-dialog">
              <div>
                <Modal.Header closeButton onHide={handleCloseModal}>
                  <Modal.Title className="client-modal-header card-head">
                    <h6 className="page-header-text">Order History</h6>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="Order-history-row">
                    <p>Order # : {props.singleOrder.orderID}</p>
                    <p>
                      Order Date : {dateFormatIST(props.singleOrder.orderDate)}
                    </p>
                    <p>
                      Item Name :{" "}
                      <span className="color-green">{itemName}</span>
                    </p>
                  </div>
                  <div className="history-div">
                    <h6>History Details</h6>
                    {tableLoading ? (
                      <Skeleton />
                    ) : (
                      <>
                        {orderHistory == undefined ||
                        orderHistory.length === 0 ? (
                          <div className="sb-history-details">
                            <img
                              src="../../../images/DefaultImages/NodataFound.jpg"
                              alt="No-Data image"
                            />
                            <p>No history available for this Item</p>
                          </div>
                        ) : (
                          <OrderStatus
                            orderHistory={orderHistory}
                            orderDate={props.singleOrder.orderDate}
                            itemName={itemName}
                          />
                        )}
                      </>
                    )}
                  </div>
                </Modal.Body>
              </div>
            </Modal.Dialog>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderHistory;
