import React, { useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Amc.scss";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import AddAmc from "./AddAmc";
import EditAmc from "./EditAmc";
// import ViewProductDetails from "./ViewProductDetails";

export default function Amc() {

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editAmc, setEditAmc] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
    setEditAmc(false);
  };

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            {/* USER TABLE START */}
            <div className="amc-table">
              <div className="seleting-menus">
                <div className="page-title">AMC</div>

                <div className="page-top-bar-dropdowns">
                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Select Product"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                  // isClearable={true}
                  />

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Select Category"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                  // isClearable={true}
                  />

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Sub-Category"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                  // isClearable={true}
                  />

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // options={MOTList}
                    placeholder="Price"
                    // value={MotId === "" ? "" : MOTList.value}
                    // onInputChange={MOTList.label}
                    // onChange={(data) => setMotId(data.value)}
                    isSearchable={true}
                  // isClearable={true}
                  />

                  <button
                    type="button"
                    className="btn btn-search-track"
                  // onClick={clearSerachBySelect}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-eraser"> </i>
                      <h6>Clear</h6>
                    </span>
                  </button>

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    isSearchable={true}
                  />

                  <button
                    type="button"
                    className="btn btn-add-amc"
                    onClick={() => setShow(!show)}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-plus-square" /> &nbsp;
                      <h6>Add New</h6>
                    </span>
                  </button>
                </div>
              </div>
              <div className="table-responsive amcs-scrollbar">
                <table className="table amc-table-data">
                  <thead className="thead-light amcs-thead">
                    <tr>
                      <th>AMC DETAILS</th>
                      <th>PRODUCT DETAILS</th>
                      <th>COMPANY DETAILS</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="amc-table-row-data">
                      <td>
                        <p>
                          <strong>Number :</strong> AMC-12345
                        </p>
                        <p>
                          <strong>Type :</strong> Premium
                        </p>
                        <p>
                          <strong>Invoice Date :</strong> 20-04-2023
                        </p>
                        <p>
                          <strong>Start Date : </strong>01/01/2021
                        </p>
                        <p>
                          <strong>End Date : </strong>31/12/2022
                        </p>
                        <p>
                          <strong>Free Service : </strong>6
                        </p>
                      </td>
                      <td>
                        <p>
                          <strong>Name :</strong> Mobile
                        </p>
                        <p>
                          <strong>Code :</strong> M-005
                        </p>
                        <p>
                          <strong>Serial No. :</strong> SL895623147
                        </p>
                        <p>
                          <strong>Model Name :</strong> Galaxy S3
                        </p>
                        <p>
                          <strong>Model No. :</strong> GS-03
                        </p>
                      </td>
                      <td style={{ maxWidth: "220px" }}>
                        <p>
                          <strong>Name :</strong> Cargo Electronics
                        </p>
                        <p>
                          {" "}
                          <strong>Email : </strong> cargoelectronic@gmail.com
                        </p>
                        <p>
                          <strong>Phone :</strong>+91 9955223344
                        </p>
                        <p>
                          <strong>Address : </strong>1st Floor, Mallikarjun
                          Avenue, Koppikar Road, Hubli-580023, Dt:Dharwad,
                          Karnataka
                        </p>
                      </td>

                      <td>
                        <button
                          onClick={() => setEditAmc(!editAmc)}
                          className="btn amc-edit-link"
                        >
                          <i className="bi bi-pencil-fill"></i> Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* USER TABLE END */}

            <div className="download-and-pagination">
              <button
                type="button"
                className="btn btn-download-excel"
              // onClick={exportExcelActiivity}
              >
                <i className="fa fa-download" /> &nbsp; Download
              </button>
            </div>

            {/* ADD PRODUCT MODEL START */}
            {show && <AddAmc show={show} handleClose={handleClose} />}
            {/* ADD PRODUCT MODEL END */}

            {/* EDIT PRODUCT MODEL START */}
            {editAmc && (
              <EditAmc
                editAmc={editAmc}
                handleClose={handleClose}
              />
            )}
            {/* EDIT PRODUCT MODEL END */}

            {/* PAGE CONTENT END*/}
          </div>
        </div>

        {/* MAIN BODY END */}
      </div>
    </div>
  );
}
