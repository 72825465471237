import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { loadUserRegion } from "../../../redux/action";
import "../../../styles/VhdBranch.scss";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";

export default function AddBranchModel(props) {
  let dispatch = useDispatch();

  const { userRegionResult } = useSelector((state) => state.data);
  const [countryList, setCountryList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [country, setCountry] = useState({ value: null, label: "" });
  const [zone, setZone] = useState({ value: null, label: "" });
  const [state, setState] = useState({ value: null, label: "" });

  const [branchData, setBranchdata] = useState({
    branchCode: "",
    branchName: "",
    countryId: "",
    zoneId: "",
    stateId: "",
    location: "",
  });

  const { branchName, branchCode, countryId, zoneId, stateId, location } =
    branchData;

  const [enableStatus, setEnableStatus] = useState(1);
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useLayoutEffect(() => {
    dispatch(loadUserRegion());
  }, []);

  useEffect(() => {
    console.log("USER-REGION:", userRegionResult);
    if (Object.keys(userRegionResult).length > 0) {
      setCountryList(
        userRegionResult?.allCountry.map((data) => {
          return {
            value: data.countryID,
            label: data.countryName,
          };
        }) ?? []
      );
      setZoneList(
        userRegionResult?.allZones.map((data) => {
          return { value: data.zoneId, label: data.zoneName };
        }) ?? []
      );
      setStateList(
        userRegionResult?.allStates.map((data) => {
          return { value: data.stateId, label: data.stateName };
        }) ?? []
      );
    }
  }, [userRegionResult]);

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setBranchdata({ ...branchData, [name]: value });
  };

  const handleSelectChange = (data, type) => {
    if (type === "country") {
      setCountry(data);
      branchData.countryId = data.value;
    }
  };

  const addBranchData = () => {
    console.log("ADD-DATA:", "api data come");
  };

  const closeModel = () => {
    props.handleClose();
    props.setBranchID(null);
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={props.branchID ? "Edit Branch" : "Add Branch"}
        formSubTitle={
          props.branchID
            ? "Edit the branch details."
            : "Please add the branch details."
        }
        sidebarItems={props.branchID ? ["Edit Branch"] : ["Add Branch"]}
        childrens={
          <>
            <form onSubmit={addBranchData}>
              <div className="add-branch-to-vhd">
                <div className="add-branch-details">
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Branch Name"
                        className={`${
                          branchName
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Branch"
                          value={branchName}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>

                      {/* {fieldReq && branchName == "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Branch Code"
                        className={`${
                          branchCode
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="number"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Code"
                          value={branchCode}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Contact Name"
                        className={`${
                          branchName
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Contact Name"
                          value={branchName}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Contact Number"
                        className={`${
                          branchCode
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="number"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Contact Number"
                          maxLength={10}
                          value={branchCode}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Contact Email"
                        className={`${
                          branchName
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="email"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Contact Email"
                          value={branchName}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Select Country"
                        className={`${
                          country
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          name="country"
                          options={countryList}
                          placeholder="Select Country"
                          value={country ? country : countryList.value}
                          onInputChange={countryList.label}
                          onChange={(data) =>
                            handleSelectChange(data, "country")
                          }
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Select Zone"
                        className={`${
                          zone
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={zoneList}
                          placeholder="Select Zone"
                          value={zoneId ? zone : zoneList.value}
                          onInputChange={zoneList.label}
                          onChange={(data) => handleSelectChange(data, "zone")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Select State"
                        className={`${
                          state
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={stateList}
                          placeholder="Select State"
                          value={stateId ? state : stateList.value}
                          onInputChange={stateList.label}
                          onChange={(data) => handleSelectChange(data, "state")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Select City"
                        className={`${
                          state
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={stateList}
                          placeholder="Select City"
                          value={state.value && state}
                          onInputChange={stateList.label}
                          onChange={(data) => handleSelectChange(data, "state")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Location *"
                        className={`${
                          location
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <textarea
                          rows="3"
                          cols="20"
                          name="locationtxt"
                          wrap="hard"
                          className="form-control form-textarea add-user-input"
                          id="exampleFormControlInput1"
                          placeholder="Location (Please click the icon for location)"
                          value={location}
                          readOnly={true}
                          // required
                          // onChange={(e) => setLocation(e)}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
                <div className="add-page-save-cancel">
                  <SBSaveUpdateBtn btnName="Save" />
                  <SbCancelBtn btnName="Cancel" onClickEffect={closeModel} />
                </div>
              </div>
            </form>
          </>
        }
      />
    </div>
  );
}
