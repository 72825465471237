import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useState } from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import '../../../styles/googleCredits.scss'
import { useNavigate } from 'react-router-dom';


function MobileExceptionTable(props) {

    const [showModal, setShowModal] = useState("");

    const handleHover = (Data) => {
        setShowModal(Data);
    };

    const removeModal = () => {
        setShowModal("");
    };

    let navigate = useNavigate();
    const navigateToGoogleHit = (key) => {
        navigate(`/googleapikeylist/${key}`)
    }

    const [tooltipRow, setTooltipRow] = useState(null);

    const handleCopyClick = (data, rowIndex) => {
             navigator.clipboard.writeText(data)
               .then(() => {
                 console.log('Text copied to clipboard!');
               })
               .catch((error) => {
                 console.error('Failed to copy text to clipboard:', error);
               });
               setTooltipRow(rowIndex);
               setTimeout(() => {
                 setTooltipRow(null);
               }, 1500);
           };

        return (
            <div className="sb-table-div sb-table-setion">
                <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                    <TableContainer sx={{ maxHeight: 440 }} >
                        <Table stickyHeader aria-label="sticky table table-responsive" className='credits-table'>
                            <TableHead className='custom-table-header'>
                                <TableRow>
                                    {props.tableHeader.map((name, i) => (
                                        < TableCell align={name.align} key={i} className={`thead-cell-products ${name.name === "API KEY" && "sticky-header"}`} >
                                            {name.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {props.isLoading ?
                                <>
                                    <SkeletonMeetPurpose />
                                    <SkeletonMeetPurpose />
                                    <SkeletonMeetPurpose />
                                    <SkeletonMeetPurpose />
                                    <SkeletonMeetPurpose />
                                    <SkeletonMeetPurpose />
                                    <SkeletonMeetPurpose />
                                    <SkeletonMeetPurpose />
                                </>
                                :
                                <>
                                    {props.apiMobileExceptionData && props.apiMobileExceptionData.length === 0 ?
                                        <TableBody>
                                            <TableRow align="center" >
                                                <TableCell>
                                                    No data found...!
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        :
                                        <TableBody>
                                            {props.apiMobileExceptionData && props.apiMobileExceptionData.map((data, i) => (
                                                <TableRow align="center" key={i} className='client-list-row-data credits-table'>
                                                    {/* <TableCell align="center">
                                                    {i + 1}
                                                </TableCell> */}

                                                    <TableCell align="left">
                                                        <span style={{ cursor: "pointer" }} >{data.ErrorDateTime}</span>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <span style={{ cursor: "pointer" }} >{data.ErrorMessage}</span>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <span style={{ cursor: "pointer" }} >{data.ApiName}</span>
                                                    </TableCell>

                                                    <TableCell align="left"  >
                                                        <span style={{ cursor: "pointer" }} 
                                                        onMouseEnter={()=>{handleHover(data.Data)}}
                                                        onMouseLeave={()=>{removeModal(data.Data)}} >
                                                            {data.Data && data.Data.length > 50
                                                            ? <><b>{data.Data.substring(0, 50) + "...}"}</b></> 
                                                            : <><b>{data.Data}</b></>}</span>
                                                        <span style={{ cursor: "pointer", marginLeft: "10px" }} >
                                                            <Tooltip 
                                                            title="Copied To ClipBoard"
                                                            open={tooltipRow === i}
                                                            >
                                                                <i className="fa fa-copy" onClick={() => handleCopyClick(data.Data, i)}></i>
                                                            {/* <img 
                                                            src="../images/FileCopyImage.png" 
                                                            alt="copyImage" height="15px" 
                                                            onClick={() => handleCopyClick(data.Data, i)} 
                                                            /> */}
                                                            </Tooltip>
                                                            </span>
                                                    </TableCell>


                                                    {/* <TableCell align="center">
                                                    <span style={{ cursor: "pointer"}} >{data.ExceptionID}</span>
                                                </TableCell> */}

                                                    {/* <TableCell className='action-class'>
                                                    <button
                                                        onClick={() => props.editGoogleCredit(data.GoogleApiKey)}
                                                        className="btn client-history-link" >
                                                        <i className="bi bi-pencil-fill"></i> Edit
                                                    </button>
                                                </TableCell> */}
                                                    {showModal === data.Data  && (
                                                    <div className="modal-box">
                                                        <div style={{position: "relative"}}>
                                                            {/* <div className='cancel-btn' onClick={removeModal}><i className="fa fa-close x-btn"></i></div> */}
                                                            {/* <span style={{ cursor: "pointer", marginLeft: "10px", position:"absolute", right:"20px",top:"-18px" }}><img src="../images/FileCopyImage.png" alt="copyImage" height="10px" onClick={() => handleCopyClick(data.Data)} /></span> */}
                                                            <span>{data.Data}</span>
                                                        </div>
                                                    </div>
                                                    )}
                                                </TableRow>
                                                
                                            ))}
                                        </TableBody>

                                    }
                                </>
                            }
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        )
    }

 export default MobileExceptionTable;

 