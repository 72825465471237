import { travelizeAuth } from "./HeaderAuthentication";
import { toast } from "react-toastify";

// let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

// const travelizeAuth = {
//   "Content-Type": "application/json",
//   Travelize_Authentication: userDetails,
// };

export const DownloadFile = (resBlob, filenameToDownload) => {
  const url = window.URL.createObjectURL(resBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filenameToDownload);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const exportExcel = (
  apiUrl,
  filenameToDownload,
  setDownloadIsLoading
) => {
  setDownloadIsLoading(true);
  let HasFile = false;
  let requestOption = {
    method: "GET",
    mode: "cors",
    headers: travelizeAuth,
  };
  fetch(apiUrl, requestOption)
    .then((res) => {
      HasFile = true;
      return res.blob();
    })
    .then((res) => {
      setDownloadIsLoading(false);
      toast.success("Download Success", { autoClose: 5000 });
      if (HasFile) {
        DownloadFile(res, filenameToDownload);
      } else {
        toast.error(res.errorMessage, { autoClose: 6000 });
      }
    });
};
