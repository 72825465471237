import React from "react";
import { FloatingLabel, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

export default function ExpenseSettingForm(props) {

  const limitLableDisplay = (label, description) => {
    return <div>
      {label} &nbsp;
      <OverlayTrigger
        placement={"top"}
        delay={{ show: 250, hide: 300 }}
        overlay={
          <Tooltip id={`tooltip-dashboard`}>
            {description}
          </Tooltip>
        }
      >
        <i
          className="fa fa-info-circle details-icon"
          aria-hidden="true"
        ></i>
      </OverlayTrigger>
    </div>
  }

  return (
    <div>
      {" "}
      <div className="policy-local-outstaion mb-3">
        <div className="heading">
          <b>{props.head} EXPENSE POLICY :</b>
        </div>
        {props.head === "OUTSTATION" && (
          <div>
            <Form.Check
              inline
              type="checkbox"
              id="sameAsLocal"
              className="policy-checkbox"
              label="Same as Local"
              value="CopyLocal"
              name="copyFromLocal"
            //   onChange={(e) => props.handlePolicyBy(e)}
            //   checked={props.policyLimitToOptions.includes(item.label)}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <FloatingLabel
            label="Category"
            className={`${props.motDepartment.value !== ""
              ? "float-visible-select"
              : "float-hidden float-select"
              }`}
          >
            <Select
              className="react-select-container-list model-select add-basefare-input"
              classNamePrefix="react-select-list"
              options={props.policyOptions}
              placeholder="Select Category"
              // value={motDepartment.value && motDepartment}
              onInputChange={props.policyOptions.label}
              onChange={(data) =>
                props.handleSelectChange(data, "categoryName")
              }
              isSearchable={true}
            // isDisabled={editReimburseID ? true : false}
            />
          </FloatingLabel>
          {/* {showAlert && !postItem.departmentID && (
                      <small id={`Error`} className="form-text text-muted ">
                        Please Select Department
                      </small>
                    )} */}
        </div>
        <div className="col-md-6 mb-3">
          <FloatingLabel
            label="Category Code"
            className={`${props.motDepartment.value
              ? "float-input-visible"
              : "float-hidden float-input"
              }`}
          >
            <input
              type="number"
              className="form-control form add-basefare-input"
              name="category_code"
              // value={postItem?.foodCliaimLimit}
              placeholder="Category Code"
              onChange={props.handleInputChange}
            // disabled={props.clickType}
            />
          </FloatingLabel>
        </div>

        <div className="options-policysetto">
          <div className="policy-set-to-label">Set Policy to</div>
          <div className="checkboxes-row mb-3">
            {props.policySetToOption.map((item, i) => (
              <div key={i} className="checkbox-item">
                <Form.Check
                  inline
                  type="checkbox"
                  id={`policy-${item.label}-${i}`}
                  className="policy-checkbox"
                  label={item.label}
                  value={item.label}
                  name={`${item.label}-${props.head}`}
                  onChange={(e) => props.handlePolicyBy(e)}
                  checked={props.policyLimitToOptions.includes(item.label)}
                  disabled={
                    item.label === "No Limit"
                      ? false
                      : props.policyLimitToOptions.includes("No Limit")
                  }
                />
              </div>
            ))}
          </div>
        </div>

        <div className="row p-0">
          {props.policyLimitToOptions.map((data, i) =>
            data !== "No Limit" ? (
              <div className="col-md-6 col-lg-4 mb-3" key={i}>
                <FloatingLabel
                  label={`Enter ${data} Limit`}
                  className={`${props.postItem?.[`${data}Limit`]
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                >
                  <input
                    type="number"
                    className="form-control form add-basefare-input"
                    name={`${data}Limit`}
                    value={props.postItem?.[`${data}Limit`] || ""}
                    placeholder={`Set ${data} Limit`}
                    onChange={props.handleInputChange}
                  />
                </FloatingLabel>
              </div>
            ) : (
              <div className="col-md-12 mb-3 no-limit-message">
                You have selected the 'No Limit' option. Users can enter an
                infinite amount for this category.
              </div>
            )
          )}
          {props.policyLimitToOptions.includes(
            "Daily" || "Weekly" || "Monthly" || "Quarterly"
          ) ? (
            <>
              <div className="limit-restriction">
                {/* <div className="limit-option">Limit Options</div> */}
                <div className="radio-row mb-3">
                  {props.limitRestrictionOptions.map((item, i) => (
                    <div key={i} className="radio-item" style={{ paddingRight: "15px" }}>
                      <Form.Check
                        inline
                        type="radio"
                        id={`limitrestrict-${item.label}-${i}`}
                        className="policy-checkbox"
                        label={limitLableDisplay(item.label, item.description)}
                        value={item.label}
                        name={`limitRestrictionTo-${props.head}`}
                      // onChange={(e) => props.handlePolicyBy(e)}
                      // checked={props.policyLimitToOptions.includes(
                      //   item.label
                      // )}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <FloatingLabel
                  label="Warning Message"
                  className={`${props.warningMessage !== ""
                    ? "float-input-visible"
                    : "float-hidden float-input"
                    }`}
                >
                  <input
                    type="text"
                    name="warningMessage"
                    className="form-control form add-basefare-input"
                    placeholder="Enter warning message"
                    maxLength="60"
                    value={props.warningMessage}
                    onChange={(e) => props.setWarningMessage(e.target.value)}
                  />
                </FloatingLabel>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {/* <div className="row p-0 mb-3"> */}

        {/* </div> */}
        <div className="row p-0 mb-3">
          <div className="other-settings mb-2">
            <span className="settings-label">Additional Settings</span>
          </div>
          <div className="settings-status">
            <Form.Check
              type="switch"
              name={`receiptAttachment-${props.head}`}
              id="custom-switch"
              label="Receipt Attachment required"
              checked={props.attachmentRequired}
              onChange={() => props.setAttachmentRequired((prev) => !prev)}
            />
            <Form.Check
              type="switch"
              name={`receiptAttachment-${props.head}`}
              id="custom-switch"
              label="Remarks required"
              checked={props.remarksRequired}
              onChange={() => props.setRemarksRequired((prev) => !prev)}
            />
          </div>
        </div>
        <div className="policyfor-options mb-3">
          <div className="policyfor-label">
            Does this Policy applies to anyone in this company?
          </div>
          <div className="radio-column mb-2">
            {props.policyAppliesToOption.map((item, i) => (
              <div key={i} className="radio-item">
                <Form.Check
                  inline
                  type="radio"
                  id={`policyapplies-${item.label}-${i}`}
                  className="policyapplies-radio"
                  label={item.label}
                  value={props.policyApplyTo}
                  name={`policyAppliesFor-${props.head}`}
                  onChange={() => props.setPolicyApplyTo(item.value, props.head)}
                  checked={item.value === props.policyApplyTo}
                />
              </div>
            ))}
          </div>
        </div>
        {props.policyApplyTo === 2 ? (
          <>
            <div className="mb-3">
              <FloatingLabel
                label="Department"
                className={`${props.departmentID.value !== ""
                  ? "float-visible-select"
                  : "float-hidden float-select"
                  }`}
              >
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  options={props.departmentDrop}
                  placeholder="Select Department"
                  value={props.departmentID.value && props.departmentID}
                  onInputChange={props.departmentDrop.label}
                  onChange={(data) => props.handleDesignation(data)}
                  isSearchable={true}
                // isDisabled={editReimburseID ? true : false}
                />
              </FloatingLabel>
              {/* {showAlert && !postItem.departmentID && (
                <small id={`Error`} className="form-text text-muted ">
                  Please Select Department
                </small>
              )} */}
            </div>
            <div className="mb-3">
              <FloatingLabel
                label="Designation"
                className={`${props.designations.value !== ""
                  ? "float-visible-select"
                  : "float-hidden float-select"
                  }`}
              >
                <MultiSelect
                  className="select-add-user-model"
                  options={props.departDesignation}
                  value={props.designations}
                  onChange={(selectedList) =>
                    props.handleSelectChange(selectedList, "designation")
                  }
                  labelledBy={"Select Designation"}
                // disabled={editReimburseID ? true : false}
                />
              </FloatingLabel>
              {/* {showAlert && props.designations.length === 0 && (
                <small id={`Error`} className="form-text text-muted">
                  Please Select Designations
                </small>
              )} */}
            </div>
            <div className="mb-3">
              <FloatingLabel
                label="Users"
                className={`${props.users.value !== ""
                  ? "float-visible-select"
                  : "float-hidden float-select"
                  }`}
              >
                <MultiSelect
                  className="select-add-user-model"
                  options={props.userDropdown}
                  value={props.users}
                  onChange={(selectedList) =>
                    props.handleSelectChange(selectedList, "users")
                  }
                  labelledBy={"Select Users"}
                // disabled={editReimburseID ? true : false}
                />
              </FloatingLabel>
              {/* {showAlert && users.length === 0 && (
                <small id={`Error`} className="form-text text-muted ">
                  Please Select Users
                </small>
              )} */}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
