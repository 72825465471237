import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function BranchTable(props) {
  return (
    <div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
          //   onScroll={(e) =>
          //     handleScroll(
          //       e,
          //       props.users,
          //       props.totalUserCount,
          //       props.setLoading,
          //       props.setPageSize,
          //       props.PageSize
          //     )
          //   }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="branch-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {props.tableHeader.map((data, i) => (
                    <TableCell key={i} align={data?.align}>
                      {data?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <div>
                        <div>
                          <strong className="color-green">{data?.name}</strong>
                        </div>
                        <div className="color-yellow">BR-{data?.code}</div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        <div>
                          <b>Name : </b>
                          {data?.poc?.name}
                        </div>
                        <div>
                          <b>Mobile :</b> {data?.poc?.mobile}
                        </div>
                        <div>
                          <b>Email :</b> {data?.poc?.email}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{data?.country}</TableCell>
                    <TableCell>{data?.zone}</TableCell>
                    <TableCell>{data?.state}</TableCell>
                    <TableCell>{data?.city}</TableCell>
                    <TableCell style={{ maxWidth: "200px" }}>
                      <div>
                        {data?.shortLocation} &nbsp;
                        <OverlayTrigger
                          placement={"bottom"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={
                            <Tooltip id={`tooltip-remarks`}>
                              {data?.location}
                            </Tooltip>
                          }
                        >
                          <span className="color-green">
                            <i className="fa fa-info-circle" />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className={`${data.status}-status`}>
                        <span className="status-text">{data.status}</span>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="edit-view-link">
                        <span
                          className="edit-icon-text"
                          onClick={() => {
                            props.openCloseAddEditModel();
                            props.setBranchID(2);
                          }}
                        >
                          <i className="fa fa-edit" />
                          Edit
                        </span>
                        &nbsp;
                        <span className="view-icon-text">
                          <i className="fa fa-eye" />
                          View
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
