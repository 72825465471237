import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadAllStateList, loadStateList, loadZoneList } from "../../../redux/action";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";

function RegionStateTable(props) {
    let tableHeader = [
        { name: "Country Name", align: "left", width: "200px" },
        { name: "Zone Name", align: "left", width: "200px" },
        { name: "State Name", align: "left", width: "200px" },
        // { name: "City Name", align: "left", width: "2000px" },
    ];

    let dispatch = useDispatch();
    const { getAllStateName } = useSelector((state) => state.data);
    console.log(getAllStateName, "getAllStateName")
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(loadAllStateList())
    }, [])

    return (
        <>
            {/* <h4 className="accounts-header" >Passbook</h4> */}
            <div className="sb-table-div sb-table-setion">
                <Paper
                    sx={{ width: "100%", overflow: "hidden" }}
                    className="table-main-div"
                >
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table
                            stickyHeader
                            aria-label="sticky table table-responsive"
                            className="basefare-table"
                        >
                            <TableHead className="custom-table-header">
                                <TableRow>
                                    {tableHeader.map((name, i) => (
                                        <TableCell
                                            key={i}
                                            align={name.align}
                                            className={`thead-cell-products`}
                                            style={{ minWidth: name.width }}
                                        >
                                            {name.name}
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        <SBSaveUpdateBtn
                                            btnName="Add State"
                                            onClickEffect={props.openStateModal}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {isLoading ? (
                                <>
                                    {tableHeader.map((name, i) => (
                                        <SkeletonMeetPurpose key={i} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {getAllStateName &&
                                        getAllStateName.length === 0 ? (
                                        <TableBody>
                                            <TableRow align="center">
                                                <TableCell align="center" colSpan={tableHeader.length}>
                                                    <div style={{ padding: "20px 20px" }}>
                                                        <img
                                                            src="../../../../images/DefaultImages/NodataFound.jpg"
                                                            alt=""
                                                            style={{ width: "10%" }}
                                                        />
                                                        <br />
                                                        <span>No Data Found...!</span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {getAllStateName && getAllStateName.map((data, index) => (
                                                <TableRow
                                                    align="center"
                                                    key={index}
                                                    className="client-list-row-data"
                                                >
                                                    <TableCell>
                                                        <div className="table-row-head-Text">
                                                            {data.countryName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="table-row-head-Text">
                                                            {data.zoneName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell colSpan={2}>
                                                        <div className="table-row-head-Text">
                                                            {data.stateName}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )}
                                </>
                            )}
                        </Table>
                    </TableContainer>
                </Paper>
            </div >
        </>
    );
}

export default RegionStateTable;
