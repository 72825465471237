import React, { useState, useEffect } from "react";
import { Modal, Image } from "react-bootstrap";
import "../../styles/Spares.scss";
import { baseURL } from "../BaseUrl";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

export default function ViewSpareDetails(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  // console.log("SUBSCRIPTION:", resPassData.subscriptionId);

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [spareDetails, setSpareDetails] = useState("");
  const [spareGalleryImages, setSpareGalleryImages] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // FETCH DATA START
  useEffect(() => {
    fetchSpareDetails();
  }, [props.sparePartId]);

  const fetchSpareDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(`${baseURL}/api/SpareParts/Get/${props.sparePartId}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("RES-VIEW-SPARE:", res.result);
          setSpareDetails(res.result);
          let spareImgs = [];
          spareImgs.push(res?.result?.galaryImages.split(","));
          spareImgs.unshift(res?.result?.image);
          setSpareGalleryImages(spareImgs.flat());
          // setIsLoading(false);
        } else {
          setSpareDetails([]);
          console.log("ERROR:", res.errorMessage);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };
  // FETCH DATA END

  return (
    <div>
      <div className="model-view-spare-details">
        <Modal
          show={props.viewProductDetail}
          onHide={props.handleClose}
          className="view-spares-model"
        >
          <div className="view-attach-heading">
            <h6 className="view-attachment-head">SPARE DETAILS</h6>
            <i className="fa fa-times" onClick={props.handleClose}></i>
          </div>
          <div className="spare-details-model">
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <div className="spare-detail-left-part">
                  <div className="spare-big-img">
                    <Swiper
                      spaceBetween={10}
                      navigation={false}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      {spareGalleryImages.map((data, i) => (
                        <SwiperSlide  key={i}>
                          <Image
                            src={`${baseURL}/${data}`}
                            // src={`${baseURL}/${spareDetails?.image}`}
                            alt="attachment-photo"
                            className="spare-img"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="carousel-sliding-part">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#000000",
                        "--swiper-pagination-color": "#000000",
                        "--swiper-navigation-size": "13px",
                        "--swiper-navigation-sides-offset": "0px",
                      }}
                      onSwiper={setThumbsSwiper}
                      spaceBetween={5}
                      slidesPerView={3}
                      navigation={true}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      {spareGalleryImages.map((data, i) => (
                        <SwiperSlide key={i}>
                          <div className="spare-small-images-row">
                            <div className="spare-small-image">
                              <Image
                                src={`${baseURL}/${data}`}
                                alt="attachment-photo"
                                className="spare-img"
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="spare-detail-right-part">
                  <div className="spare-name">
                    <h5>{spareDetails?.name}</h5>
                  </div>
                  <div className='specifications'>
                        {spareDetails?.name &&
                          <div className='products-specifications'>
                            <span>Spare Name </span>
                            <b>:</b>
                            <b>{spareDetails?.name}</b>
                          </div>
                        }
                        {spareDetails?.code &&
                          <div className='products-specifications'>
                            <span>Spare Code </span>
                            <b>:</b>
                            <b>{spareDetails?.code}</b>
                          </div>
                        }
                        {spareDetails?.productNames &&
                          <div className='products-specifications'>
                            <span>Product Name </span>
                            <b>:</b>
                            <b>{spareDetails?.productNames}</b>
                          </div>
                        }
                        {spareDetails?.inWarranty ?
                          <div className='products-specifications'>
                            <span>Is In Warranty</span>
                            <b>:</b>
                            <b>{spareDetails?.inWarranty == 1 ? "YES" : "NO" ?? "-"}</b>
                          </div>
                          :
                          ""
                        }
                        {spareDetails?.warrantyPeriod &&
                          <div className='products-specifications'>
                            <span>Warranty </span>
                            <b>:</b>
                            <b>{spareDetails?.warrantyPeriod ?? "-"}</b>
                          </div>
                        }
                        {(spareDetails?.height && spareDetails?.width && spareDetails?.length) &&
                          <div className='products-specifications'>
                            <span>Dimension (H * W * L)</span>
                            <b>:</b>
                            <b>
                              {spareDetails?.height ?? "-"} x
                              {spareDetails?.width ?? "-"} x{" "}
                              {spareDetails?.length ?? "-"}
                            </b>
                          </div>
                        }
                        {spareDetails?.weight &&
                          <div className='products-specifications'>
                            <span>Weight </span>
                            <b>:</b>
                            <b>{spareDetails?.weight ?? "-"}</b>
                          </div>
                        }
                        {spareDetails?.mrp &&
                          <div className='products-specifications'>
                            <span>MRP </span>
                            <b>:</b>
                            <b>{spareDetails?.mrp ?? "-"}</b>
                          </div>
                        }
                        {spareDetails?.price &&
                          <div className='products-specifications'>
                            <span>Price </span>
                            <b>:</b>
                            <b>{spareDetails?.price ?? "-"}</b>
                          </div>
                        }
                        
                      </div>
                      <div className="spare-description">
                    <div className="description-instruction">
                      <Tabs
                        defaultActiveKey="description"
                        id="uncontrolled-tab-example"
                        className="description-instruction-nav-tab"
                      >
                        <Tab eventKey="description" title="Description">
                          {/* <pre> */}
                          <p>{spareDetails?.description}</p>
                          {/* </pre> */}
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
