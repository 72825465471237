import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { baseURL } from "../BaseUrl";
import { travelizeAuth } from "../HeaderAuthentication";
import moment from "moment";
import "../../styles/MeetingDetailsModel.scss";
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import bikeicon from "../../images/bike.svg";
import caricon from "../../images/car.svg";
import busicon from "../../images/bus.svg";
import autoicon from "../../images/auto.svg";
import railicon from "../../images/railway.svg";
import metroicon from "../../images/metro.png";
import taxiicon from "../../images/taxiicon.png";
import moticon from "../../images/mot.svg";

function MeetingDetailsModel(props) {
  const [meetingDetails, setMeetingDetails] = useState([]);

  useEffect(() => {
    fetchMeetingDetailsByUser();
  }, []);

  const fetchMeetingDetailsByUser = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Meeting/GetMeetingDetails?PageSize=100&CurrentPage=1&UserId=${props.UserId}&FromDate=${props.ViewDate}&ToDate=${props.ViewDate}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMeetingDetails(res.result);
        } else {
          setMeetingDetails("");
        }
      });
  };

  return (
    <div>
      <Modal
        show={props.visitDetailsModel}
        onHide={props.closeVisitModel}
        className="view-meet-details-model"
      >
        <div className="add-user-heading">
          <h6 className="add-user-head">{props.userName} : Meeting Details</h6>
          <i className="fa fa-times" onClick={props.closeVisitModel} />
        </div>
        <div className="details-body">
          <div className="table-responsive meetings-scrollbar">
            <table className="table meetings-list-table">
              <thead className="thead-light meetings-list-thead">
                <tr>
                  <th>COMPANY</th>
                  <th>MEETING</th>
                  <th>MOT</th>
                  <th>REMARKS</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {meetingDetails !== "" ? (
                  meetingDetails.map((data, i) => (
                    <tr className="meeting-details-list-row-data" key={i}>
                      <td style={{ maxWidth: "220px" }}>
                        <span className="client-meet d-flex">
                          <p>
                            <b>{data?.clientName}</b>
                          </p>
                          &nbsp;
                          <p className="client-meet-status">
                            {data?.meetingType}
                          </p>
                        </span>
                        <i className="fa fa-user mtdtls-icon" />
                        &nbsp;
                        {data?.contactPersonName ??
                          data?.contactPerson ??
                          data?.clientName}
                        <br />
                        <i className="fa fa-mobile mtdtls-icon" /> &nbsp;
                        {data?.contactPersonNumber ?? data?.contact}
                        <br />
                        <i className="fa fa-thumb-tack mtdtls-icon" /> &nbsp;
                        {data?.clientName == "Check-Out"
                          ? data?.endLoc?.location
                          : data?.clientLoc?.location}
                        <span className="client-meet-location">
                          <p className="client-meet-lat">
                            <span className="color-yellow">
                              <i className="bi bi-geo-alt" />
                            </span>
                            {data?.startLoc?.latitude ?? "NA"},
                            {data?.startLoc?.longitude ?? "NA"}
                          </p>
                          <p className="client-meet-lan">
                            <span className="color-yellow">
                              <i className="bi bi-geo-alt" />
                            </span>
                            {data?.endLoc?.latitude ?? "NA"},
                            {data?.endLoc?.longitude ?? "NA"}
                          </p>
                        </span>
                      </td>
                      <td className="td-meeting-details">
                        <span className="client-meet-purpose d-flex">
                          <p>
                            <b>Purpose :</b>

                            <span className="client-meet-purpose-status">
                              &nbsp;{data?.purpose ?? "NA"}
                            </span>
                          </p>
                        </span>
                        <span className="client-meet-purpose d-flex">
                          <p>
                            <b>Travelled Time :</b>
                            <span className="client-meet-purpose-status">
                              &nbsp;{data.travelledTimes ?? "NA"}
                            </span>
                          </p>
                        </span>

                        <p>
                          In Time :{" "}
                          {data?.inTime === null || data?.inTime === ""
                            ? "NA"
                            : data?.inTime}
                        </p>
                        <p>
                          Out Time :{" "}
                          {data?.outTime === null || data?.outTime === ""
                            ? "NA"
                            : data?.outTime}
                        </p>
                        <p>Date : {data?.meetingDate} </p>
                        <p>Scheduled : {data?.meetingTime}</p>
                        <p>
                          <b>Duration : </b> &nbsp;{data?.timeSpent}
                        </p>
                        <p>
                          <b>Distance Travelled : </b>&nbsp;
                          {data?.distanceTravelled === null ||
                          data?.distanceTravelled === ""
                            ? 0
                            : data?.distanceTravelled}
                        </p>
                      </td>
                      <td>
                        {" "}
                        <div style={{ textAlign: "left" }}>
                          <div>
                            <Image
                              style={{ marginLeft: "2px" }}
                              src={
                                data?.mot == "Bike" || data?.mot == "bike"
                                  ? bikeicon
                                  : data?.mot == "Car" || data?.mot == "car"
                                  ? caricon
                                  : data?.mot == "Bus" || data?.mot == "bus"
                                  ? busicon
                                  : data?.mot == "Auto" || data?.mot == "auto"
                                  ? autoicon
                                  : data?.mot == "Train" || data?.mot == "train"
                                  ? railicon
                                  : data?.mot == "Taxi" ||
                                    data?.mot == "taxi" ||
                                    data?.mot == "Cab" ||
                                    data?.mot == "cab"
                                  ? taxiicon
                                  : data?.mot == "Metro" || data?.mot == "metro"
                                  ? metroicon
                                  : data?.mot == "NA" || data?.mot == null
                                  ? ""
                                  : moticon
                              }
                              className={
                                data?.mot == "Bus" ||
                                data?.mot == "bus" ||
                                data?.mot == "bus" ||
                                data?.mot == "Taxi" ||
                                data?.mot == "taxi" ||
                                data?.mot == "Cab" ||
                                data?.mot == "cab" ||
                                data?.mot == "Metro" ||
                                data?.mot == "metro" ||
                                data?.mot == "NA" ||
                                data?.mot == null
                                  ? "mot-icon-sml"
                                  : "mot-icon"
                              }
                            />
                            <br />
                            {data?.mot}
                          </div>
                        </div>
                      </td>
                      <td style={{ maxWidth: "150px" }}>
                        {data?.lessRemark === "NA" ? (
                          data?.lessRemark
                        ) : (
                          <OverlayTrigger
                            placement={"bottom"}
                            delay={{ show: 250, hide: 300 }}
                            overlay={
                              <Tooltip id={`tooltip-remarks`}>
                                {data?.remark}
                              </Tooltip>
                            }
                          >
                            <div>{data?.lessRemark}</div>
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>
                        <p className={`status-badge ${data?.status}`}>
                          {data?.status === "PCancelled"
                            ? "Pre-Cancelled"
                            : data?.status == "Re-Schedule" ||
                              data?.status == "Re-schedule"
                            ? "Re-Scheduled"
                            : data.status ?? "-"}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>-----NO DATA FOUND----</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MeetingDetailsModel;
