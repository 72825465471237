import React, { useState, useEffect, useLayoutEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Adddropdowndata.scss";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import AccessDenied from "../../AccessDenied";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import Footer from "../Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { travelizeAuth } from "../HeaderAuthentication";
import { loadUserRegion } from "../../redux/action";

export default function AddDropdownData() {
  let userLoginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let dispatch = useDispatch();

  const { userRegionResult } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [countryList, setCountryList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [countryId, setCountryId] = useState("");
  const [country, setCountry] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [newCountry, setNewCountry] = useState("");
  const [newState, setNewState] = useState("");
  const [newCity, setNewCity] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [zoneIdFilter, setZoneIdFilter] = useState(0);
  const [stateIdFilter, setStateIdFilter] = useState(0);
  const [userRegionStates, setUserRegionStates] = useState([]);
  const [userRegionCities, setUserRegionCities] = useState([]);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // GET API DATA START
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadUserRegion());
  }, []);

  useEffect(() => {
    {
      userRegionResult && fetchandSetRegionData();
    }
  }, [userRegionResult]);

  const fetchandSetRegionData = () => {
    if (userRegionResult) {
      let regionResult = userRegionResult;
      setUserRegionStates(regionResult.allStates);
      setUserRegionCities(regionResult.allCities);
      //   setCountryCodeList(
      //     regionResult?.allCountryCode.map((data) => {
      //       return { value: data.code, label: data.code };
      //     })
      //   );
      setCountryList(
        regionResult?.allCountry?.map((data) => {
          return { value: data.countryID, label: data.countryName };
        })
      );
      setZoneList(
        regionResult?.allZones?.map((data) => {
          return { value: data.zoneId, label: data.zoneName };
        })
      );
      setStateList(
        regionResult?.allStates?.map((data) => {
          return { value: data.stateId, label: data.stateName };
        })
      );
      setCityList(
        regionResult?.allCities?.map((data) => {
          return { value: data.cityId, label: data.cityName };
        })
      );
    }
  };

  // GET API DATA END

  const addCountryNametoCountryId = () => {
    let newCountries = [
      {
        countryName: newCountry,
        code: countryId,
      },
    ];
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(newCountries),
    };
    fetch(`${baseURL}/api/Zone/AddCountryCode`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          window.location.reload();
        } else {
          toast.error(res.errorMessage, { autoClose: 5000 });
        }
      });
  };

  const addStatesToZone = () => {
    let newStates = [
      {
        stateName: newState,
        zoneId: zoneId,
        countryID: country,
      },
    ];
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(newStates),
    };
    fetch(`${baseURL}/api/Zone/AddStates`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          window.location.reload();
        } else {
          toast.error(res.errorMessage, { autoClose: 5000 });
        }
      });
  };

  const addCityToState = () => {
    let newCities = [
      {
        stateId: stateId,
        cityName: newCity,
      },
    ];
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(newCities),
    };
    fetch(`${baseURL}/api/Zone/AddCities`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          window.location.reload();
        } else {
          toast.error(res.errorMessage, { autoClose: 5000 });
        }
      });
  };

  const clearSerachBySelect = () => {
    setCountry(0);
    setZoneId(0);
    setStateId(0);
    setZoneIdFilter(0);
    setStateIdFilter(0);
    setNewState("");
    setNewCity("");
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userLoginDetails?.subscriptionId == "SUB0000000001" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div className="clients-table">
                  <Row className="search-row">
                    <Col md={5} sm={5} xs={12} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          ADD COUNTRY / STATE / CITY
                        </h6>
                      </div>
                    </Col>
                    <Col md={7} sm={7} xs={12} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <MiClearButton
                            btnName="Clear"
                            onClickEffect={clearSerachBySelect}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* USER TABLE START */}
                <div className="googleapi-table">
                  <div className="table-responsive adddropdowndata-scrollbar">
                    <div className="row">
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="add-state-cities">
                          <div className="add-country-state-city-part">
                            <div className="add-country">
                              <h6>ADD COUNTRY</h6>
                              <div className="select-dropdown">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-state-input mb-2"
                                  placeholder="Enter Country Code"
                                  value={countryId}
                                  onChange={(e) => setCountryId(e.target.value)}
                                />
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-state-input mb-2"
                                  placeholder="Add New Country"
                                  value={newCountry}
                                  onChange={(e) =>
                                    setNewCountry(e.target.value)
                                  }
                                />
                                <button
                                  className="btn btn-update-zone"
                                  onClick={addCountryNametoCountryId}
                                >
                                  UPDATE COUNTRY
                                </button>
                              </div>
                            </div>
                            <div className="add-state">
                              <h6>ADD STATE</h6>
                              <div className="select-dropdown">
                                <Select
                                  className="react-select-container-list mb-2"
                                  classNamePrefix="react-select-list"
                                  options={countryList}
                                  placeholder="Select Country"
                                  value={countryList?.value}
                                  onInputChange={countryList?.label}
                                  onChange={(data) => setCountry(data.value)}
                                  isSearchable={true}
                                />
                                <Select
                                  className="react-select-container-list mb-2"
                                  classNamePrefix="react-select-list"
                                  options={zoneList}
                                  placeholder="Select Zone"
                                  value={zoneList?.value}
                                  onInputChange={zoneList?.label}
                                  onChange={(data) => setZoneId(data.value)}
                                  isSearchable={true}
                                />
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-state-input mb-2"
                                  placeholder="Add New State"
                                  value={newState}
                                  onChange={(e) => setNewState(e.target.value)}
                                />
                                <button
                                  className="btn btn-update-zone"
                                  onClick={addStatesToZone}
                                >
                                  UPDATE STATE
                                </button>
                              </div>
                            </div>
                            <div className="add-city">
                              <h6>ADD CITY</h6>
                              <div className="select-dropdown">
                                <Select
                                  className="react-select-container-list mb-2"
                                  classNamePrefix="react-select-list"
                                  options={stateList}
                                  placeholder="Select State"
                                  value={stateList?.value}
                                  onInputChange={stateList?.label}
                                  onChange={(data) => setStateId(data.value)}
                                  isSearchable={true}
                                />
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-state-input mb-2"
                                  placeholder="Add New City"
                                  value={newCity}
                                  onChange={(e) => setNewCity(e.target.value)}
                                />
                                <button
                                  className="btn btn-update-zone"
                                  onClick={addCityToState}
                                >
                                  UPDATE CITY
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 col-12">
                        <div className="country-table">
                          <div className="country-head">COUNTRY LIST</div>
                          {countryList?.map((data, i) => (
                            <div className="country-list" key={i}>
                              {data?.label}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        {" "}
                        <div className="state-table">
                          <div className="top-sticky">
                            <Select
                              className="react-select-container-list mb-2 mt-1"
                              classNamePrefix="react-select-list"
                              options={zoneList}
                              placeholder="Select Zone"
                              value={zoneList?.value}
                              onInputChange={zoneList?.label}
                              onChange={(data) => setZoneIdFilter(data.value)}
                              isSearchable={true}
                            />
                            <div className="country-head">STATE LIST</div>
                          </div>
                          <div className="table-height">
                            {userRegionStates
                              ?.filter((state) => state.zoneId == zoneIdFilter)
                              ?.map((data, i) => (
                                <div className="country-list" key={i}>
                                  {data?.stateName}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        {" "}
                        <div className="city-table">
                          <div className="top-sticky">
                            <Select
                              className="react-select-container-list mb-2 mt-1"
                              classNamePrefix="react-select-list"
                              options={stateList}
                              placeholder="Select State"
                              value={stateList?.value}
                              onInputChange={stateList?.label}
                              onChange={(data) => setStateIdFilter(data.value)}
                              isSearchable={true}
                            />
                            <div className="country-head">CITY LIST</div>
                          </div>
                          <div className="table-height">
                            {userRegionCities
                              ?.filter((city) => city.stateId == stateIdFilter)
                              ?.map((data, i) => (
                                <div className="country-list" key={i}>
                                  {data?.cityName}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
