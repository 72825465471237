import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/TravelApproval.scss";
import Footer from "../../Footer";
import { loadUserDropdown } from "../../../redux/action";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TripPreviewTable from "../TripPreviewTable";
import { travelByOptions, travelDetails } from "../CommonData";

export default function TravelViewNApprove() {

  let dispatch = useDispatch();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [ticketList, setTicketList] = useState(travelDetails);

  const setMotHandle = (id, typeName, val, type) => {
    const newTravelList = { ...ticketList }
    const itemArray = newTravelList[typeName];

    const rowIndex = itemArray.findIndex((item) => item.id === id);
    if (rowIndex !== -1) {
      const updatedRow = JSON.parse(JSON.stringify(itemArray[rowIndex]));
      if (type === "remark") {
        updatedRow.remarks = val;
        itemArray[rowIndex] = updatedRow;
      } else {
        updatedRow.status = val;
        if (val === "Approve" && updatedRow.remarks) {
          delete updatedRow.remarks;
        }
        itemArray[rowIndex] = updatedRow;
      }
    }
    newTravelList[typeName] = itemArray;
    setTicketList(newTravelList)
  }

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadUserDropdown());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div>
      <div id="main-page">
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={6} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          {" "}
                          TRAVEL PLAN DETAILS{" "}
                        </h6>
                      </div>
                    </Col>
                    <Col md={4} className="cat-col"></Col>

                    <Col md={5} className="cat-col"></Col>
                  </Row>
                </div>
                {/* Filter and Header Section */}
                <div className="approver-view-and-approve-part">
                  {/* BODY CONTENT START */}
                  <div className="container-fluid preview-data">
                    <TripPreviewTable
                      userNames={[
                        { fullName: "Yuvaraj" },
                        { fullName: "Shweta" },
                        { fullName: "Seema" },
                      ]}
                      clientName={[
                        { label: "Lobotus Office" },
                        { label: "SmartInfo Office" },
                        { label: "Lobotus Office" },
                      ]}
                      mobilenum={"7760142475"}
                      remarks={"Test remarks"}
                      advanceCash={10000}
                      otherOption={"Need product Samples"}
                      travelByOptions={travelByOptions}
                      travelDetails={ticketList}
                      loggedInBy={"Admin"}
                      fromDate={"01/04/2024"}
                      toDate={"10/04/2024"}
                      setMotHandle={setMotHandle}
                    />
                  </div>
                  {/* BODY CONTENT END */}
                </div>
                {/* PAGE CONTENT END*/}
              </div>
            </div>
          </div>
          <Footer />
        </>
      </div>
    </div>
  );
}
