import React from "react";
import "../src/styles/PageNotFound.scss";

function PageNotFound() {
  return (
    <div>
      <div
        className="page-not-found-page"
        style={{
          textAlign: "center",
          position: "relative",
          top: "200px",
          color: "black",
        }}
      >
        <h2 className="page-not-found-head">404 Page Not Found</h2>
        <p>We are sorry but the page you are looking for does not exist.</p>
      </div>
    </div>
  );
}

export default PageNotFound;
