import React, { useEffect, useState } from 'react'
import { FloatingLabel, Modal } from 'react-bootstrap'
import { SBSaveUpdateBtn } from '../../SbButtons/SbAddBtn'
import SbCancelBtn from '../../SbButtons/SbCancelBtn'
import { loadCustomFieldsForCompany } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';

function AddExpensesCategory(props) {

    let dispatch = useDispatch();
    const { getCustomFieldsForCompany } = useSelector((state) => state.data);
    console.log(getCustomFieldsForCompany, "getCustomFieldsForCompany");

    // #region define initial states
    // const initialStates = {
    //     categoryName: "",
    //     categoryCode: ""
    // }
    const [initStates, setInitStates] = useState([]);

    useEffect(() => {
        dispatch(loadCustomFieldsForCompany(41));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
      if(getCustomFieldsForCompany.length !== 0){
        setInitStates(getCustomFieldsForCompany)
      }
    }, [getCustomFieldsForCompany])
    
    // #regon handle inputs
    const handleInputs = (e) => {
        const {name, value} = e.target;
        setInitStates((prevState) => ({...prevState, value : value}))
    }

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.categoryModuleClose}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">ADD EXPENSE CATEGORY</h6>
          <i className="fa fa-times" onClick={props.categoryModuleClose} />
        </div>
        <form>
          <div className="container add-edit">
            <div className="product-inputs">
                {initStates.map((fields, i) => (
              <div className="mb-3" key={i}>
                <FloatingLabel label={fields.fieldName} className={`${fields.value ? "float-input-visible" : "float-hidden float-input"}`}>
                  <input
                    type="text"
                    className="form-control form add-input"
                    placeholder={fields.placeHolder}
                    name="categoryName"
                    value={fields.value}
                    onChange={(e) => handleInputs(e)}
                  />
                </FloatingLabel>
                {/* {fieldReq && departmentName === "" && (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                )} */}
              </div>
              
              ))}

            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          {/* <SBSaveUpdateBtn onClickEffect={addDepartment} btnName="Save" /> */}
          <SBSaveUpdateBtn  btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.categoryModuleClose} />
        </div>
      </Modal>
    </div>
  )
}

export default AddExpensesCategory