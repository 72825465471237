import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import { dateFormatLocal } from '../../FormattedAmount';
import '../../../styles/googleCredits.scss'
  
function HitCountTable(props) {

    const [showModal, setShowModal] = useState("");

    const handleHover = (apikey) => {
        setShowModal(apikey);
    };

    const removeModal = () => {
        setShowModal("");
    };

    const [totalAPIHits,setTotalAPIHits] = useState(0)
    useEffect(() => {
        if(props.ApiHitList){
            let totalHitCount = 0
            props.ApiHitList.map(item => {
                totalHitCount += item.HitCountMonthly;
            })
            setTotalAPIHits(totalHitCount)
        }
    }, [props.ApiHitList])
    
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='credits-table google-hit-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell align={name.align} style={{minWidth: `${name.width}`}} key={i} className={`thead-cell-products`}>
                                        {name.name}
                                    </TableCell>
                                ))}
                                {props.numbersArray.map((number, i) => (
                                    < TableCell align="center" key={i} style={{minWidth: `80px`}} className={`thead-cell-products`}>
                                        {number}
                                    </TableCell>
                                ))}
                                <TableCell align="center" style={{minWidth: `150px`}} className={`thead-cell-products`}>
                                    Total Hits
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <TableBody>
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                            </TableBody>
                            :
                            <>
                            {props.ApiHitList && props.ApiHitList.length === 0 ?
                                <TableBody>
                                    <TableRow align="center" >
                                        <TableCell colSpan={props.tableHeader.length}>
                                            No data found...!
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                :
                                <TableBody>
                                    {props.ApiHitList && props.ApiHitList.map((data, i) => (
                                        <TableRow align="center" key={i} >
                                            <TableCell align="center">
                                                {i + 1}
                                            </TableCell>
                                            <TableCell className='company-subscriptionId'>
                                                    <b>{data.Company}</b>
                                            </TableCell>
                                            <TableCell
                                                    onClick={() => handleHover(data.RowNumber)}
                                                    // onMouseLeave={handleHoverEnd}
                                                >
                                                    <span className='primary-color' style={{ cursor: "pointer" }} >
                                                        {data.GoogleApiKey && data.GoogleApiKey.length > 25
                                                            ? <><b>{data.GoogleApiKey.substring(0, 25) + "..."}</b></>
                                                            : <><b>{data.GoogleApiKey}</b></>}
                                                    </span>
                                                    
                                            </TableCell>
                                            <TableCell>
                                                {dateFormatLocal(data.FromDate)}
                                            </TableCell>
                                            <TableCell>
                                                {dateFormatLocal(data.ToDate)}
                                            </TableCell>
                                            <TableCell>
                                                {dateFormatLocal(data.LastHitDate)}
                                            </TableCell>
                                            {Object.keys(data).map((key, index) => {
                                                if (key.startsWith("HitCountDate")) {
                                                    // const date = key.replace("HitCountDate", "");
                                                    return <TableCell key={index} align="center">{data[key]}</TableCell>
                                                }
                                                return null;
                                            })}
                                           
                                            <TableCell align="center" style={{minWidth: `80px`}} className={`thead-cell-products`}>
                                                <b>{data.HitCountMonthly}</b>
                                            </TableCell>
                                            {showModal === data.RowNumber  && (
                                                    <div className="modal-box">
                                                        <div style={{position: "relative"}}>
                                                            <div className='cancel-btn' onClick={removeModal}><i className="fa fa-close x-btn"></i></div>
                                                            <span>{data.GoogleApiKey}</span>
                                                        </div>
                                                    </div>
                                                    )}
                                        </TableRow>
                                    ))}
                                    <TableRow align="center"  className='client-list-row-data'>
                                            <TableCell style={{textAlign: "right"}} colSpan={props.tableHeader.length + 31}>
                                                <b>Grand Total</b>
                                            </TableCell>
                                            <TableCell align="center">
                                                <b>{totalAPIHits}</b>
                                            </TableCell>
                                        </TableRow>
                                </TableBody>

                            }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default HitCountTable;


