import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Role.scss";
import { baseURL } from "../../BaseUrl";
import moment from "moment";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";
import EditRole from "./EditRole";
import AddRole from "./AddRole";
import { MiClearButton, SbBackBtn } from "../../SbButtons/SbCancelBtn";
import { Col, Row } from "react-bootstrap";
import { loadPageBreadCome } from "../../../redux/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";

export default function Role() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [editRoleModel, setEditRoleModel] = useState(false);
  const [roles, setRoles] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [roleId, setRoleId] = useState(0);
  const [editRoleId, setEditRoleId] = useState(0);
  const [activeStatus, setActiveStatus] = useState(2);
  const [reportingRoleId, setReportingRoleId] = useState(0);
  const [levelId, setLevelId] = useState(0);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let dispatch = useDispatch();

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditRoleModel(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "Roles" };
    dispatch(loadPageBreadCome(breadCome));
    fetchRoleDropdown();
  }, []);

  const fetchRoleDropdown = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Role/BindRolesDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoleList(
            res.result.map((data) => {
              return { value: data.roleID, label: data.roleName };
            })
          );
        } else {
          setRoleList([]);
        }
      });
  };

  // GET ROLES PAGE START
  useEffect(() => {
    fetchRoleswithReporting();
  }, [reportingRoleId, levelId, roleId]);

  const fetchRoleswithReporting = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Role/Get?ReportingRoleID=${reportingRoleId}&Level=${levelId}&RoleID=${roleId}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("ROLES::", res);
        if (res.success) {
          setRoles(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setRoles("");
          setIsLoading(false);
        }
      });
  };
  // GET ROLES PAGE END

  const clearSerachBySelect = () => {
    setRoleId(0);
  };

  // const exportExcelDownload = () => {
  //   exportExcel(
  //     `${baseURL}/api/Export/RoleExcelExport?ReportingRoleID=${reportingRoleId}&Level=${levelId}&RoleID=${roleId}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
  //     "Roles.xlsx"
  //   );
  // };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="roles-table">
                  <Row className="search-row">
                    <Col md={3} sm={4} xs={12} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">ROLES</h6>
                      </div>
                    </Col>
                    <Col md={9} sm={8} xs={12} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            defaultValue={roleList[0]}
                            options={roleList}
                            placeholder="Select Role"
                            value={roleId == 0 ? "" : roleList.value}
                            onInputChange={roleList.label}
                            onChange={(data) => setRoleId(data.value)}
                            isSearchable={true}
                          />
                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive roles-scrollbar">
                    <table className="table roles-table-data">
                      <thead className="thead-light roles-thead">
                        {isLoading ? (
                          <>
                            <SkeletonStdTableHead />
                          </>
                        ) : (
                          <tr>
                            <th>SL.NO.</th>
                            <th>ROLE NAME</th>
                            <th>REPORTING TO</th>
                            <th>LEVEL</th>
                            {/* <th>STATUS</th> */}
                            {/* <th>ACTION</th> */}
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                          </>
                        ) : roles !== "" ? (
                          roles.map((data, i) => (
                            <tr key={i} className="roles-table-row-data">
                              <td>{i + 1}</td>
                              <td>{data?.roleName}</td>
                              <td>{data?.reportingRoleName}</td>
                              <td>{data?.roleLevel}</td>
                              {/* <td>{data?.status}</td> */}
                              {/* <td>
                                <button
                                  onClick={() => {
                                    setEditRoleModel(!editRoleModel);
                                    setEditRoleId(JSON.parse(data.roleID));
                                  }}
                                  className="btn roles-edit-link"
                                >
                                  <i className="bi bi-pencil-fill" /> Edit
                                </button>
                              </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5}> --- NO DATA FOUND --- </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="download-and-pagination">
                    <button
                      type="button"
                      className="btn btn-download-excel"
                    >
                      <i className="fa fa-download" /> &nbsp; Download
                    </button>
                    <div className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div> */}
                </div>
                {/* USER TABLE END */}

                {/* ADD ROLE MODEL START */}
                {show ? (
                  <AddRole
                    show={show}
                    handleClose={handleClose}
                    fetchRoleswithReporting={fetchRoleswithReporting}
                  />
                ) : (
                  ""
                )}
                {/* ADD ROLE MODEL END */}

                {/* EDIT ROLE MODEL START */}
                {editRoleModel ? (
                  <EditRole
                    editRoleModel={editRoleModel}
                    editRoleId={editRoleId}
                    handleClose={handleClose}
                    fetchRoleswithReporting={fetchRoleswithReporting}
                  />
                ) : (
                  " "
                )}
                {/* EDIT ROLE MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
