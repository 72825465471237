import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Products.scss";
import productimg1 from "../../images/DefaultImages/emptyProduct.svg";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { baseURL } from "../BaseUrl";

function AddWhiteLabelingModal(props) {

    const multipleFileInput = useRef(null);

    const [localGallery, setLocalGallery] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [selectLength, setSelectLength] = useState(false)
    const handleChangeMultipleImg = (event) => {
        if (event) {
            let images = [...localGallery];
            const fileUploaded = Array.from(event.target.files);

            // Filter to keep only SVG files
            const svgFiles = fileUploaded.filter((file) => file.type === "image/svg+xml");

            if (svgFiles.length + galleryImages.length + images.length > 6) {
                setSelectLength(true);
            } else {
                setSelectLength(false);
                if (images.length === 0) {
                    svgFiles.map((file) => {
                        images.push(file);
                    });
                } else {
                    let filterImages = svgFiles.filter((file) =>
                        images.every((files) => files.name !== file.name)
                    );
                    if (filterImages.length !== 0) {
                        filterImages.map((file) => {
                            images.push(file);
                        });
                    }
                }
            }
            setLocalGallery(images);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFiles(files);
    };

    const handleFiles = (files) => {
        let images = [...localGallery];
        const fileUploaded = Array.from(files);
        // Filter to keep only SVG files
        const svgFiles = fileUploaded.filter((file) => file.type === "image/svg+xml");

        if (svgFiles.length + galleryImages.length + images.length > 6) {
            setSelectLength(true);
        } else {
            setSelectLength(false);
            if (images.length === 0) {
                svgFiles.map((file) => {
                    images.push(file);
                });
            } else {
                let filterImages = svgFiles.filter((file) =>
                    images.every((files) => files.name !== file.name)
                );
                if (filterImages.length !== 0) {
                    filterImages.map((file) => {
                        images.push(file);
                    });
                }
            }
        }
        setLocalGallery(images);
    };

    const deleteImage = (name) => {
        setSelectLength(false)
        setLocalGallery(localGallery.filter((data) => data.name !== name.name));
    };

    const deleteGalImage = (name) => {
        setSelectLength(false)
        setGalleryImages(galleryImages.filter((data) => data !== name));
    };

    const uploadMultiple = (e) => {
        e.preventDefault();
        multipleFileInput.current.click();
    };

    const closeAddModal = () => {
        props.handleClose();
    };

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                className="add-edit-product-model"
            >
                <div className="add-edit-product-heading">
                    <h6 className="add-edit-product-head"> ADD IMAGES</h6>
                    <i className="fa fa-times" onClick={closeAddModal} />
                </div>
                <form >
                    <div className="container add-edit-product">
                        <div className="product-inputs">
                            <div className="mb-3">
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-add-product-img"
                                    >
                                        <span
                                            style={{
                                                color: "#04A96D",
                                                fontSize: "13px",
                                                fontWeight: "700",
                                            }} >
                                            <div className="d-flex justify-content-center"
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={handleDrop}>
                                                <>
                                                    {galleryImages.length !== 0 && (
                                                        <>
                                                            {galleryImages.map((data, i) => (
                                                                <div className="product-image-box" key={i}>
                                                                    <img
                                                                        className="product-images"
                                                                        src={`${baseURL}/${data}`}
                                                                        alt="Spare Images"
                                                                    />
                                                                    <div
                                                                        className="delete-image"
                                                                        onClick={() => deleteGalImage(data)}
                                                                    >
                                                                        <i
                                                                            className="fa fa-times-circle delete-icon"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                                <>
                                                    {localGallery &&
                                                        localGallery.map((data, i) => (
                                                            <div className="product-image-box" key={i}>
                                                                {/* <img
                                                                    className="product-images"
                                                                    src={
                                                                        localGallery
                                                                            ? URL.createObjectURL(data)
                                                                            : productimg1
                                                                    }
                                                                    alt="Spare Images"
                                                                /> */}

                                                                {/* Render SVG using an <object> tag */}
                                                                <object
                                                                    data={URL.createObjectURL(data)}
                                                                    type="image/svg+xml"
                                                                    className="product-images"
                                                                >
                                                                    Your browser does not support SVGs.
                                                                </object>
                                                                <div
                                                                    className="delete-image"
                                                                    onClick={() => deleteImage(data)}
                                                                >
                                                                    <i
                                                                        className="fa fa-times-circle delete-icon"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </>
                                            </div>
                                            {(galleryImages.length !== 0 || localGallery.length !== 0) && (
                                                galleryImages.length + localGallery.length < 6 && (
                                                    <div className="drop-btn">
                                                        <p>You can Upload up to 6 Images</p>
                                                        <p>Supports Only SVG</p>
                                                        <SbCancelBtn btnName="Browse" onClickEffect={uploadMultiple} />
                                                    </div>
                                                )
                                            )}
                                            {galleryImages.length === 0 && localGallery.length === 0 && (
                                                <div
                                                    className="drop-area"
                                                    onDragOver={(e) => e.preventDefault()}
                                                    onDrop={handleDrop}
                                                >
                                                    <h6>Upload Your Logo</h6>
                                                    <br />
                                                    <img
                                                        src="../../../images/DefaultImages/UploadImages.svg"
                                                        alt=""
                                                    />
                                                    <p>Drag and drop logo here</p>
                                                    <p>OR</p>
                                                    <SbCancelBtn btnName="Browse" onClickEffect={uploadMultiple} />
                                                    <p style={{ fontSize: "11px" }}>Supports Only SVG</p>
                                                </div>
                                            )}
                                        </span>
                                    </button>
                                    <input
                                        id="getFile"
                                        type="file"
                                        multiple
                                        name="filetobase64"
                                        accept="image/*"
                                        className="btn-primary upload"
                                        ref={multipleFileInput}
                                        onChange={handleChangeMultipleImg}
                                        style={{ display: "none" }}
                                    />
                                </div>
                                {selectLength && (
                                    <small
                                        id={`Error`}
                                        className="form-text text-muted ">
                                        Please Select maximum 6 images
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="btns-save-cancel product-btn-section">
                        <SBSaveUpdateBtn btnName="Upload" />
                        <SbCancelBtn btnName="Cancel" onClickEffect={closeAddModal} />
                    </div>
                </form>
            </Modal>
        </div>
    );
}

export default AddWhiteLabelingModal;
