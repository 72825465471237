import React, { useState } from "react";
import { Col, Row, Form, Image, FormLabel } from "react-bootstrap";
import { FieldNamesIcons } from "./FieldNamesIcons";
import { useDrop } from "react-dnd";
import AddedFormFields from "./AddedFormFields";
import FormFields from "./FormFields";

export default function CreateForm() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [addedFieldNames, setAddedFieldNames] = useState([]);
  const [formName, setFormName] = useState("");

  const [{ isOver }, addToAddedFormRef] = useDrop({
    accept: "fromFields",
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
  });

  const [{ isOver: isFormOver }, removeFromAddedRef] = useDrop({
    accept: "addedFields",
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
  });

  const moveFromFieldsToAddedForm = (data) => {
    // let fName = nData.inputData.name;
    // console.log("MOVED-DATA::", data);
    // nData["inputData"]["name"] = `${fName}-${newId}`;
    // setFieldNames((prev) => [...prev, nData]);
    let nData = { ...data };
    let newId = `${Math.floor(Math.random() * 999)}`;
    nData["id"] = newId;
    setAddedFieldNames([...addedFieldNames, nData]);
  };

  // console.log("FORM_FIELDS:::***", fieldNames);

  return (
    <div>
      {" "}
      <div className="customForms_table">
        <div className="customForms_table-scroll">
          <Row>
            <Col sm={4} className="pl-0">
              <div className="custom_form_fields" ref={removeFromAddedRef}>
                {FieldNamesIcons.map((data, i) => (
                  <FormFields
                    key={i}
                    type="fromFields"
                    data={data}
                    onDropPlayer={moveFromFieldsToAddedForm}
                  />
                ))}
              </div>
            </Col>
            <Col sm={8}>
              <div className="form-part-height-scroll">
                <div
                  className="dropped-form-fields-part"
                  ref={addToAddedFormRef}
                >
                  <Row>
                    <Col sm={2}></Col>
                    <Col>
                      <div className="custom-form-heading">
                        ------------&nbsp;&nbsp;&nbsp; Enter Form Name
                        &nbsp;&nbsp;&nbsp;------------
                      </div>
                      <Form.Control
                        type="text"
                        name="form-name"
                        className="form-control mb-1"
                        onChange={(e) => setFormName(e.target.value)}
                        placeholder="Please Eneter Form Name"
                        autoComplete="off"
                        required
                      />
                    </Col>
                    <Col sm={2}></Col>
                  </Row>
                  <div className="hr-line-custom-form" />

                  <AddedFormFields
                    setAddedFieldNames={setAddedFieldNames}
                    addedFieldNames={addedFieldNames}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
