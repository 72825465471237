import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, FloatingLabel, Form, ModalFooter, Row } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSubscriptionDropdown,
  loadUpdateSubscription,
} from "../../redux/action";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import SbCancelBtn from "../SbButtons/SbCancelBtn";

const UpGradeSubscriptionModal = (props) => {
  let dispatch = useDispatch();
  const { subscriptionTypeDropdown } = useSelector((state) => state.data);
  const [updatedSub, setUpdatedSub] = useState({ value: "", Label: "" });
  const [subScriptionTypeID, setSubscriptionTypeID] = useState();

  const closeModal = () => {
    props.setSubScribeId();
    props.handleClose(); // Close the modal
  };

  useEffect(() => {
    dispatch(loadSubscriptionDropdown());
  }, []);

  const handleSelectChange = (data) => {
    setUpdatedSub(data);
    setSubscriptionTypeID(data.value);
  };

  const [showAlert, setShowAlert] = useState(false);
  const postUpdateSub = (e) => {
    e.preventDefault();
    if (subScriptionTypeID && props.subscriptionId) {
      let updateData = {
        subscriptionTypeID: parseInt(subScriptionTypeID),
        subscriptionId: props.subscriptionId,
      };
      console.log(updateData, "updateData");
      let postSubscription = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.postTravelizeAuth,
        body: JSON.stringify(updateData),
      };
      dispatch(loadUpdateSubscription(postSubscription, closeModal));
    } else {
      setShowAlert(true);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="main_model add-edit-model"
    >
      <div className="add-edit-heading">
        <h6 className="add-edit-head">Upgrade Subscription</h6>
        <i className="fa fa-times" onClick={closeModal} />
      </div>

      <Form onSubmit={postUpdateSub}>
        <div className="container add-edit">
          <div className="upgrade-inputs my-2">
            <div className="form-field">
              <FloatingLabel
                label="Select Subscription"
                className={`${
                  updatedSub.value !== ""
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Select
                  className="react-select-container-list model-select"
                  classNamePrefix="react-select-list"
                  value={
                    !updatedSub.value ? "" : subscriptionTypeDropdown.value
                  }
                  onInputChange={subscriptionTypeDropdown.label}
                  onChange={(data) => {
                    handleSelectChange(data);
                  }}
                  options={subscriptionTypeDropdown}
                  placeholder="Select Subscription"
                  isSearchable={true}
                  // value={updatedSub}
                  // onChange={handleSelectChange}
                />
              </FloatingLabel>
              {showAlert === true && !subScriptionTypeID && (
                <small id={`Error`} className="form-text text-muted ">
                  Please Select Subscription
                </small>
              )}
            </div>
          </div>
        </div>

        <div className="btns-save-cancel">
          <SBSaveUpdateBtn btnName="Update" />
          <SbCancelBtn btnName="Cancel" onClickEffect={closeModal} />
        </div>
      </Form>
    </Modal>
  );
};

export default UpGradeSubscriptionModal;
