import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/AddEditModel.scss";
import { baseURL } from "../../BaseUrl";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import { travelizeAuth } from "../../HeaderAuthentication";
import { useSelector, useDispatch } from "react-redux";
import { loadUserDropdown, loadAllUserDetails } from "../../../redux/action";

export default function AddReportingTo(props) {
  let dispatch = useDispatch();
  const { allUserDetails } = useSelector((state) => state.data);

  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [designationsSingleSelect, setDesignationsSingleSelect] = useState([]);
  const [designation, setDesignation] = useState("");
  const [userDesignations, setUserDesignations] = useState([]);
  const [userDesignationIDs, setUserDesignationIDs] = useState(null);
  const [userNames, setUserNames] = useState([]);
  const [userIDs, setUserIDs] = useState([]);
  const [userDesigLevel, setUserDesigLevel] = useState("");
  const [userListByDesigLevel, setUserListByDesigLevel] = useState([]);
  const [multipleUserList, setMultipleUserList] = useState([]);

  const [designationLevels, setDesignationLevels] = useState([
    {
      level: "L1",
      desigName: "",
      desigID: "",
    },
  ]);
  const [reportingRole, setReportingRole] = useState(0);
  const [reportDesignation, setReportDesignation] = useState(0);
  const [designationDescription, setDesignationDescription] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useLayoutEffect(() => {
    let multiSelect = true;
    dispatch(loadUserDropdown(multiSelect));
    dispatch(loadAllUserDetails());
    fetchDropdownsData();
  }, []);

  useEffect(() => {
    if (userDesignationIDs) {
      let usersByDesignation = allUserDetails.filter(
        (data) =>
          data.desingation.designationID == userDesignationIDs.toString()
      );

      let userDesigArray = [];
      userDesigArray.push(
        usersByDesignation.map((data) => {
          return {
            fullName: data?.fullName,
            userId: data.userId,
            departmentName: data.desingation.departmentName,
            departmentID: data.desingation.departmentID,
            designationName: data.desingation.designationName,
            designationID: data.desingation.designationID,
            designationLevel: data.desingation.designationLevel,
          };
        })
      );
      setMultipleUserList(userDesigArray.flat());
    }
  }, [userDesignationIDs]);

  useEffect(() => {
    if (multipleUserList.length !== 0) {
      handleMultiSelect(
        {
          fullName: "Select All",
          userId: -1,
        },
        {
          fullName: "Select All",
          userId: -1,
        }
      );
    }
  }, [userDesignationIDs, multipleUserList]);

  useEffect(() => {
    filterByDesignationLevel();
  }, [userDesigLevel]);

  const filterByDesignationLevel = () => {
    const designationByUserLevel = allUserDetails.filter(
      (data) => data.desingation.designationLevel < userDesigLevel
    );

    setDesignationsSingleSelect(
      designationByUserLevel.map((data) => {
        return {
          value: data.desingation.designationID,
          label: data.desingation.designationName,
        };
      })
    );
    setUserListByDesigLevel(
      designationByUserLevel.map((data) => {
        return {
          value: data.userId,
          label: data.fullName,
          designID: data.desingation.designationID,
        };
      })
    );
  };

  // console.log("ALL-USER-DETAILS:", allUserDetails);

  const fetchDropdownsData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result.map((data) => {
              return { value: data.designationID, label: data.designationName };
            })
          );
        } else {
          setDesignationList([]);
        }
      });
  };

  // console.log("CHEKC***", multipleUserList);

  const handleMultiSelect = (selectedList, selectedItem) => {
    console.log("CHEKC-1***", multipleUserList);
    // Check if "Select All" is selected
    if (selectedItem && selectedItem.userId == -1) {
      if (selectedList.length === multipleUserList.length) {
        // If "Select All" is selected and all other options are also selected, clear selections
        setUserNames([]);
        setUserIDs([]);
        setUserDesigLevel("");
      } else {
        // Otherwise, select all options
        setUserNames(multipleUserList);
        setUserIDs(multipleUserList.map((d) => d.userId));
        setUserDesigLevel(
          [
            ...new Set(multipleUserList.map((d) => d.designationLevel)),
          ].toString()
        );
      }
    } else {
      setUserNames(selectedList);
      setUserIDs(selectedList.map((d) => d.userId));
      setUserDesigLevel(
        [...new Set(selectedList.map((d) => d.designationLevel))].toString()
      );
    }
  };

  // console.log("USER-DESIG-LEVEL:", userDesigLevel);

  // ADD LELVEL DESIGNATION AND SET VALUE
  const addNextLevelsDesignations = (e) => {
    e.preventDefault();
    let selectCount = designationLevels.length + 1;
    setDesignationLevels([
      ...designationLevels,
      {
        level: `L${selectCount}`,
        desigName: "",
        desigID: "",
      },
    ]);
  };

  const setReportingDesignations = (value, name, desigLevel) => {
    let fiterObj = designationLevels.filter((item) => item.level == desigLevel);
    fiterObj[0].desigID = value;
    fiterObj[0].desigName = name;
    fiterObj[0].level = desigLevel;
    setDesignationLevels([...designationLevels]);
  };

  const removeLevelsDesignations = (index) => {
    let finalDesignationLevels = designationLevels.filter(
      (data, i) => i != index
    );
    setDesignationLevels(finalDesignationLevels);
  };
  const setReportingUserName = (value, name, desigLevel) => {
    console.log("REPORT NAME:", value, name, desigLevel);
  };
  // console.log("SELECT COUNT:", designationLevels);

  // ADD LELVEL DESIGNATION AND SET VALUE

  // const addReportingTo = () => {
  //   if (
  //     designation !== "" &&
  //     departmentId !== 0 &&
  //     reportingRole !== 0 &&
  //     reportDesignation !== 0 &&
  //     subscriptionId !== ""
  //   ) {
  //     const designationDetails = {
  //       designationName: designation,
  //       departmentID: departmentId,
  //       role: {
  //         roleID: reportingRole,
  //       },
  //       reportingDesignationID: reportDesignation,
  //       subscriptionID: subscriptionId,
  //       description: designationDescription,
  //       status: enableStatus,
  //     };

  //     let requestOption = {
  //       method: "POST",
  //       mode: "cors",
  //       headers: travelizeAuth,
  //       body: JSON.stringify(designationDetails),
  //     };
  //     fetch(`${baseURL}/api/Designation/AddDesignation`, requestOption)
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if (res.success) {
  //           props.handleClose();
  //           props.fetchDesignations();
  //           swal.showAlert("Success", `${res.message}`, "success");
  //         } else {
  //           props.handleClose();
  //           swal.showAlert("Fail", `${res.errorMessage}`, "warning");
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("ERROR:", error);
  //       });
  //   } else {
  //     setFieldReq(true);
  //     setAlertMessage({
  //       alertType: "fail",
  //       message: "Required!",
  //     });
  //   }
  // };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-model reporting-hierarchy-modal"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">ADD APPROVAL LEVELS</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-reporting">
            <div className="product-inputs">
              <div className="mb-3">
                <FloatingLabel
                  label="Select Designations"
                  className={`${
                    userDesignationIDs !== null
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={designationList}
                    placeholder="Select Designations"
                    value={designationList.value}
                    onInputChange={designationList.label}
                    onChange={(data) => setUserDesignationIDs(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && userDesignationIDs == null ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message}
                  </small>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-3">
                <FloatingLabel
                  label="Users"
                  className={`${
                    userNames.length !== 0
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Multiselect
                    className="select-add-user-model model-select maxHeight-n-scroller"
                    options={[
                      {
                        fullName: "Select All",
                        userId: -1,
                      },
                      ...multipleUserList,
                    ]} // Options to display in the dropdown
                    selectedValues={userNames} // Preselected value to persist in dropdown
                    onSelect={handleMultiSelect} // Function will trigger on select event
                    onRemove={handleMultiSelect} // Function will trigger on remove event
                    showCheckbox={true}
                    displayValue="fullName" // Property name to display in the dropdown options
                    placeholder={"Select Users"}
                    emptyRecordMsg="No options available"
                    avoidHighlightFirstOption={true}
                    closeIcon="cancel"
                    showArrow={true}
                    selectionLimit={100}
                    closeOnSelect={false}
                    hidePlaceholder={true}
                  />
                </FloatingLabel>
                {fieldReq && userNames.length <= 0 ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {alertMessage.message}
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="add-approval-levels-part">
                {designationLevels.map((desig, i) => (
                  <div className="dflex-j-between mb-3" key={i}>
                    <FloatingLabel
                      label={`${desig.level} - Approval`}
                      className={`${
                        desig.desigID !== ""
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                      style={{ width: "100%", paddingRight: "10px" }}
                    >
                      <Select
                        id={desig.level}
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={designationsSingleSelect}
                        placeholder="Approval Designation"
                        value={designationsSingleSelect.value}
                        onInputChange={designationsSingleSelect.label}
                        onChange={(data) =>
                          setReportingDesignations(
                            data.value,
                            data.label,
                            desig.level
                          )
                        }
                        isSearchable={true}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      label={`${desig.level} - Approval User Name`}
                      className={`${
                        desig.desigName !== ""
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                      style={{ width: "100%", paddingRight: "10px" }}
                    >
                      <Select
                        id={desig.level}
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        options={userListByDesigLevel.filter(
                          (item) => item.designID == desig.desigID
                        )}
                        placeholder="User Name"
                        value={userListByDesigLevel.value}
                        onInputChange={userListByDesigLevel.label}
                        onChange={(data) =>
                          setReportingUserName(
                            data.value,
                            data.label,
                            desig.level
                          )
                        }
                        isSearchable={true}
                      />
                    </FloatingLabel>
                    {i != 0 ? (
                      <div
                        className="remove-icon"
                        onClick={() => removeLevelsDesignations(i)}
                      >
                        <i className="fa fa-times" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
              {designationLevels.length < 10 ? (
                <div className="mt-1">
                  <button
                    className="btn add-select-dropdown"
                    onClick={(e) => addNextLevelsDesignations(e)}
                  >
                    <i className="fa fa-plus" /> Add
                  </button>
                </div>
              ) : (
                ""
              )}
              {/* <div className="mt-3">
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </span>
                </span>
              </div> */}
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn
            // onClickEffect={addReportingTo}
            btnName="Save"
          />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
