import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { ToastContainer } from "react-toastify";

createRoot(document.getElementById("root")).render(
  <Router>
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <App />
        <ToastContainer
          hideProgressBar={false}
          autoClose={false}
          newestOnTop={true}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          closeOnClick={false}
          theme="dark"
        />
      </DndProvider>
    </Provider>
  </Router>
);
