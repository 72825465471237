import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";

function ActivityTable(props) {
  console.log(props.activitiesByUser, "props.activitiesByUser");
  return (
    <div className="sb-table-div">
      <Paper
        sx={{ width: "100%", overflow: "hidden" }}
        className="table-main-div"
      >
        <TableContainer
          onScroll={(e) =>
            handleScroll(
              e,
              props.activitiesByUser,
              props.totalData,
              props.setLoading,
              props.setPageSize,
              props.PageSize
            )
          }
        >
          <Table
            stickyHeader
            aria-label="sticky table table-responsive"
            className="basefare-table"
          >
            <TableHead className="custom-table-header">
              <TableRow>
                {props.tableHeader.map((name, i) => (
                  <TableCell
                    key={i}
                    className={`thead-cell-products`}
                    align={name.align}
                    style={{ minWidth: `${name.width}` }}
                  >
                    {name.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.activitiesByUser.length === 0 ? (
              <TableBody>
                <TableRow align="center">
                  <TableCell align="center" colSpan={props.tableHeader.length}>
                    <div style={{ padding: "20px 20px" }}>
                      <img
                        src="../../../../images/DefaultImages/no-data-found.png"
                        alt=""
                        style={{ width: "65%" }}
                      />
                      <br />
                      <span className="color-yellow">No Data Found...!</span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {props.activitiesByUser &&
                  props.activitiesByUser.map((data, i) => (
                    <TableRow
                      align="center"
                      key={i}
                      className="client-list-row-data"
                    >
                      <TableCell>{data.onlyTime}</TableCell>
                      <TableCell>
                        <p>{data.cordLoc.location}</p>
                        <p>
                          lat:{" "}
                          <span className="color-yellow">
                            {data.cordLoc.latitude}
                          </span>
                          &nbsp;&nbsp;lng:{" "}
                          <span className="color-yellow">
                            {data.cordLoc.longitude}
                          </span>
                        </p>
                      </TableCell>
                      <TableCell>
                        {data.batteryStrength} | {data.batteryTemp}
                      </TableCell>
                      <TableCell align="center">
                        {data.mobileNetwork} | {data.networkType}
                      </TableCell>
                      <TableCell align="center">
                        {data.mockLocation} | &nbsp;
                        <i className="fa fa-male" />
                      </TableCell>
                    </TableRow>
                  ))}
                {props.loading && <OnScrollLoading />}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default ActivityTable;
