import React, { useState, useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import "../../styles/AddEditModel.scss";
import DatePicker from "react-datepicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";

export default function SetDateTimeModel(props) {
  const [newdate, setNewDate] = useState("");
  const [meetTime, setMeetTime] = useState({ val: null, time: null });
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [visitDateNTime, setVisitDateNTime] = useState({
    vDate: "",
    vTime: "",
  });

  const selectTime = (time) => {
    setMeetTime({ val: time, time: `${time.$H}:${time.$m}` });
    let timeS = time.$d.toTimeString().slice(0, 5);
    const currentTime = timeS.slice(0, 2);
    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        props.setVisitTime("0" + exactTime + " " + "PM", props.clientId);
      }
    } else if (currentTime === 12) {
      props.setVisitTime(timeS + " " + "PM", props.clientId);
    } else {
      props.setVisitTime(timeS + " " + "AM", props.clientId);
    }
  };

  const handleChange = (date, id) => {
    props.setVisitDate(date, id);
    setNewDate(date);
  };

  const checkValidation = (e) => {
    e.preventDefault();
    if (newdate !== "" && meetTime.val !== null && meetTime.time !== null) {
      props.handleCloseDateTime();
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Required",
      });
    }
  };

  return (
    <div>
      {" "}
      <Modal
        show={props.dateTimemodel}
        className="main_model add-edit-model set-date-time-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">SET DATE-TIME</h6>
          <i className="fa fa-times" onClick={props.handleCloseDateTime} />
        </div>

        <form>
          <div className="container add-edit">
            <div className="add-route-plan-model">
              <div className="model-inputs client-list-with-date-time">
                <div className="display-datentime-field">
                  <div className="date-n-time-part">
                    <div className="set-visit-date mb-3">
                      <FloatingLabel
                        label="Select Date"
                        className={`${
                          props.fromDate
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <div className="meeting-date-picker-with-icon">
                          <DatePicker
                            selected={newdate}
                            className="select-add-meeting-model meeting-date"
                            id={props.clientId}
                            dateFormat="dd/MM/yyyy"
                            // selectsEnd
                            // startDate={date}
                            // endDate={toDate}
                            minDate={props.fromDate}
                            onChange={(date) =>
                              handleChange(date, props.clientId)
                            }
                            maxDate={props.toDate}
                            autoComplete="off"
                          />
                          <i className="fa fa-calendar-o calander-icon" />
                        </div>
                      </FloatingLabel>
                      {fieldReq && (newdate == "" || newdate == null) ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="set-visit-time">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          // label="Time"
                          className="form-control select-add-meeting-model"
                          value={meetTime.val}
                          onChange={(time) => {
                            selectTime(time);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          minTime={dayjs("2023-01-30T06:00")}
                          maxTime={dayjs("2023-01-31T22:00")}
                          minutesStep={5}
                        />
                      </LocalizationProvider>

                      {(fieldReq && meetTime.val == null) ||
                      meetTime.time == null ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn
              btnName="Save"
              onClickEffect={(e) => checkValidation(e)}
            />
            <SbCancelBtn
              btnName="Cancel"
              onClickEffect={() => {
                props.handleCloseDateTime();
                props.setVisitTime("", props.clientId);
                props.setVisitDate("", props.clientId);
              }}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}
