import React, { useState, useEffect } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/TravelApproval.scss";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  Button,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Image,
} from "react-bootstrap";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import TicketDetailsTable from "../TicketDetailsTable";
import { travelByOptions, travelDetails } from "../CommonData";
import PaymentEntryDetails from "../PaymentEntryDetails";

export default function TicketPaymentDetails(props) {
  const [filterUserId, setFilterUserId] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);

    // Check if both start and end dates are selected
    if (start && end) {
      setToDate(end);
    } else {
      // If only the start date is selected, set toDate to null
      setToDate(null);
    }
  };
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  // Page navigation
  const goToBackPage = () => {
    props.setTicketPaymentDetailsPage(false);
  };
  // page navigation

  return (
    <div>
      {" "}
      <div>
        {props.ticketPaymentDetailsPage && (
          <div className="page-wrapper">
            <div className={`main-body ${props.toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          {" "}
                          TRIP & PAYMENT DETAILS{" "}
                        </h6>
                      </div>
                    </Col>
                    <Col md={4} className="cat-col"></Col>

                    <Col md={5} className="cat-col"></Col>
                  </Row>
                </div>
                {/* Filter and Header Section */}

                {/* USER TABLE START */}
                <div className="travel-approval-table">
                  <div className="container-fluid travel-ticket-details-page">
                    <div className="tripId-n-date dflex-j-between">
                      <div>
                        <b>TRIP ID : </b>#R1035
                      </div>
                      <div className="ticket-status">
                        <span>Status :</span>&nbsp;&nbsp;
                        <span className="status approved">
                          <i className="fa fa-check-square" /> &nbsp; Approved
                        </span>
                      </div>
                      <div>
                        <b>TRAVEL DATE : </b> 01/04/2024 to 04/04/2024
                      </div>
                    </div>
                    <div className="name-team-client-other-details">
                      <div className="team-details-n-client-details">
                        <div className="planerName-n-teamDetails">
                          <div className="trp-dtls-label-value">
                            <div className="trp-dtls-label">
                              Planner Name :{" "}
                            </div>
                            <div className="trp-dtls-value">
                              {" "}
                              Dimpu Kumar Pandey
                            </div>
                          </div>
                          <div className="trp-dtls-label-value">
                            <div className="trp-dtls-label">Team Details: </div>
                            <div className="trp-dtls-value">
                              {" "}
                              Yuva, Bharath, Shweta, Praveen, Aakash
                            </div>
                          </div>
                          <div className="trp-dtls-label-value">
                            <div className="trp-dtls-label">Remarks : </div>
                            <div className="trp-dtls-value">
                              {" "}
                              Test remarks for trip details.
                            </div>
                          </div>
                        </div>
                        <div className="client-details">
                          <div className="trp-dtls-label-value">
                            <div className="trp-dtls-label">Client Name : </div>
                            <div className="trp-dtls-value">
                              {" "}
                              Dimpu Kumar Pandey
                            </div>
                          </div>
                          <div className="trp-dtls-label-value">
                            <div className="trp-dtls-label">
                              Client Contact :{" "}
                            </div>
                            <div className="trp-dtls-value">
                              {" "}
                              +91 9900336001
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="other-details">
                        <div className="trp-dtls-label-value">
                          <div className="trp-dtls-label">Other : </div>
                          <div className="trp-dtls-value">
                            {" "}
                            Need test sample products for client visit.
                          </div>
                        </div>
                      </div>

                      <div className="advance-cash-aprvd-dtls">
                        <div className="particular cash-data">
                          <div className="head">Particulars</div>
                          <div className="body">Advance Cash</div>
                        </div>
                        <div className="applied cash-data">
                          <div className="head">Applied</div>
                          <div className="body">Rs. 15000</div>
                        </div>
                        <div className="approved cash-data">
                          <div className="head">Approved</div>
                          <div className="body color-green">Rs. 10000</div>
                        </div>
                        <div className="remarks cash-data">
                          <div className="head">Remarks</div>
                          <div className="body">
                            Advance Cash Advance Cash Advance Cash Advance Cash
                            Advance Cash Advance Cash Advance Cash
                          </div>
                        </div>
                      </div>
                      <div className="remarks-for-rejection">
                        <div className="trp-dtls-label-value">
                          <div className="trp-dtls-label">
                            Approver Remarks :{" "}
                          </div>
                          <div className="trp-dtls-value">
                            Please cancel the flight and book train for journey.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trip-ticket-details">
                      <TicketDetailsTable
                        travelByOptions={travelByOptions}
                        travelDetails={travelDetails}
                      />
                    </div>
                    <div className="payment-details">
                      <PaymentEntryDetails modeofPayment={"Net Banking"} />
                    </div>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
