import React, { useState, useEffect, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import { baseURL } from "../../BaseUrl";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { travelizeAuth } from "../../HeaderAuthentication";

export default function AddDepartment(props) {
  const [departmentName, setDepartmentName] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });
  const [deptDetails, setDeptDetails] = useState("");

  useLayoutEffect(() => {
    {
      props.deptId !== 0 && getDepartmentById();
    }
  }, [props.deptId]);

  const getDepartmentById = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Department/Get/${props.deptId}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDeptDetails(res.result);
          setDepartmentName(res?.result?.name);
          setEnableStatus(res?.result?.statusID);
        } else {
          setDeptDetails("");
          setDepartmentName("");
        }
      });
  };

  const addDepartment = () => {
    if (departmentName !== "") {
      const departmentDetail = {
        name: departmentName,
        statusID: enableStatus,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(departmentDetail),
      };
      fetch(`${baseURL}/api/Department/AddDepartment`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchDepartments();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.message);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Enter the Department Name",
      });
    }
  };

  const editDepartment = () => {
    if (departmentName !== "") {
      const departmentDetail = {
        id: props.deptId,
        name: departmentName,
        statusID: enableStatus,
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(departmentDetail),
      };
      fetch(`${baseURL}/api/Department/UpdateDepartment`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchDepartments();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.message);
          }
        })
        .catch((error) => {
          toast.error(`${error}`);
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fail",
        message: "Enter the Department Name",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            {props.deptId !== 0 ? "EDIT DEPARTMENT" : "ADD DEPARTMENT"}
          </h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit">
            <div className="product-inputs mt-2">
              <div className="mb-2">
                <FloatingLabel
                  label="Department Name"
                  className={`${
                    departmentName
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <input
                    type="text"
                    className="form-control form add-input"
                    placeholder="Department Name"
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                  />
                </FloatingLabel>
                {fieldReq && departmentName == "" ? (
                  <small
                    id={`Error${alertMessage.alertType}`}
                    className="form-text text-muted "
                  >
                    {`${alertMessage.message}`}
                  </small>
                ) : (
                  ""
                )}
              </div>
              {props.deptId !== 0 && (
                <div className="mt-3">
                  <span className="enable-status d-flex">
                    <h6>Enable</h6>
                    <span className="enable-radio-client d-flex">
                      <div className="form-check enable-radio-status">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={enableStatus == 1 ? true : false}
                          onChange={() => setEnableStatus(1)}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check enable-radio-status">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={enableStatus == 0 ? true : false}
                          onChange={() => setEnableStatus(0)}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          No
                        </label>
                      </div>
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn
            onClickEffect={props.deptId !== 0 ? editDepartment : addDepartment}
            btnName={props.deptId !== 0 ? "Update" : "Save"}
          />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}
