import React, { useEffect, useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import TablePage from "../../SbTables/TablePage";
import { Col, Row } from "react-bootstrap";
import "../../../styles/category.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  loadHolidayList,
  loadSingleHoliday,
  loadStateList,
} from "../../../redux/action";
import Addholidays from "./Addholidays";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import Select from "react-select";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";

function Holiday() {
  let dispatch = useDispatch();
  const { holidayList } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);

  let tableHeader = [
    "Sl.No",
    "Name",
    "From Date",
    "To Date",
    "Type",
    "States",
    "Description",
    "Status",
    "Action",
  ];

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [PageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [show, setShow] = useState(false);
  const [holidayId, setHolidayId] = useState("");
  const [holidayStateID, setHolidayStateID] = useState("");
  const [searchValue, setsearchValue] = useState("");

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      loadHolidayList(
        holidayStateID,
        PageSize,
        currentPage,
        setTotalData,
        setIsLoading,
        setLoading
      )
    );
    dispatch(loadStateList(0));
  }, [holidayStateID, PageSize, currentPage]);

  const holidayModuleOpen = () => {
    setShow(true);
  };
  const holidayModuleClose = () => {
    setShow(false);
  };

  const handleEdit = (id) => {
    holidayModuleOpen();
    setHolidayId(id);
    dispatch(loadSingleHoliday(id));
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setHolidayStateID("");
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* addHoliday modal page */}
      {show && (
        <Addholidays
          show={show}
          holidayModuleClose={holidayModuleClose}
          holidayId={holidayId}
          setHolidayId={setHolidayId}
        />
      )}
      {/* addHoliday modal page */}
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <Row className="page-filter-section">
              <Col md={3} sm={12} xs={12} className="cat-col">
                <div className="page-header-text-div">
                  <div className="back-btn" onClick={goToBackPage}>
                    <img
                      src="../../../../images/DefaultImages/back-btn-arrow.svg"
                      alt=""
                    />
                  </div>
                  <h6 className="page-header-text">Holiday List</h6>
                </div>
              </Col>
              <Col md={5} sm={12} xs={12} className="cat-col">
                <div className="input-div">
                  <input
                    type="search"
                    id="metricInfo_search_bar"
                    placeholder="Search Holiday"
                    className="form-control setting-input"
                    value={searchValue}
                    onChange={(e) => setsearchValue(e.target.value)}
                  />
                  <i className="fa fa-search search-icon" />
                </div>
              </Col>
              <Col md={4} sm={12} xs={12} className="cat-col">
                <div style={{ float: "right" }}>
                  <div className="filter-row-input-boxes-end">
                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      // defaultValue={optionsRoleList[0].label}
                      options={stateList}
                      placeholder="Holiday State"
                      value={holidayStateID == 0 ? "" : stateList.value}
                      onInputChange={stateList.label}
                      onChange={(data) => setHolidayStateID(data.label)}
                      isSearchable={true}
                    />
                    <MiClearButton
                      onClickEffect={clearSerachBySelect}
                      btnName="Clear"
                    />
                    <SbAddBtn onClickEffect={holidayModuleOpen} />
                  </div>
                </div>
              </Col>
            </Row>
            <TablePage
              tableHeader={tableHeader}
              clientCategoryData={holidayList}
              handleEdit={handleEdit}
              page="holiday"
              searchValue={searchValue}
              setLoading={setLoading}
              setPageSize={setPageSize}
              PageSize={PageSize}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Holiday;
