import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import { baseURL } from "../../BaseUrl";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import Select from "react-select";
import { optionsUnitList, optionsMOTTypes } from "../../DropdownsData";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { FloatingLabel } from "react-bootstrap";
import { addCityName, loadCountryData, loadDropdownStateName, loadStateList, loadZoneList } from "../../../redux/action";

function AddCity(props) {

    let dispatch = useDispatch();
    const { getCountryName } = useSelector((state) => state.data);
    const { zoneList } = useSelector((state) => state.data);
    const { getDropdownState } = useSelector((state) => state.data);
    const [selectedCountryName, setSelectedCountryName] = useState(
        {
            value: "",
            label: "",
        })
    const [selectedZoneName, setSelectedZoneName] = useState(
        {
            value: "",
            label: "",
        })
    const [selectedStateName, setSelectedStateName] = useState(
        {
            value: "",
            label: "",
        })
    const [cityName, setCityName] = useState("")

    useEffect(() => {
        dispatch(loadCountryData())
        dispatch(loadZoneList())
        dispatch(loadDropdownStateName())
    }, [])

    const handleSaveCityName = (e) => {
        e.preventDefault()

        let data = [];
        (cityName.split(",")).forEach(element => {
            data.push({
                "zoneId": selectedZoneName.value,
                "zoneName": selectedZoneName.label,
                "stateId": selectedStateName.value,
                "stateName": selectedStateName.label,
                "countryID": selectedCountryName.value,
                "countryName": selectedCountryName.label,
                "countryCode": props.getAllStateName[0].countryCode,
                "cityId": 0,
                "cityName": element
            });
        });
        let postData = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(data)
        }
        console.log(data, 'data')
        dispatch(addCityName(postData, handleCityClose))
    }

    const handleCityClose = () => {
        props.handleCityClose();
    };

    return (
        <div>
            <Modal
                show={props.showCity}
                onHide={props.handleCityClose}
                className="main_model add-edit-basefare-model"
            >
                <div className="add-edit-basefare-heading">
                    <h6 className="add-edit-basefare-head">
                        ADD CITY
                    </h6>
                    <i className="fa fa-times" onClick={handleCityClose} />
                </div>
                <form>
                    <div className="container add-edit-basefare">
                        <div className="product-inputs">
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                // label="Select Country"
                                // className={`${bankName
                                //     ? "float-visible-select"
                                //     : "float-hidden float-select"
                                //     }`}
                                >
                                    <Select
                                        className="react-select-container-list model-select"
                                        classNamePrefix="react-select-list"
                                        options={getCountryName}
                                        placeholder="Select Country"
                                        value={selectedCountryName.value && selectedCountryName}
                                        onChange={(selectedOption) => { setSelectedCountryName(selectedOption) }}
                                        isSearchable={true}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Select Zone"
                                    className={`${selectedZoneName
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                >
                                    <Select
                                        className="react-select-container-list model-select"
                                        classNamePrefix="react-select-list"
                                        options={zoneList}
                                        placeholder="Select Zone"
                                        value={selectedZoneName.value && selectedZoneName}
                                        onChange={(selectedOption) => { setSelectedZoneName(selectedOption) }}
                                        isSearchable={true}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <FloatingLabel
                                    label="Select State"
                                    className={`${selectedStateName
                                        ? "float-visible-select"
                                        : "float-hidden float-select"
                                        }`}
                                >
                                    <Select
                                        className="react-select-container-list model-select"
                                        classNamePrefix="react-select-list"
                                        options={getDropdownState}
                                        placeholder="Select State"
                                        value={selectedStateName.value && selectedStateName}
                                        onChange={(selectedOption) => { setSelectedStateName(selectedOption) }}
                                        isSearchable={true}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="mb-3" style={{ position: "relative" }}>
                                <label className="float-label">City Name</label>
                                <input
                                    type="text"
                                    className="form-control form add-basefare-input"
                                    id="exampleFormControlInput1"
                                    name="cityname"
                                    value={cityName}
                                    placeholder="Enter City Name"
                                    onChange={(e) => { setCityName(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div className="btns-save-cancel">
                    <SBSaveUpdateBtn
                        btnName="Save"
                        onClickEffect={handleSaveCityName}
                    />
                    <SbCancelBtn btnName="Cancel" onClickEffect={handleCityClose} />
                </div>
            </Modal>
        </div>
    );
}

export default AddCity;
