import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/BaseFare.scss";
import * as HeaderAuthentication from "../../HeaderAuthentication";
import Select from "react-select";
import { optionsUnitList, optionsMOTTypes } from "../../DropdownsData";
import {
  AddbaseFare,
  loadDepartmentDrop,
  loadDepasrtDesignation,
  loadMotDrop,
  loadSingleBaseFare,
  loadUpdateBasefare,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { FloatingLabel } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { NumberValidation } from "../../ValidationFormat";

function AddBaseFareModel(props) {
  let EditmotID = props.EditmotID;
  let setBasefareId = props.basefareId;

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let dispatch = useDispatch();
  const { departDesignation } = useSelector((state) => state.data);
  const { motDrop } = useSelector((state) => state.data);
  const { departmentDrop } = useSelector((state) => state.data);
  const { singleBaseFire } = useSelector((state) => state.data);

  const [showAlert, setShowAlert] = useState(false);
  const [showValidAlert, setShowValidAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [motType, setMotType] = useState({ value: "", label: "" });
  const [motUnit, setMotUnit] = useState({ value: "", label: "" });
  const [motDepartment, setMotDepartment] = useState({ value: "", label: "" });
  const [motDesignation, setMotDesignation] = useState([]);
  const [allMotDesignation, setAllMotDesignation] = useState([]);
  const [selected, setSelected] = useState([]);
  const [statusFlag, setStatusFlag] = useState(true);
  const setSelectedMot = (value) => {
    if (value) {
      setShowAlert(false);
      setSelected(value);
    }
  };

  useEffect(() => {
    dispatch(loadMotDrop());
    dispatch(loadDepartmentDrop());
    if (setBasefareId || EditmotID) {
      setPostItem(singleBaseFire);
      if (singleBaseFire) {
        if (singleBaseFire.status == 1) {
          setStatusFlag(true);
        } else {
          setStatusFlag(false);
        }
      }
      motDrop.map((mot) => {
        if (mot.value === singleBaseFire?.motID) {
          setSelected([mot]);
        }
      });
      optionsMOTTypes.map((type) => {
        if (type.value === singleBaseFire?.motType) {
          setMotType(type);
        }
      });
      optionsUnitList.map((unit) => {
        if (unit.value === singleBaseFire?.motUnit) {
          setMotUnit(unit);
        }
      });
      departmentDrop.map((department) => {
        if (department.value === singleBaseFire?.departmentID) {
          setMotDepartment(department);
          dispatch(loadDepasrtDesignation(singleBaseFire?.departmentID));
        }
      });
    }
  }, [setBasefareId, EditmotID, singleBaseFire]);

  // useEffect(() => {
  //   if ((setBasefareId || EditmotID) && singleBaseFire) {
  //     let desList = [...departDesignation];
  //     if (desList) {
  //       desList.map((designation) => {
  //         if (designation.value === singleBaseFire?.designationID) {
  //           setMotDesignation(designation);
  //         }
  //       });
  //     }
  //   }
  // }, [departDesignation]);

  useEffect(() => {
    if ((setBasefareId || EditmotID) && singleBaseFire) {
      let desList = [...departDesignation];
      if (desList) {
        desList.map((designation) => {
          if (designation.value === singleBaseFire?.designationID) {
            setMotDesignation([designation]); // Ensure it's an array with the selected designation
            setAllMotDesignation([designation]); // Ensure it's an array with the selected designation
          }
        });
      }
    }
  }, [departDesignation, setBasefareId, EditmotID, singleBaseFire]);

  const [postItem, setPostItem] = useState({
    subscriptionID: resPassData.subscriptionId,
    addedByID: resPassData.userId,
    addedByUserFullName: resPassData.fullName,
    addedOn: "",
    status: 2,
    motName: "",
    motType: "",
    motUnit: 0,
    baseFareAmount: 0,
    motStatus: 1,
    departmentID: 0,
    designationID: 0,
    dayLimit: "",
  });

  const handleInputChange = (e) => {
    setShowValidAlert(false);
    if (e.target.type == "number") {
      const { name, value } = e.target;
      setPostItem({ ...postItem, [name]: +value });
    } else {
      const { name, value } = e.target;
      setPostItem({ ...postItem, [name]: value });
    }
  };

  const handleSelectChange = (value, type) => {
    console.log("Selected Value:", value);
    if (type === "mot-type") {
      setMotType(value);
      postItem.motType = value.value;
    } else if (type === "unit") {
      setMotUnit(value);
      postItem.motUnit = value.value;
    } else if (type === "department") {
      dispatch(loadDepasrtDesignation(value.value));
      setMotDepartment(value);
      setMotDesignation([]);
      postItem.departmentID = value.value;
    }
    // else if (type === "designation") {
    //   setMotDesignation(value);
    //   postItem.designationID = value.value;
    // }
  };

  const habdleEnableStatus = (e) => {
    if (e === 1) {
      setStatusFlag(true);
      postItem.status = 1;
    } else if (e === 0) {
      setStatusFlag(false);
      postItem.status = 0;
    }
  };

  // const handleSelect = (selectedList) => {
  //   setMotDesignation(selectedList);
  //   // postItem.designationID = selectedList.map(item => item.value);
  // };
  const [selectAll, setSelectAll] = useState(false);

  const handleSelect = (selectedList, selectedItem) => {
    if (selectedItem && selectedItem.value === "*" && !selectAll) {
      setSelectAll(!selectAll);
      setAllMotDesignation([
        { label: "Select All", value: "*" },
        ...departDesignation,
      ]);
      setMotDesignation(departDesignation);
    } else {
      setAllMotDesignation(selectedList);
      setMotDesignation(selectedList);
    }
  };

  const handleRemove = (selectedList, selectedItem) => {
    if (selectedItem && selectedItem.value === "*") {
      setMotDesignation([]);
      setAllMotDesignation([]);
      setSelectAll(!selectAll);
      return;
    }
    setAllMotDesignation(selectedList);
    setMotDesignation(selectedList);
  };

  useEffect(() => {
    if (selected && selected.length !== 0) {
      postItem.motName = selected[0].label;
      // postItem.motID = selected[0].value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleAutoInputChange = (input) => {
    const filteredOptions = motDrop.filter((option) =>
      option.label.toLowerCase().startsWith(input.toLowerCase())
    );
    return filteredOptions;
  };

  const closeAddModal = () => {
    props.setBasefareId("");
    props.handleClose();
    props.setEditMotID("");
    props.setClickType("");
    dispatch(loadSingleBaseFare(null));
    props.fetchBaseFareData();
  };

  const addBaseFare = () => {
    if (
      postItem.motUnit &&
      postItem.motName &&
      postItem.motType &&
      postItem.departmentID &&
      motDesignation.length !== 0
    ) {
      if (
        (postItem.dayLimit !== 0 || postItem.dayLimit !== "") &&
        ((postItem.motUnit == "Per KM" && postItem.baseFareAmount > 0) ||
          (postItem.motUnit == "1" && postItem.baseFareAmount >= 0))
      ) {
        // console.log(motDesignation, "postItem");
        for (var i = 0; i < motDesignation.length; i++) {
          const postItemClone = { ...postItem };
          postItemClone.designationID = motDesignation[i].value;
          postItemClone.designationName = motDesignation[i].label;
          let lastBaseFare = false;
          if (i === motDesignation.length - 1) {
            lastBaseFare = true;
          }
          let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(postItemClone),
          };

          if (setBasefareId) {
            dispatch(
              loadUpdateBasefare(requestOption, closeAddModal, lastBaseFare)
            );
          } else {
            dispatch(AddbaseFare(requestOption, closeAddModal, lastBaseFare));
          }
        }
      } else {
        setShowValidAlert(true);
        setShowAlert(true);
        setAlertMessage({
          alertType: "fails",
          message: "Required !",
        });
      }
    } else {
      setShowAlert(true);
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">
            {props.clickType ? (
              "Base fare Details"
            ) : (
              <>{setBasefareId ? "EDIT BASE FARE" : "ADD BASE FARE"}</>
            )}
          </h6>
          <i className="fa fa-times" onClick={closeAddModal} />
        </div>
        <form>
          <div className="container add-edit mt-2">
            <div className="mb-3" style={{ position: "relative" }}>
              <FloatingLabel
                label="Select MOT Name"
                className={`${
                  selected.length !== 0
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Typeahead
                  id="autosuggest-example"
                  options={motDrop}
                  selected={selected}
                  className="custom-auto-select"
                  onChange={setSelectedMot}
                  onInputChange={handleAutoInputChange}
                  placeholder="Select MOT Name"
                  disabled={props.clickType}
                  allowNew
                />
              </FloatingLabel>
              {showAlert === true && postItem.motName === "" && (
                <small
                  id={`Error${alertMessage.alertType}`}
                  className="form-text text-muted "
                >
                  Please Select mot type
                </small>
              )}
            </div>

            <div className="mb-3" style={{ position: "relative" }}>
              <FloatingLabel
                label="Select MOT Type"
                className={`${
                  motType.value !== ""
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  options={optionsMOTTypes}
                  placeholder="Select MOT Type"
                  value={motType.value && motType}
                  onInputChange={motType.label}
                  onChange={(data) => handleSelectChange(data, "mot-type")}
                  isSearchable={true}
                  isDisabled={props.clickType}
                />
              </FloatingLabel>
              {showAlert && !postItem.motType && (
                <small id={`Error`} className="form-text text-muted ">
                  Please Select MOT Type
                </small>
              )}
            </div>

            <div className="mb-3" style={{ position: "relative" }}>
              <FloatingLabel
                label="Select MOT Unit"
                className={`${
                  motUnit.value !== ""
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  options={optionsUnitList}
                  placeholder="Select Unit"
                  value={motUnit.value && motUnit}
                  onInputChange={optionsUnitList.label}
                  onChange={(data) => handleSelectChange(data, "unit")}
                  isSearchable={true}
                  isDisabled={props.clickType}
                />
              </FloatingLabel>
              {showAlert && !postItem.motUnit && (
                <small id={`Error`} className="form-text text-muted ">
                  Please Select MOT Unit
                </small>
              )}
            </div>

            <div className="mb-3" style={{ position: "relative" }}>
              <FloatingLabel
                label="Select Department"
                className={`${
                  motUnit.value !== ""
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Select
                  className="react-select-container-list model-select add-basefare-input"
                  classNamePrefix="react-select-list"
                  options={departmentDrop}
                  placeholder="Select Department"
                  value={motDepartment.value && motDepartment}
                  onInputChange={departmentDrop.label}
                  onChange={(data) => handleSelectChange(data, "department")}
                  isSearchable={true}
                  isDisabled={setBasefareId}
                />
              </FloatingLabel>
              {showAlert && !postItem.departmentID && (
                <small id={`Error`} className="form-text text-muted ">
                  Please Select MOT Department
                </small>
              )}
            </div>
            <div className="mb-3" style={{ position: "relative" }}>
              <FloatingLabel
                label="Select Designation"
                className={`${
                  motDesignation.value !== ""
                    ? "float-visible-select"
                    : "float-hidden float-select"
                }`}
              >
                <Multiselect
                  className="select-add-user-model"
                  options={[
                    { label: "Select All", value: "*" },
                    ...departDesignation,
                  ]}
                  // options={departDesignation} // Ensure departDesignation is an array
                  value={allMotDesignation} // Ensure motDesignation is defined and an array
                  selectedValues={allMotDesignation} // Set the pre-selected values
                  displayValue="label"
                  onSelect={handleSelect}
                  onRemove={handleRemove}
                  showCheckbox={true}
                  placeholder="Select Designation"
                  emptyListMsg="No options available"
                  avoidHighlightFirstOption={true}
                  closeIcon="cancel"
                  showArrow={true}
                  closeOnSelect={true}
                  disable={setBasefareId}
                />
              </FloatingLabel>
              {showAlert && motDesignation.length === 0 && (
                <small id={`Error`} className="form-text text-muted ">
                  Please Select MOT Designation
                </small>
              )}
            </div>
            <div className="mb-3" style={{ position: "relative" }}>
              <FloatingLabel
                label="Enter BaseFare"
                className={`${
                  postItem?.baseFareAmount !== ""
                    ? "float-input-visible"
                    : "float-hidden float-input"
                }`}
              >
                <input
                  type="number"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  name="baseFareAmount"
                  value={postItem?.baseFareAmount}
                  placeholder="Enter BaseFare"
                  onChange={handleInputChange}
                  disabled={props.clickType}
                />
              </FloatingLabel>
              {showAlert &&
                ((postItem.motUnit == "Per KM" &&
                  postItem.baseFareAmount <= 0) ||
                  (postItem.motUnit == "1" && postItem.baseFareAmount < 0)) && (
                  <small id={`Error`} className="form-text text-muted">
                    Please Enter Valid BaseFare Amount
                  </small>
                )}
            </div>

            <div className="mb-3" style={{ position: "relative" }}>
              <FloatingLabel
                label="Limit Per Day"
                className={`${
                  postItem?.dayLimit
                    ? "float-input-visible"
                    : "float-hidden float-input"
                }`}
              >
                <input
                  type="number"
                  className="form-control form add-basefare-input"
                  id="exampleFormControlInput1"
                  placeholder="Limit Per Day"
                  name="dayLimit"
                  value={postItem?.dayLimit || ""}
                  onChange={handleInputChange}
                  disabled={props.clickType}
                />
              </FloatingLabel>
              {/* {showValidAlert &&  (
                      <small
                        id={`Error`}
                        className="form-text text-muted "
                      >
                        Please Enter BaseFare Amount
                      </small>
                    )} */}
            </div>
            <div className="mt-2">
              {setBasefareId && (
                <span className="enable-status d-flex">
                  <h6>Enable</h6>
                  <span className="enable-radio d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={statusFlag}
                        onChange={() => habdleEnableStatus(1)}
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={!statusFlag}
                        onChange={() => habdleEnableStatus(0)}
                      />
                      <label className="form-check-label">No</label>
                    </div>
                  </span>
                </span>
              )}
            </div>
          </div>

          <div className="btns-save-cancel">
            {!props.clickType && (
              <SBSaveUpdateBtn
                onClickEffect={addBaseFare}
                btnName={setBasefareId ? "Update" : "Save"}
              />
            )}
            <SbCancelBtn btnName="Cancel" onClickEffect={closeAddModal} />
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddBaseFareModel;
