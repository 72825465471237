import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeletonLoginActivity() {
  return (
    <div>
      <div className="act-right-body">
        <Row className="dflex-j-arround">
          <Col lg={2} md={2} sm={2}>
            <div className="table-user-image">
              <Skeleton
                className="table-data-user-img"
                circle={true}
                width={80}
                height={80}
              />
              <h6>
                <Skeleton />
              </h6>
            </div>
          </Col>
          <Col lg={10} md={10} sm={10}>
            <div>
              <Skeleton style={{ width: "100%", height: "10px" }} />
              <Skeleton style={{ width: "100%", height: "40px" }} />
              <div className="dflex-j-between pt-2">
                <Skeleton style={{ width: "30px", height: "30px" }} />
                <Skeleton style={{ width: "30px", height: "30px" }} />
                <Skeleton style={{ width: "30px", height: "30px" }} />
                <Skeleton style={{ width: "30px", height: "30px" }} />
                <Skeleton style={{ width: "30px", height: "30px" }} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SkeletonLoginActivity;
