import React, { useState, useEffect, useRef } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/TravelApproval.scss";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import Footer from "../../Footer";
import { loadUserDropdown } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate } from "react-router-dom";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";

export default function TravelApproval() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let loginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const loggedInRole = loginDetails?.desingation?.role?.roleID;
  /* HEADER AUTH END */

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [searchVal, setSearchVal] = useState("");
  const [filterUserId, setFilterUserId] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadUserDropdown());
  }, []);

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);

    // Check if both start and end dates are selected
    if (start && end) {
      setToDate(end);
    } else {
      // If only the start date is selected, set toDate to null
      setToDate(null);
    }
  };
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setFromDate(new Date());
    setToDate(new Date());
  };

  const componentRef = useRef();

  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });
  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div>
      <div id="main-page">
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text"> TRAVEL APPROVAL </h6>
                      </div>
                    </Col>
                    <Col md={4} className="cat-col">
                      <div className="input-div">
                        <input
                          type="search"
                          id="metricInfo_search_bar"
                          placeholder="Search By Trip Id"
                          className="form-control setting-input"
                          value={searchVal}
                          onChange={(e) => setSearchVal(e.target.value)}
                        />
                        <i className="fa fa-search search-icon" />
                      </div>
                    </Col>

                    <Col md={5} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setFilter(!filter)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {filter && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={8}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={userDropdown}
                                  placeholder="Select User"
                                  value={
                                    filterUserId == "" ? "" : userDropdown.value
                                  }
                                  onInputChange={userDropdown.label}
                                  onChange={(data) =>
                                    setFilterUserId(data.value)
                                  }
                                  isSearchable={true}
                                />
                              </div>

                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                                className="input-group from-to-date-select"
                              >
                                <span
                                  className="input-group-text"
                                  id="from-label"
                                >
                                  From - To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate}
                                    className="select from-date date-filter"
                                    id="date-range-picker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={(date) =>
                                      setSelectSearchDrop(date)
                                    }
                                  // maxDate={new Date()}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fa fa-calendar-o calander-icon"
                                    style={{ right: "15px" }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                {/* Filter and Header Section */}

                {/* USER TABLE START */}
                <div className="travel-approval-table">
                  <div
                    className="table-responsive travelapproval-scrollbar"
                  // onScroll={(e) =>
                  //   handleScroll(
                  //     e,
                  //     routePlanData,
                  //     totalData,
                  //     setLoading,
                  //     setPageSize,
                  //     PageSize
                  //   )
                  // }
                  >
                    <table
                      className="table travelapproval-table-data"
                      ref={componentRef}
                    >
                      <thead className="thead-light travelapproval-thead">
                        {/* {isLoading ? (
                          <>
                            <SkeletonStdTableHead />
                          </>
                        ) : ( */}
                        <tr>
                          <th>TRIP ID</th>
                          <th>NAME</th>
                          <th>FROM DATE</th>
                          <th>TO DATE</th>
                          <th>BOOKED BY</th>
                          <th>TRAVEL TYPE</th>
                          <th>STATUS</th>
                          <th style={{ textAlign: "center" }}>ACTION</th>
                        </tr>
                        {/* )} */}
                      </thead>
                      <tbody>
                        {/* {isLoading ? ( */}
                        <>
                          {/* <SkeletonStdTableBody />
                          <SkeletonStdTableBody />
                          <SkeletonStdTableBody />
                          <SkeletonStdTableBody />
                          <SkeletonStdTableBody />
                          <SkeletonStdTableBody />
                          <SkeletonStdTableBody />
                          <SkeletonStdTableBody />
                          <SkeletonStdTableBody />
                          <SkeletonStdTableBody />
                          <SkeletonStdTableBody /> */}
                        </>
                        {/* ) : routePlanData !== "" ? (
                          routePlanData.map((data, i) => ( */}
                        <tr>
                          <td>#R001</td>
                          <td>Dimpu Kumar</td>
                          <td>01/04/2024</td>
                          <td>10/04/2024</td>
                          <td>Akash</td>
                          <td>Single</td>
                          <td>Pending</td>
                          <td>
                            {" "}
                            <div className="hv-center">
                              <div className="edit-travelplan">
                                <Link to="/travelmanagement/travelrequest/23">
                                  <p className="color-green">
                                    <i className="fa fa-edit" />
                                    &nbsp;Edit
                                  </p>
                                </Link>
                              </div>
                              <div className="view-travelplan">
                                <Link to="/travelmanagement/travelviewnapprove">
                                  <p className="color-green">
                                    <i className="bi bi-eye-fill" />
                                    &nbsp;View
                                  </p>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {/* ))
                        ) : ( */}
                        {/* <tr>
                          <td colSpan={6} className="text-center">
                            {" "}
                            --- NO DATA FOUND ---{" "}
                          </td>
                        </tr> */}
                        {/* )} */}
                        {/* {loading && <OnScrollLoading />} */}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <SbDownloadBtn onClickEffect={pdfDownload} />
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
          </div>
          <Footer />
        </>
      </div>
    </div>
  );
}
