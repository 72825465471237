import React from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/AddEditModel.scss";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";

function EditAmc(props) {

  return (
    <div>
      <Modal
        show={props.editAmc}
        // onHide={props.handleClose}
        className="main_model add-edit-model amc-model"
      >
        <div className="add-edit-heading">
          <h6 className="add-edit-head">EDIT AMC</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit">
            <div className="add-inputs">
              <Row>
                <div className="col-md-4 col-sm-12">
                  <div className="mb-3">
                    <Form.Label>Invoice Date</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control form add-input"
                      placeholder="Invoice Date"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control form add-input"
                      placeholder="Company Name"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Company Email</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control form add-input"
                      placeholder="Company Email"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control form add-input"
                      placeholder="Phone Number"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="2"
                      cols="20"
                      name="usrtxt"
                      wrap="hard"
                      className="form-control form add-input"
                      id="descriptionArea"
                      placeholder="Address"
                      required
                    // value={location}
                    // onChange={(e) => setLocation(e)}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="mb-3">
                    <Form.Label>Product Name</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="Product Name"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Product Code</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="Product Code"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Serial Number</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="Serial Number"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Instalation Date</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="Instalation Date"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Installed By</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="Installed By"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="mb-3">
                    <Form.Label>AMC Number</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="AMC Number"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>AMC Start Date</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="AMC Start Date"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>AMC Start Date</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="AMC End Date"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>AMC Type</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="AMC Type"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>

                  <div className="mb-2">
                    <Form.Label>Free Service</Form.Label>
                    <input
                      type="text"
                      className="form-control form add-input"
                      placeholder="Free Service"
                    // readOnly={true}
                    // value={fullname}
                    // onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="mb-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-add-img"
                    onClick={uploadClick}
                  >
                    Upload Product Images
                  </button>
                  <input
                    id="getFile"
                    type="file"
                    name="filetobase64"
                    accept="image/*"
                    className="btn-primary upload"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="d-flex">
                  <div className="product-image-box">
                    <img
                      className="product-images"
                      src={localImage ? localImage : productimg1}
                      alt="Product Images"
                    />
                  </div>
                  <div className="product-image-box">
                    <img
                      className="product-images"
                      src={localImage ? localImage : productimg2}
                      alt="Product Images"
                    />
                  </div>
                  <div className="product-image-box">
                    <img
                      className="product-images"
                      src={localImage ? localImage : productimg3}
                      alt="Product Images"
                    />
                  </div>
                </div>
              </div> */}
              </Row>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <div className="products-save-button">
            <button
              //   onClick={postinvoicedata}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="products-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditAmc;
