import React from "react";
import {
  Button,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Image,
} from "react-bootstrap";

export default function PaymentEntryDetails(props) {
  return (
    <div>
      <div className="booking-payment-details">
        <div className="head-n-mop dflex-j-between">
          <div className="row-heading">Payment Details</div>
          <div className="booking-mop">
            <div className="label-head">Mode of Payment : </div>
            <div className="color-green">
              <b>{props.modeofPayment}</b>
            </div>
          </div>
        </div>
        <div className="entry-details">
          <Row>
            <Col md={6} className="left-part br-1">
              <div className="row-label-n-value">
                <div className="data-label">Payment Date : </div>
                <div className="data-value">25-04-2024</div>
              </div>
              <div className="row-label-n-value">
                <div className="data-label">Payment Amount : </div>
                <div className="data-value">25,000</div>
              </div>
              <div className="row-label-n-value">
                <div className="data-label">UTR No./Ref. No. : </div>
                <div className="data-value">67686768768</div>
              </div>
              {props.modeofPayment == "UPI" && (
                <div className="row-label-n-value">
                  <div className="data-label">Mobile Number : </div>
                  <div className="data-value">7760142475</div>
                </div>
              )}
            </Col>
            <Col md={6} className="right-part">
              {props.modeofPayment == "Card" ||
              props.modeofPayment == "Net Banking" ? (
                <div className="row-label-n-value">
                  <div className="data-label">Bank Name : </div>
                  <div className="data-value">SBI, Rajajinagar</div>
                </div>
              ) : (
                ""
              )}
              {props.modeofPayment == "Card" ? (
                <>
                  <div className="row-label-n-value">
                    <div className="data-label">Card Number : </div>
                    <div className="data-value">2664587879879</div>
                  </div>
                  <div className="row-label-n-value">
                    <div className="data-label">Name on Card : </div>
                    <div className="data-value">Aakash Kumar</div>
                  </div>
                </>
              ) : (
                props.modeofPayment == "Net Banking" && (
                  <>
                    <div className="row-label-n-value">
                      <div className="data-label">Account Number : </div>
                      <div className="data-value">20195608834</div>
                    </div>
                    <div className="row-label-n-value">
                      <div className="data-label">Account Name : </div>
                      <div className="data-value">Aakash Kumar</div>
                    </div>
                  </>
                )
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
