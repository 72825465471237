import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../../styles/CommonStyle.scss";
import "../../styles/PanBankDetailsComp.scss";
import profileimg from "../../images/profile-1.png";

function PanBankDetailsComp(props) {
  return (
    <div>
      <div className="modal-backdrop">
        <div className="modal-right-fade">
          <Modal.Dialog className="right-fade-modal-dialog">
            <div>
              <Modal.Header closeButton onHide={props.handleCloseModal}>
                <Modal.Title className="client-modal-header card-head">
                  {props.pageName === "UserBankDetails" && (
                    <button
                      onClick={() =>
                        props.addEditButtonClick(props.gfReferelProfileId)
                      }
                      className="btn client-edit-link"
                    >
                      {props.singleUserReferralsData &&
                      Object.keys(props.singleUserReferralsData).length ===
                        0 ? (
                        <div className="dflex-j-start">
                          <i className="fa fa-plus" /> &nbsp;Add
                        </div>
                      ) : (
                        <div className="dflex-j-start">
                          <i className="fa fa-edit" /> &nbsp;Edit
                        </div>
                      )}
                    </button>
                  )}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="bank-details-body">
                  {props.singleUserReferralsData &&
                  Object.keys(props.singleUserReferralsData).length === 0 ? (
                    <div className="no-data-found-part">
                      <img
                        src="../../../../images/DefaultImages/NodataFound.jpg"
                        alt="No Data"
                        style={{ width: "80%" }}
                      />
                      <br />
                      <div>--- No Data Found ---</div>
                    </div>
                  ) : (
                    <div>
                      <div className="pan-n-bank-details">
                        <div className="card-details">
                          <div className="user-card-details">
                            <div className="pan-card-header mb-3">
                              PAN CARD DETAILS
                            </div>
                            <div className="photo-n-number">
                              <div className="user-pan-image">
                                <img
                                  className="pan-card-image"
                                  src={profileimg}
                                  alt="user-image"
                                />
                              </div>
                              <div className="pan-number-part">
                                <div className="num-title mb-2">
                                  Permanent Account Number
                                </div>{" "}
                                <div className="pan-acc-number">
                                  <h5>
                                    {props.singleUserReferralsData[0]?.panCard}
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="name-on-pancard mt-2">
                              <span>Name </span>
                              <span>:</span>
                              <span className="pan-name">
                                {props.singleUserReferralsData[0]?.accountName}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="card-details">
                          <div className="bank-details-header">
                            <div className="accounts-header">Bank Details</div>
                          </div>

                          <div className="user-card-details">
                            <div className="bank-details-part">
                              <div className="bank-acc-number">
                                <span className="pan-name">
                                  <p>Name</p> :{" "}
                                  <p>
                                    <b>
                                      {
                                        props.singleUserReferralsData[0]
                                          .accountName
                                      }
                                    </b>
                                  </p>
                                </span>
                                <span className="pan-name">
                                  <p>Bank Name</p> :{" "}
                                  <p>
                                    <b>
                                      {
                                        props.singleUserReferralsData[0]
                                          .bankName
                                      }
                                    </b>
                                  </p>
                                </span>
                                <span className="pan-name">
                                  <p>Acc.No</p> :{" "}
                                  <p>
                                    <b>
                                      {
                                        props.singleUserReferralsData[0]
                                          .accountNumber
                                      }
                                    </b>
                                  </p>
                                </span>
                                <span className="pan-name">
                                  <p>Aadhar.No</p> :{" "}
                                  <p>
                                    <b>
                                      {
                                        props.singleUserReferralsData[0]
                                          .adharCard
                                      }
                                    </b>
                                  </p>
                                </span>
                                <span className="pan-name">
                                  <p>GST.No</p> :{" "}
                                  <p>
                                    <b>
                                      {
                                        props.singleUserReferralsData[0]
                                          .gsT_VAT_Number
                                      }
                                    </b>
                                  </p>
                                </span>
                                <span className="pan-name">
                                  <p>Acc Type</p> :{" "}
                                  <p>
                                    <b>
                                      {
                                        props.singleUserReferralsData[0]
                                          .accountType
                                      }
                                    </b>
                                  </p>
                                </span>
                                <span className="pan-name">
                                  <p>IFSC Code</p> :{" "}
                                  <p>
                                    <b>
                                      {
                                        props.singleUserReferralsData[0]
                                          .ifscCode
                                      }
                                    </b>
                                  </p>
                                </span>
                                <span className="pan-name">
                                  <p>Branch</p> :{" "}
                                  <p>
                                    <b>
                                      {props.singleUserReferralsData[0].branch}
                                    </b>
                                  </p>
                                </span>
                                <span className="pan-name">
                                  <p>UPI Id</p> :{" "}
                                  <p>
                                    <b>
                                      {props.singleUserReferralsData[0].upiid}
                                    </b>
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Modal.Body>
            </div>
          </Modal.Dialog>
        </div>
      </div>
    </div>
  );
}

export default PanBankDetailsComp;
