import React from "react";
import Modal from "react-bootstrap/Modal";
import "../styles/CommonStyle.scss";
import { SBSaveUpdateBtn } from "./SbButtons/SbAddBtn";
import SbCancelBtn from "./SbButtons/SbCancelBtn";

function ConfirmModel(props) {
  const confirmed = () => {
    props.confirmAction();
    props.handleClose();
  };

  return (
    <div>
      <Modal show={props.show} className="main_model add-edit-model">
        <div className="add-edit-heading">
          <h6 className="add-edit-head">CONFIRM</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>

        <div className="container add-edit">
          <div className="confirmation-text-part">
            <span className="confirmation-text">
              {props.confirmationMessage}
            </span>
          </div>
        </div>
        <div className="btns-save-cancel confirm">
          <SBSaveUpdateBtn onClickEffect={confirmed} btnName="Yes" />
          <SbCancelBtn btnName="No" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}

export default ConfirmModel;
