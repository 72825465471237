import React from "react";
import "../../styles/CommonStyle.scss";

export default function DataDisplayByColumn(props) {
  return (
    <div className="dataview-by-column">
      <div className="row">
        <div className="col-6">
          <span className="data-head">{props.dataHead}</span>
        </div>
        <div className="col-6">
          <span className="data-body">
            :&nbsp;&nbsp;&nbsp;&nbsp;
            {props.dataBody}
          </span>
        </div>
      </div>
    </div>
  );
}
