export const radialBarChartOptions = {
  options: {
    chart: {
      type: "radialBar",
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e4e4e4",
          strokeWidth: "96%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 1.5,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 0,
            fontSize: "18px",
          },
        },
      },
    },
    fill: {
      colors: ["#04A96D"],
    },
  },
};

export const donutChartOptions = {
  options: {
    chart: {
      type: "donut",
    },
    responsive: [
      {
        breakpoint: 500,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "top",
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
    // fill: {
    //   colors: ["#02b6e4", "#04A96D", "rgb(255, 101, 83)"],
    // },
    labels: ["30% Task Ongoing", "70% Task Completed", "30% Task Pending"],
  },
};

export const roundChartOptions = {
  options: {
    chart: {
      height: 150,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "40%",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 5,
            fontSize: "14px",
          },
        },
      },
    },
    labels: ["Phase 01"],
    fill: {
      colors: ["#04A96D"],
    },
  },
};

export const roundChartPendingOptions = {
  options: {
    chart: {
      height: 150,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "40%",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 5,
            fontSize: "14px",
          },
        },
      },
    },
    labels: ["Phase 01"],
    fill: {
      colors: ["rgb(255, 101, 83)"],
    },
  },
};

export const overallTaskReportOptions = {
  options: {
    chart: {
      //   height: 130,
      type: "bar",
      stacked: false,
      toolbar: {
        autoSelected: "pan",
        show: false,
      },
    },
    yaxis: {
      labels: {
        minWidth: 10,
        style: {
          colors: ["#5f5f5f"],
          fontSize: "10px",
          fontWeight: 600,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        barHeight: "50%",
        borderRadius: 5,
        borderRadiusApplication: "end",
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#ff7b00", "#04A96D"],
    // fill: {
    // },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      labels: {
        minHeight: 80,
        style: {
          colors: ["#5f5f5f"],
          fontSize: "10px",
          fontWeight: 600,
        },
      },
    },
    legend: {
      show: true,
      horizontalAlign: "left",
      customLegendItems: ["Total Tasks", "Completed Tasks"],
      offsetX: 10,
      offsetY: 10,
      markers: {
        width: 15,
        height: 15,
        // fillColors: ["#ff7b00", "#04A96D"],
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: 5,
      style: {
        fontSize: "6px",
        colors: ["#ffffff"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#ffffff"],
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      style: {
        fontSize: "12px",
      },
      marker: {
        show: true,
        // fillColors: ["#ff7b00", "#04A96D"],
      },
    },
  },
};

export const overBudejetCostOptions = {
  options: {
    chart: {
      offsetX: -5,
      offsetY: 0,
      type: "bar",
      stacked: true,
      toolbar: {
        autoSelected: "pan",
        show: false,
      },
    },
    yaxis: {
      labels: {
        minWidth: 0,
        // minHeight: 10,
        style: {
          colors: ["#5f5f5f"],
          fontSize: "10px",
          fontWeight: 600,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "10%",
        barHeight: "100%",
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#015a57", "#cc3300"],
    // fill: {
    //   colors: ["#015a57", "#cc3300"],
    // },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: ["BUDGET"],
      labels: {
        minHeight: 0,
        style: {
          colors: ["#5f5f5f"],
          fontSize: "10px",
          fontWeight: 600,
        },
      },
    },
    legend: {
      show: true,
      horizontalAlign: "left",
      customLegendItems: ["Total Budget", "Over Budget"],
      offsetX: 10,
      offsetY: 10,
      markers: {
        width: 15,
        height: 15,
        // fillColors: ["#015a57", "#cc3300"],
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: 5,
      style: {
        fontSize: "10px",
        colors: ["#ffffff"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#ffffff"],
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      style: {
        fontSize: "12px",
      },
      marker: {
        show: true,
        // fillColors: ["#015a57", "#cc3300"],
      },
    },
  },
};
