import "../../styles/AddUserDetails.scss";
import { Col, FloatingLabel, Nav, Row, Tab } from "react-bootstrap";
import Select from "react-select";
import "../../styles/AddUserDetails.scss";
import { useEffect, useRef, useState } from "react";
import DefaultLogo from "../../images/defalut-logo.png";
import { Link } from "react-router-dom";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCityList,
  loadClientCategoryDrop,
  loadClientDropDown,
  loadClientStageGrop,
  loadCustomFieldsForCompany,
  loadPostCompanyData,
  loadStateList,
  loadUpdateCompanyData,
  loadUploadSingleImage,
  loadZoneList,
} from "../../redux/action";
import * as HeaderAuthentication from "../HeaderAuthentication";
import { baseURL } from "../BaseUrl";
import GoogleMapAddressModel from "../GoogleMapAddressModel";
import "../../styles/Clients.scss";
import CustomFieldsForCompany from "./CustomFieldsForCompany";
import AddEditFormComponent from "../ReUseComponents/AddEditFormComponent";

const AddClientData = (props) => {
  let editClientId = props.editClientId;
  const hiddenFileInput = useRef(null);

  let dispatch = useDispatch();
  const { singleClient } = useSelector((state) => state.data);
  const { clientDropdown } = useSelector((state) => state.data);
  const { getCustomFieldsForCompany } = useSelector((state) => state.data);

  const { categoryDropdown } = useSelector((state) => state.data);
  const { stageDropdown } = useSelector((state) => state.data);
  const { zoneList } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);

  const formattedClientDropdown = clientDropdown.map((option) => ({
    ...option,
    label: `${option.label} - ${option.name}`,
  }));

  const [imgFile, setImgFile] = useState("");
  const [imgFileName, setImgFileName] = useState("");

  const [selectedCategory, setSelectedCategory] = useState({
    value: 0,
    label: "",
  });
  const [selectedStage, setSelectedStage] = useState({ value: 0, label: "" });
  const [country, setCountry] = useState({ value: 0, label: "" });
  const [zoneId, setZoneId] = useState({ value: 0, label: "" });
  const [countryList, setCountryList] = useState([]);
  const [statusFlag, setStatusFlag] = useState(true);
  const [stateId, setStateId] = useState({ value: 0, label: "" });
  const [selectNavKey, setSelectNavKey] = useState("Company Details");
  const [customFields, setCustomFields] = useState([]);

  let cntyList = [{ value: 1, label: "India" }];

  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [googleMapsModel, setGoogleMapsModel] = useState(false);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });
  const [fieldReq, setFieldReq] = useState(false);

  const [cityId, setCityId] = useState({ value: 0, label: "" });
  const [localImage, setLocalImage] = useState("");
  const [customeLocalImage, setCustomeLocalImage] = useState("");

  // set state for post client data
  const [clientLocation, setClientLocation] = useState({
    latitude: "",
    longitude: "",
    location: "",
  });

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [clientData, setClientData] = useState({
    clientName: "",
    logo: "",
    mobile: "",
    clientLoc: clientLocation,
    email: "",
    contactPersonName: "",
    landline: "",
    countryID: "",
    zoneID: "",
    stateID: "",
    cityID: "",
    pincode: "",
    categoryID: "",
    clientCategoryName: "",
    stageID: "",
    clientStageName: "",
    enabledStatus: 1,
    city: {
      zoneId: 0,
      zoneName: "",
      stateId: 0,
      stateName: "",
      countryID: 0,
      countryName: "",
      countryCode: "",
      cityId: 0,
      cityName: "",
    },
  });
  // set state for post client data

  const [fieldValues, setFieldValues] = useState({});

  useEffect(() => {
    dispatch(loadClientDropDown());
    dispatch(loadClientCategoryDrop());
    dispatch(loadClientStageGrop());
    dispatch(loadZoneList(props.setIsLoading));
    dispatch(loadCustomFieldsForCompany(2));
    props.setIsLoading(true);
    setCountryList(cntyList);
    setLocation("");
  }, []);

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (
          singleClient.countryID !== undefined &&
          singleClient.countryID !== 0
        ) {
          setCountry({ value: 1, label: "India" });
        }
        if (singleClient) {
          setClientData(singleClient);
          if (singleClient.enabledStatus === 1) {
            setStatusFlag(true);
          } else {
            setStatusFlag(false);
          }
          setLatLan({
            lat: Number(singleClient?.clientLoc?.latitude),
            lng: Number(singleClient?.clientLoc?.longitude),
          });
          setLocation(singleClient?.clientLoc?.location);
          setAddress(singleClient?.clientLoc?.location);
        }

        let categoryArray = [...categoryDropdown];
        categoryArray.forEach((category) => {
          if (category.value === singleClient.categoryID) {
            setSelectedCategory(category);
          }
        });
        let stageArray = [...stageDropdown];
        stageArray.forEach((stage) => {
          if (stage.value === singleClient.stageID) {
            setSelectedStage(stage);
          }
        });

        let zoneArray = [...zoneList];

        zoneArray.forEach((zone) => {
          if (zone.value === singleClient.zoneID) {
            setZoneId(zone);
            dispatch(loadStateList(zone.value));
          }
        });
      }
    }
  }, [editClientId, singleClient, zoneList]);

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (stateList) {
          stateList.forEach((state) => {
            if (state.value === singleClient.stateID) {
              setStateId(state);
              dispatch(loadCityList(state.value));
            }
          });
        }
      }
    }
  }, [stateList, editClientId]);

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (cityList) {
          cityList.forEach((city) => {
            if (city.value === singleClient.cityID) {
              setCityId(city);
            }
          });
        }
      }
    }
  }, [cityList, editClientId]);

  useEffect(() => {
    let list = { ...clientLocation };
    if (latLan.lat == null && latLan.lng == null) {
      getLocation();
    } else {
      list.latitude = JSON.stringify(latLan.lat);
      list.longitude = JSON.stringify(latLan.lng);
    }

    if (location) {
      list.location = location;
    }
    clientData.clientLoc = list;
  }, [latLan, location]);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  // Onchange Event
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(value)) {
        setClientData({ ...clientData, [name]: parseInt(value) });
      } else {
        e.preventDefault();
      }
    } else {
      setClientData({ ...clientData, [name]: value });
    }
  };

  const habdleEnableStatus = (e) => {
    if (e === 1) {
      setStatusFlag(true);
      clientData.enabledStatus = 1;
    } else if (e === 0) {
      setStatusFlag(false);
      clientData.enabledStatus = 0;
    }
  };
  // Onchange Event

  // handle select
  const handleSelectChange = (data, type) => {
    if (type === "category") {
      setSelectedCategory(data);
      clientData.categoryID = data.value;
      clientData.clientCategoryName = data.label;
    } else if (type === "stage") {
      setSelectedStage(data);
      clientData.stageID = data.value;
      clientData.clientStageName = data.label;
    } else if (type === "country") {
      setCountry(data);
      clientData.countryID = data.value;
    } else if (type === "zone") {
      setZoneId(data);
      dispatch(loadStateList(data.value));
      clientData.zoneID = data.value;
      setStateId({ value: "", label: "" });
      setCityId({ value: "", label: "" });
      clientData.stateID = "";
      clientData.cityID = "";
    } else if (type === "state") {
      setStateId(data);
      clientData.stateID = data.value;
      dispatch(loadCityList(data.value));
      setCityId({ value: "", label: "" });
      clientData.cityID = "";
    } else if (type === "city") {
      setCityId(data);
      clientData.cityID = data.value;
    }
  };
  // handle select

  useEffect(() => {
    if (props.companyReportingData.length !== 0 && editClientId) {
      let companyReportingClientsLabels = props.companyReportingData.map(
        (client) => {
          return { label: client.clientName, value: client.clientID, name: "" };
        }
      );
      setSelectedOptions(companyReportingClientsLabels);
    }
  }, [props.companyReportingData, editClientId]);

  const handleSelectOption = (selected) => {
    setSelectedOptions(selected);
  };

  //  ADDING CLIENT START
  const addClientDetails = (e) => {
    e.preventDefault();
    clientData.city = {
      zoneId: clientData.zoneID,
      stateId: clientData.stateID,
      countryID: clientData.countryID,
      cityId: clientData.cityID,
    };

    if (
      // imgFile !== "" &&
      clientData.clientName !== "" &&
      clientData.contactPersonName !== "" &&
      Math.abs(clientData?.mobile).toString().length === 10 &&
      clientData.categoryID &&
      clientData.stageID &&
      location !== "" &&
      clientData.countryID &&
      clientData.zoneID &&
      clientData.stateID
    ) {
      const formData = new FormData();
      formData.append("Files", imgFile);
      formData.append("Directory", "Company");
      formData.append("FileNamePrefix", clientData.clientName);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.salesbeeImgAuth,
        body: formData,
      };
      let actionType;
      if (editClientId) {
        actionType = "update-data";
      } else {
        actionType = "post-data";
      }
      const categoryReportingClientIDsArray = selectedOptions.map((option) =>
        parseInt(option.value)
      );
      clientData.categoryReportingClientIds = categoryReportingClientIDsArray;

      if (imgFile !== "") {
        dispatch(
          loadUploadSingleImage(
            requestOption,
            clientData,
            closeModal,
            actionType
          )
        );
      } else {
        const clientDetails = {
          client: clientData,
        };

        let requestOption = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.postTravelizeAuth,
          body: JSON.stringify(clientDetails),
        };
        if (editClientId) {
          dispatch(
            loadUpdateCompanyData(
              requestOption,
              closeModal,
              customFields,
              editClientId,
              customeLocalImage
            )
          );
        } else {
          dispatch(
            loadPostCompanyData(requestOption, closeModal, customFields)
          );
        }
      }
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required...!",
      });
    }
  };
  //  ADDING CLIENT END

  /*  GET LIVE LOCATION BY SYSTEM START  */
  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported for your browser");
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLan({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      });
    }
  };
  /*  GET LIVE LOCATION BY SYSTEM END  */

  // ADDRESS FROM GOOGLE MAPS START

  const openGoogleMaps = () => {
    setGoogleMapsModel(!googleMapsModel);
  };

  // ADDRESS FROM GOOGLE MAPS END

  const handleTabItem = (navKey) => {
    setSelectNavKey(navKey);
  };

  const moveToNextTab = (e, selectNavKey) => {
    e.preventDefault();
    if (selectNavKey === "Company Details") {
      if (
        clientData.clientName !== "" &&
        clientData.contactPersonName !== "" &&
        Math.abs(clientData?.mobile).toString().length === 10 &&
        clientData.categoryID &&
        clientData.stageID &&
        location !== "" &&
        clientData.countryID &&
        clientData.zoneID &&
        clientData.stateID
      ) {
        handleTabItem("Custom Fields");
      } else {
        setFieldReq(true);
        setAlertMessage({
          alertType: "fails",
          message: "Required...!",
        });
      }
    } else if (selectNavKey === "Custom Fields") {
      handleTabItem("preview");
    }
  };

  const moveToPreviousTab = (e, selectNavKey) => {
    e.preventDefault();
    if (selectNavKey === "Custom Fields") {
      handleTabItem("Company Details");
    } else if (selectNavKey === "address") {
      handleTabItem("Company Details");
    }
  };

  const { getSingleCustomFieldsForCompany } = useSelector(
    (state) => state.data
  );

  useEffect(() => {
    if (
      getSingleCustomFieldsForCompany &&
      getSingleCustomFieldsForCompany.length > 0 &&
      getCustomFieldsForCompany &&
      getCustomFieldsForCompany.length > 0
    ) {
      const updatedCustomFields = getCustomFieldsForCompany.map((field) => {
        const fieldValue = getSingleCustomFieldsForCompany.find(
          (singleField) => singleField.customeFieldID === field.customeFieldID
        );
        return {
          ...field,
          value: fieldValue ? fieldValue.value : "",
        };
      });
      setCustomFields(updatedCustomFields);
    } else if (
      getSingleCustomFieldsForCompany &&
      getSingleCustomFieldsForCompany.length === 0 &&
      getCustomFieldsForCompany &&
      getCustomFieldsForCompany.length > 0
    ) {
      setCustomFields(getCustomFieldsForCompany);
    }
  }, [getSingleCustomFieldsForCompany, getCustomFieldsForCompany]);

  const closeModal = () => {
    props.fetchClientData();
    props.setEditClientId("");
    setSelectedOptions([]);
    setLocation("");
    props.handleClose();
  };

  return (
    <AddEditFormComponent
      handleClose={closeModal}
      formName={"Add Company"}
      formSubTitle={"Please enter the company/client infomation."}
      sidebarItems={["Company Details", "Custom Fields"]}
      setSelectNavKey={setSelectNavKey}
      selectNavKey={selectNavKey}
      childrens={
        <>
          <div className="add-comapny-details">
            <div className="add-user-details-second-div">
              <form onSubmit={addClientDetails}>
                <div className="add-form-template-div">
                  {selectNavKey === "Company Details" && (
                    <div>
                      <div className="mi-subform-header">
                        <div className="form-header-sub">
                          <h6>
                            Enter Company Details<span></span>
                          </h6>
                        </div>
                      </div>

                      <Row>
                        <Col md={4}>
                          <div className="mi-image-form-col">
                            <div className="add-user-main-logo-image mb-3">
                              {editClientId && singleClient ? (
                                <img
                                  className="add-user-images"
                                  src={
                                    localImage
                                      ? localImage
                                      : singleClient.logo
                                      ? `${baseURL}/${singleClient.logo}`
                                      : DefaultLogo
                                  }
                                  alt="logo"
                                />
                              ) : (
                                <img
                                  className="add-user-images"
                                  src={localImage ? localImage : DefaultLogo}
                                  alt="Product Images"
                                />
                              )}
                              <div>
                                <button
                                  type="button"
                                  className="btn upload-logo"
                                  onClick={uploadClick}
                                >
                                  <i className="bi bi-camera-fill" />
                                </button>
                                <input
                                  id="getFile"
                                  type="file"
                                  name="filetobase64"
                                  accept="image/*"
                                  className="btn-primary upload"
                                  ref={hiddenFileInput}
                                  onChange={handleChange}
                                  style={{ display: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Company Name"
                              className={`${
                                clientData.clientName || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                className="form-control form add-client-input model-input"
                                id="exampleFormControlInput1"
                                placeholder="Company Name"
                                name="clientName"
                                value={clientData?.clientName || ""}
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </FloatingLabel>
                            <small
                              id={`Error`}
                              style={{
                                visibility: `${
                                  fieldReq && clientData?.clientName === ""
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}{" "}
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </small>
                          </div>

                          <div className="mb-1">
                            <FloatingLabel
                              label="Mobile Number"
                              className={`${
                                clientData.mobile || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                autoComplete="off"
                                className="form-control form add-client-input model-input"
                                id="exampleFormControlInput1"
                                placeholder="Mobile Number"
                                name="mobile"
                                value={clientData?.mobile || ""}
                                maxLength={10}
                                onChange={handleInputChange}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error${alertMessage.alertType}`}
                              style={{
                                visibility: `${
                                  fieldReq &&
                                  Math.abs(clientData?.mobile).toString()
                                    .length !== 10
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                              className="form-text text-muted "
                            >
                              {`${alertMessage.message} - 10 Numbers`}{" "}
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </small>
                          </div>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Landline"
                              className={`${
                                clientData.landline || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="number"
                                autoComplete="off"
                                className="form-control form add-client-input model-input"
                                id="exampleFormControlInput1"
                                placeholder="Landline"
                                name="landline"
                                value={clientData?.landline || ""}
                                onChange={handleInputChange}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error${alertMessage.alertType}`}
                              style={{ visibility: "hidden" }}
                              className="form-text text-muted "
                            ></small>
                          </div>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Select Category"
                              className={`${
                                selectedCategory.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={categoryDropdown}
                                placeholder="Select Category"
                                value={
                                  selectedCategory.value
                                    ? selectedCategory
                                    : categoryDropdown.value
                                }
                                onInputChange={categoryDropdown.label}
                                onChange={(data) =>
                                  handleSelectChange(data, "category")
                                }
                                isSearchable={true}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error${alertMessage.alertType}`}
                              style={{
                                visibility: `${
                                  fieldReq &&
                                  (clientData.categoryID === "" ||
                                    clientData.categoryID === 0)
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}{" "}
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </small>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Contact Person Name"
                              className={`${
                                clientData.contactPersonName || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="text"
                                autoComplete="off"
                                className="form-control form add-client-input model-input"
                                id="exampleFormControlInput1"
                                placeholder="Contact Person Name"
                                name="contactPersonName"
                                value={clientData?.contactPersonName || ""}
                                onChange={handleInputChange}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                              style={{
                                visibility: `${
                                  fieldReq &&
                                  clientData?.contactPersonName === ""
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                            >
                              {alertMessage.message}{" "}
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </small>
                          </div>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Email"
                              className={`${
                                clientData.email || ""
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="email"
                                autoComplete="off"
                                className="form-control form add-client-input model-input"
                                id="exampleFormControlInput1"
                                placeholder="Email"
                                name="email"
                                value={clientData?.email || ""}
                                onChange={handleInputChange}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error`}
                              className="form-text text-muted "
                              style={{
                                visibility: `${
                                  fieldReq && clientData?.email === ""
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                            >
                              {alertMessage.message}{" "}
                              <i className="fa fa-exclamation-circle" />
                            </small>
                          </div>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Select Stage"
                              className={`${
                                selectedStage.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={stageDropdown}
                                placeholder="Select Stage"
                                value={
                                  selectedStage.value
                                    ? selectedStage
                                    : stageDropdown.value
                                }
                                onInputChange={stageDropdown.label}
                                onChange={(data) =>
                                  handleSelectChange(data, "stage")
                                }
                                isSearchable={true}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error${alertMessage.alertType}`}
                              style={{
                                visibility: `${
                                  fieldReq &&
                                  (clientData.stageID === "" ||
                                    clientData.stageID === 0)
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}{" "}
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </small>
                          </div>
                          <div className="mb-2">
                            <FloatingLabel
                              label="Select Company"
                              className={`${
                                selectedOptions.length !== 0
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <MultiSelect
                                options={formattedClientDropdown}
                                value={selectedOptions}
                                onChange={handleSelectOption}
                                labelledBy={"Select"}
                                overrideStrings={{
                                  selectSomeItems: "Select Company",
                                }}
                              />
                            </FloatingLabel>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Select Country"
                              className={`${
                                country.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={countryList}
                                placeholder="Select Country"
                                value={
                                  country.value ? country : countryList.value
                                }
                                onInputChange={countryList.label}
                                onChange={(data) =>
                                  handleSelectChange(data, "country")
                                }
                                isSearchable={true}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error${alertMessage.alertType}`}
                              style={{
                                visibility: `${
                                  fieldReq && clientData.countryID === ""
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}{" "}
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </small>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Select Zone"
                              className={`${
                                zoneId.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={zoneList}
                                placeholder="Select Zone"
                                value={zoneId.value ? zoneId : zoneList.value}
                                onInputChange={zoneList.label}
                                onChange={(data) =>
                                  handleSelectChange(data, "zone")
                                }
                                isSearchable={true}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error${alertMessage.alertType}`}
                              style={{
                                visibility: `${
                                  fieldReq && clientData.zoneID === ""
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}{" "}
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </small>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Select State"
                              className={`${
                                stateId.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={stateList}
                                placeholder="Select State"
                                value={stateId.value && stateId}
                                onInputChange={stateList.label}
                                onChange={(data) =>
                                  handleSelectChange(data, "state")
                                }
                                isSearchable={true}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error${alertMessage.alertType}`}
                              style={{
                                visibility: `${
                                  fieldReq && clientData.stateID === ""
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}{" "}
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </small>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={4}>
                          <div className="mb-1">
                            <FloatingLabel
                              label="Select City"
                              className={`${
                                cityId.value
                                  ? "float-visible-select"
                                  : "float-hidden float-select"
                              }`}
                            >
                              <Select
                                className="react-select-container-list model-select"
                                classNamePrefix="react-select-list"
                                options={cityList}
                                placeholder="Select City"
                                value={cityId.value && cityId}
                                onInputChange={cityList.label}
                                onChange={(data) =>
                                  handleSelectChange(data, "city")
                                }
                                isSearchable={true}
                                isDisabled={!stateId && true}
                              />
                            </FloatingLabel>
                            <small
                              id={`Error${alertMessage.alertType}`}
                              style={{
                                visibility: `${
                                  fieldReq && cityId.value === 0
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                              className="form-text text-muted "
                            >
                              {alertMessage.message}{" "}
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </small>
                          </div>
                          <div className={`${editClientId ? "mb-4" : "mb-2"}`}>
                            <FloatingLabel
                              label="PinCode"
                              className={`${
                                clientData.pincode
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <input
                                type="number"
                                autoComplete="off"
                                className="form-control form add-client-input model-input"
                                id="exampleFormControlInput1"
                                placeholder="PinCode"
                                name="pincode"
                                value={clientData?.pincode}
                                onChange={handleInputChange}
                              />
                            </FloatingLabel>
                          </div>
                          <div>
                            {editClientId && (
                              <div className="enable-status d-flex">
                                <h6>Enable</h6>
                                <div className="enable-radio d-flex">
                                  <div className="form-check enable-radio-status">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="enableDisable"
                                      id="enableDisable1"
                                      checked={statusFlag}
                                      onChange={() => habdleEnableStatus(1)}
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check enable-radio-status">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="enableDisable"
                                      id="enableDisable2"
                                      checked={!statusFlag}
                                      onChange={() => habdleEnableStatus(0)}
                                    />
                                    <label className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="client-location-by-map">
                            <FloatingLabel
                              label="Street Address/House No./Locality"
                              className={`${
                                location
                                  ? "float-input-visible"
                                  : "float-hidden float-input"
                              }`}
                            >
                              <textarea
                                rows="4"
                                cols="20"
                                name="usrtxt"
                                wrap="hard"
                                className="form-control form-textarea add-user-input"
                                id="exampleFormControlInput1"
                                placeholder="Please click location icon for address"
                                value={location}
                                readOnly={true}
                                onChange={(e) => setLocation(e)}
                              />
                            </FloatingLabel>
                            <i
                              className="bi bi-geo-alt"
                              title="Google Location"
                              onClick={openGoogleMaps}
                            />
                          </div>
                          <small
                            id={`Error${alertMessage.alertType}`}
                            style={{
                              visibility: `${
                                fieldReq && location === ""
                                  ? "visible"
                                  : "hidden"
                              }`,
                            }}
                            className="form-text text-muted "
                          >
                            {alertMessage.message}{" "}
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                            />
                          </small>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {selectNavKey === "Custom Fields" && (
                    <div>
                      <div className="mi-subform-header">
                        <div className="form-header-sub">
                          <h6>
                            Add Custom Fields<span></span>
                          </h6>
                        </div>
                      </div>
                      <CustomFieldsForCompany
                        customFields={customFields}
                        setCustomFields={setCustomFields}
                        addClientDetails={addClientDetails}
                        fieldValues={fieldValues}
                        setFieldValues={setFieldValues}
                        setCustomeLocalImage={setCustomeLocalImage}
                      />
                    </div>
                  )}
                </div>
                <div className="add-form-save-cancel">
                  {selectNavKey !== "Custom Fields" ? (
                    <SBSaveUpdateBtn
                      btnName="Next"
                      onClickEffect={(e) => moveToNextTab(e, selectNavKey)}
                    />
                  ) : (
                    <SBSaveUpdateBtn
                      btnName={editClientId ? "Update" : "Save"}
                    />
                  )}
                  <SbCancelBtn
                    onClickEffect={(e) => moveToPreviousTab(e, selectNavKey)}
                    btnName="Previous"
                  />
                </div>
              </form>
            </div>

            {googleMapsModel && (
              <GoogleMapAddressModel
                googleMapsModel={googleMapsModel}
                openGoogleMaps={openGoogleMaps}
                latLan={latLan}
                setLatLan={setLatLan}
                address={address}
                setLocation={setLocation}
              />
            )}
          </div>
        </>
      }
    />
  );
};

export default AddClientData;
