import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SkeletonMeetPurpose } from "../Skeleton Loading/SkeletonMeetPurpose";
import { FormattedAmount } from "../FormattedAmount";
import { useDispatch, useSelector } from "react-redux";
import { loadPassbookSummeryDataByProfile } from "../../redux/action";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useReactToPrint } from "react-to-print";

function PassBookSummeryTable(props) {
  let tableHeader = [
    { name: "TRANSACTION DETAILS", align: "left", width: "200px" },
    { name: "ENTRY TYPE", align: "left", width: "150px" },
    { name: "EARNED", align: "left", width: "130px" },
    { name: "PAID", align: "left", width: "130px" },
    { name: "CLOSING BALANCE", align: "left", width: "130px" },
  ];

  let dispatch = useDispatch();
  const { userPassbookSummeryById } = useSelector((state) => state.data);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (props.selectedUser.value) {
      dispatch(loadPassbookSummeryDataByProfile(props.selectedUser.value, pageSize, currentPage, setIsLoading));
    }
  }, [props.selectedUser])

  const componentRef = useRef();
  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="basefare-table"
              ref={componentRef}
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {tableHeader.map((name, i) => (
                    <TableCell
                      key={i}
                      align={name.align}
                      className={`thead-cell-products`}
                      style={{ minWidth: name.width }}
                    >
                      {name.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <>
                  {tableHeader.map((name, i) => (
                    <SkeletonMeetPurpose key={i} />
                  ))}
                </>
              ) : (
                <>
                  {userPassbookSummeryById &&
                    userPassbookSummeryById.length === 0 ? (
                    <TableBody>
                      <TableRow align="center">
                        <TableCell align="center" colSpan={tableHeader.length}>
                          <div style={{ padding: "20px 20px" }}>
                            <img
                              src="../../../../images/DefaultImages/NodataFound.jpg"
                              alt=""
                              style={{ width: "10%" }}
                            />
                            <br />
                            <span>No Data Found...!</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {userPassbookSummeryById
                        .filter(data => data.earned || data.paid !== 0)
                        .map((data, index) => (
                          <TableRow
                            align="center"
                            key={index}
                            className="client-list-row-data"
                          >
                            <TableCell>
                              <div className="table-row-head-Text">
                                {data.transactionDate}
                              </div>
                              <div className="table-row-head-Text">
                                {data.bankTransactionID ? data.bankTransactionID : "-"}
                              </div>
                              <div className="table-row-sub-text">
                                {data.transactionType}
                              </div>
                            </TableCell>
                            <TableCell>
                              {data.entryType}
                            </TableCell>
                            <TableCell>
                              <div className="table-row-sub-text">
                                <span className='green-Color'>
                                  <i className="fa fa-inr" aria-hidden="true"></i>{FormattedAmount(data.earned)}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="table-row-sub-text">
                                <span className='color-secondary'>
                                  <i className="fa fa-inr" aria-hidden="true"></i>{FormattedAmount(data.paid)}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="table-row-sub-text">
                                <span className='primary-color'>
                                  <i className="fa fa-inr" aria-hidden="true"></i>{FormattedAmount(data.closingBalance)}
                                </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div >
      <div className="download-and-pagination">
        <SbDownloadBtn btnName="Download" onClickEffect={pdfDownload} />
      </div>
    </>
  );
}

export default PassBookSummeryTable;
